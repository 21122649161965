import { Messages } from "./messages";
import { Patterns } from "./patterns";

export const vsmProfile = {
	success: "Password changed successfully.",
	validation: {
		old_password: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 3,
				message: Messages.PASSWORD_3_LENGTH,
			},
			{
				max: 50,
				message: Messages.PASSWORD_3_LENGTH,
			},
			{
				pattern: Patterns.PASSWORD,
				message: Messages.PASSWORD_INVALID_CHARACTERS,
			},
		],
		new_password: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 8,
				message: Messages.PASSWORD_LENGTH,
			},
			{
				max: 20,
				message: Messages.PASSWORD_LENGTH,
			},
			{
				pattern: Patterns.PASSWORD,
				message: Messages.PASSWORD_INVALID_CHARACTERS,
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					const password = getFieldValue("password")

					if (password !== getFieldValue("new_password")) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.PASSWORD_SAME_ERROR);
				},
			}),

		],
		confirm_password: [
			{ required: true, message: Messages.REQUIRED },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue("new_password") === value) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.PASSWORD_NOT_MATCH);
				},
			}),
		],
	}
};