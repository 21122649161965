import React, { useState } from "react";
import { Form, Button, Modal, Col, Row, Radio, Divider } from "antd";
import { observer } from "mobx-react";
import useStore from "./../store";
import { vsmNotify, vsmStickerPreference } from "../config/messages/common";
import { Messages } from "../config/messages/messages";
import { debounce } from "@syncfusion/ej2-base";
import InputComponent from "./InputComponent";

const StickerPreferenceComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		AUTH: { AllocateOldStickers },
		AUTH
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [spFlagValue, setSpFlagValue] = useState(false);
	const [spFlagCashValue, setSpcashFlagValue] = useState(false);
	const [spFlagAcchValue, setSpAcchFlagValue] = useState(false);
	const [spFlagCashcheck, setSpFlagCashCheck] = useState(false);
	const [spFlagAcccheck, setSpFlagAccCheck] = useState(false);

	const handleChange = debounce((values, e) => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
				if (form.getFieldValue('sp_flag') === 1 && !spFlagCashValue && !spFlagAcchValue) {
					setDisabled(true);
				}
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	// Make function call to delete existing record
	const handleSubmit = (data) => {
		setSaving(true);
		data.center_id = AUTH.user.default_location
		data.sp_flag_cash = (spFlagCashcheck === true) ? 1 : 0;
		data.sp_flag_acc = (spFlagAcccheck === true) ? 1 : 0;
		if (data.sp_flag === 0) {
			data.sp_flag_cash = 0;
			data.sp_flag_acc = 0;
		}
		delete data.sp_flag;
		AllocateOldStickers(data)
			.then((data) => {
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => {
				setSaving(false);
			});
	};

	const bookingStickerOption = [
		{ value: 0, name: "No, I don't have any booking sticker" },
		{ value: 1, name: "Yes, I do have booking stickers" },
	]

	const handleSpCashChange = () => {
		form.setFieldsValue({
			cash_from_barcode: null,
			cash_to_barcode: null,
		});
	};

	const handleSpAccChange = () => {
		form.setFieldsValue({
			acc_from_barcode: null,
			acc_to_barcode: null,
		});
	};

	// reset form and close add form
	const close = () => {
		close();
		setSpFlagCashCheck(false)
		setSpFlagAccCheck(false)
	};

	return (
		<Modal
			centered
			className="addModal StickerPreferenceModal"
			title={"Sticker Preference"}
			visible={props.visible}
			closeIcon={false}
			onCancel={props.close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="1"
					form="StickerPreferenceForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					disabled={disabled}
				>
					Continue
				</Button>
			]}
		>
			<Form form={form} id="StickerPreferenceForm" onFinish={handleSubmit} className="Sticker_Preference_Form" labelCol={{ span: 24 }}
				onChange={handleChange}>
				<Row gutter={30} className="mt-10">
					<Col xs={{ span: 24 }} style={{ marginBottom: "5px" }}>
						<label><span style={{ color: '#ff4d4f', paddingRight: "5px" }}>*</span>Please choose any of the option below</label>
					</Col>
					<Col xs={{ span: 24 }} style={{ marginBottom: "10px" }}>
						<Form.Item name="sp_flag" rules={[
							{ required: true, message: Messages.NOT_SELECTED }
						]}>
							<Radio.Group
								options={bookingStickerOption.map(column => ({ value: column.value, label: column.name, }))}
								onChange={(e) => {
									handleChange(e)
									if (e.target.value === 1) {
										setSpFlagValue(true)
									}
									else {
										setSpFlagValue(false)
									}
								}}
							>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
				{spFlagValue &&
					<>
						<Divider className="mt-0 mb-10" />
						<Row gutter={30}>
							<Col xs={{ span: 24 }} className="custom_checkbox_design">
								<InputComponent
									type="checkbox"
									name="sp_flag_cash"
									label="Yes, I do have cash booking stickers"
									checked={spFlagCashcheck}
									onChange={(e) => {
										setSpFlagCashCheck(e.target.checked)
										handleChange(e)
										handleSpCashChange()
										if (e.target.checked === true) {
											setSpcashFlagValue(true)
										}
										else {
											setSpcashFlagValue(false)
										}
									}}
								/>
							</Col>
						</Row>
						<Row gutter={15}>
							<Col xs={{ span: 12 }}>
								<InputComponent
									type="text"
									required={spFlagCashValue}
									disabled={!spFlagCashValue}
									label="From Barcode"
									placeholder="From Barcode"
									name="cash_from_barcode"
									rules={spFlagCashValue ? vsmStickerPreference.validation.cash_from_barcode : [{ require: false }]}
									onChange={(e) => {
										handleChange()
									}}
								/>
							</Col>
							<Col xs={{ span: 12 }}>
								<InputComponent
									type="text"
									required={spFlagCashValue}
									disabled={!spFlagCashValue}
									label="To Barcode"
									placeholder="To Barcode"
									name="cash_to_barcode"
									rules={spFlagCashValue ? vsmStickerPreference.validation.cash_to_barcode : [{ require: false }]}
									onChange={(e) => {
										handleChange(e)
									}}
								/>
							</Col>
						</Row>
						<Row gutter={30}>
							<Col xs={{ span: 24 }} className="custom_checkbox_design">
								<InputComponent
									type="checkbox"
									name="sp_flag_acc"
									label="Yes, I do have account booking stickers"
									value="2"
									onChange={(e) => {
										setSpFlagAccCheck(e.target.checked)
										handleSpAccChange()
										handleChange(e)
										if (e.target.checked === true) {
											setSpAcchFlagValue(true)
										}
										else {
											setSpAcchFlagValue(false)
										}
									}}
								/>
							</Col>
						</Row>
						<Row gutter={15}>
							<Col xs={{ span: 12 }}>
								<InputComponent
									type="text"
									required={spFlagAcchValue}
									disabled={!spFlagAcchValue}
									label="From Barcode"
									placeholder="From Barcode"
									name="acc_from_barcode"
									rules={spFlagAcchValue ? vsmStickerPreference.validation.acc_from_barcode : [{ require: false }]}
									onChange={(e) => {
										handleChange(e)
									}}
								/>
							</Col>
							<Col xs={{ span: 12 }}>
								<InputComponent
									type="text"
									required={spFlagAcchValue}
									disabled={!spFlagAcchValue}
									label="To Barcode"
									placeholder="To Barcode"
									name="acc_to_barcode"
									rules={spFlagAcchValue ? vsmStickerPreference.validation.acc_to_barcode : [{ require: false }]}
									onChange={(e) => {
										handleChange(e)
									}}
								/>
							</Col>
						</Row></>}
			</Form>
		</Modal>
	);
});

export default StickerPreferenceComponent;