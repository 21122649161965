/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { b64toBlob, convertTextToID, convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";
import { Constant } from "../../../../config/Constant";

export default class ManageExpressStickerOrderStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	deleteValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	remainStickerPayment = null;
	remainStickerPaymentError = "";
	stockRates = null;
	paymentStatus = null;
	paymentDetails = null;
	listPurpose = 1;
	listPurposeNew = 5;
	orderConfirmationValues = null;
	dropdown_order_status_list = null;
	dropdown_payment_status_list = null;
	duplicateValues = null;
	listProd_id = null;

	setListProdId = (data) => {
		this.listProd_id = data
	}

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}
	setOrderConfirmationValue = (data) => {
		this.orderConfirmationValues = data
	}
	setRemainStickerPayment = (status) => {
		this.remainStickerPayment = status;
	}
	setListPurposeNew = (purpose) => {
		this.listPurposeNew = purpose;
	};
	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};

	setPaymentDetails = (data) => {
		this.paymentDetails = data;
		return true;
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	//set list purpose
	setListPurpose = (purpose) => {
		this.listPurpose = purpose;
		this.agGrid.api.setFilterModel(null)
		this.agGrid.api.setSortModel(null)
		this.agGrid.columnApi.resetColumnState()
		this.agGrid.api.onFilterChanged(null)
		this.refreshList();
	};

	setOrderStatusList = (data) => {
		this.dropdown_order_status_list = data;
	};

	setPaymentStatusList = (data) => {
		this.dropdown_payment_status_list = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};
	//set form values to barcode
	setDuplicateValues = (data) => {
		this.duplicateValues = data
	}

	// call api to get records
	getList = (payload) => {
		return Axios.post(`stickers/sticker-orders/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					//     item.cities = item.cities && item.cities.name
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.dropdown_order_status_list, 'name', 'id')
		}
		if (final_filter["payment_status"]) {
			final_filter['payment_status'].values = convertTextToID(final_filter['payment_status'], this.dropdown_payment_status_list, 'name', 'id')
		}
		final_sort && final_sort.map((item) => {
			switch (item.colId) {
				case 'status':
					return item.colId = 'lov_stk_ord_status.name ';
				default:
					return null
			}

		})
		return { final_filter, final_sort };
	};
	invoiceData = (ID) => {
		return Axios.get(`/stickers/sticker-orders/invoice-print/${ID}`).then(({ data }) => {
			const file = b64toBlob(data.print, "application/pdf");
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			window.open(fileURL);
			//pdfWindow.location.href = fileURL
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}
	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = this?.listProd_id === Constant.STICKERS_FOR_PACKETS ? {
					purpose: this.listPurpose,
					prod_id: this.listProd_id,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					is_location: Constant.IS_LOCATION,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				} :
					{
						purpose: this.listPurpose,
						prod_id: this.listProd_id,
						filter_data: filter_data.final_filter,
						sort_data: filter_data.final_sort,
						per_page: params.request.endRow - params.request.startRow,
						page: Math.ceil(
							(params.request.startRow + 1) /
							(params.request.endRow - params.request.startRow)
						),
					};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	// Call add api
	ReOrder = (formdata) => {
		return Axios.post(`stickers/sticker-orders/reorder/${formdata.id}`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};


	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`stickers/sticker-orders/details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setEditValues(setData);
			return data;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`stickers/sticker-orders/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call sticker order api
	StickerOrderData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/confirm`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call sticker cancellation api
	CancellationData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/cancellation`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call sticker order api
	GetStickerOrderData = (ID) => {
		return Axios.get(`stickers/sticker-orders/get-barcode-series/${ID}`)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call print sticker api
	PrintStickerData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/print-sticker`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call dispatch sticker api
	DispatchStickerData =(formdata) => {
		return Axios.post(`stickers/sticker-orders/dispatch-sticker/${formdata.id}`,formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call print sticker api
	RefundedData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/refunded`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	// Call payment confirmation api
	PaymentConfirmationData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/payment-confirmation`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	AddPaymentData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/payment-new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	ViewPaymentData = (ID) => {
		return Axios.get(`account/payment/details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.paymentDetails) {
				let oldval = this.paymentDetails;
				setData = { ...oldval, ...data.view };
			}
			this.setPaymentDetails(setData);
			return data;
		});
	};

	EditPaymentData = (id, formdata) => {
		return Axios.post(`stickers/sticker-orders/payment-edit/${id}`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get List of center for dropdown
	getPaymentStaus = (conditional = {}) => {
		return Axios.post(`stickers/sticker-orders/get-order-status`, conditional)
			.then(({ data }) => {
				if (data?.order_status&&data?.order_status.error_msg) {
					this.remainStickerPaymentError = data?.order_status?.error_msg;
				}else if (data.order_status&&!data?.order_status.error_msg) {
					this.remainStickerPayment = data.order_status?.payment_status;
					switch (data.order_status?.payment_status) {
						case 10:
							this.remainStickerPaymentError = `The payment for the ${data.order_status.code} "${(data.order_status.mst_product) ? data.order_status.mst_product.name : ''}" order is pending, please resolve the outstanding`
							break;
						case 20:
							this.remainStickerPaymentError = `The ${data.order_status.code} "${(data.order_status.mst_product) ? data.order_status.mst_product.name : ''}" order was confirmed without payment verification, please ask HO to complete the verification process`
							break;
						case 30:
							this.remainStickerPaymentError = `Payment for the ${data.order_status.code} "${(data.order_status.mst_product) ? data.order_status.mst_product.name : ''}" order was failed, please resolve the outstanding`
							break;
						default:
							this.remainStickerPaymentError = ""
							this.remainStickerPayment = null;
							break;
					}
				}else {
					this.remainStickerPaymentError = ""
					this.remainStickerPayment = null;
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of center for dropdown
	getStockRate = (conditional = {}) => {
		return Axios.post(`stickers/sticker-orders/get-stock-rate`, conditional)
			.then(({ data }) => {
				this.stockRates = data.view.rates
				return data.view;
			})
			.catch((response) => {
				this.stockRates = null;
				return Promise.reject(response);
			});
	};

	// Call get barcode series api
	GetBarcodeSeriesData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/get-barcode-series`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call change label api
	ChangeLabelData = (formdata) => {
		return Axios.post(`stickers/sticker-orders/change-label`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call get Payment Status api
	GetPaymentStatusData = (ID) => {
		return Axios.post(`stickers/sticker-orders/get-payment-status/${ID}`)
			.then(({ data }) => {
				this.paymentStatus = data.view;
				return data;
			})
			.catch((response) => {
				this.paymentStatus = null;
				return Promise.reject(response);
			});
	};
}

decorate(ManageExpressStickerOrderStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	statusValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_center_list: observable,
	setPaymentDetails: action,
	paymentDetails: observable,
	typeMode: observable,
	setTypeMode: action,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setCompletePendingFilterList: action,
	completePendingFilterList: observable,
	setRemainStickerPayment: action,
	remainStickerPayment: observable,
	remainStickerPaymentError: observable,
	getStockRate: action,
	stockRates: observable,
	paymentStatus: observable,
	setListPurpose: action,
	listPurpose: observable,
	setOrderConfirmationValue: action,
	orderConfirmationValues: observable,
	GetStickerOrderData: action,
	dropdown_order_status_list: observable,
	dropdown_payment_status_list: observable,
	setListPurposeNew: action,
	listPurposeNew: observable,
	duplicateValues: observable,
	setDuplicateValues: action,
	GetPaymentStatusData: action
});
