import React from "react";
import { Button, Modal, Col, Row } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../config/Constant";
import useStore from "../store";

const PopupComponent = observer((props) => {
  const {AUTH} = useStore()
  const { visible, onClose , onViewOutstanding } = props;
  return (
    <>
      <Modal
        centered
        className={props.className ? props.className : "statusModal"}
        title={props.modalTitle}
        visible={visible}
        onCancel={onClose}
        closeIcon={<FontAwesomeIcon icon={faTimes} />}
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        zIndex={1001}
        width={700}
        footer={[
          <Button
            key="2"
            htmlType="button"
            className="cancelBtn mr-35"
            onClick={onClose}
          >
            Close
			</Button>,
          AUTH.communicationData.popup_message.map(
            (modal) =>
              modal.outstanding_popup === 1 && (
                <Button
                  key="1"
                  htmlType="submit"
                  type="primary"
                  onClick={onViewOutstanding}
                >
                  View Outstanding
                </Button>
              )
          ),
        ]} 
        //footer={false}
      >
        <Row align="middle">
          <Col span={24} className="text-center popup-msg-custom">
            <div dangerouslySetInnerHTML={{ __html: props.modalContent }} />
            <img className="mt-15 w-100" src={Constant.S3BUCKET_API_BASEURL + props.modalImage} alt="" />
          </Col>
        </Row>
      </Modal>
    </>
  );
});
export default PopupComponent;
