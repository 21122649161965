import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Drawer, Divider, Image as PreviewImage, Alert } from "antd";
import { observer } from "mobx-react";
import { vsmNotify } from "../../../../../config/messages/common";
import Modal from "antd/lib/modal/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faTimes } from "@fortawesome/free-solid-svg-icons";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Constant } from "../../../../../config/Constant";
import MultipleUploadComponent from "../../../../../component/MultipleUploadComponent";
import useStore from "../../../../../store";
import imageCompression from "browser-image-compression";
import DefaulHeader from "./DefaulHeader";
import { Messages } from "../../../../../config/messages/messages";
import { replaceString } from "../../../../../utils/GlobalFunction";

const KycFormComponent = observer((props) => {
	// const { form, kycFormDetails, editAgencyId, deleteKYCData, uploadKYCData } = props;
	const [form] = Form.useForm();
	const {
		ManageCentersStore: { finishKycData, kycFormDetails, deleteKYCData, uploadKYCData, editValues, typeMode },
		AUTH
	} = useStore();

	const [deleteModal, setDeleteModal] = useState(false);
	const [kycFileList, updateFileList] = useState([]);
	const [docId, setDocId] = useState();
	const [saving, setSaving] = useState();
	const [btnDisabled, setBtnDisabled] = useState(true);
	const [btnTerm, setBtnTerm] = useState(false);

	useEffect(() => {
		let allFileList = [];
		kycFormDetails && kycFormDetails.length > 0 && kycFormDetails.map((item) => {
			allFileList[item.key] = [];
			item.docs && item.docs.length > 0 && item.docs.map((innerItem) => {
				let fileObject = {
					uid: `-${innerItem.id}`,
					name: (innerItem.doc_file) ? innerItem.doc_file.substring(innerItem.doc_file.lastIndexOf('/') + 1) : null,
					status: "done",
					url: Constant.S3BUCKET_API_BASEURL + innerItem.thumb_file,
					fullimage: Constant.S3BUCKET_API_BASEURL + innerItem.doc_file,
				};
				allFileList[item.key].push(fileObject);
				return allFileList;
			});
			return allFileList;
		});
		updateFileList([allFileList]);
	}, [kycFormDetails, updateFileList, form]);


	const onRemoveImage = (event, key) => {
		let docName = kycFormDetails.find((i) => i.key === key);
		setDocId({ doc_id: event.uid, key: key, name: docName.name });
		setDeleteModal(true);
	};

	const deleteKycDocument = () => {
		setSaving(true);
		let id = docId.doc_id.replace("-", "");
		let key = docId.key;
		let formdata = { cr_id: editValues.id, doc_id: id }
		deleteKYCData(formdata)
			.then((data) => {
				close();
				setSaving(false);
				let allfiles = kycFileList[0];
				let index = allfiles[key].findIndex((item) => item.uid === docId.doc_id);
				if (allfiles[key] && index !== -1) {
					allfiles[key] = allfiles[key].filter((item) => item.uid !== docId.doc_id);
					updateFileList([allfiles]);
				}
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			});
	}

	const customUpload = ({ onError, onSuccess, file }, type_id, key) => {
		let option = {
			useWebWorker: true,
			initialQuality: Constant.IMAGEQUALITY,
			maxWidthOrHeight: Constant.MAXWIDTHORHEIGHT,
		};
		if (file.type !== "application/pdf" && (file.size / 1045504) > 25) {
			vsmNotify.error({
				message: replaceString(Messages.IMAGE_SIZE_VALIDATION, "#SIZE#", "25 MB")
			});
		} else if (file.type === "application/pdf" && (file.size / 1045504) > 1) {
			vsmNotify.error({
				message: replaceString(Messages.IMAGE_SIZE_VALIDATION, "#SIZE#", "1 MB")
			});
		} else if (file.type !== "application/pdf") {
			imageCompression(file, option).then((result) => {
				if ((result.size / 1045504) <= Constant.COMPRESS_SIZE) {
					uploadImage(type_id, key, result, onError, onSuccess)
				} else {
					vsmNotify.error({
						message: Messages.COMPRESS_ERROR
					});
				}
				return result;
			}).catch((error) => {
				vsmNotify.error({
					message: error.message
				});
			});
		} else {
			uploadImage(type_id, key, file, onError, onSuccess);
		}
	}

	const uploadImage = (type_id, key, file, onError, onSuccess) => {
		const formData = new FormData();
		formData.append('doc_file', file);
		formData.append('id', editValues.id);
		formData.append('type_id', type_id);
		formData.append('name', key);

		uploadKYCData(formData)
			.then((data) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
				let fileObject = {
					uid: `-${data.doc.id}`,
					name: (data.doc.doc_file) ? data.doc.doc_file.substring(data.doc.doc_file.lastIndexOf('/') + 1) : null,
					status: "done",
					url: Constant.S3BUCKET_API_BASEURL + data.doc.thumb_file,
					fullimage: Constant.S3BUCKET_API_BASEURL + data.doc.doc_file,
				};
				let allfiles = kycFileList[0];
				allfiles[data.doc.key].push(fileObject);
				updateFileList([allfiles]);
				onSuccess("ok");
			})
			.catch((e) => {
				if (e.errors && e.errors.length > 0) {
					let errorMessage = e.errors[0].errors.join(", ");
					vsmNotify.error({
						message: errorMessage,
					});
				}
				onError(e);
			});
	}

	const uploadCameraCapture = (file, type_id, key) => {
		let option = {
			useWebWorker: true,
			initialQuality: Constant.IMAGEQUALITY,
			maxWidthOrHeight: Constant.MAXWIDTHORHEIGHT,
		};
		imageCompression(file, option).then((result) => {
			if ((result.size / 1045504) <= Constant.COMPRESS_SIZE) {
				const formData = new FormData();
				formData.append('doc_file', file);
				formData.append('id', editValues.id);
				formData.append('type_id', type_id);
				formData.append('name', key);

				uploadKYCData(formData)
					.then((data) => {
						vsmNotify.success({
							message: data.STATUS.NOTIFICATION[0],
						});
						let fileObject = {
							uid: `-${data.doc.id}`,
							name: (data.doc.doc_file) ? data.doc.doc_file.substring(data.doc.doc_file.lastIndexOf('/') + 1) : null,
							status: "done",
							url: Constant.S3BUCKET_API_BASEURL + data.doc.thumb_file,
							fullimage: Constant.S3BUCKET_API_BASEURL + data.doc.doc_file,
						};
						let allfiles = kycFileList[0];
						allfiles[data.doc.key].push(fileObject);
						updateFileList([allfiles]);
					})
					.catch((e) => {
						if (e.errors && e.errors.length > 0) {
							let errorMessage = e.errors[0].errors.join(", ");
							vsmNotify.error({
								message: errorMessage,
							});
						}
					});
			} else {
				vsmNotify.error({
					message: Messages.COMPRESS_ERROR
				});
			}
			return result;
		}).catch((error) => {
			vsmNotify.error({
				message: error.message
			});
		});
	}

	// reset form and close add form
	const close = (submitValue) => {
		if (submitValue === "submit") {
			props.close();
		} else if (submitValue === "submitNext") {
			props.closeNext();
		}
		setBtnTerm(false);
		setBtnDisabled(true);
		setDeleteModal(false);
	};

	// Handle submit and call function to save new record
	const handleSubmitKYC = (formSubmitValue) => {
		setSaving(true);
		finishKycData(editValues.id)
			.then((data) => {
				close(formSubmitValue);
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};


	return (
		<Drawer
			className="editModal"
			title="Location KYC"
			width="70%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={() => { close("submit") }}
			footer={[
				(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="1"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={() => { close("submitNext") }}
				>
					Skip
				</Button>),
				(typeMode !== Constant.VIEW && <Button
					key="2"
					loading={saving}
					htmlType="button"
					type="primary"
					className="mr-35"
					onClick={() => { handleSubmitKYC("submit"); }}
					disabled={btnDisabled}
				>
					Finish KYC
				</Button>),
				(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="3"
					loading={saving}
					htmlType="button"
					type="primary"
					onClick={() => { handleSubmitKYC("submitNext"); }}
					disabled={btnDisabled}
				>
					Finish KYC & Continue
				</Button>),
				(typeMode === Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="4"
					type="primary"
					onClick={() => {
						close("submitNext");
					}}
				>
					Continue
				</Button>)
			]}
		>
			{props.showErrorNotes && props.visible && editValues && <Alert className="mb-25"
				type="error"
				showIcon
				message={
					editValues.is_terminated ? `This location is terminated so you cannot make any changes` : (!editValues.is_active) ? `This location is inactive so you cannot make any changes` : null
				}
			/>}
			<DefaulHeader />
			<Divider className="mt-0" />
			{kycFormDetails && kycFormDetails.length > 0 && kycFormDetails.map((field, index) =>
				<Row gutter={30} key={index}>
					<Col xs={{ span: 24 }}>
						{typeMode === Constant.VIEW &&
							<div className="kycFileUpload kycFileUploadView">
								{(kycFileList && kycFileList[0] && kycFileList[0][field.key] && kycFileList[0][field.key]?.length > 0) ?
									(
										<>
											<label key={index}>{field.name}</label>
											{
												kycFileList[0][field.key].map((item, i) => {
													if (item.fullimage.includes(".pdf") || item.fullimage.includes(".PDF")) {
														return <a href={item.fullimage} target="_blank" rel="noreferrer"><img height={75} alt={item.url} src={item.url} /></a>
													} else {
														return <PreviewImage width={100} src={item.fullimage} key={i} />
													}
												})
											}
										</>)
									: <><label key={index}>{field.name}</label><p>File not uploaded</p></>
								}
							</div>
						}
						{typeMode !== Constant.VIEW &&
							<MultipleUploadComponent
								name={field.name}
								identifier={field.key}
								id={field.id}
								is_mandatory={field.is_mandatory}
								max_uploads={field.max_uploads}
								fileList={kycFileList}
								allowType=".pdf,.png,.jpeg,.jpg"
								type='pdf'
								onRemoveImage={onRemoveImage}
								customUpload={customUpload}
								uploadCameraCapture={uploadCameraCapture}
							/>
						}
					</Col>
				</Row>
			)}
			{typeMode !== Constant.VIEW && <Row gutter={30} className="mt-20">
				<Col xs={{ span: 24 }}>
					<span className="proxy-notes"><strong>* Note:</strong></span>
					<ul className="help_text">
						<li>Image size should not exceed 25 MB</li>
						<li>PDF size should not exceed 1MB</li>
						<li>Valid formats are JPEG, JPG,PNG and PDF</li>
						<li>Compress image size should not exceed 1 MB</li>
					</ul>
				</Col>
				<Col xs={{ span: 24 }}>
					<Checkbox className="small-check" checked={btnTerm} onClick={(e) => {
						setBtnTerm(e.target.checked)
						if (e.target.checked) {
							setBtnDisabled(false);
						} else {
							setBtnDisabled(true);
						}
					}}>I agree, uploaded documents are original for KYC.</Checkbox>
				</Col>
			</Row>
			}
			<Modal
				centered
				className="deleteModal"
				title="Delete KYC document?"
				visible={deleteModal}
				closeIcon={<FontAwesomeIcon icon={faTimes} />}
				onCancel={close}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				footer={[
					<Button
						key="2"
						htmlType="button"
						className="cancelBtn mr-35"
						onClick={close}
					>
						Cancel
					</Button>,
					<Button
						key="1"
						loading={saving}
						htmlType="button"
						onClick={deleteKycDocument}
						type="primary"
						danger
					>
						Delete
					</Button>,
				]}
			>
				<Row align="middle">
					<Col span={24} className="text-center">
						<h3>
							<span className="danger_color"><FontAwesomeIcon className="" icon={faExclamationTriangle} /></span> Would you like to delete <span>{docId?.name}</span>?
						</h3>
					</Col>
				</Row>
			</Modal>
		</Drawer>
	);
});
export default KycFormComponent;