import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class ManageStaffAttendanceStore {
  list_data = [];

  // call api to get records
  getList = (payload) => {
    return Axios.post(`/bookings/staff-attendance/list`, payload).then(
      ({ data }) => {
        this.list_data = data.list ? data.list : null;
        return data;
      }
    );
  };

  // Call add api
  EditData = (formdata) => {
    return Axios.post(`bookings/staff-attendance/edit`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data && data?.STATUS) {
          const { NOTIFICATION, ...fieldErrors } = data.STATUS;
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };
}

decorate(ManageStaffAttendanceStore, {
  list_data: observable,
  getList: action,
});
