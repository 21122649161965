import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
export default class ManageProductsStore {
    agGrid = null;
    per_page = LocalGridConfig.options.paginationPageSize;
    current_page = 1;
    list_data = null;
    total = 0;
    allColumnIds = [];
    statusValues = null;
    editValues = null;
    viewValues = null;
    dropdown_product_list = null;

    //set form values to edit
    setEditValues = (data) => {
        this.editValues = data;
    }

    // set form values to status
    setStatusValues = (data) => {
        this.statusValues = data;
    };

    // set form values to view
    setViewValues = (data) => {
        this.viewValues = data;
    };

    setIsActiveFilterList = (data) => {
        this.isActiveFilterList = data;
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params;
    };
    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSize = (page = this.per_page) => {
        this.per_page = page;
        if (this.agGrid) {
            this.agGrid.api.paginationSetPageSize(parseInt(page));
        }
    };
    // call api to get records
    getList = () => {
        if (this.agGrid) {
            var filter = this.agGrid.api.getFilterModel();
            var sort = this.agGrid.api.getSortModel();
        }
        this.list_data = null;
        return Axios.get(`admin/products/list`).then(({ data }) => {
            if (data.list.data.length) {
                data.list.data.map((item, index) => {
                    item.srno = index + 1;
                    return null;
                });
            }
            this.list_data = data.list ? data.list.data : null;
            this.total = data.list.total;
            this.current_page = data.list.current_page;
            var allColumnIds = [];
            if (this.agGrid && this.agGrid.columnApi) {
                this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIds.push(column.colId);
                });
            }
            if (this.agGrid) {
                this.agGrid.api.setFilterModel(filter);
                this.agGrid.api.setSortModel(sort);
            }
        })
    }
    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params;
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
            this.agGrid.api.hideOverlay();
        }
    };
    AddData = (formdata) => {
        return Axios.post(`admin/products/new`, formdata)
            .then(({ data }) => {
                this.getList();
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });

                    })
                }
                return Promise.reject({ errors, notify });
            })
    }

    //call edit api
    EditData = (formdata) => {
        return Axios.post(`admin/products/edit/` + formdata.id, formdata)
            .then(({ data }) => {
                this.getList();
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0]
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] })
                    })
                }
                return Promise.reject({ errors, notify })
            })
    }

    //Get List of product for dropdown
    getProduct = (conditional = {}) => {
        return Axios.post(`admin/products/lov `, conditional)
            .then(({ data }) => {
                this.dropdown_product_list = data.products;
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    }

    // call api to get records
    ViewData = (ID) => {
        return Axios.get(`admin/products/details/${ID}`).then(({ data }) => {
            this.setViewValues(data.view);
            return data;
        });
    };

    TogglePublishData = (formdata) => {
        const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
        return Axios.patch("admin/products/" + api_link + formdata.id)
            .then(({ data }) => {
                this.getList();
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };
}
decorate(ManageProductsStore, {
    per_page: observable,
    agGrid: observable,
    list_data: observable,
    total: observable,
    allColumnIds: observable,
    editValues: observable,
    viewValues: observable,
    statusValues: observable,
    setupGrid: action,
    setPageSize: action,
    setEditValues: action,
    onFilterChanged: action,
    TogglePublishData: action,
    setStatusValues: action,
    setViewValues: action,
    setIsActiveFilterList: action,
    dropdown_product_list: observable,
    getProduct: action
})