import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { Constant } from "../../../../config/Constant";

export default class ManageBookingDeliveryStore {
    detail_filter = null;
    agGridDetail = null;
    per_page_detail = ServerGridConfig.options.paginationPageSize;
    current_page_detail = 1;
    list_data_detail = null;
    total_detail = 0;
    allColumnIdsDetail = [];

    // set form values to  customefilter
    setDetailFilterValues = (data = null) => {
        return new Promise((resolve) => {
            this.detail_filter = data;
            if (this.agGridDetail) {
                this.setupGridDetail(this.agGridDetail);
            }
            resolve()
        });
    };

    // Setup grid and set column size to autosize
    setupGridDetail = (params) => {
        this.agGridDetail = params;
        const { api } = params;
        let datasource = this.createDatasource(ServerGridConfig.options);
        api.setServerSideDatasource(datasource);
    };

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGridDetail = params;
        const { api } = params;
        let datasource = this.createDatasource(ServerGridConfig.options);
        api.setServerSideDatasource(datasource);
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSize = (page = this.per_page) => {
        this.per_page = page;
        if (this.agGrid) {
            this.agGridSummary.api.paginationSetPageSize(parseInt(page));
        }
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSizeDetail = (page = this.per_page_detail) => {
        this.per_page_detail = page;
        if (this.agGridDetail) {
            this.agGridDetail.api.paginationSetPageSize(parseInt(page));
        }
    };

    // Filter function for no record found message
    onFilterChangedDetail = (params) => {
        this.agGridDetail = params;
        if (this.agGridDetail && this.agGridDetail.api.getModel().getRowCount() === 0) {
            this.agGridDetail.api.showNoRowsOverlay();
        }
        if (this.agGridDetail && this.agGridDetail.api.getModel().getRowCount() > 0) {
            this.agGridDetail.api.hideOverlay();
        }
    };

    // Filter function for no record found message
    changeFilterAndSort = (params) => {
        var final_filter = params.filterModel;
        var final_sort = params.sortModel;
        return { final_filter, final_sort };
    };

    // call api to get records
    getList = (payload) => {
        return Axios.post(`/reports/system-reports/booking-vs-delivery`, payload).then(({ data }) => {
            if (data.list.data.length) {
                let startPage = (data.list.current_page - 1) * data.list.per_page;
                data.list.data.map((item, index) => {
                    item.srno = startPage + index + 1;
                    return null;
                });
            }
            this.list_data_detail = data.list ? data.list.data : null;
            this.total_detail = data.list.total;
            this.current_page_detail = data.list.current_page;
            return data;
        });
    };

    downloadMultiPod = (payload) => {
        return Axios.post(`/reports/system-reports/booking-vs-delivery/download-pod`, payload).then(({ data }) => {
            return data;
        });
    };

    downloadSinglePod = (payload) => {
        return Axios.post(`/reports/system-reports/booking-vs-delivery/download-single-pod`, payload).then(({ data }) => {
            return data;
        });
    };

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                var filter_data = this.changeFilterAndSort(params.request);
                var payload = {
                    filter_data: filter_data.final_filter,
                    sort_data: filter_data.final_sort,
                    per_page: params.request.endRow - params.request.startRow,
                    center_id: this.detail_filter.center_id,
                    center_name: this.detail_filter.center_name,
                    catg_id: this.detail_filter.catg_id,
                    catg_name: this.detail_filter.catg_name,
                    from_date: this.detail_filter.from_date,
                    to_date: this.detail_filter.to_date,
                    client_id: this.detail_filter.client_id,
                    client_name: this.detail_filter.client_name,
                    // advance_filter: this.generatePayload(this.detail_filter),
                    page: Math.ceil(
                        (params.request.startRow + 1) /
                        (params.request.endRow - params.request.startRow)
                    ),
                };
                this.getList(payload).then((data) => {
                    if (data.list.total === 0) {
                        this.agGridDetail.api.showNoRowsOverlay();
                    } else {
                        this.agGridDetail.api.hideOverlay();
                    }
                    params.successCallback(data.list.data, data.list.total);
                    var allColumnIds = [];
                    if (this.agGridDetail && this.agGridDetail.columnApi && data.total) {
                        this.agGridDetail.columnApi.getAllColumns().forEach(function (column) {
                            allColumnIds.push(column.col_id);
                        });
                    }
                })
            },
        };
    };

    generatePayload = (summary_filter) => {
        if (summary_filter.from_date) {
            summary_filter.from_date = moment(summary_filter.from_date).format(Constant.PAYLOAD_DATE);
        }
        if (summary_filter.to_date) {
            summary_filter.to_date = moment(summary_filter.to_date).format(Constant.PAYLOAD_DATE);
        }
        return summary_filter;
    }

    refreshList = () => {
        if (this.agGridSummary) {
            this.setupGrid(this.agGridSummary);
        }
    }

    doExportDownload = (data) => {
        return Axios.post(`/reports/system-reports/booking-vs-delivery`, data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };
}

decorate(ManageBookingDeliveryStore, {
    per_page_detail: observable,
    agGridDetail: observable,
    list_data_detail: observable,
    total_detail: observable,
    allColumnIdsDetail: observable,
    setSummaryFilterValues: action,
    setupGridDetail: action,
    setPageSizeDetail: action,
    getListDetail: action,
    downloadMultiPod: action,
    downloadSinglePod: action,
    onFilterChangedDetail: action,
    per_page_summary: observable,
    agGridSummary: observable,
    list_data_summary: observable,
    total_summary: observable,
    allColumnIdsSummary: observable,
    setupGridSummary: action,
    setPageSizeSummary: action,
    getListSummary: action,
    onFilterChangedSummary: action,
    summary_filter: observable,
    detail_filter: observable,
    setDetailFilterValues: action,
});
