import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class ManageLocationProfitRateStore {
	list_data = [];	

	// call api to get records
	getList = (payload) => {
		return Axios.post(`international/center-profit-rate-list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				this.list_data = data.list ? data.list.data : null;
			}
			return data;
		});
	};	

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`international/update-center-profit-rate`, formdata)
			.then(({ data }) => {				
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageLocationProfitRateStore, {	
	list_data: observable,	
	getList: action,	
});
