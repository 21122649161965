import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import moment from "moment";
import { Constant } from "../../../../config/Constant";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageOutInScanStatisticsReportStore {
    detail_filter = null;
    detail_filter_item = null;
    agGridDetail = null;
    agGridDetailItem = null;
    per_page_detail = LocalGridConfig.options.paginationPageSize;
    per_page = ServerGridConfig.options.paginationPageSize;
    current_page_detail = 1;
    current_page_detail_item = 1;
    list_data_detail = null;
    list_data_detail_item = null
    total_detail = 0;
    total_detail_item = 0;
    allColumnIdsDetail = [];

    // set form values to  customefilter
    setDetailFilterValues = (data = null) => {
        return new Promise((resolve) => {
            this.detail_filter = data;
            if (this.agGridDetail) {
                this.setupGridDetail(this.agGridDetail);
            }
            resolve()
        });
    };
    setDetailItemFilterValues= (data = null) => {
        return new Promise((resolve) => {
            this.detail_filter_item = data;
            resolve()
        });
    };
    

    // Setup grid and set column size to autosize
    setupGridDetail = (params) => {
        this.agGridDetail = params;
    };
    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGridDetailItem = params;
        const { api } = params;
        let datasource = this.createDatasource(ServerGridConfig.options);
        api.setServerSideDatasource(datasource);
    };
    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSizeDetailsItem = (page = this.per_page) => {
        this.per_page = page;
        if (this.agGridDetailItem) {
            this.agGridDetailItem.api.paginationSetPageSize(parseInt(page));
        }
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSizeDetail = (page = this.per_page_detail) => {
        this.per_page_detail = page;
        if (this.agGridDetail) {
            this.agGridDetail.api.paginationSetPageSize(parseInt(page));
        }
    };

    // Filter function for no record found message
    onFilterChangedDetail = (params) => {
        this.agGridDetail = params;
        if (this.agGridDetail && this.agGridDetail.api.getModel().getRowCount() === 0) {
            this.agGridDetail.api.showNoRowsOverlay();
        }
        if (this.agGridDetail && this.agGridDetail.api.getModel().getRowCount() > 0) {
            this.agGridDetail.api.hideOverlay();
        }
    };

    generatePayload = (filter) => {
        if (filter.from_date) {
            filter.from_date = moment(filter.from_date).format(Constant.PAYLOAD_DATE);
        }
        if (filter.to_date) {
            filter.to_date = moment(filter.to_date).format(Constant.PAYLOAD_DATE);
        }
        return filter;
    }

    // call api to get records
    getListDetail = () => {
        if (this.agGridDetail) {
            var filter = this.agGridDetail.api.getFilterModel();
            var sort = this.agGridDetail.api.getSortModel();
        }
        this.list_data_detail = null;
        let detail_filter = this.generatePayload(this.detail_filter);
        return Axios.post(`reports/mis/outscan-inscan-detail-new`, { advance_filter: detail_filter }).then(({ data }) => {
            //let data = initData;

            if (data.list.data.length) {
                data.list.data.map((item, index) => {
                    item.srno = index + 1;
                    // item.is_active_display =
                    //     item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
                    return null;
                });
            }
            this.list_data_detail = data.list.data ? data.list.data : null;
            this.total_detail = data.list.data.length;
            this.current_page_detail = data.list.data.current_page;
            var allColumnIdsDetail = [];
            if (this.agGridDetail && this.agGridDetail.columnApi) {
                this.agGridDetail.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIdsDetail.push(column.colId);
                });
            }
            if (this.agGridDetail) {
                this.agGridDetail.api.setFilterModel(filter);
                this.agGridDetail.api.setSortModel(sort);
            }
        });
    };
    // call api to get records
    getListDetailItem = (payload) => {
        return Axios.post(`reports/mis/outscan-inscan-detail-items-new`, payload).then(({ data }) => {
            if (data.list.data.length) {
                let startPage = (data.list.page - 1) * data.list.per_page;
                data.list.data.map((item, index) => {
                    item.srno = startPage + index + 1;
                    //     item.cities = item.cities && item.cities.name
                    return null;
                });
            }
            this.list_data_detail_item = data.list ? data.list.data : null;
            this.total_detail_item = data.list.total;
            this.current_page_detail_item = data.list.current_page;
            return data;
        });
    };

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGridDetailItem = params;
        if (this.agGridDetailItem && this.agGridDetailItem.api.getModel().getRowCount() === 0) {
            this.agGridDetailItem.api.showNoRowsOverlay();
        }
        if (this.agGridDetailItem && this.agGridDetailItem.api.getModel().getRowCount() > 0) {
            this.agGridDetailItem.api.hideOverlay();
        }
    };
    // Filter function for no record found message
    changeFilterAndSort = (params) => {
        var final_filter = params.filterModel;
        var final_sort = params.sortModel;

        return { final_filter, final_sort };
    };

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                var filter_data = this.changeFilterAndSort(params.request);
                var payload = {
                    advance_filter :this.detail_filter_item,
                    purpose: this.listPurpose,
                    filter_data: filter_data.final_filter,
                    sort_data: filter_data.final_sort,
                    per_page: params.request.endRow - params.request.startRow,
                    page: Math.ceil(
                        (params.request.startRow + 1) /
                        (params.request.endRow - params.request.startRow)
                    ),
                };
                this.getListDetailItem(payload).then((data) => {
                    if (data.list.total === 0) {
                        this.agGridDetailItem.api.showNoRowsOverlay();
                    } else {
                        this.agGridDetailItem.api.hideOverlay();
                    }
                    params.successCallback(data.list.data, data.list.total);
                    var allColumnIds = [];
                    if (this.agGridDetailItem && this.agGridDetailItem.columnApi && data.total) {
                        this.agGridDetailItem.columnApi.getAllColumns().forEach(function (column) {
                            allColumnIds.push(column.col_id);
                        });
                    }
                });
            },
        };
    };
    doExportDownload = (data) => {
        return Axios.post(`reports/mis/outscan-inscan-detail-new`, { advance_filter: data })
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };
    doExportDownloadItem = (data) => {
        return Axios.post(`/reports/mis/outscan-inscan-detail-items-new`, { advance_filter: data })
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };
    
}

decorate(ManageOutInScanStatisticsReportStore, {
    per_page: observable,
    per_page_detail: observable,
    agGridDetail: observable,
    list_data_detail: observable,
    list_data_detail_item: observable,
    total_detail: observable,
    total_detail_item:observable,
    allColumnIdsDetail: observable,
    setSummaryFilterValues: action,
    setupGridDetail: action,
    setPageSizeDetail: action,
    getListDetail: action,
    onFilterChangedDetail: action,
    setupGrid: action,
	setPageSize: action,
    getList: action,
	onFilterChanged: action,
    setPageSizeDetailsItem:action
});
