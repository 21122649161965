import React, { useEffect, useState } from "react";
import { Form, Row, Col, Switch, Divider, Drawer, Button } from "antd";
import { observer } from "mobx-react";
import useStore from "../../../../../store";
import { vsmNotify } from "../../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import debounce from "lodash/debounce";
import Unauthorized from "../../../../commonpage/Unauthorized";

const CashBookingSettingsForm = observer((props) => {
    const [form] = Form.useForm();
    const {
        setDisabled = () => { },
    } = props;
    const [saving, setSaving] = useState();
    // const [consignment, setConsignment] = useState(false)
    // const [product, setProduct] = useState(false)
    // const [date, setDate] = useState(false)
    // const [pincode, setPincode] = useState(false)
    // const [senderName, setSenderName] = useState(false)
    // const [accountParty, setAccountParty] = useState(false)
    // const [senderMobile, setSenderMobile] = useState(false)
    // const [refNo, setRefNo] = useState(false)
    // const [senderAddress, setSenderAddress] = useState(false)
    // const [receiverName, setReceiverName] = useState(false)
    // const [receiverMobile, setReceiverMobile] = useState(false)
    // const [receiverAddress, setReceiverAddress] = useState(false)
    // const [description, setDescription] = useState(false)
    // const [value, setValue] = useState(false)
    // const [remarks, setRemarks] = useState(false)
    // const [captureImage, setCaptureImage] = useState(false)
    // const [receipt, setReceipt] = useState(false)
    // const [gst, setGst] = useState(false)
    // const [psAmount, setPsAmount] = useState(false)
    // const [psRemark, setPsRemark] = useState(false)
    // const [psAddress, setPsAddress] = useState(false)
    // const [prePrinted, setPrePrinted] = useState(false)
    const {
        ManageCashBookingSettingsStore: { EditData, viewValues, typeValues },
        AUTH: { checkPrivileges },
    } = useStore();
    const handleChange = debounce(() => {
        form
            .validateFields()
            .then((d) => {
                setDisabled(false);
            })
            .catch((d) => {
                setDisabled(true);
            });
    }, 500);
    const handleSubmit = (data) => {
        setSaving(true);
        data.id = viewValues?.id
        data.bk_date = (data.bk_date !== undefined) ? data.bk_date : viewValues.bk_date;
        data.receipt_remarks = (data.receipt_remarks !== undefined) ? data.receipt_remarks : viewValues.receipt_remarks;
        data.s_address2 = (data.s_address2 !== undefined) ? data.s_address2 : viewValues.s_address2;
        data.s_address3 = (data.s_address3 !== undefined) ? data.s_address3 : viewValues.s_address3;
        data.s_city = (data.s_city !== undefined) ? data.s_city : viewValues.s_city;
        data.s_pincode = (data.s_pincode !== undefined) ? data.s_pincode : viewValues.s_pincode;
        data.s_whatssappno = (data.s_whatssappno !== undefined) ? data.s_whatssappno : viewValues.s_whatssappno;
        data.s_pan = (data.s_pan !== undefined) ? data.s_pan : viewValues.s_pan;
        data.s_gstno = (data.s_gstno !== undefined) ? data.s_gstno : viewValues.s_gstno;
        data.r_address1 = (data.r_address1 !== undefined) ? data.r_address1 : viewValues.r_address1;
        data.r_address2 = (data.r_address2 !== undefined) ? data.r_address2 : viewValues.r_address2;
        data.r_address3 = (data.r_address3 !== undefined) ? data.r_address3 : viewValues.r_address3;
        data.r_whatssappno = (data.r_whatssappno !== undefined) ? data.r_whatssappno : viewValues.r_whatssappno;
        data.description = (data.description !== undefined) ? data.description : viewValues.description;
        data.remarks = (data.remarks !== undefined) ? data.remarks : viewValues.remarks;
        data.doc_file = (data.doc_file !== undefined) ? data.doc_file : viewValues.doc_file;
        data.ps_address = (data.ps_address !== undefined) ? data.ps_address : viewValues.ps_address;
        data.ps_gst = (data.ps_gst !== undefined) ? data.ps_gst : viewValues.ps_gst;
        data.ps_pre_printed = (data.ps_pre_printed !== undefined) ? data.ps_pre_printed : viewValues.ps_pre_printed;
        data.ps_receipt = (data.ps_receipt !== undefined) ? data.ps_receipt : viewValues.ps_receipt;
        data.ps_remarks = (data.ps_remarks !== undefined) ? data.ps_remarks : viewValues.ps_remarks;
        data.ps_amount = (data.ps_amount !== undefined) ? data.ps_amount : viewValues.ps_amount;
        data.r_mobile = (data.r_mobile !== undefined) ? data.r_mobile : viewValues.r_mobile;
        data.awb_no = (data.awb_no !== undefined) ? data.awb_no : viewValues.awb_no;
        data.crossing_chrg = (data.crossing_chrg !== undefined) ? data.crossing_chrg : viewValues?.crossing_chrg;
        data.fr_product_id = (data.fr_product_id !== undefined) ? data.fr_product_id : viewValues.fr_product_id;
        data.fr_weight = (data.fr_weight !== undefined) ? data.fr_weight : viewValues.fr_weight;
        data.is_insured = (data.is_insured !== undefined) ? data.is_insured : viewValues.is_insured;
        EditData(data)
            .then((data) => {
                close();
                vsmNotify.success({
                    message: data.STATUS.NOTIFICATION[0],
                });
            })
            .catch((e) => {
                if (e.errors) {
                    form.setFields(e.errors);
                }
            })
            .finally(() => setSaving(false));
    };
    const close = () => {
        props.close()
        form.resetFields();
    }
    useEffect(() => {
        if (viewValues) {
            form.setFieldsValue({
                booking_type: viewValues.booking_type,
                bk_date: viewValues.bk_date,
                receipt_remarks: viewValues.receipt_remarks,
                s_address2: viewValues.s_address2,
                s_address3: viewValues.s_address3,
                s_city: viewValues.s_city,
                s_pincode: viewValues.s_pincode,
                s_whatssappno: viewValues.s_whatssappno,
                s_pan: viewValues.s_pan,
                s_gstno: viewValues.s_gstno,
                r_address1: viewValues.r_address1,
                r_address2: viewValues.r_address2,
                r_address3: viewValues.r_address3,
                r_whatssappno: viewValues.r_whatssappno,
                r_email: viewValues.r_email,
                description: viewValues.description,
                remarks: viewValues.remarks,
                doc_file: viewValues.doc_file,
                ps_pre_printed: viewValues.ps_pre_printed,
                ps_address: viewValues.ps_address,
                ps_remarks: viewValues.ps_remarks,
                ps_amount: viewValues.ps_amount,
                ps_gst: viewValues.ps_gst,
                ps_receipt: viewValues.ps_receipt,
                r_mobile: viewValues.r_mobile,
                awb_no: viewValues.awb_no,
                crossing_chrg: viewValues.crossing_chrg,
                fr_product_id: viewValues.fr_product_id,
                fr_weight: viewValues.fr_weight,
                is_insured: viewValues.is_insured
            })
        }
    }, [viewValues, form])
    return props.visible && (
        <>

            <Drawer
                className="bookingSettingsModal addModal"
                title={typeValues !== 1 ? "Account Booking Settings" : "Cash Booking Settings"}
                width="75%"
                visible={props.visible}
                onClose={close}
                closeIcon={<FontAwesomeIcon icon={faTimes} />}
                destroyOnClose={true}
                footer={[
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn mr-35"
                        onClick={close}
                    >
                        Cancel
                    </Button>,
                    (((checkPrivileges("#1070001#")) && (checkPrivileges("#1070011#"))) && <Button
                        key="1"
                        form="setting_form"
                        loading={saving}
                        htmlType="submit"
                        type="primary"
                    >
                        Save
                    </Button>)
                ]}
            >
                {((!checkPrivileges("#1070001#")) && (!checkPrivileges("#1070011#"))) ? (
                    <Unauthorized goBackHide={true} />
                ) : (
                    <Form
                        form={form}
                        id={"setting_form"}
                        onFinish={handleSubmit}
                        labelCol={{ span: 24 }}
                    >
                        <Row gutter={30}>
                            <Col span={24}><h2>Stop For Cursor</h2></Col>
                            <Col xs={{ span: 6 }} className="bookingSetting">
                                <label>Booking Type</label>
                                <Form.Item
                                    name="booking_type"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                booking_type: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} className="bookingSetting">
                                <label>Date</label>
                                <Form.Item
                                    name="bk_date"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={product}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                bk_date: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} className="bookingSetting">
                                <label>Receipt with Remarks?</label>
                                <Form.Item
                                    name="receipt_remarks"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={consignment}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                receipt_remarks: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={30}>
                            <Col xs={{ span: 6 }} className="bookingSetting">
                                <label>AWB Number</label>
                                <Form.Item
                                    name="awb_no"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={consignment}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                awb_no: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} className="bookingSetting">
                                <label>Crossing Charge</label>
                                <Form.Item
                                    name="crossing_chrg"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={consignment}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                crossing_chrg: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} className="bookingSetting">
                                <label>Act. Service Type</label>
                                <Form.Item
                                    name="fr_product_id"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={consignment}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                fr_product_id: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 6 }} className="bookingSetting">
                                <label>Act. Weight </label>
                                <Form.Item
                                    name="fr_weight"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={consignment}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                fr_weight: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {typeValues === 1 &&
                            <>
                                <Divider />
                                <Row gutter={30}>
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignor Area Name</label>
                                        <Form.Item
                                            name="s_address2"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={date}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        s_address2: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignor Landmark</label>
                                        <Form.Item
                                            name="s_address3"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={pincode}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        s_address3: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignor City</label>
                                        <Form.Item
                                            name="s_city"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={date}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        s_city: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignor Pincode</label>
                                        <Form.Item
                                            name="s_pincode"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={pincode}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        s_pincode: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Divider />
                                <Row gutter={30}>
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignor Whatsapp No.</label>
                                        <Form.Item
                                            name="s_whatssappno"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                //checked={senderName}
                                                className="Switch__with_label"
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        s_whatssappno: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignor PAN Card</label>
                                        <Form.Item
                                            name="s_pan"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={senderMobile}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        s_pan: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignor GST No.</label>
                                        <Form.Item
                                            name="s_gstno"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={senderAddress}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        s_gstno: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Divider />
                        <Row gutter={30}>
                            {typeValues === 2 &&
                                <Col span={6} className="bookingSetting">
                                    <label>Consignee Shop/Apartment</label>
                                    <Form.Item
                                        name="r_address1"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            className="Switch__with_label"
                                            //checked={receiverName}
                                            onChange={(val) => {
                                                let flag = (val) ? 1 : 0
                                                form.setFieldsValue({
                                                    r_address1: flag
                                                });
                                                handleChange();
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            <Col span={6} className="bookingSetting">
                                <label>Consignee Area Name</label>
                                <Form.Item
                                    name="r_address2"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={receiverMobile}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                r_address2: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="bookingSetting">
                                <label>Consignee Landmark</label>
                                <Form.Item
                                    name="r_address3"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={receiverAddress}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                r_address3: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {typeValues === 1 &&
                                <Col span={6} className="bookingSetting">
                                    <label>Consignee Whatsapp No.</label>
                                    <Form.Item
                                        name="r_whatssappno"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            className="Switch__with_label"
                                            //checked={receiverAddress}
                                            onChange={(val) => {
                                                let flag = (val) ? 1 : 0
                                                form.setFieldsValue({
                                                    r_whatssappno: flag
                                                });
                                                handleChange();
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                            {typeValues === 2 &&
                                <Col span={6} className="bookingSetting">
                                    <label>Consignee Email</label>
                                    <Form.Item
                                        name="r_email"
                                        valuePropName="checked"
                                    >
                                        <Switch
                                            className="Switch__with_label"
                                            //checked={receiverName}
                                            onChange={(val) => {
                                                let flag = (val) ? 1 : 0
                                                form.setFieldsValue({
                                                    r_email: flag
                                                });
                                                handleChange();
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row gutter={30}>
                            {typeValues === 2 &&
                                <>
                                    <Divider />
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignee Mobile No.</label>
                                        <Form.Item
                                            name="r_mobile"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={receiverAddress}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        r_mobile: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6} className="bookingSetting">
                                        <label>Consignee Whatsapp No.</label>
                                        <Form.Item
                                            name="r_whatssappno"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={receiverAddress}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        r_whatssappno: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                        </Row>
                        <Divider />
                        <Row gutter={30}>
                            <Col span={6} className="bookingSetting">
                                <label>Insured?</label>
                                <Form.Item
                                    name="is_insured"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                is_insured: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="bookingSetting">
                                <label>Description</label>
                                <Form.Item
                                    name="description"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={description}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                description: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="bookingSetting">
                                <label>Remarks</label>
                                <Form.Item
                                    name="remarks"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={remarks}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                remarks: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="bookingSetting">
                                <label>Capture Image</label>
                                <Form.Item
                                    name="doc_file"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={captureImage}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                doc_file: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={30}>
                            <Col span={24}><h2>Print Settings</h2></Col>
                            <Col span={6} className="bookingSetting">
                                <label>Receipt</label>
                                <Form.Item
                                    name="ps_receipt"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={receipt}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                ps_receipt: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            {
                                typeValues === 1 ?
                                    <Col span={6} className="bookingSetting">
                                        <label>GST</label>
                                        <Form.Item
                                            name="ps_gst"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={gst}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        ps_gst: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    : <Col span={6} className="bookingSetting">
                                        <label>Amount</label>
                                        <Form.Item
                                            name="ps_amount"
                                            valuePropName="checked"
                                        >
                                            <Switch
                                                className="Switch__with_label"
                                                //checked={psAmount}
                                                onChange={(val) => {
                                                    let flag = (val) ? 1 : 0
                                                    form.setFieldsValue({
                                                        ps_amount: flag
                                                    });
                                                    handleChange();
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                            }
                            <Col span={6} className="bookingSetting">
                                <label>Remarks</label>
                                <Form.Item
                                    name="ps_remarks"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={psRemark}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                ps_remarks: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={6} className="bookingSetting">
                                <label>Address</label>
                                <Form.Item
                                    name="ps_address"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={psAddress}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                ps_address: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={30}>
                            <Col span={6} className="bookingSetting">
                                <label>Pre Printed</label>
                                <Form.Item
                                    name="ps_pre_printed"
                                    valuePropName="checked"
                                >
                                    <Switch
                                        className="Switch__with_label"
                                        //checked={prePrinted}
                                        onChange={(val) => {
                                            let flag = (val) ? 1 : 0
                                            form.setFieldsValue({
                                                ps_pre_printed: flag
                                            });
                                            handleChange();
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Drawer>
        </>
    )
});
export default CashBookingSettingsForm;