import { Spin } from "antd"
import { Constant } from "../config/Constant"

const Fullscreenspinner = (props) => {
    return (
        <div className={props.customClassName ? props.customClassName : "fullscreen__spinner"}>
            <img className="spin_logo" src={`${Constant.S3BUCKET_API_BASEURL}branding/spinner_logo.png`} alt="Logo" />
            <Spin size="large" />
        </div>
    )
}

export default Fullscreenspinner