import Dexie from 'dexie';

export const db = new Dexie('cmsBooking');

db.version(2).stores({
    bk_cash: 'id, con_no, bk_date, product_id, product_name, r_name, r_city, to_pincode, s_name, bk_type, c_weight, net, status, nandan_srno, is_parent',
    bk_account: 'id, con_no, bk_date, product_id, product_name, r_name, r_city, to_pincode, s_name, bk_type, c_weight, gross, status, nandan_srno, is_parent',
    senders: '++id, s_name, s_mobile, s_whatsappno, s_email, s_address1, s_address2, s_address3, s_state_id, s_state, s_city_id, s_city, s_pincode_id, s_pincode, is_gst_registered, state_code, s_gst_no, s_pan',
    receivers_cash: '++id, r_name, r_mobile, r_whatsappno, r_email, r_address1, r_address2, r_address3',
    receivers_ac: '++id, r_name, r_mobile, r_whatsappno, r_email, r_address1, r_address2, r_address3',
    states: 'id, name, gst_code, gst_no, is_active, is_deleted',
    insurance_details: 'id, from_date, to_date, policy_number, is_active'
});