import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID, convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";

export default class ManageConfigPendingDrsStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = [];
	viewValues = null;
	total = 0;
	allColumnIds = [];
	loValues = null;
	isActiveFilterList = null;
	isTexFilterList = null;
	completePendingFilterList = null;
	isTerminated = 1;
	partyTypeFilterList = null;
	stock_list_data = null;
	isActiveFilterList = null;

	setisTerminated = (is_terminated) => {
		this.isTerminated = is_terminated
		this.setupGrid(this.agGrid);
	}

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};

	//set form values to terminate
	setTerminateValues = (data) => {
		this.terminateValues = data
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	refreshgetAllocatedStickersList = () => {
		this.getAllocatedStickersList()
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`locks/pending-drs/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				this.list_data = data.list ? data.list.data : null;
			}
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["is_active"]) {
			final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_terminated"]) {
			final_filter['is_terminated'].values = convertTextToID(final_filter['is_terminated'], this.isTerminatesFilterList, 'name', 'id')
		}
		if (final_filter["is_completed"]) {
			final_filter['is_completed'].values = convertTextToID(final_filter['is_completed'], this.completePendingFilterList, 'name', 'id')
		}
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					purpose: this.isTerminated,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};


	// Call add api
	AddData = (formdata) => {
		return Axios.post(`locks/pending-drs/update`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageConfigPendingDrsStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	viewValues: observable,
	setDefaultValue: action,
	defaultValue: observable,
	typeMode: observable,
	setTypeMode: action,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setisTerminated: action,
	setTerminateValues: action,
	terminateValues: observable,
	isTexFilterList: observable,
	setISTexFilterList: action,
});
