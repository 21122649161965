import React from "react";
import { Button, Row, Col, Drawer, Avatar, Divider, Collapse } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../../../../../config/Constant";
import { convertDate, isActiveStatus, pendingComplateStatus } from "../../../../../utils/GlobalFunction";
const { Panel } = Collapse;

const MoreInfoComponent = observer((props) => {
	const {
		ManageCentersStore,
	} = useStore();

	// reset form and close add form
	const close = () => {
		props.close();
	};
	const locked = (
		<Row gutter={6} className="more_info_sec">
			<Col span={20}>Locked</Col>
			<Col span={4} className="text-right">
				{ManageCentersStore.editValues?.is_locked === 1 ? (
					<>
						<div className="isactive_tag ant-tag ant-tag-success">
							{isActiveStatus[1]}{" "}
						</div>
					</>
				) : (
					<div className="isactive_tag ant-tag ant-tag-error">
						{isActiveStatus[0]}
					</div>
				)}
			</Col>
		</Row>
	)
	const Terminated = (
		<Row gutter={6} className="more_info_sec">
			<Col span={20}>Terminated</Col>
			<Col span={4} className="text-right">
				{ManageCentersStore.editValues?.is_terminated === 1 ? (
					<>
						<div className="isactive_tag ant-tag ant-tag-success">
							{isActiveStatus[1]}{" "}
						</div>
					</>
				) : (
					<div className="isactive_tag ant-tag ant-tag-error">
						{isActiveStatus[0]}
					</div>
				)}
			</Col>
		</Row>
	)

	const onPreview = async (src) => {
		if(src){
			const image = new Image();
			image.src = src;
			const imgWindow = window.open(src);
			imgWindow.document.write(image.outerHTML);
		}
	};

	return (
		<Drawer
			className="editModal"
			title="More Info"
			width="50%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={close}
			footer={[
				<Button
					key="1"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Close
				</Button>
			]}
		>
			<Row className="avtar_row text-center  more_info_sec">
				<Col span={24} className="form__input__textbox">
					<Avatar size={120} className={(ManageCentersStore && ManageCentersStore.editValues && ManageCentersStore.editValues.cover_photo) ? "cursor-pointer" : ""} onClick={() => {
						onPreview((ManageCentersStore && ManageCentersStore.editValues && ManageCentersStore.editValues.cover_photo) ? Constant.S3BUCKET_API_BASEURL + ManageCentersStore.editValues.cover_photo : null)
					}} src={(ManageCentersStore && ManageCentersStore.editValues && ManageCentersStore.editValues.cover_photo) ? Constant.S3BUCKET_API_BASEURL + ManageCentersStore.editValues.cover_photo : null} />
				</Col>
				<Col span={24}>
					<h2>{ManageCentersStore.editValues?.name}</h2>
				</Col>
				<Col span={24}>
					<h3>{ManageCentersStore.editValues?.company}</h3>
				</Col>
				<Col span={24}>
					<h4>{ManageCentersStore.editValues?.owner_name}</h4>
				</Col>
				<Col span={24}>
					<h5>{ManageCentersStore.editValues?.mst_ho?.name}</h5>
				</Col>
			</Row>
			<Divider />
			<Row gutter={6} className="more_info_sec">
				<Col span={20}>Legal Info</Col>
				<Col span={4} className="text-right">
					{ManageCentersStore.editValues?.lo_flag === 1 ? (
						<div className="isactive_tag ant-tag ant-tag-success">
							{pendingComplateStatus[1]}{" "}
						</div>
					) : (
						<div className="isactive_tag ant-tag ant-tag-error">
							{pendingComplateStatus[0]}
						</div>
					)}
				</Col>
			</Row>
			<Divider />
			<Row gutter={6} className="more_info_sec">
				<Col span={20}>KYC</Col>
				<Col span={4} className="text-right">
					{ManageCentersStore.editValues?.kyc_flag === 1 ? (
						<div className="isactive_tag ant-tag ant-tag-success">
							{pendingComplateStatus[1]}{" "}
						</div>
					) : (
						<div className="isactive_tag ant-tag ant-tag-error">
							{pendingComplateStatus[0]}
						</div>
					)}
				</Col>
			</Row>
			<Divider />
			<Row gutter={6} className="more_info_sec">
				<Col span={20}>Completed</Col>
				<Col span={4} className="text-right">
					{ManageCentersStore.editValues?.is_completed === 1 ? (
						<div className="isactive_tag ant-tag ant-tag-success">
							{isActiveStatus[1]}{" "}
						</div>
					) : (
						<div className="isactive_tag ant-tag ant-tag-error">
							{isActiveStatus[0]}
						</div>
					)}
				</Col>
			</Row>
			<Divider />
			{
				props.from === "rateCardCash" && (
					<>
						<Row gutter={6} className="more_info_sec">
							<Col span={20}>Cash Booking Policy</Col>
							<Col span={4} className="text-right">
								{ManageCentersStore.editValues?.cb_flag === 1 ? (
									<div className="isactive_tag ant-tag ant-tag-success">
										{isActiveStatus[1]}{" "}
									</div>
								) : (
									<div className="isactive_tag ant-tag ant-tag-error">
										{isActiveStatus[0]}
									</div>
								)}
							</Col>
						</Row>
						<Divider />
						<Row gutter={6} className="more_info_sec">
							<Col span={20}>Contract</Col>
							<Col span={4} className="text-right">
								{ManageCentersStore.editValues?.contract_flag === 1 ? (
									<div className="isactive_tag ant-tag ant-tag-success">
										{isActiveStatus[1]}{" "}
									</div>
								) : (
									<div className="isactive_tag ant-tag ant-tag-error">
										{isActiveStatus[0]}
									</div>
								)}
							</Col>
						</Row>
						<Divider />
					</>
				)
			}
			<Row gutter={6} className="more_info_sec">
				{ManageCentersStore.editValues?.is_locked !== 1 &&
					<>
						<Col span={20}>Locked</Col>
						<Col span={4} className="text-right">
							{ManageCentersStore.editValues?.is_locked === 1 ? (
								<>
									<div className="isactive_tag ant-tag ant-tag-success">
										{isActiveStatus[1]}{" "}
									</div>
								</>
							) : (
								<div className="isactive_tag ant-tag ant-tag-error">
									{isActiveStatus[0]}
								</div>
							)}
						</Col>
					</>}
				{ManageCentersStore.editValues?.is_locked === 1 && <Collapse
					ghost
					bordered={false}
					//expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					className="moreInfoCollapse"
					expandIconPosition={"end"}
				>
					<Panel header={locked} key="1">
						<Row gutter={6} className="more_info_sec more_info_sec_table">
							<Col span={12}>
								<label>Locked On</label>
							</Col>
							<Col span={12}>
								<span>{ManageCentersStore.editValues ? convertDate(ManageCentersStore.editValues?.locked) : Constant.UNDEFINED_VALUES}</span>
							</Col>
							<Col span={12}>
								<label>By Whom</label>
							</Col>
							<Col span={12}>
								<span>{ManageCentersStore.editValues.locked_by
									? `${ManageCentersStore.editValues.locked_by.name}(${ManageCentersStore.editValues.locked_by.email})`
									: Constant.UNDEFINED_VALUES}</span>
							</Col>
							<Col span={12}>
								<label>Note</label>
							</Col>
							<Col span={12}>
								<span>{ManageCentersStore.editValues.lock_note ? ManageCentersStore.editValues.lock_note : Constant.UNDEFINED_VALUES}</span>
							</Col>
						</Row>

					</Panel>
				</Collapse>}

			</Row>
			<Divider />
			<Row gutter={6} className="more_info_sec">
				{ManageCentersStore.editValues?.is_terminated !== 1 &&
					<>
						<Col span={20}>Terminated</Col>
						<Col span={4} className="text-right">
							{ManageCentersStore.editValues?.is_terminated === 1 ? (
								<>
									<div className="isactive_tag ant-tag ant-tag-success">
										{isActiveStatus[1]}{" "}
									</div>
								</>
							) : (
								<div className="isactive_tag ant-tag ant-tag-error">
									{isActiveStatus[0]}
								</div>
							)}
						</Col>
					</>}
				{ManageCentersStore.editValues?.is_terminated === 1 &&
					<Collapse
						ghost
						bordered={false}
						//expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
						className="moreInfoCollapse"
						expandIconPosition={"end"}
					>
						<Panel header={Terminated} key="1">
							<Row gutter={6} className="more_info_sec more_info_sec_table">
								<Col span={12}>
									<label>Terminated On</label>
								</Col>
								<Col span={12}>
									<span>{ManageCentersStore.editValues ? convertDate(ManageCentersStore.editValues?.terminated) : Constant.UNDEFINED_VALUES}</span>
								</Col>
								<Col span={12}>
									<label>By Whom</label>
								</Col>
								<Col span={12}>
									<span>{ManageCentersStore.editValues.terminated_by
										? `${ManageCentersStore.editValues.terminated_by.name}(${ManageCentersStore.editValues.terminated_by.email})`
										: Constant.UNDEFINED_VALUES}</span>
								</Col>
								<Col span={12}>
									<label>Note</label>
								</Col>
								<Col span={12}>
									<span>{ManageCentersStore.editValues.terminate_note ? ManageCentersStore.editValues.terminate_note : Constant.UNDEFINED_VALUES}</span>
								</Col>
							</Row>
						</Panel>
					</Collapse>}
			</Row>
		</Drawer>
	)
});

export default MoreInfoComponent;
