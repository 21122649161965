import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { vsmNotify } from "../../../../config/messages/common";
export default class ManageStateStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	dropdown_ondemand_report_list = null;
	formPayload = null;
	apiRes = null;
	agGridload = false

	setFormPayload = data => {
		this.formPayload = data
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};
	// call api to get records
	getList = (payload) => {
		return Axios.post(`/admin/on-demand-report/list`, payload).then(({ data }) => {
			this.agGridload = true
			const { NOTIFICATION } = data.STATUS;
			if (data && data.STATUS.NOTIFICATION[0]) {
				if (NOTIFICATION) {
					vsmNotify.error({
						message: NOTIFICATION[0],
					});
				}
			}
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			this.apiRes = data.header
			return data;
		}).catch(({ response: { data } }) => {
			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
			if (data && data.STATUS) {
				if (NOTIFICATION) {
					vsmNotify.error({
						message: NOTIFICATION[0],
					});
				}
				Object.keys(fieldErrors).forEach((name) => {
					vsmNotify.error({
						message: data.STATUS[name],
					});
				});
			}
			return Promise.reject();
		});
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};


	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				if (this.formPayload) {
					payload = { ...payload, ...this.formPayload };
					this.getList(payload).then((data) => {
						if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
						else { this.agGrid.api.hideOverlay() }
						params.successCallback(data.list.data, data.list.total)
						var allColumnIds = []
						if (this.agGrid && this.agGrid.columnApi && data.total) {
							this.agGrid.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id)
							})
						}
					})
				} else {
					this.agGrid.api.showNoRowsOverlay();
					params.successCallback([], 0)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && 0) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				}
			}
		}
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};
	getOnDemandReportList = (conditional = {}) => {
		return Axios.post(`admin/on-demand-report/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_ondemand_report_list = data.continents;

				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(ManageStateStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	dropdown_ondemand_report_list: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	getOnDemandReportList: action,
	onFilterChanged: action,
	apiRes: observable,
	setFormPayload: action,
	formPayload: observable,
	changeFilterAndSort: action,
	createDatasource: action,
	agGridload: observable
});
