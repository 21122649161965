import { Messages } from "../../../../config/messages/messages";
import { Patterns } from "../../../../config/messages/patterns";

export const vsmLocation = {
	success: Messages.CREATED,
	error: Messages.DUPLICATED,
	validation: {
		ir: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.INSURANCE_RATE_INVALID_CHARACTERS },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 100.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.INSURANCE_RATE_RANGE);
				}
			})
		],
		vr: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.INSURANCE_RATE_INVALID_CHARACTERS },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 100.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.INSURANCE_RATE_RANGE);
				}
			})
		],
		fc: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.INSURANCE_RATE_INVALID_CHARACTERS },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 100.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.INSURANCE_RATE_RANGE);
				}
			})
		],
		fuel: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.INSURANCE_RATE_INVALID_CHARACTERS },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 100.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.INSURANCE_RATE_RANGE);
				}
			})
		],
		oda1_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.INSURANCE_RATE_INVALID_CHARACTERS },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 100.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.INSURANCE_RATE_RANGE);
				}
			})
		],
		oda2_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.INSURANCE_RATE_INVALID_CHARACTERS },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 100.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.INSURANCE_RATE_RANGE);
				}
			})
		],
		pod_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.INSURANCE_RATE_INVALID_CHARACTERS },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 100.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.INSURANCE_RATE_RANGE);
				}
			})
		],
		rec_sms_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.SMS_CHARGES_INVALID_CHARACTE },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 2.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.SMS_CHARGES_RANGE);
				}
			})
		],
		productList: [{ required: true, message: Messages.NOT_SELECTED }],
		disc_rate: [{ required: true, message: Messages.NOT_SELECTED }],
		def_weight: [{ required: true, message: Messages.NOT_SELECTED }],
	},
	validationLocation: {
		latitude: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
		],
		longitude: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
		]
	},
};