import { Button, Col, Form, Row, Tooltip } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import InputComponent from "../component/InputComponent";
import { vsmAuth, vsmNotify } from "../config/messages/common";
import useStore from "../store";
import logo from "../images/corporateLogo.png"
import courier_boy from "../images/Hero_images.png"
import { Constant } from "../config/Constant";
import { randomnumbersubstring, stringEncryption } from "../utils/GlobalFunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { InfoCircleOutlined } from "@ant-design/icons";


const Login = observer((props) => {
	const [form] = Form.useForm();
	const history = useHistory()
	const [disabled, setDisabled] = useState(true);
	const [saving, setSaving] = useState();
	const [MACAddress, setMACAddress] = useState();
	const state = props?.location?.state ? props.location.state : {}

	const {
		AUTH: { doLogin, remember_me, },
		AUTH,
	} = useStore();
	const captcha_on_login = (AUTH && AUTH.company.branding.captcha_on_login) ? ((AUTH.company.branding.captcha_on_login === 1) ? true : false) : false;
	const macInfo = (
		<div>
			<h3>MAC - <span>{(AUTH.UUID ? MACAddress : Constant.UNDEFINED_VALUES)}</span> </h3>
		</div>
	)

	// handle password tool tip visiblility
	const handleChangePassword = (e) => {
		form
			.validateFields()
			.then((data) => {
				setDisabled(false);
			})
			.catch((e) => {
				setDisabled(true);
			});
	};
	// make function call to login
	const handleSubmit = (data, event) => {
		setSaving(true);
		doLogin(data)
			.then((data) => {
				if (data?.otp_on_login === 1) {
					history.replace("/captcha-verify-otp/" + stringEncryption(data?.user_id.toString()));
				}
				else {
					if (state?.redirectPath) {
						history.push({ pathname: state.redirectPath })
					}
					vsmNotify.success({
						message: data.STATUS.NOTIFICATION[0],
					});
				}
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			}).finally(() => setSaving(false));
	};

	const CaptchaChange = () => {
		form.setFieldsValue({ captcha: randomnumbersubstring() });
	}

	useEffect(() => {
		form.setFieldsValue({ captcha: randomnumbersubstring() });
		if (AUTH.UUID || localStorage.getItem("UUID")) {
			let uuid = (AUTH.UUID) ? AUTH.UUID : (localStorage.getItem("UUID")) ? localStorage.getItem("UUID") : Constant.UNDEFINED_VALUES;
			setMACAddress(uuid);
		}
		if (remember_me) {
			form.setFieldsValue({
				username: remember_me.email,
				password: remember_me.password,

				remember: true
			});
			setDisabled(false);
		}
	}, [remember_me, form, AUTH]);

	return (
		<Row className="main_corporate">
			<Col span={12}>
				<div className="svg_img">
					<svg version="1.1" preserveAspectRatio="none" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" fill=""
						viewBox="0 0 1345.9 1079">
						<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="672.9308" y1="4.1442" x2="672.9308" y2="1076.8485">
							<stop offset="0" />
							<stop offset="1" />
						</linearGradient>
						<path className="st0" d="M1168.5,467.3c27.2-27.9,37.4-61.1,30.1-99.3c-4.7-24.7-17-45.2-36.3-61.6c-7.7-6.6-16.1-11.7-25.1-16.1
							c-4-2-4.1-3-1.2-6.3c0.6-0.6,1.2-1.2,1.7-1.8c38.4-38.3,76.8-76.7,115.1-115.1c26.6-26.6,53.3-53.2,79.8-79.8
							c6.4-6.4,11.7-13.6,12.6-23.2c0.6-6,0.5-12,0.5-17.9c0-14.1-6.6-24.5-17.5-32.9c-14.5-11.1-40-11.9-54.9-0.9
							c-7.8,5.8-14.2,13.1-21,19.9c-40,39.8-79.8,79.8-119.8,119.6c-10.3,10.3-22.7,15.4-37.6,12.7c-16.7-2.9-27.9-12.7-33-28.4
							c-5.1-15.6-1.7-30,10.1-41.8c16.6-16.8,33.4-33.4,50.1-50.2c12.5-12.5,25-24.9,37.4-37.5c1.7-1.8,4.6-3.3,2.9-6.7
							c-150,0-300,0-450,0C570.8,0,429.1,0,287.3,0H136.9H15.5H0v1079h19.9c0.3,0,0.6,0,0.9,0c28.4,0,56.7,0,85.1,0h31.1v0
							c254.8-0.1,509.7-0.1,764.5,0c3.5,0,5.8-1.1,8.2-3.5c26.4-26.6,52.9-53,79.4-79.5c43.3-43.3,86.7-86.6,129.9-130
							c28.1-28.2,24.2-73.5-7.9-96.4c-24.7-17.6-58.7-15-80.6,6.6c-26.4,26.1-52.6,52.4-78.8,78.7c-36.4,36.4-72.7,72.8-109.2,109.1
							c-17.8,17.7-44,22.6-67,13c-22.8-9.5-37.6-32-37.5-57c0.1-18.1,7.6-32.9,20.4-45.6L1168.5,467.3z"/>
					</svg>
					<img src={(AUTH.company) ? AUTH.company.branding.hero_image : courier_boy} alt="" className="courier_boy" />
				</div>

			</Col>
			<Col span={12} className="form_corporate">
				<div className="login__page__wrapper">
					<div className="login__page__title__wrapper ">
						<div className="logo_corporater">
							<img
								src={(AUTH.company) ? AUTH.company.branding.logo : logo}
								alt="Logo"
							/>
						</div>
						<Form form={form} onFinish={handleSubmit} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} className="corporate_login_form">
							<InputComponent
								label="Username"
								type="text"
								hasFeedback
								name="username"
								placeholder="Username"
								onChange={handleChangePassword}
								onFocus={handleChangePassword}
								rules={vsmAuth.validation.email}
							/>

							<InputComponent
								className="corporate_input"
								hasFeedback
								label="Password"
								type="password"
								name="password"
								placeholder="Password"
								onChange={handleChangePassword}
								onFocus={handleChangePassword}
								rules={vsmAuth.validation.password}
							/>
							{captcha_on_login &&
								<Row className="captcha" gutter={15}>
									<Col span={12}>
										<InputComponent
											type="text"
											name="captcha"
											label="Captcha verification"
											disabled
											onCopy={(e) => {
												e.preventDefault();
												return false;
											}}
										/>
									</Col>
									<Col span={10}>
										<InputComponent
											type="text"
											placeholder="CAPTCHA"
											name="re_captcha"
											rules={vsmAuth.validation.recaptcha}
											onChange={handleChangePassword}
										/>
									</Col>
									<Col span={2} >
										<Button
											type="text"
											title={"Resend Captcha"}
											className="reSend"
											size="large"
											style={{ padding: 7 }}
											onClick={CaptchaChange}
										>
											<FontAwesomeIcon icon={faSync} />
										</Button>
									</Col>
								</Row>
							}
							<Form.Item className="remember_forgot_wrap">
								<InputComponent
									hasFeedback
									type="checkbox"
									name="remember"
									label="Remember me"
									valuePropName="checked"
								/>
								<Link
									to="/forgot-password"
									type="link"
									className="forgot_pass_link login-form-forgot"
								>
									Forgot password?
								</Link>
							</Form.Item>
							<div className="remember_forgot_wrap">

							</div>
							<Button
								disabled={disabled}
								htmlType="submit"
								block
								type="primary"
								size="large"
								loading={saving}
							>
								sign in
							</Button>
						</Form>

					</div>
					<div className="ver_mac_info">
						<div className="version">
							<h3>V-<span>{(AUTH.version ?? Constant.UNDEFINED_VALUES)}</span></h3>
						</div>
						<Tooltip
							overlayClassName="macInfo"
							placement="topLeft"
							title={macInfo}
						>
							<InfoCircleOutlined />
						</Tooltip>
					</div>
				</div>
			</Col>
		</Row>
	);
});

export default Login;
