import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import LocalGridConfig from "../../../../../config/LocalGridConfig";
import { Constant } from "../../../../../config/Constant";
//import initData from "./../../../../json/init.json";

export default class ManageInscanAndOutscanActivitiesStore {
    detail_filter = null;
    agGridDetail = null;
    per_page_detail = LocalGridConfig.options.paginationPageSize;
    current_page_detail = 1;
    list_data_detail = null;
    total_detail = 0;
    allColumnIdsDetail = [];
    //This is for summary
    summary_filter = null;
    agGridSummary = null;
    per_page_summary = LocalGridConfig.options.paginationPageSize;
    current_page_summary = 1;
    list_data_summary = null;
    total_summary = 0;
    allColumnIdsSummary = [];

    // set form values to  customefilter
    setDetailFilterValues = (data = null) => {
        return new Promise((resolve) => {
            this.detail_filter = data;
            if (this.agGridDetail) {
                this.setupGridDetail(this.agGridDetail);
            }
            resolve()
        });
    };

    // Setup grid and set column size to autosize
    setupGridDetail = (params) => {
        this.agGridDetail = params;
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSizeDetail = (page = this.per_page_detail) => {
        this.per_page_detail = page;
        if (this.agGridDetail) {
            this.agGridDetail.api.paginationSetPageSize(parseInt(page));
        }
    };

    // Filter function for no record found message
    onFilterChangedDetail = (params) => {
        this.agGridDetail = params;
        if (this.agGridDetail && this.agGridDetail.api.getModel().getRowCount() === 0) {
            this.agGridDetail.api.showNoRowsOverlay();
        }
        if (this.agGridDetail && this.agGridDetail.api.getModel().getRowCount() > 0) {
            this.agGridDetail.api.hideOverlay();
        }
    };

    // call api to get records
    getListDetail = () => {
        if (this.agGridDetail) {
            var filter = this.agGridDetail.api.getFilterModel();
            var sort = this.agGridDetail.api.getSortModel();
        }
        this.list_data_detail = null;
        let detail_filter = this.generatePayload(this.detail_filter);
        return Axios.post(`/reports/daily-activities/inscan-outscan`, { advance_filter: detail_filter }).then(({ data }) => {
            //let data = initData;

            if (data.list.detail.length) {
                data.list.detail.map((item, index) => {
                    item.srno = index + 1;
                    // item.is_active_display =
                    //     item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
                    return null;
                });
            }
            this.list_data_detail = data.list.detail ? data.list.detail : null;
            this.total_detail = data.list.detail.total_detail;
            this.current_page_detail = data.list.detail.current_page;
            var allColumnIdsDetail = [];
            if (this.agGridDetail && this.agGridDetail.columnApi) {
                this.agGridDetail.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIdsDetail.push(column.colId);
                });
            }
            if (this.agGridDetail) {
                this.agGridDetail.api.setFilterModel(filter);
                this.agGridDetail.api.setSortModel(sort);
            }
        });
    };

    // set form values to  customefilter
    setSummaryFilterValues = (data = null) => {
       return new Promise((resolve) => {
            this.summary_filter = data;
            if (this.agGridSummary) {
                this.setupGridSummary(this.agGridSummary);
            }
            resolve()
        });
    };

    // Setup grid and set column size to autosize
    setupGridSummary = (params) => {
        this.agGridSummary = params;
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSizeSummary = (page = this.per_page_summary) => {
        this.per_page_summary = page;
        if (this.agGridSummary) {
            this.agGridSummary.api.paginationSetPageSize(parseInt(page));
        }
    };

    // Filter function for no record found message
    onFilterChangedSummary = (params) => {
        this.agGridSummary = params;
        if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() === 0) {
            this.agGridSummary.api.showNoRowsOverlay();
        }
        if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() > 0) {
            this.agGridSummary.api.hideOverlay();
        }
    };

    generatePayload = (summary_filter) => {
        if(summary_filter.from_date){
            summary_filter.from_date = moment(summary_filter.from_date).format(Constant.PAYLOAD_DATE);
        }
        if(summary_filter.to_date){
            summary_filter.to_date = moment(summary_filter.to_date).format(Constant.PAYLOAD_DATE);
        }
        return summary_filter;
    }
    // call api to get records
    getListSummary = () => {
        if (this.agGridSummary) {
            var filter = this.agGridSummary.api.getFilterModel();
            var sort = this.agGridSummary.api.getSortModel();
        }
        this.list_data_summary = null;
        let summary_filter = this.generatePayload(this.summary_filter);
        return Axios.post(`/reports/daily-activities/inscan-outscan`, {advance_filter: summary_filter}).then(({ data }) => {
            //let data = initData;
            if (data.list.data.length) {
                data.list.data.map((item, index) => {
                    item.srno = index + 1;
                    // item.is_active_display =
                    //     item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
                    return null;
                });
            }
            this.list_data_summary = data.list ? data.list.data : null;
            this.total_summary = data.list.data.total_summary;
            this.current_page_summary = data.list.data.current_page;
            var allColumnIdsSummary = [];
            if (this.agGridSummary && this.agGridSummary.columnApi) {
                this.agGridSummary.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIdsSummary.push(column.colId);
                });
            }
            if (this.agGridSummary) {
                this.agGridSummary.api.setFilterModel(filter);
                this.agGridSummary.api.setSortModel(sort);
            }
        });
    };
}

decorate(ManageInscanAndOutscanActivitiesStore, {
    per_page_detail: observable,
    agGridDetail: observable,
    list_data_detail: observable,
    total_detail: observable,
    allColumnIdsDetail: observable,
    setSummaryFilterValues:action,
    setupGridDetail: action,
    setPageSizeDetail: action,
    getListDetail: action,
    onFilterChangedDetail: action,
    per_page_summary: observable,
    agGridSummary: observable,
    list_data_summary: observable,
    total_summary: observable,
    allColumnIdsSummary: observable,
    setupGridSummary: action,
    setPageSizeSummary: action,
    getListSummary: action,
    onFilterChangedSummary: action,
});
