import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class ManageCoLoaderStore {
	dropdown_rate_card_account_party_list = null;
	dropdown_rate_card_account_party_source_list = null;	

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`organization/account-parties/replace-acc-party-rate-card`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getRateCardAccountPartiesSourceList = (conditional = {}) => {
		return Axios.post(`organization/account-parties/rate-card/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_rate_card_account_party_source_list = data.account_party;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})

	};

	getRateCardAccountPartiesList = (conditional = {}) => {
		return Axios.post(`organization/account-parties/rate-card/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_rate_card_account_party_list = data.account_party;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})

	};

	JsonToFormData = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach(key => {
		  if (!['source_id', 'level_id', "created_by", "dest_id"].includes(key)) {
			formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
		  }
		})
		return formData
	  }
}

decorate(ManageCoLoaderStore, {
	dropdown_rate_card_account_party_list: observable,
	getRateCardAccountPartiesList: action,
	getRateCardAccountPartiesSourceList:action,
	dropdown_rate_card_account_party_source_list:observable
});
