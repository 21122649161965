import React from "react";
import { Button, Modal, Col, Row } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const AlertModalComponent = observer((props) => {

	// reset form and close add form
	const close = () => {
		props.close();
	};

	return (
		<Modal
			centered
			className={props.className ? props.className : "deleteModal"}
			title={props.title}
			visible={props.visible}
			closable={false}
			keyboard={false}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			zIndex={1001}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn"
					onClick={close}
				>
					Ok
				</Button>
			]}
		//footer={false}
		>
			<Row align="middle">
				<Col span={24} className="text-center">
					<h3>
						<span><FontAwesomeIcon icon={faExclamationTriangle} /></span> {props.messages}
						{props.note &&
							<div>
								<strong className="text-red red"> Note : {props.note}</strong>
							</div>
						}
					</h3>
				</Col>
			</Row>
		</Modal>
	);
});

export default AlertModalComponent;