import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../../utils/GlobalFunction";

export default class ManageVehiclesStore {
    agGrid = null;
    per_page = LocalGridConfig.options.paginationPageSize;
    current_page = 1;
    list_data = null;
    editValues = null;
    deleteValues = null;
    statusValues = null;
    total = 0;
    allColumnIds = [];
    dropdown_state_list = null; 
    typeMode = null;
    viewValues = null;
    DocumentUploadFormDetails = null;
    dropdown_vehicleNumber_list = null;

    // set form values to edit
    setEditValues = (data) => {
        this.editValues = data;
    };
    setTypeMode = (mode) => {
        this.typeMode = mode;
    }

    // set form values to delete
    setDeleteValues = (data) => {
        this.deleteValues = data;
    };
    // set form values to view
    setViewValues = (data) => {
        this.viewValues = data;
    };

    // set form values to status
    setStatusValues = (data) => {
        this.statusValues = data;
    };

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params;
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSize = (page = this.per_page) => {
        this.per_page = page;
        if (this.agGrid) {
            this.agGrid.api.paginationSetPageSize(parseInt(page));
        }
    };

    // call api to get records
    getList = () => {
        if (this.agGrid) {
            var filter = this.agGrid.api.getFilterModel();
            var sort = this.agGrid.api.getSortModel();
        }
        this.list_data = null;
        return Axios.get(`/admin/vehicles/list`).then(({ data }) => {
            if (data.list.data.length) {
                data.list.data.map((item, index) => {
                    item.srno = index + 1;
                    item.is_active_display =
                        item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
                    return null;
                });
            }
            this.list_data = data.list ? data.list.data : null;
            this.total = data.list.total;
            this.current_page = data.list.current_page;
            var allColumnIds = [];
            if (this.agGrid && this.agGrid.columnApi) {
                this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIds.push(column.colId);
                });
            }
            if (this.agGrid) {
                this.agGrid.api.setFilterModel(filter);
                this.agGrid.api.setSortModel(sort);
            }
        });
    };

    // call api to get records
    ViewData = (ID) => {
        return Axios.get(`admin/vehicles/details/${ID}`).then(({ data }) => {
            this.setViewValues(data.view);
            return data;
        });
    };

    // Call add api
    AddData = (formdata) => {
        return Axios.post(`admin/vehicles/new`, formdata)
            .then(({ data }) => {
                this.getList();
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // Call edit api
    EditData = (id, formdata) => {
        return Axios.post(`admin/vehicles/edit/${id}`, formdata)
            .then(({ data }) => {
                this.getList();
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // Call delete api
    DeleteData = (formdata) => {
        return Axios.delete(`admin/vehicles/destroy/` + formdata.id)
            .then(({ data }) => {
                this.getList();
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    //Get Default KYC Form 
    getDocDetails = (conditional = {}) => {
        conditional.id = this.viewValues.id;
        return Axios.post(`admin/vehicles/doc-details`, conditional).then(({ data }) => {
            this.DocumentUploadFormDetails = data.doc_types;
            return data;
        }).catch((response) => {
            return Promise.reject(response);
        });
    };

    // Call delete api
    deleteDocData = (formdata) => {
        return Axios.delete(`admin/vehicles/doc-delete/${formdata.doc_id}/${formdata.vehicle_id}`)
            .then(({ data }) => {
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    uploadDocData = (formdata) => {
        return Axios.post(`admin/vehicles/doc-upload`, formdata)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    TogglePublishData = (formdata) => {
        const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
        return Axios.patch("admin/vehicles/" + api_link + formdata.id)
            .then(({ data }) => {
                this.getList();
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params;
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
            this.agGrid.api.hideOverlay();
        }
    };

    getVehiclesList = (conditional = {}) => {
        return Axios.post(`/admin/vehicles/lov-vehicle-ownership`, conditional)

            .then(({ data }) => {
                this.dropdown_vehicle_list = data.lov_vehicle_ownership;

                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    getFuelTypeList = (conditional = {}) => {
        return Axios.post(`/admin/vehicles/lov-fuel-types`, conditional)

            .then(({ data }) => {
                this.dropdown_FuelType_list = data.lov_fuel_types;

                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    getVehicleTypeList = (conditional = {}) => {
        return Axios.post(`/admin/vehicle-types/lov`, conditional)
            .then(({ data }) => {
                this.dropdown_VehicleType_list = data.lov_vehicle_types;
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    getVehicleNumberList = (conditional = {}) => {
        return Axios.post('/admin/vehicles/lov', conditional)
            .then(({ data }) => {
                this.dropdown_vehicleNumber_list = data.vehicles;
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            })
    }

    JsonToFormData = (data) => {
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            if (!['id', 'front_img', 'back_img', 'side_img', "reg_date", "ins_due_date", "fc_due_date", "puc_due_date", "loan_start_date", "loan_end_date", "permit_due_date", "loan_flag"].includes(key)) {
                formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
            }
        })
        return formData
    }
}

decorate(ManageVehiclesStore, {
    per_page: observable,
    agGrid: observable,
    list_data: observable,
    editValues: observable,
    deleteValues: observable,
    statusValues: observable,
    dropdown_state_list: observable,
    total: observable,
    dropdown_vehicle_list: observable,
    dropdown_VehicleType_list: observable,
    allColumnIds: observable,
    setupGrid: action,
    setPageSize: action,
    setEditValues: action,
    setDeleteValues: action,
    setStatusValues: action,
    getList: action,
    getStatesList: action,
    onFilterChanged: action,
    typeMode: observable,
    setTypeMode: action,
    setViewValues: action,
    DocumentUploadFormDetails: observable,
    dropdown_vehicleNumber_list: observable,
    getVehicleNumberList: action,
    getDocDetails: action,
    finishKycData: action,
    uploadDocData: action,
});
