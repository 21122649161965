import { faEye, faPencilAlt, faPhoneAlt, faTimes, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, Col, Collapse, Divider, Drawer, PageHeader, Popover, Row } from "antd";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import { useEffect, useState } from "react";
import { convertDate, isActiveStatus } from "../../../../utils/GlobalFunction";
import { Constant } from "../../../../config/Constant";

import EditComponent from "../../organization/Center/component/EditComponent";
import LegalOperationsComponent from "../../organization/Center/component/LegalOperationsComponent";
import KycFormComponent from "../../organization/Center/component/KycFormComponent";
import BankInfoComponent from "../../organization/Center/component/BankInfoComponent";
import ContactInfoComponent from "../../organization/Center/component/ContactInfoComponent";
import CoverImageComponent from "../../organization/Center/component/CoverImageComponent";
import LocationComponent from "../../organization/Center/component/LocationComponent";
import { default as EditCashBookingComponent } from "../../business/CashBookingPolicy/component/EditComponent";
import { default as ViewCashRateCardModal } from "../../business/RateCardCash/component/ViewComponent";
import ZoneViewComponent from "../../business/RateCardCash/zoneComponent/ViewComponent";


const { Panel } = Collapse;
const LocationsProfile = observer((props) => {
    const {
        AUTH: { checkPrivileges },
        ManageCountryStore: { getCountriesList },
        ManageCentersStore,
        ManageCashBookingPolicyStore,
        ManageRateCardCashStore
    } = useStore();

    const [editModal, setEditModal] = useState(false);
    const [legalOperationModal, setLegalOperationModal] = useState(false);
    const [kycModal, setKycModal] = useState(false);
    const [bankInfoModal, setBankInfoModal] = useState(false);
    const [contactInfoModal, setContactInfoModal] = useState(false);
    const [coverImageModal, setCoverImageModal] = useState(false);
    const [locationModal, setLocationModal] = useState(false);
    const [cashBookingPolicyModal, setCashBookingPolicyModal] = useState(false);
    const [viewCashRateCardModal, setViewCashRateCardModal] = useState(false);
    const [viewZoneModal, setViewZoneModal] = useState(false);

    useEffect(() => {
        //setAvtarValues(user)
    })

    const locked = (
        <Row gutter={6} className="">Locked
            <Col className="text-right">
                {ManageCentersStore.viewValues?.is_locked === 1 ? (
                    <>
                        <div className="isactive_tag ant-tag ant-tag-success">
                            {isActiveStatus[1]}{" "}
                        </div>
                    </>
                ) : (
                    <div className="isactive_tag ant-tag ant-tag-error">
                        {isActiveStatus[0]}
                    </div>
                )}
            </Col>
        </Row>
    )
    const Terminated = (
        <Row gutter={6} >
            <Col>Terminated</Col>
            <Col>
                {ManageCentersStore.viewValues?.is_terminated === 1 ? (
                    <>
                        <div className="isactive_tag ant-tag ant-tag-success">
                            {isActiveStatus[1]}{" "}
                        </div>
                    </>
                ) : (
                    <div className="isactive_tag ant-tag ant-tag-error">
                        {isActiveStatus[0]}
                    </div>
                )}
            </Col>
        </Row>
    )
    const address = (
        <Row gutter={6}>
            <Col span={22}>Address & Contact Info</Col>
        </Row>
    )
    const profileClose = () => {
        props.close();
    };
    const data = ManageCentersStore.viewValues;

    // Open & Close  form for edit State
    const openEditModal = (data, viewType) => {
        ManageCentersStore.setEditValues(data)
        getCountriesList({ is_active: 1 }).then(() => {
            ManageCentersStore.ViewData(data.id).then((data) => {
                let type = checkCenterIsViewOnly(data.view, viewType);
                ManageCentersStore.setTypeMode(type);
                setEditModal(true);
            })
        });
    };
    const closeEditModal = () => {
        setEditModal(false);
        ManageCentersStore.setTypeMode(null);
    }

    const openLegalOperationModal = () => {
        ManageCentersStore.getLegalOperationData().then(() => {
            setLegalOperationModal(true);
        })
    }
    const closeLegalOperationModal = () => {
        profileClose();
        setLegalOperationModal(false);
        ManageCentersStore.setTypeMode(null);
    }

    const openKycModal = () => {
        ManageCentersStore.getKycForm().then(() => {
            setKycModal(true);
        })
    }
    const closeKycModal = () => {
        profileClose();
        setKycModal(false);
        ManageCentersStore.setTypeMode(null);
    }

    const openBankInfoModal = () => {
        if (ManageCentersStore && ManageCentersStore.editValues && ManageCentersStore.editValues.center_bank_acc && ManageCentersStore.editValues.center_bank_acc.id) {
            getCountriesList({ is_active: 1 }).then(() => {
                ManageCentersStore.getBankInfoForm().then(() => {
                    setBankInfoModal(true);
                })
            });
        } else {
            getCountriesList({ is_active: 1 }).then(() => {
                setBankInfoModal(true);
            });
        }
    }
    const closeBankInfoModal = () => {
        profileClose();
        setBankInfoModal(false);
        ManageCentersStore.setTypeMode(null);
    }

    const openContactInfoModal = () => {
        ManageCentersStore.getContactInfoForm().then(() => {
            setContactInfoModal(true);
        })
    }
    const closeContactInfoModal = () => {
        profileClose();
        setContactInfoModal(false);
        ManageCentersStore.setTypeMode(null);
    }

    // Open & Close  form for Cover Image
    const openCoverImageModal = () => {
        setCoverImageModal(true);
    };
    // const openViewModal = () => setViewModal(true);
    const closeCoverImageModal = () => {
        setCoverImageModal(false);
        ManageCentersStore.setTypeMode(null);
    }

    // Open & Close  form for Cover Image
    const openLocationModal = () => {
        setLocationModal(true);
    };
    // const openViewModal = () => setViewModal(true);
    const closeLocationModal = () => {
        setLocationModal(false);
        ManageCentersStore.setTypeMode(null);
    }

    // Open & Close  form for Cover Image
    const openCashBookingPolicyModal = (data, viewType) => {
        let type = viewType;
        if (viewType !== Constant.VIEW) {
            type = checkCenterIsViewOnly(data, viewType);
        }
        ManageCashBookingPolicyStore.setTypeMode(type);
        //setEditValues(data);
        ManageCentersStore.ViewData(data.id).then((data) => {
            if (data && data.view && data.view.bm_booking_policy && data.view.bm_booking_policy.id) {
                ManageCashBookingPolicyStore.setEditValues(data.view.bm_booking_policy);
                setCashBookingPolicyModal(true);
            } else if (data && data.view && data.view.bm_booking_policy_id) {
                ManageCashBookingPolicyStore.ViewData(data.view.bm_booking_policy_id).then((data) => {
                    setCashBookingPolicyModal(true);
                })
            } else {
                setCashBookingPolicyModal(true);
            }
        });
    };
    // const openViewModal = () => setViewModal(true);
    const closeCashBookingPolicyModal = () => {
        setCashBookingPolicyModal(false);
        ManageCashBookingPolicyStore.setTypeMode(null);
    }

    const openViewCashRateCardModal = (data) => {
        let formData = {};
        formData.type = Constant.VIEW;
        formData.id = data.id;
        ManageRateCardCashStore.ViewData(formData).then((data) => {
            ManageCentersStore.setEditValues(data.center);
            setViewCashRateCardModal(true)
        });
    }
    const closeViewCashRateCardModal = () => {
        setViewCashRateCardModal(false);
        ManageCentersStore.setEditValues(null);
    }

    // Open & Close  form for view Zone
    const openViewZoneModal = (id, rc_id) => {
        ManageRateCardCashStore.ViewZoneData(id, rc_id).then(() => setViewZoneModal(true));
    }
    const closeViewZoneModal = () => {
        ManageRateCardCashStore.setZoneViewValues(null)
        ManageRateCardCashStore.setViewZoneModal(false);
    }

    const checkCenterIsViewOnly = (data, type = "") => {
        let response = Constant.VIEW;
        if (checkPrivileges("#12065#") && type === Constant.EDIT) {
            response = Constant.EDIT;
        }
        if (data.is_terminated) {
            response = Constant.VIEW;
        } else if (!data.is_active) {
            response = Constant.VIEW;
        }
        return response;
    }

    const showModelFromList = (modalName, viewType) => {
        let data = ManageCentersStore.viewValues;
        ManageCentersStore.setEditValues(data);
        let type = checkCenterIsViewOnly(data, viewType);
        ManageCentersStore.setTypeMode(type);
        switch (modalName) {
            case "lo":
                openLegalOperationModal()
                break;
            case "kyc":
                openKycModal()
                break;
            case "bankInfo":
                openBankInfoModal()
                break;
            case "contactInfo":
                openContactInfoModal()
                break;
            case "coverImage":
                openCoverImageModal()
                break;
            case "location":
                openLocationModal()
                break;

            default:
                break;
        }
    }

    return (
        ManageCentersStore.viewValues && (
            <>
                <Drawer
                    className="LocationsProfileDesign"
                    width="35%"
                    visible={props.visible}
                    closeIcon={<FontAwesomeIcon icon={faTimes} />}
                    onClose={profileClose}
                    destroyOnClose={true}
                    footer={[
                        <Button
                            key="2"
                            htmlType="button"
                            className="cancelBtn mr-35"
                            onClick={profileClose}
                        >
                            Cancel
                        </Button>
                    ]}
                >
                    <PageHeader
                        className="userInfo_Modal LocationsProfileInfo_Modal"
                    >
                        <Row gutter={6} className="avtar_row">
                            <Col className="form__input__textbox">
                                <Avatar src={(data.cover_photo) ? (Constant.S3BUCKET_API_BASEURL + data.cover_photo) : (Constant.S3BUCKET_API_BASEURL + `branding/LocationsProfile.png`)} />
                                {checkPrivileges('#13002#') && (<Button
                                    className="userInfo_Icon"
                                    onClick={() => {
                                        showModelFromList("coverImage", Constant.EDIT)
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>)}
                            </Col>
                        </Row>
                        <Row className="avtar_row text-center  more_info_sec">
                            <Col span={24}>
                                <h2>{(data.name) ? (data.name) : Constant.UNDEFINED_VALUES}</h2>
                            </Col>
                            <Col span={24}>
                                <h3>{(data.company) ? (data.company) : Constant.UNDEFINED_VALUES}</h3>
                            </Col>
                            <Col span={24}>
                                <h4>{(data.owner_name) ? (data.owner_name) : Constant.UNDEFINED_VALUES}</h4>
                            </Col>
                        </Row>
                        <Divider />
                        <Row className="position-re">
                            <Col span={21}>
                                <Collapse
                                    ghost
                                    bordered={false}
                                    //expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                    className="moreInfoCollapse more_info_sec pr-0"
                                    expandIconPosition={"end"}
                                >
                                    <Panel header={"Parent info"} key="1">
                                        <Row gutter={6} className="more_info_sec more_info_sec_table">
                                            <Col span={12}>
                                                <label>Franchisee</label>
                                            </Col>
                                            <Col span={12}>
                                                <span>{(data.mst_franchisee?.company) ? (data.mst_franchisee?.company) : Constant.UNDEFINED_VALUES}</span>
                                            </Col>
                                            <Col span={12}>
                                                <label>Hub</label>
                                            </Col>
                                            <Col span={12}>
                                                <span>{(data.mst_hub?.name) ? (data.mst_hub?.name) : Constant.UNDEFINED_VALUES}</span>
                                            </Col>
                                            <Col span={12}>
                                                <label>Operational State</label>
                                            </Col>
                                            <Col span={12}>
                                                <span>{(data.states?.name) ? (data.states?.name) : Constant.UNDEFINED_VALUES}</span>
                                            </Col>
                                            <Col span={12}>
                                                <label>From Pincode</label>
                                            </Col>
                                            <Col span={12}>
                                                <span>{(data.from_pincode) ? (data.from_pincode?.pincode) : Constant.UNDEFINED_VALUES}</span>
                                            </Col>
                                        </Row>
                                    </Panel>
                                </Collapse>
                            </Col>
                            <Col span={3} onClick={() => { openEditModal(ManageCentersStore.viewValues, Constant.EDIT); }} className="text-right profileDesignicon_sp">
                                <Button className="profileDesignicon">
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Col>
                        </Row>


                        <Divider />
                        <Collapse
                            ghost
                            bordered={false}
                            //expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            className="moreInfoCollapse more_info_sec"
                            expandIconPosition={"end"}
                        >
                            <Panel header={address} key="1">
                                <Row gutter={6} className="more_info_sec">
                                    <p style={{ width: "100%" }}>{(data.address) ?? Constant.UNDEFINED_VALUES}</p>
                                    <p><span className="mr-5"><FontAwesomeIcon icon={faPhoneAlt} /></span>{data.mobile} {data.mobile2 && `, ${data.mobile2}`} {data.phone1 && `, ${data.phone1}`} {data.phone2 && `, ${data.phone2}`} </p>
                                </Row>
                            </Panel>
                        </Collapse>
                        <Divider />
                        {data?.is_locked !== 1 &&
                            <Row gutter={6} className="more_info_sec">
                                <Col>Locked</Col>
                                <Col>
                                    {data?.is_locked === 1 ? (
                                        <>
                                            <div className="isactive_tag ant-tag ant-tag-success">
                                                {isActiveStatus[1]}{" "}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="isactive_tag ant-tag ant-tag-error">
                                            {isActiveStatus[0]}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        }

                        {data?.is_locked === 1 &&
                            <Collapse
                                ghost
                                bordered={false}
                                //expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                className="moreInfoCollapse more_info_sec"
                                expandIconPosition={"end"}
                            >
                                <Panel header={locked} key="1">
                                    <Row gutter={6} className="more_info_sec more_info_sec_table">
                                        <Col span={12}>
                                            <label>Locked On</label>
                                        </Col>
                                        <Col span={12}>
                                            {(data.locked) ? convertDate(data.locked) : Constant.UNDEFINED_VALUES}
                                        </Col>
                                        <Col span={12}>
                                            <label>By Whom</label>
                                        </Col>
                                        <Col span={12}>
                                            <span>{data.locked_by?.name} (<span>{data.locked_by?.email}</span>)</span>
                                        </Col>
                                        <Col span={12}>
                                            <label>Note</label>
                                        </Col>
                                        <Col span={12}>
                                            <span>{(data.lock_note) ? (data.lock_note) : Constant.UNDEFINED_VALUES}</span>
                                        </Col>
                                    </Row>

                                </Panel>
                            </Collapse>}

                        <Divider />
                        {data?.is_Terminated !== 1 &&
                            <Row gutter={6} className="more_info_sec">
                                <Col>Terminated</Col>
                                <Col >
                                    {data?.is_terminated === 1 ? (
                                        <>
                                            <div className="isactive_tag ant-tag ant-tag-success">
                                                {isActiveStatus[1]}{" "}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="isactive_tag ant-tag ant-tag-error">
                                            {isActiveStatus[0]}
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        }
                        {data?.is_terminated === 1 &&
                            <Collapse
                                ghost
                                bordered={false}
                                //expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                className="moreInfoCollapse more_info_sec"
                                expandIconPosition={"end"}
                            >
                                <Panel header={Terminated} key="1">
                                    <Row gutter={6} className="more_info_sec more_info_sec_table">
                                        <Col span={12}>
                                            <label>Terminated On</label>
                                        </Col>
                                        <Col span={12}>
                                            <span>{(data.terminated) ? convertDate(data.terminated) : Constant.UNDEFINED_VALUES}</span>
                                        </Col>
                                        <Col span={12}>
                                            <label>By Whom</label>
                                        </Col>
                                        <Col span={12}>
                                            <span>{data.terminated_by?.name} (<span>{data.terminated_by?.email}</span>)</span>
                                        </Col>
                                        <Col span={12}>
                                            <label>Note</label>
                                        </Col>
                                        <Col span={12}><span>{(data.terminate_note) ? (data.terminate_note) : Constant.UNDEFINED_VALUES}</span>
                                        </Col>
                                    </Row>
                                </Panel>
                            </Collapse>
                        }
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col>Active</Col>
                            <Col>
                                {data?.is_active === 1 ? (
                                    <>
                                        <div className="isactive_tag ant-tag ant-tag-success">
                                            {isActiveStatus[1]}{" "}
                                        </div>
                                    </>
                                ) : (
                                    <div className="isactive_tag ant-tag ant-tag-error">
                                        {isActiveStatus[0]}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col span={22}>Legal Info
                                {data?.lo_flag === 1 ? (
                                    <div className="isactive_tag ant-tag ant-tag-success">
                                        Completed
                                    </div>
                                ) : (<div className="isactive_tag ant-tag ant-tag-error">
                                    Pending
                                </div>)
                                }
                            </Col>
                            <Col span={2} onClick={() => showModelFromList("lo", Constant.VIEW)}>
                                <Button className="profileDesignicon viewIconDesignicon">
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col span={22}>KYC
                                {data?.kyc_flag === 1 ? (
                                    <div className="isactive_tag ant-tag ant-tag-success">
                                        Completed
                                    </div>
                                ) : (<div className="isactive_tag ant-tag ant-tag-error">
                                    Pending
                                </div>)
                                }
                            </Col>
                            <Col span={2} onClick={() => showModelFromList("kyc", Constant.EDIT)}>
                                <Button className="profileDesignicon">
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col span={22}>Bank Info</Col>
                            <Col span={2} onClick={() => showModelFromList("bankInfo", Constant.View)}>
                                <Button className="profileDesignicon viewIconDesignicon">
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col span={22}>Booking Policy - Cash
                                {data?.cb_flag === 1 ? (
                                    <div className="isactive_tag ant-tag ant-tag-success">
                                        Completed
                                    </div>
                                ) : (<div className="isactive_tag ant-tag ant-tag-error">
                                    Pending
                                </div>)
                                }

                            </Col>
                            {data?.cb_flag === 1 ?
                                (
                                    <Col span={2} onClick={() => {
                                        openCashBookingPolicyModal(data, Constant.VIEW);
                                    }}>
                                        <Button className="profileDesignicon viewIconDesignicon">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                    </Col>
                                ) :
                                <Col span={2}>
                                    <Popover content="Pending" placement="topRight" >
                                        <Button className="profileDesignicon viewIconDesignicon">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                    </Popover>
                                </Col>
                            }
                        </Row>
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col span={22}>Rate Card - Cash
                                {data.bm_rate_card ? (
                                    <div className="isactive_tag ant-tag ant-tag-success">
                                        Completed
                                    </div>
                                ) : (<div className="isactive_tag ant-tag ant-tag-error">
                                    Pending
                                </div>)
                                }
                            </Col>
                            {data.bm_rate_card ? (
                                <Col span={2} onClick={() => { openViewCashRateCardModal(data.bm_rate_card); }}>
                                    <Button className="profileDesignicon viewIconDesignicon">
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>
                                </Col>
                            ) :
                                <Col span={2}>
                                    <Popover content="Pending" placement="topRight" >
                                        <Button className="profileDesignicon viewIconDesignicon">
                                            <FontAwesomeIcon icon={faEye} />
                                        </Button>
                                    </Popover>
                                </Col>
                            }
                        </Row>
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col span={22}>Contract
                                {data?.contract_flag === 1 ? (
                                    <div className="isactive_tag ant-tag ant-tag-success">
                                        Completed
                                    </div>
                                ) : (<div className="isactive_tag ant-tag ant-tag-error">
                                    Pending
                                </div>)
                                }
                            </Col>

                            <Col span={2}>
                                <Popover content={Constant.COMING_SOON} placement="topRight" >
                                    <Button className="profileDesignicon viewIconDesignicon">
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>
                                </Popover>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col span={22}>Slab Rate Card
                                {data?.contract_flag === 1 ? (
                                    <div className="isactive_tag ant-tag ant-tag-success">
                                        Completed
                                    </div>
                                ) : (<div className="isactive_tag ant-tag ant-tag-error">
                                    Pending
                                </div>)
                                }
                            </Col>
                            <Col span={2}>
                                <Popover content={Constant.COMING_SOON} placement="topRight" >
                                    <Button className="profileDesignicon viewIconDesignicon">
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>
                                </Popover>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={6} className="more_info_sec">
                            <Col span={22}>Piece Rate Card
                                {data?.contract_flag === 1 ? (
                                    <div className="isactive_tag ant-tag ant-tag-success">
                                        Completed
                                    </div>
                                ) : (<div className="isactive_tag ant-tag ant-tag-error">
                                    Pending
                                </div>)
                                }
                            </Col>
                            <Col span={2}>
                                <Popover content={Constant.COMING_SOON} placement="topRight" >
                                    <Button className="profileDesignicon viewIconDesignicon">
                                        <FontAwesomeIcon icon={faEye} />
                                    </Button>
                                </Popover>
                            </Col>
                        </Row>
                        <Divider />
                        {/* <Row gutter={6} className="more_info_sec">
                            <Col span={22}>Contacts</Col>
                            <Col span={2} onClick={() => showModelFromList("contactInfo", Constant.EDIT)}>
                                <Button className="profileDesignicon">
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Col>
                        </Row>
                        <Divider /> */}
                        <Row gutter={6} className="more_info_sec mb-20">
                            <Col span={22}>Location On Map</Col>
                            <Col span={2} onClick={() => showModelFromList("location", Constant.EDIT)}>
                                <Button className="profileDesignicon">
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>
                            </Col>
                        </Row>
                    </PageHeader>
                </Drawer>

                <EditComponent visible={editModal} close={closeEditModal} showErrorNotes={false} />
                <LegalOperationsComponent visible={legalOperationModal} close={closeLegalOperationModal} showErrorNotes={false} />
                <KycFormComponent visible={kycModal} close={closeKycModal} showErrorNotes={false} />
                <BankInfoComponent visible={bankInfoModal} close={closeBankInfoModal} showErrorNotes={false} />
                <ContactInfoComponent visible={contactInfoModal} close={closeContactInfoModal} showErrorNotes={false} />
                <CoverImageComponent visible={coverImageModal} close={closeCoverImageModal} showErrorNotes={false} />
                <LocationComponent visible={locationModal} close={closeLocationModal} showErrorNotes={false} />
                <EditCashBookingComponent visible={cashBookingPolicyModal} close={closeCashBookingPolicyModal} showErrorNotes={false} openFrom={"location"} />
                <ViewCashRateCardModal visible={viewCashRateCardModal} close={closeViewCashRateCardModal} openViewZoneModal={openViewZoneModal} />
                <ZoneViewComponent visible={viewZoneModal} close={closeViewZoneModal} openFrom={"locationProfile"} />
            </>
        )
    )
})
export default LocationsProfile;