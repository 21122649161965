import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class ManageTrainingVideosStore {
    list_data = null;
    getList = (conditional = {}) => {
        return Axios.post(`help/help-items/videolist`, conditional).then(({ data }) => {
            this.list_data = data.list
        });
    }
}

decorate(ManageTrainingVideosStore, {
    list_data: observable,
    getList: action
});