import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../../utils/GlobalFunction";

export default class LocationWalletStore {
  agGrid = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  list_data = null;
  editValues = null;
  deleteValues = null;
  statusValues = null;
  total = 0;
  allColumnIds = [];

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to status
  setStatusValues = (data) => {
    this.statusValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  // call api to get records
  getList = () => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.api.getSortModel();
    }
		this.list_data = null;
    return Axios.get(`organization/location-wallet/list`).then(({ data }) => {
      if (data.list.data.length) {
        data.list.data.map((item, index) => {
          item.srno = index + 1;
          item.is_active_display =
            item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
          return null;
        });
      }
      this.list_data = data.list ? data.list.data : null;
      this.total = data.list.total;
      this.current_page = data.list.current_page;
      var allColumnIds = [];
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
      }
      if (this.agGrid) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.api.setSortModel(sort);
      }
      return data;
    });
  };

  // Call add api
  AddManualCreditData = (formdata) => {
    return Axios.post(`organization/location-wallet/manualcreaditadd`, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };
  // Call add api
  AddAdvancedPaymentData = (formdata) => {
		return Axios.post(`organization/location-wallet/advancedpaymentadd`, formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

  AddWalletPaymentData = (formData) => {
    return Axios.post(`organization/location-wallet/payment`, formData)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

}

decorate(LocationWalletStore, {
  per_page: observable,
  agGrid: observable,
  list_data: observable,
  editValues: observable,
  deleteValues: observable,
  statusValues: observable,
  total: observable,
  allColumnIds: observable,
  setupGrid: action,
  setPageSize: action,
  setEditValues: action,
  setDeleteValues: action,
  setStatusValues: action,
  getList: action,
  onFilterChanged: action,
});
