import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../../utils/GlobalFunction";

export default class ManageDriversStore {
  agGrid = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  list_data = null;
  list_receipts_data = null;
  editValues = null;
  deleteValues = null;
  statusValues = null;
  viewValues = null;
  total = 0;
  allColumnIds = [];
  typeMode = null;
  DocumentUploadFormDetails = null;
  dropdown_driver_list = null;

  setTypeMode = (mode) => {
    this.typeMode = mode;
  }

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // set form values to view
  setViewValues = (data) => {
    this.viewValues = data;
  };

  // set form values to status
  setStatusValues = (data) => {
    this.statusValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  // call api to get records
  getList = () => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.api.getSortModel();
    }
    this.list_data = null;
    return Axios.get(`admin/drivers/list`).then(({ data }) => {
      if (data.list.data.length) {
        data.list.data.map((item, index) => {
          item.srno = index + 1;
          item.is_active_display =
            item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
          return null;
        });
      }
      this.list_data = data.list ? data.list.data : null;
      this.total = data.list.total;
      this.current_page = data.list.current_page;
      var allColumnIds = [];
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
      }
      if (this.agGrid) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.api.setSortModel(sort);
      }
    });
  };


  // Call add api
  AddData = (formdata) => {
    return Axios.post(`admin/drivers/new`, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // Call edit api
  EditData = (id, formdata) => {
    return Axios.post(`admin/drivers/edit/${id}`, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // call api to get records
  ViewData = (ID) => {
    return Axios.get(`admin/drivers/details/${ID}`).then(({ data }) => {
      this.setViewValues(data.view);
      return data;
    });
  };

  // Call delete api
  DeleteData = (formdata) => {
    return Axios.delete(`admin/drivers/destroy/` + formdata.id)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // Call delete api
  DeleteData = (formdata) => {
    return Axios.delete(`admin/drivers/destroy/` + formdata.id)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  //Get Default KYC Form 
  getDocDetails = (conditional = {}) => {
    conditional.id = this.viewValues.id;
    return Axios.post(`admin/drivers/doc-details`, conditional).then(({ data }) => {
      this.DocumentUploadFormDetails = data.doc_types;
      return data;
    }).catch((response) => {
      return Promise.reject(response);
    });
  };

  // Call delete api
  deleteDocData = (formdata) => {
    return Axios.delete(`admin/drivers/doc-delete/${formdata.doc_id}/${formdata.driver_id}`)
      .then(({ data }) => {
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };
  uploadDocData = (formdata) => {
    return Axios.post(`admin/drivers/doc-upload`, formdata)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  TogglePublishData = (formdata) => {
    const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
    return Axios.patch("admin/drivers/" + api_link + formdata.id)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  getDriverList = (conditional = {}) => {
    return Axios.post('/admin/drivers/lov ', conditional)
      .then(({ data }) => {
        this.dropdown_driver_list = data.drivers;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      })
  }

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
      this.agGrid.api.hideOverlay();
    }
  };
  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (!['id', 'exp_date', "doj", 'avatar', 'licence_img', 'aadhar_img'].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }
}

decorate(ManageDriversStore, {
  per_page: observable,
  agGrid: observable,
  list_data: observable,
  editValues: observable,
  deleteValues: observable,
  statusValues: observable,
  total: observable,
  dropdown_bank_list: observable,
  allColumnIds: observable,
  setupGrid: action,
  setPageSize: action,
  setEditValues: action,
  setDeleteValues: action,
  setStatusValues: action,
  getList: action,
  getStatesList: action,
  setViewValues: action,
  viewValues: observable,
  onFilterChanged: action,
  list_receipts_data: observable,
  typeMode: observable,
  setTypeMode: action,
  DocumentUploadFormDetails: observable,
  getDocDetails: action,
  finishKycData: action,
  uploadDocData: action,
  dropdown_driver_list: observable,
  getDriverList: action
});
