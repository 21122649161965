import React, { useState, useEffect } from "react";
import { Form, Button, Drawer, Row, Col, Divider, Alert, Spin, Tooltip } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify } from "../../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../../../../../config/Constant";
import debounce from "lodash/debounce";
import InputComponent from "../../../../../component/InputComponent";
import { vsmLocation } from "../Validation";
import DefaulHeader from "./DefaulHeader";
import { getFirstRecordValue, properCaseString } from "../../../../../utils/GlobalFunction";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { click } from "@syncfusion/ej2/grids";

const BankInfoComponent = observer((props) => {
	const [form] = Form.useForm();
	const [fetchCity, setFetchCity] = useState(true);
	const [fetchState, setFetchState] = useState(true);
	const [fetchCountry, setFetchCountry] = useState(true);
	const [fetchBank, setFetchBank] = useState(true);

	const {
		ManageCentersStore: { setBankInfoData, bankInfoValues, typeMode, editValues, setBankInfoValues },
		ManageBankStore,
		ManageCountryStore,
		ManageStateStore,
		ManageCityStore,
		CommonStore,
		AUTH
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [isViewPurpose, setIsViewPurpose] = useState();
	const [submitValue, setSubmit] = useState();
	const [buttonEvent, setButtonEvenet] = useState()

	// set the form values to edit
	useEffect(() => {
		if (ManageCountryStore && ManageCountryStore.dropdown_country_list && props.visible) {
			let country_id = getFirstRecordValue(ManageCountryStore.dropdown_country_list, "id")
			form.setFieldsValue({
				country_id: country_id,
			});
		}
		let view = (typeMode === Constant.VIEW) ? true : false;
		setIsViewPurpose(view);
		if (bankInfoValues && props.visible) {
			ManageStateStore.dropdown_state_list = [bankInfoValues.states];
			ManageCityStore.dropdown_city_list = [bankInfoValues.cities];
			ManageBankStore.dropdown_bank_list = [bankInfoValues.bank];
			form.setFieldsValue({
				bank_id: bankInfoValues.bank_id,
				branch: bankInfoValues.branch,
				accno: bankInfoValues.accno,
				phone1: bankInfoValues.phone1,
				phone2: bankInfoValues.phone2,
				ifsc: bankInfoValues.ifsc,
				micr: bankInfoValues.micr,
				swift: bankInfoValues.swift,
				address1: bankInfoValues.address1,
				address2: bankInfoValues.address2,
				address3: bankInfoValues.address3,
				country_id: bankInfoValues.countries?.id,
				state_id: bankInfoValues.states?.id,
				city_id: bankInfoValues.cities?.id,
				pincode: bankInfoValues.pincode,
			});
		}
		if (editValues && props.visible) {
			form.setFieldsValue({
				title: editValues.name
			});
		}
	}, [bankInfoValues, props, editValues, form, ManageCountryStore, ManageStateStore, ManageCityStore, ManageBankStore, typeMode]);

	const handleStateChange = () => {
		form.setFieldsValue({
			city_id: null,
		});
		setFetchCity(true);
		ManageCityStore.dropdown_city_list = null;
	};

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);


	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true);
		data.center_id = editValues.id;
		setBankInfoData(data)
			.then((data) => {
				close(submitValue);
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// reset form and close add form
	const close = (submitValue) => {
		if (submitValue === "submit") {
			props.close();
		} else if (submitValue === "submitNext") {
			props.closeNext();
		}
		setSubmit();
		setFetchState(true);
		setFetchCity(true);
		setFetchCountry(true);
		setFetchBank(true);
		ManageCountryStore.dropdown_country_list = null;
		ManageStateStore.dropdown_state_list = null;
		ManageCityStore.dropdown_city_list = null;
		ManageBankStore.dropdown_bank_list = null;
		setBankInfoValues(null);
		form.resetFields();
		setDisabled(true);
	};

	const pincodeText = (
		<ui>
			<li><span>- Allow numeric only</span></li>
			<li><span>- Allow 6 digits only</span></li>
		</ui>
	);

	return (
		<Drawer
			className="editModal"
			title="Location Bank Info"
			width="70%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={() => { close("submit") }}
			destroyOnClose={true}
			footer={[
				(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="1"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={() => { close("submitNext") }}
				>
					Skip
				</Button>),
				(typeMode !== Constant.VIEW && <Button
					key="2"
					disabled={disabled}
					form="bankInfoForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					className="mr-35"
					onClick={() => {
						setSubmit("submit");
					}}
				>
					Save
				</Button>),
				(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="3"
					disabled={disabled}
					form="bankInfoForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					onClick={() => {
						setSubmit("submitNext");
					}}
				>
					Save & Continue
				</Button>),
				(typeMode === Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="4"
					type="primary"
					onClick={() => {
						close("submitNext");
					}}
				>
					Continue
				</Button>)
			]}
		>
			{props.showErrorNotes && props.visible && editValues && <Alert className="mb-25"
				type="error"
				showIcon
				message={
					editValues.is_terminated ? `This location is terminated so you cannot make any changes` : (!editValues.is_active) ? `This location is inactive so you cannot make any changes` : null
				}
			/>}
			<Form
				form={form}
				id={"bankInfoForm"}
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<DefaulHeader />
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							disabled
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Location"
							placeholder="Location"
							name="title"
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Account No."
							placeholder="Account No."
							name="accno"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.account}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="IFSC"
							placeholder="IFSC"
							name="ifsc"
							onChange={(e) => { handleChange(e); setButtonEvenet(e.target.value) }}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.ifsc}
						/>
					</Col>
					<Col xs={{ span: 6 }} className="mt-25 moreInfoBtn searchBtn">
						<Form.Item>
							<Button type="primary"
								disabled={(buttonEvent && (buttonEvent.length >= 8 && buttonEvent.length <= 20)) ? false : true}
								onClick={() => {
									const ifsc_data = form.getFieldValue('ifsc')
									CommonStore.getBankInfo({ ifsc: ifsc_data }).then((data) => {
										ManageStateStore.dropdown_state_list = [{ id: data?.state_id, name: data?.state }]
										ManageCityStore.dropdown_city_list = [{ id: data?.city_id, name: data?.city }]
										if (data.bank) {
											ManageBankStore.dropdown_bank_list = [{ id: data?.bank_id, name: data?.bank }]
											setFetchBank(true);
										}
										form.setFieldsValue({
											branch: data.branch,
											bank_id: data.bank_id,
											micr: data.micr,
											swift: data.swift,
											address1: data.address1,
											address2: data.address2,
											address3: data.address3,
											state_id: data.state_id,
											city_id: data.city_id,
											pincode: data.pincode
										})
										handleChange()
									})
								}}><SearchOutlined /></Button>
						</Form.Item>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }} className="tooltipText">
						<InputComponent
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							type="text"
							label="Branch"
							placeholder="Branch"
							name="branch"
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.branch}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_bank_id">
						<InputComponent
							type="select"
							allowClear={!isViewPurpose}
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							autoComplete="chrome-off"
							label="Bank"
							name="bank_id"
							getPopupContainer={() => document.getElementById('parent_bank_id')}
							placeholder="Select Bank"
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.bank}
							onChange={() => {
								handleChange();
							}}
							onFocus={() =>
								!isViewPurpose && fetchBank &&
								ManageBankStore.getBanksList({ is_active: 1 }).then(() => setFetchBank(false))
							}
							notFoundContent={
								(!isViewPurpose && fetchBank) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageBankStore.dropdown_bank_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageBankStore.dropdown_bank_list &&
									ManageBankStore.dropdown_bank_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="MICR"
							placeholder="MICR"
							name="micr"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.micr}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Swift"
							placeholder="Swift"
							name="swift"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.swift}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" style={{ display: 'none' }} />
				<Row gutter={30} style={{ display: 'none' }}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							hidden
							readOnly={isViewPurpose}
							label="Mobile"
							placeholder="Mobile"
							maxLength={10}
							name="phone1"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.mobile}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							hidden
							readOnly={isViewPurpose}
							label="Phone"
							placeholder="Phone"
							name="phone2"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.phone2}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Address Line 1"
							placeholder="Address Line 1"
							name="address1"
							onChange={(e) => {
								form.setFieldsValue({
									address1: properCaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.address1}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Address Line 2"
							placeholder="Address Line 2"
							name="address2"
							onChange={(e) => {
								form.setFieldsValue({
									address2: properCaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.address2}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Address Line 3"
							placeholder="Address Line 3"
							name="address3"
							onChange={(e) => {
								form.setFieldsValue({
									address3: properCaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.address3}
						/>
					</Col>
				</Row>
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_country_id">
						<InputComponent
							type="select"
							disabled
							required
							autoComplete="chrome-off"
							label="Country"
							name="country_id"
							getPopupContainer={() => document.getElementById('parent_country_id')}
							placeholder="Select Country"
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.country_id}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose && fetchCountry &&
								ManageCountryStore.getCountriesList({ is_active: 1 }).then(() =>
									setFetchCountry(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchCountry) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageCountryStore.dropdown_country_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageCountryStore && ManageCountryStore.dropdown_country_list &&
									ManageCountryStore.dropdown_country_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_state_id">
						<InputComponent
							type="select"
							allowClear={!isViewPurpose}
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							label="State"
							name="state_id"
							getPopupContainer={() => document.getElementById('parent_state_id')}
							placeholder="Select State"
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.state_id}
							onChange={() => {
								handleChange();
								handleStateChange();
							}}
							onFocus={() =>
								!isViewPurpose && fetchState &&
								form?.getFieldValue("country_id") &&
								ManageStateStore.getStateList({
									country_id: form.getFieldValue("country_id"),
									// is_active: 1,
								}).then(() => setFetchState(false))
							}
							notFoundContent={
								(!isViewPurpose && fetchState) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageStateStore.dropdown_state_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageStateStore && ManageStateStore.dropdown_state_list &&
									!fetchState &&
									ManageStateStore.dropdown_state_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_city_id">
						<InputComponent
							type="select"
							allowClear={!isViewPurpose}
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							autoComplete="chrome-off"
							label="City"
							name="city_id"
							getPopupContainer={() => document.getElementById('parent_city_id')}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.city_id}
							placeholder="Select City"
							onChange={() => {
								handleChange();
							}}
							onFocus={() =>
								!isViewPurpose && fetchCity &&
								form?.getFieldValue("state_id") &&
								ManageCityStore.getCityList({
									state_id: form.getFieldValue("state_id"),
									// is_active: 1,
								}).then(() => setFetchCity(false))
							}
							notFoundContent={
								(!isViewPurpose && fetchCity) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageCityStore.dropdown_city_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageCityStore.dropdown_city_list &&
									!fetchCity &&
									ManageCityStore.dropdown_city_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Pin Code"
							placeholder="Pin Code"
							name="pincode"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationBankInfo.pincode}
							suffix={
								<Tooltip title={pincodeText} trigger={click}>
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
						/>
					</Col>
				</Row>
			</Form>
		</Drawer>
	);
});

export default BankInfoComponent;
