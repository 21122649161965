import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Col, Row } from "antd";
import { vsmNotify } from "../../../../config/messages/common";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../../../component/InputComponent";
import { vsmProfile } from "./Validation";
import { properCaseString } from "../../../../utils/GlobalFunction";
import { debounce } from "@syncfusion/ej2-base";

const ChangeNameComponent = observer((props) => {
    const [form] = Form.useForm();
    const {
        AUTH: { editAvtarValues, user, updateUser },
        UserSettingStore
    } = useStore();
    const [saving, setSaving] = useState();
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (props.visible && editAvtarValues) {
            form.setFieldsValue({
                name: editAvtarValues?.name
            })
        }
    }, [editAvtarValues, form, props])

    // check for valid form values then accordingly make save button disable / enable
    const handleChange = debounce(() => {
        form
            .validateFields()
            .then((d) => {
                setDisabled(false);
            })
            .catch((d) => {
                setDisabled(true);
            });
    }, 500);

    // Handle submit and call function to save new record
    const handleSubmit = (data) => {
        setSaving(true);
        let user_id = user.id;
        data.user_id = user_id;
        let currentName = data.name
        UserSettingStore.changeName(data)
            .then((data) => {
                close();
                updateUser("name",currentName)
                vsmNotify.success({
                    message: data.STATUS.NOTIFICATION[0],
                });
            })
            .catch((e) => {
                if (e.errors) {
                    form.setFields(e.errors);
                }
            })
            .finally(() => setSaving(false));
    };

    // reset form and close add form
    const close = () => {
        props.close();
        form.resetFields();
        setDisabled(true);
    };

    return (editAvtarValues && props.visible) && (
        <Modal
            className="addModal"
            centered
            title="Change Name"
            width={534}
            visible={props.visible}
            closeIcon={<FontAwesomeIcon icon={faTimes} />}
            onCancel={close}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            footer={[
                <Button
                    key="2"
                    htmlType="button"
                    className="cancelBtn mr-35"
                    onClick={close}
                >
                    Cancel
                </Button>,
                <Button
                    key="1"
                    disabled={disabled}
                    form="changeNameForm"
                    loading={saving}
                    htmlType="submit"
                    type="primary"
                >
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                id={"changeNameForm"}
                onFinish={handleSubmit}
                labelCol={{ span: 24 }}
                onChange={handleChange}
            >
                <Row>
                    <Col xs={{ span: 24 }}>
                        <InputComponent
                            type="text"
                            required
                            label="Name"
                            placeholder="Name"
                            name="name"
                            onChange={(e) => {
                                form.setFieldsValue({
                                    name: properCaseString(e.target.value)
                                })
                            }}
                            rules={vsmProfile.validation.name}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
});

export default ChangeNameComponent;
