import Axios from "axios";
import { action, decorate } from "mobx";

export default class ManageDrsSettingsStore {
	// Call add api
	UpdateDrsSettings = (formdata) => {
		return Axios.post(`/utilities/settings/drs-per-page-edit/${formdata.center_id}`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

}

decorate(ManageDrsSettingsStore, {
	UpdateDrsSettings: action,
});
