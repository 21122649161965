import { Messages } from "../../../../config/messages/messages";
import { Patterns } from "../../../../config/messages/patterns";
import { mobileValidation, replaceString } from "../../../../utils/GlobalFunction";

export const vsmProfile = {
	success: Messages.CREATED,
	error: Messages.DUPLICATED,
	validation: {
		name: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		otp: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
		],
		username: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.USERNAME,
				message: Messages.USERNAME_INVALID_CHARACTER,
			},
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		password: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.PASSWORD,
				message: Messages.PASSWORD_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: Messages.PASSWORD_LENGTH,
			},
			{
				max: 20,
				message: Messages.PASSWORD_LENGTH,
			},
		],
		old_password: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.PASSWORD,
				message: Messages.PASSWORD_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: Messages.PASSWORD_LENGTH,
			},
			{
				max: 20,
				message: Messages.PASSWORD_LENGTH,
			},
		],
		new_password: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.PASSWORD,
				message: Messages.PASSWORD_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: Messages.PASSWORD_LENGTH,
			},
			{
				max: 20,
				message: Messages.PASSWORD_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue("password") === value) {
						return Promise.reject(Messages.PASSWORD_SAME_ERROR);
					}
					return Promise.resolve();
				},
			}),
		],
		confirm_password: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.PASSWORD,
				message: Messages.PASSWORD_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: Messages.PASSWORD_LENGTH,
			},
			{
				max: 20,
				message: Messages.PASSWORD_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue("new_password") === value) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.PASSWORD_MISMATCH_ERROR);
				},
			}),
		],
		email: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.EMAIL,
				message: Messages.EMAIL_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 70,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 70),
			},
		],
		mobile: [
			{
				pattern: Patterns.PHONE,
				whitespace: true,
				message: Messages.MOBILE_INVALID_CHARACTERS,
			},
			{
				min: 10,
				message: Messages.MOBILE_LENGTH,
			},
			{
				max: 10,
				message: Messages.MOBILE_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (rule && rule.length === 10) {
						let validate = mobileValidation(rule);
						if (!validate) {
							return Promise.reject(Messages.MOBILE_INVALID);
						}
					}
					return Promise.resolve();
				}
			})
		],
		confirm_mobile: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.PHONE,
				whitespace: true,
				message: Messages.MOBILE_INVALID_CHARACTERS,
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue("mobile") === value) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.MOBILE_NOT_MATCH);
				},
			}),
		],
		confirm_email: [
			{ required: true, message: Messages.REQUIRED },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue("email") === value) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.EMAIL_NOT_MATCH);
				},
			}),
		],
	},
};
