import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { Constant } from "../../../../config/Constant";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManageUsersClientStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	scopeValues = null;
	deleteValues = null;
	viewValues = null
	statusValues = null;
	total = 0;
	allColumnIds = [];
	ResetPasswordValue = null;
	isVerifyFilterList = null;
	

	// set form values to edit
	setEditValues = async (data) => {
		this.editValues = await this.ViewData(data.id);
		//this.editValues = data;
	};

	setIsVerifyFilterList = (data) => {
		this.isVerifyFilterList = data;
	}

	// set form values to scope
	setScopeValues = (data) => {
		this.scopeValues = data;
	};


	// set form values to delete
	setResetPasswordValues = (data) => {
		this.ResetPasswordValue = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`admin/client-users/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["is_active"]) {
			final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_verified"]) {
			final_filter['is_verified'].values = convertTextToID(final_filter['is_verified'], this.isVerifyFilterList, 'name', 'id')
		}
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					level_id: Constant.CLIENT_USER,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Call add api
	AddData = (formdata, level_id) => {
		return Axios.post(`admin/client-users/add`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata, id) => {
		return Axios.post(`admin/client-users/edit/${id}`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	TogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
		return Axios.patch("admin/client-users/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(
			`admin/client-users/details/${ID}`
		).then(({ data }) => {
			this.setViewValues(data.view)
			return data.view;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`admin/client-users/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call reset Password api
	ResetPassword = (data) => {
		return Axios.post(`admin/client-users/reset-password`, data)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditScopeData = (data) => {
		return Axios.post(`/admin/users/change-scope/` + data.id, data)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	ToggleisVerifyData = (formdata) => {
		const api_link = formdata.is_verified === 1 ? "unverify/" : "verify/";
		return Axios.patch("admin/users/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	getPrivileges = (privileges) => {
		return Axios.post(`admin/privileges/lov/`, { level_id: Constant.CLIENT_USER })
			.then(({ data }) => {
				data.list.data.map((item) => {
					item.submenu && item.submenu.map((subitem) => {
						subitem.is_selected = privileges && privileges.includes("#" + subitem.id + "#") ? 1 : 0
						subitem.actions && subitem.actions.map((action) => {
							action.is_selected = privileges && privileges.includes("#" + action.id + "#") ? 1 : 0
							return null;
						})
						return null;
					})
					return null;
				})
				this.dropdown_privileges_list = data.list.data;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	}
}

decorate(ManageUsersClientStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	total: observable,
	allColumnIds: observable,
	statusValues: observable,
	viewValues: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	scopeValues: observable,
	setDeleteValues: action,
	setStatusValues: action,
	getList: action,
	onFilterChanged: action,
	ViewData: action,
	setViewValues: action,
	dropdown_privileges_list: observable,
	setIsActiveFilterList: action,
	setResetPasswordValues: action,
	ResetPasswordValue: observable,
	setIsVerifyFilterList: action,
	isVerifyFilterList: observable,
});
