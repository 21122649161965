import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManageAllocatedStickerStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	total = 0;
	allColumnIds = [];
	typeFilterList = []
	isDeleted = 0;
	isUsedFilterList = null;
	remainStickerPaymentError = "";
	remainStickerPayment = null;

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};
	setRemainStickerPayment = (status) => {
		this.remainStickerPayment = status;
	}
	setTypeFilterList = (data) => {
		this.typeFilterList = data.inv_trans_types
	}
	setIsUsedFilterList = (data)=>{
		this.isUsedFilterList = data
	}
	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}
	setIsdeleted = (is_deleted) => {
		this.isDeleted = is_deleted
	}
	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;

		let datasource = this.createDatasource(ServerGridConfig.options);

		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/stickers/allocated/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter['tt_id']) {
			final_filter['tt_id'].values = convertTextToID(final_filter['tt_id'], this.typeFilterList, 'name', 'id')
		}
		if (final_filter["is_used"]) {
			final_filter['is_used'].values = convertTextToID(final_filter['is_used'], this.isUsedFilterList, 'name', 'id')
		}
		return { final_filter, final_sort }
	}

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					is_deleted: this.isDeleted,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow)),
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`/stickers/allocated/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`stickers/allocated/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`/stickers/allocated/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	//Get List of center for dropdown
	getPaymentStaus = (conditional = {}) => {
		return Axios.post(`stickers/sticker-orders/get-order-status`, conditional)
			.then(({ data }) => {
				if (data.order_status) {
					this.remainStickerPayment = data.order_status?.payment_status;
					switch (data.order_status?.payment_status) {
						case 20:
							this.remainStickerPaymentError = "The last sticker order was confirmed without payment, please resolve the outstanding"
							break;
						case 30:
							this.remainStickerPaymentError = "The last sticker order was confirmed without payment verification, please ask HO to complete the verification process"
							break;
						case 40:
							this.remainStickerPaymentError = "Payment for the last sticker order was failed, please resolve the outstanding"
							break;

						default:
							this.remainStickerPayment = null;
							break;
					}
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	allocateToPartyStickers  = (conditional = {}) => {
		return Axios.post(`stickers/allocated/allocate-to-party `, conditional)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageAllocatedStickerStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	total: observable,
	allColumnIds: observable,
	dropdown_city_list: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	getList: action,
	changeFilterAndSort: action,
	createDatasource: action,
	setViewValues: action,
	setIsActiveFilterList: action,
	getStockProductList: action,
	getTypeList: action,
	setTypeFilterList: action,
	typeFilterList: observable,
	setIsUsedFilterList:action,
	isUsedFilterList:observable,
	remainStickerPayment: observable,
	remainStickerPaymentError: observable,
});
