import { action, decorate, observable } from "mobx";
import Axios from "axios";

export default class ManageEwayTransitStore {
	list_data = null;
	editValues = null;
	listPayload = null;

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set list params for call getList
	setListPayload = (data) => {
		this.listPayload = data;
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`bookings/eway/transit`, payload).then(({ data }) => {
			this.list_data = data.eway ? data.eway.vehicles : [];

			return data;
		}).catch(({ response: { data } }) => {
			var errors = [];
			var notify = null;
			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
			if (data && data.STATUS) {
				if (NOTIFICATION) {
					notify = NOTIFICATION[0];
				}
				Object.keys(fieldErrors).forEach((name) => {
					errors.push({ name, errors: data.STATUS[name] });
				});
			}
			return Promise.reject({ errors, notify });
		});
	};

	singleUpdate = (payload) => {
		return Axios.post(`bookings/eway/update_vehicle_ind`, payload)
			.then(({ data }) => {
				this.getList(this.listPayload);
				return data
			}).catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	bulkUpdate = (payload) => {
		return Axios.post(`bookings/eway/update_vehicle_bulk`, payload)
			.then(({ data }) => {
				this.getList(this.listPayload);
				return data;
			}).catch(({ response }) => {
				var errors = [];
				var notify = null;
				if (response?.data) {
					let data = response.data;
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (data && data.STATUS) {
						if (NOTIFICATION) {
							notify = NOTIFICATION[0];
						}
						Object.keys(fieldErrors).forEach((name) => {
							errors.push({ name, errors: data.STATUS[name] });
						});
					}
				}
				return Promise.reject({ errors, notify });
			});;
	};

	ExtendValidity = (payload) => {
		return Axios.post(`bookings/eway/extend_validity`, payload)
			.then(({ data }) => {
				this.getList(this.listPayload);
				return data
			}).catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	doGenerateEwayPdf = (data) => {
		return Axios.post(`bookings/eway/print`, data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageEwayTransitStore, {
	list_data: observable,
	getList: action,
	singleUpdate: action,
	bulkUpdate: action,
	editValues: observable,
	setEditValues: action,
	listPayload: observable,
	setListPayload: action,
});
