import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../../utils/GlobalFunction";

export default class ManageWeightMachineStore {
  agGrid = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  list_data = null;
  editValues = null;
  deleteValues = null;
  statusValues = null;
  total = 0;
  allColumnIds = [];
  dropdown_state_list = null;

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  // set form values to status
  setStatusValues = (data) => {
    this.statusValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  // call api to get records
  getList = () => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.api.getSortModel();
    }
		this.list_data = null;
    return Axios.get(`admin/weight-machine/list`).then(({ data }) => {
      if (data.list.data.length) {
        data.list.data.map((item, index) => {
          item.srno = index + 1;
          item.is_active_display =
            item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
          return null;
        });
      }
      this.list_data = data.list ? data.list.data : null;
      this.total = data.list.total;
      this.current_page = data.list.current_page;
      var allColumnIds = [];
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
      }
      if (this.agGrid) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.api.setSortModel(sort);
      }
    });
  };

  // Call add api
  AddData = (formdata) => {
    return Axios.post(`admin/weight-machine/new`, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // Call edit api
  EditData = (formdata) => {
    return Axios.post(`admin/weight-machine/edit/` + formdata.id, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // Call delete api
  DeleteData = (formdata) => {
    return Axios.delete(`admin/weight-machine/destroy/` + formdata.id)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  TogglePublishData = (formdata) => {
    const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
    return Axios.patch("admin/weight-machine/" + api_link + formdata.id)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  getWeightMachinesList = (conditional = {}) => {
    return Axios.post(`admin/weight-machine/lov`, conditional)

      .then(({ data }) => {
        this.dropdown_weight_machine_list = data.weight_machine;

        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };
}

decorate(ManageWeightMachineStore, {
  per_page: observable,
  agGrid: observable,
  list_data: observable,
  editValues: observable,
  deleteValues: observable,
  statusValues: observable,
  dropdown_state_list: observable,
  total: observable,
  dropdown_weight_machine_list:observable,
  allColumnIds: observable,
  setupGrid: action,
  setPageSize: action,
  setEditValues: action,
  setDeleteValues: action,
  setStatusValues: action,
  getList: action,
  getStatesList: action,
  onFilterChanged: action,

});
