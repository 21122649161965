import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
export default class CompanySettingStore {
	agGrid = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
	company_data = null;
	editValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_brand_list = null;

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// call api to get records
	getList = () => {
		this.company_data = null;
		return Axios.get(`admin/company-settings/details`).then(({ data }) => {
			//let data = company
			this.company_data = data.view;
		});
	};

	// Call edit api
	EditCompanyProfileData = (formdata) => {
		return Axios.post(
			`admin/company-settings/change-profile/`,
			formdata
		)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditThemeColorData = (formdata) => {
		return Axios.post(
			`admin/company-settings/change-theme-color`,
			formdata
		)
			.then(({ data }) => {
				this.changeBrandingLocalStorage("theme_color", formdata.theme_color);
				document.documentElement.style.setProperty(
					"--main-color",
					formdata.theme_color
				);
				this.getList();
				return Promise.resolve(data);
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditLogoImageData = (formdata) => {
		return Axios.post(`admin/company-settings/upload-logo`, formdata)
			.then(({ data }) => {
				this.changeBrandingLocalStorage("logo", data?.view?.branding?.logo);
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditHeroImageData = (formdata) => {
		return Axios.post(
			`admin/company-settings/upload-hero-image`,
			formdata
		)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditIconData = (formdata) => {
		return Axios.post(`admin/company-settings/upload-icon`, formdata)
			.then(({ data }) => {
				this.getList();
				this.changeBrandingLocalStorage("icon", data?.view?.branding?.icon);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditFavIconData = (formdata) => {
		return Axios.post(`admin/company-settings/upload-fav-icon`, formdata)
			.then(({ data }) => {
				this.getList();
				this.changeBrandingLocalStorage("favicon", data?.view?.branding?.favicon);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditEmailHeaderData = (formdata) => {
		return Axios.post(
			`admin/company-settings/upload-email-banner`,
			formdata
		)
			.then(({ data }) => {
				this.getList();
				this.changeBrandingLocalStorage("email_banner", data?.view?.branding?.email_banner);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditEmailFooterData = (formdata) => {
		return Axios.post(
			`admin/company-settings/change-email-footer/`,
			formdata
		)
			.then(({ data }) => {
				this.getList();
				this.changeBrandingLocalStorage("email_footer", data?.view?.branding?.email_footer);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditPreferencesData = (formdata, prefObj = null) => {
		return Axios.post(
			`admin/company-settings/change-preferences`,
			formdata
		)
			.then(({ data }) => {
				this.getList();
				prefObj && this.changePreferencesLocalStorage(prefObj);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	changeBrandingLocalStorage = (key, value) => {
		let company = localStorage.getItem("company");
		company = JSON.parse(company);
		company.branding[key] = value;
		localStorage.setItem("company", JSON.stringify(company));
	};

	changePreferencesLocalStorage = (obj) => {
		let company = localStorage.getItem("company");
		company = JSON.parse(company);
		company.branding = {...company.branding, ...obj};
		localStorage.setItem("company", JSON.stringify(company));
	};
}

decorate(CompanySettingStore, {
	per_page: observable,
	agGrid: observable,
	company_data: observable,
	total: observable,
	allColumnIds: observable,
	editValues: observable,
	dropdown_brand_list: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	getBrandsList: action,
	getList: action,
	DeleteData: action,
	onFilterChanged: action,
});
