import { Col, Row } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { Constant } from "../../../../../config/Constant";
import MoreInfoComponent from "./MoreInfoComponent";
import { useState } from "react";

const DefaulHeader = observer((props) => {
    const {
        ManageCentersStore: { editValues },
    } = useStore();

    const [moreInfoModal, setMoreInfoModal] = useState(false);

    const openMoreInfoModal = () => {
        setMoreInfoModal(true);
    }

    const closeMoreInfoModal = () => setMoreInfoModal(false);

    return (
        <Row gutter={30} wrap={false} className="zform_block_wrapper">
            <Col xs={{ span: 6 }}>
                <div className="zform_block blue_block">
                    <label>{editValues?.name ? editValues.name : Constant.UNDEFINED_VALUES}</label>
                    <span>{editValues?.company ? editValues.company : Constant.UNDEFINED_VALUES}</span>
                </div>
            </Col>
            <Col xs={{ span: 6 }}>
                <div className="zform_block  green_block">
                    <label>Franchisee</label>
                    <span>{(editValues?.mst_franchisee) ? editValues.mst_franchisee.owner_name : Constant.UNDEFINED_VALUES}</span>
                </div>
            </Col>
            <Col xs={{ span: 6 }}>
                <div className="zform_block pink_block">
                    <label>Hub</label>
                    <span>{editValues?.mst_hub ? editValues.mst_hub.name : Constant.UNDEFINED_VALUES}</span>
                </div>
            </Col>
            <Col xs={{ span: 6 }} onClick={openMoreInfoModal}>
                <div className="zform_block orange_block cursor_pointer">
                    <label className="cursor_pointer"><svg className="cursor_pointer" height="40" viewBox="0 0 48 48" width="40" fill="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none"></path><path d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"></path></svg></label>
                    <span>More Info</span>
                </div>
            </Col>
            <MoreInfoComponent visible={moreInfoModal} close={closeMoreInfoModal} />
        </Row>

    )
})

export default DefaulHeader;