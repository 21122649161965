import React from "react";
import { AgGridReact } from "@ag-grid-community/react";

const ServerAgGridWrapper = ({ customParentClass ,pinnedBottomRowData=[], ...rest }) => (
	<div className={`ag-theme-alpine grid_wrapper ${customParentClass}`}>
		<AgGridReact
			pinnedBottomRowData={pinnedBottomRowData}
			{...rest}
		/>
	</div>
);

export default ServerAgGridWrapper;
