import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Col, Layout, Row, Timeline, Table, Collapse, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../store";
import { Constant } from "../../../config/Constant";
import { vsmCommon } from "../../../config/messages/common";
import { convertDate } from "../../../utils/GlobalFunction";
const BagDetailcolumns = [
	{
		title: 'Type',
		dataIndex: 'ti_name',
		width: '9%',
	},
	{
		title: 'Shipment No.',
		dataIndex: 'item_no',
		width: '10%',
	},
	{
		title: 'Booking Location',
		dataIndex: 'booking_center',
		width: '12%',
		render: (booking_center) => {
			return (
				<span>{booking_center ? booking_center : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Booking Date',
		dataIndex: 'booking_date',
		width: '10%',
		render: (booking_date) => {
			return (
				<span>{convertDate(booking_date, Constant.DEFAULT_DATE_FORMAT)}</span>
			)
		},
	},
	{
		title: 'Destination',
		dataIndex: 'dest_hub',
		width: '20%',
		render: (dest_hub) => {
			return (
				<span>{dest_hub ? dest_hub : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Consignee Name ',
		dataIndex: 'receiver',
		width: '16%',
		render: (receiver) => {
			return (
				<span>{receiver ? receiver : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Pincode',
		dataIndex: 'dest_pincode',
		width: '10%',
		render: (dest_pincode) => {
			return (
				<span>{dest_pincode ? dest_pincode : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Weight ',
		dataIndex: 'weight',
		width: '12%',
		render: (weight) => {
			return (
				<span>{weight ? weight : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
];
const BagTrackingComponent = observer((props) => {
	const {
		ManageTrackingStore: { bagTrackingValues,getbagTrackingData },
		ManageTrackingStore
	} = useStore();
	const { Panel } = Collapse;
	const [defaultActive, setdefaultActive] = useState([])
	// reset form and close add form
	const close = () => {
		props.close();
		setdefaultActive([]);
	};
	const onChange = (key) => {
		setdefaultActive(key)
		if (key.includes('2') && !bagTrackingValues.bag) {
			getbagTrackingData({ bag_no: bagTrackingValues.bag_no, purpose: 2 }).then((data) => {
				ManageTrackingStore.bagTrackingValues = ({ ...bagTrackingValues, ...data });
			})
		}
	};
	return (
		bagTrackingValues && (
			<>
				<Drawer
					className="addModal"
					title={`Bag Tracking - ${bagTrackingValues.bag_no}`}
					width="70%"
					visible={props.visible}
					closeIcon={<FontAwesomeIcon icon={faTimes} />}
					onClose={close}
					zIndex={1002}
					footer={[
						<Button
							key="2"
							htmlType="button"
							className="cancelBtn mr-35"
							onClick={close}
							
						>
							Cancel
						</Button>,
					]}
				>
					{bagTrackingValues.traveling_info ?
						<>
							<h3>Traveling Information</h3>
							<div className="details_table_timeline">
								<Timeline mode={"left"}>
									{bagTrackingValues?.traveling_info.map(item => {
										return (<Timeline.Item label={convertDate(item.created, Constant.DEFAULT_DISPLAY_DATE_TIME_FORMAT)}>{item.result}</Timeline.Item>)
									}
									)}
								</Timeline>
							</div></> : <Layout className="details_table mb-15">
										<Row gutter={6} wrap={false}>
											<Col span={24} className="form__input__textbox">
												{/* <i className="mb-0">Booking information is not available</i> */}
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={
														<span>
															Traveling Information is not available
														</span>
													}
												></Empty>
											</Col>
										</Row></Layout>
					}
					<Collapse
						//accordion
						expandIconPosition={"end"}
						onChange={onChange}
						className="collapse_new_design"
						activeKey={defaultActive}
					>
						<Panel header="Bag Information" key="2">
						{bagTrackingValues?.bag ? <Layout className="details_table" style={{ margin: "0 0px 20px" }}>
								<Row gutter={6} wrap={false}>
									<Col span={6} className="form__input__textbox">
										<label>Bag No.</label>
										<span>{(bagTrackingValues?.bag?.bag_no) ? (bagTrackingValues?.bag?.bag_no) : Constant.UNDEFINED_VALUES}</span>
									</Col>
									<Col span={6} className="form__input__textbox">
										<label>Created Time : </label>
										<span>{(bagTrackingValues?.bag?.created) ? convertDate(bagTrackingValues?.bag?.created, Constant.DEFAULT_DATE_TIME_FORMAT) : Constant.UNDEFINED_VALUES}</span>
									</Col>
									<Col span={6} className="form__input__textbox">
										<label>Origin Hub : </label>
										<span>{(bagTrackingValues?.bag?.org_name) ? (bagTrackingValues?.bag?.org_name) : Constant.UNDEFINED_VALUES}</span>
									</Col>
									<Col span={6} className="form__input__textbox">
										<label>Hub's Phone : </label>
										<span>{(bagTrackingValues?.bag?.hub_mobile) ? (bagTrackingValues?.bag?.hub_mobile) : Constant.UNDEFINED_VALUES}</span>
									</Col>
								</Row>
								<Row gutter={6} wrap={false}>
									<Col span={6} className="form__input__textbox">
										<label>Next Stop : </label>
										<span>{(bagTrackingValues?.bag?.next_name) ? (bagTrackingValues?.bag?.next_name) : Constant.UNDEFINED_VALUES}</span>
									</Col>
									<Col span={6} className="form__input__textbox">
										<label>Final Destination : </label>
										<span>{(bagTrackingValues?.bag?.dest_name) ? (bagTrackingValues?.bag?.dest_name) : Constant.UNDEFINED_VALUES}</span>
									</Col>
								</Row>
							</Layout> :
								(<Layout className="details_table" style={{ margin: "0 0px 20px" }}>
									<Row gutter={6} wrap={false}>
										<Col span={24} className="form__input__textbox">
											{/* <i className="mb-0">Packet information is not available</i> */}
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={
													<span>
														Bag information is not available
													</span>
												}
											></Empty>
										</Col>
									</Row></Layout>)}
							{bagTrackingValues.bag_details &&
								<>
									<h3>Bag Detail Information</h3>
									<Layout className="details_table" style={{ margin: "0 0px 20px" }}>
										<Table
											className="packetentry ViewDRSentry"
											columns={BagDetailcolumns}
											locale={{ emptyText: vsmCommon.noRecord }}
											dataSource={bagTrackingValues?.bag_details}
											pagination={false}
										/>
									</Layout></>}
						</Panel>
					</Collapse>
				</Drawer>
			</>
		)
	);
});
export default BagTrackingComponent;
