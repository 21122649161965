import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageCorporatePaymentsStore {
	detail_filter = null;
	agGridDetail = null;
	per_page_overview = ServerGridConfig.options.paginationPageSize;
	current_page_detail = 1;
	list_data_detail = null;
	total_detail = 0;
	allColumnIdsDetail = [];
	//This is for summary
	summary_filter = null;
	settlement_filter = null;
	agGridSummary = null;
	agGridOverview = null;
	agGridSettlement = null;
	agGridCancelled = null
	per_page_summary = ServerGridConfig.options.paginationPageSize;
	per_page_cancelled = ServerGridConfig.options.paginationPageSize;
	per_page_settlement = ServerGridConfig.options.paginationPageSize;
	current_page_summary = 1;
	list_data_summary = null;
	list_data = null;
	total_summary = 0;
	allColumnIdsSummary = [];
	overview_filter = null;
	cancelled_filter = null;
	deleteValues = null;
	sendemailValues = null;
	viewValues = null;
	editValues = null;
	daliyBookingValues = null;
	selectAll = false;
	selectCount = 0;
	paymentSummaryValues = null
	outsandingviewValues = null
	pendingSettlementValues = null
	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to Send Email
	setSendEmailValues = (data) => {
		this.sendemailValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};
	// set form values to Edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setOutsandingViewValues = (data) => {
		this.outsandingviewValues = data;
	};

	// set form values to view
	setPendingSettlementViewValues = (data) => {
		this.pendingSettlementValues = data;
	};

	// set form values to view
	setDaliyBookingValues = (data) => {
		this.daliyBookingValues = data;
	};

	// set form values to view
	setPaymentSummaryValues = (data) => {
		this.paymentSummaryValues = data;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGridSummary.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					from_date: this.overview_filter ? this.generatePayload(this.overview_filter.from_date) : this.summary_filter ? this.generatePayload(this.summary_filter.from_date) : this.settlement_filter ? this.generatePayload(this.settlement_filter.from_date) : this.generatePayload(this.cancelled_filter),
					to_date: this.overview_filter ? this.generatePayload(this.overview_filter.to_date) : this.summary_filter ? this.generatePayload(this.summary_filter.to_date) : this.settlement_filter ? this.generatePayload(this.settlement_filter.to_date) : this.generatePayload(this.cancelled_filter),
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				if (this.summary_filter) {
					this.getHistoryList(payload).then((data) => {
						if (data.list.total === 0) {
							this.agGridSummary.api.showNoRowsOverlay();
						} else {
							this.agGridSummary.api.hideOverlay();
						}
						params.successCallback(data.list.data, data.list.total);
						var allColumnIds = [];
						if (this.agGridSummary && this.agGridSummary.columnApi && data.total) {
							this.agGridSummary.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
						}
					})
				}
				else if (this.overview_filter) {
					this.getListOverview(payload).then((data) => {
						if (data.client.total === 0) {
							this.agGridOverview.api.showNoRowsOverlay();
						} else {
							this.agGridOverview.api.hideOverlay();
						}
						params.successCallback(data.client.data, data.client.total);
						var allColumnIds = [];
						if (this.agGridOverview && this.agGridOverview.columnApi && data.total) {
							this.agGridOverview.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
						}
					})
				}
				else if (this.settlement_filter) {
					this.getListSettlement(payload).then((data) => {
						if (data.list.total === 0) {
							this.agGridSettlement.api.showNoRowsOverlay();
						} else {
							this.agGridSettlement.api.hideOverlay();
						}
						params.successCallback(data.list.data, data.list.total);
						var allColumnIds = [];
						if (this.agGridSettlement && this.agGridSettlement.columnApi && data.list.total) {
							this.agGridSettlement.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id);
							});
						}
					})
				}
			},
		};
	};

	// set form values to  customefilter
	setSummaryFilterValues = (data) => {
		return new Promise((resolve) => {
			this.summary_filter = data;
			if (this.agGridSummary) {
				this.setupGridSummary(this.agGridSummary);
			}
			resolve()
		});
	};

	// set form values to  customefilter
	setOverviewFilterValues = (data) => {
		return new Promise((resolve) => {
			this.overview_filter = data;
			if (this.agGridOverview) {
				this.setupGridOverview(this.agGridOverview);
			}
			resolve()
		});
	};
	// set form values to  customefilter
	setSettlementFilterValues = (data) => {
		return new Promise((resolve) => {
			this.settlement_filter = data;
			if (this.agGridSettlement) {
				this.setupGridSettlement(this.agGridSettlement);
			}
			resolve()
		});
	};
	// set form values to  customefilter
	setCancelledFilterValues = (data) => {
		return new Promise((resolve) => {
			this.cancelled_filter = data;
			if (this.agGridCancelled) {
				this.setupGridCancelled(this.agGridCancelled);
			}
			resolve()
		});
	};

	// Setup grid and set column size to autosize
	setupGridSummary = (params) => {
		this.agGridSummary = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};


	// Setup grid and set column size to autosize
	setupGridOverview = (params) => {
		// this.agGridOverview = params;
		this.agGridOverview = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};
	setOverviewFilter = (data) => {
		this.overview_filter = data;
	};

	// Setup grid and set column size to autosize
	setupGridSettlement = (params) => {
		this.agGridSettlement = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};
	setSettlementFilter = (data) => {
		this.settlement_filter = data;
	};

	// Setup grid and set column size to autosize
	setupGridCancelled = (params) => {
		// this.agGridCancelled = params;
		this.agGridCancelled = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};
	setCancelledFilter = (data) => {
		this.cancelled_filter = data;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeSummary = (page = this.per_page_summary) => {
		this.per_page_summary = page;
		if (this.agGridSummary) {
			this.agGridSummary.api.paginationSetPageSize(parseInt(page));
		}
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeOverview = (page = this.per_page_overview) => {
		this.per_page_overview = page;
		if (this.agGridOverview) {
			this.agGridOverview.api.paginationSetPageSize(parseInt(page));
		}
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeSettlement = (page = this.per_page_settlement) => {
		this.per_page_settlement = page;
		if (this.agGridSettlement) {
			this.agGridSettlement.api.paginationSetPageSize(parseInt(page));
		}
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeCancelled = (page = this.per_page_cancelled) => {
		this.per_page_cancelled = page;
		if (this.agGridCancelled) {
			this.agGridCancelled.api.paginationSetPageSize(parseInt(page));
		}
	};

	// Filter function for no record found message
	onFilterChangedSummary = (params) => {
		this.agGridSummary = params;
		if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() === 0) {
			this.agGridSummary.api.showNoRowsOverlay();
		}
		if (this.agGridSummary && this.agGridSummary.api.getModel().getRowCount() > 0) {
			this.agGridSummary.api.hideOverlay();
		}
	};
	// Filter function for no record found message
	onFilterChangedOverview = (params) => {
		this.agGridOverview = params;
		if (this.agGridOverview && this.agGridOverview.api.getModel().getRowCount() === 0) {
			this.agGridOverview.api.showNoRowsOverlay();
		}
		if (this.agGridOverview && this.agGridOverview.api.getModel().getRowCount() > 0) {
			this.agGridOverview.api.hideOverlay();
		}
	};
	onFilterChangedSettlement = (params) => {
		this.agGridSettlement = params;
		if (this.agGridSettlement && this.agGridSettlement.api.getModel().getRowCount() === 0) {
			this.agGridSettlement.api.showNoRowsOverlay();
		}
		if (this.agGridSettlement && this.agGridSettlement.api.getModel().getRowCount() > 0) {
			this.agGridSettlement.api.hideOverlay();
		}
	};
	// Filter function for no record found message
	onFilterChangedCancelled = (params) => {
		this.agGridCancelled = params;
		if (this.agGridCancelled && this.agGridCancelled.api.getModel().getRowCount() === 0) {
			this.agGridCancelled.api.showNoRowsOverlay();
		}
		if (this.agGridCancelled && this.agGridCancelled.api.getModel().getRowCount() > 0) {
			this.agGridCancelled.api.hideOverlay();
		}
	};
	generatePayload = (summary_filter) => {
		// if (summary_filter.from_date) {
		//     summary_filter.from_date = moment(summary_filter.from_date).format('YYYY-MM-DD');
		// }
		// if (summary_filter.to_date) {
		//     summary_filter.to_date = moment(summary_filter.to_date).format('YYYY-MM-DD');
		// }
		return summary_filter;
	}

	refreshList = () => {
		if (this.agGridSummary) {
			this.setupGridSummary(this.agGridSummary);
		}
		else if (this.agGridOverview) {
			this.setupGridOverview(this.agGridOverview);
		}
		else if (this.agGridSettlement) {
			this.setupGridSettlement(this.agGridSettlement);
		}
	}
	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort }
	}
	// call api to get records
	getListOverview = (payload) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/outstanding-by-client`, payload).then(({ data }) => {
			if (data.client.data.length) {
				let startPage = (data.client.current_page - 1) * data.client.per_page;
				data.client.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.overview_data_summary = data.client ? data.client.data : null;
			this.total_summary = data.client.total;
			this.current_page_summary = data.client.current_page;
			return data;
		});
	};

	// call api to get records
	getHistoryList = (payload) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data_summary = data.list ? data.list.data : null;
			this.total_summary = data.list.total;
			this.current_page_summary = data.list.current_page;
			return data;
		});
	};

	// call api to get records
	getListSettlement = (payload) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/pending-settlement`, payload).then(({ data }) => {
			if (data.list.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.overview_data_settlement = data.list ? data.list.data : null;
			this.total_summary = data.list.total;
			this.current_page_summary = data.list.current_page;
			return data;
		});
	};

	AddPaymentData = (formdata) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/generate`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	AddPaymentSettlementByBranch = (formdata) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/branch-invoice-settlement`, formdata)
			.then(({ data }) => {
				if (this.agGridSettlement) {
					this.setupGridSettlement(this.agGridSettlement);
				}
				return data;
				
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	getListCancelled = (payload) => {
		return Axios.post(`monthly-activities/client-invoices/cancel-list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data_summary = data.list ? data.list.data : null;
			this.total_summary = data.list.total;
			this.current_page_summary = data.list.current_page;
			return data;
		});
	};

	doGenerateBills = (data) => {
		return Axios.post(`/monthly-activities/client-invoices/generate`, data)
			.then(({ data }) => {
				this.agGridOverview.api.deselectAll();
				if (this.agGridOverview) {
					this.setupGridOverview(this.agGridOverview);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	doGenerateBillsPdf = (data) => {
		return Axios.post(`/monthly-activities/client-invoices/print`, data)
			.then(({ data }) => {
				this.agGridSummary.api.deselectAll();
				if (this.agGridSummary) {
					this.setupGridSummary(this.agGridSummary);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (formData) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/detail`, formData).then(({ data }) => {
			this.setViewValues(data.detail)
			return data;
		}).catch(({ response: { data } }) => {
			var errors = [];
			var notify = null;
			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
			if (data && data.STATUS) {
				if (NOTIFICATION) {
					notify = NOTIFICATION[0];
				}
				Object.keys(fieldErrors).forEach((name) => {
					errors.push({ name, errors: data.STATUS[name] });
				});
			}
			return Promise.reject({ errors, notify });
		});

	};

	ViewOutstandingData = (formData) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/outstanding-by-client/detail`, formData).then(({ data }) => {
			this.setOutsandingViewValues(data)
			return data;
		});
	};

	ViewPendingSettlementData = (formData) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/pending-settlement-detail`, formData).then(({ data }) => {
			this.setPendingSettlementViewValues(data)
			return data;
		});
	};

	//Get List of center for dropdown
	getPaymentSummary = (conditional = {}) => {
		return Axios.post(`monthly-activities/corporate-invoices-payments/get-summary`, conditional)
			.then(({ data }) => {
				this.setPaymentSummaryValues(data)
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of center for dropdown
	getPaymentSummaryDetails = (conditional = {}) => {
		return Axios.post(`ho-activities/corporate-invoices/get-payment-create-details`, conditional)
			.then(({ data }) => {
				this.setPaymentSummaryValues(data)
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.get(`/monthly-activities/corporate-invoices-payments/cancellation/${formdata.id}`)
			.then(({ data }) => {
				if (this.agGridSummary) {
					this.setupGridSummary(this.agGridSummary);
				}
				return data;
			})
	};

	doExportDownloadDetail = (formData) => {
		return Axios.post(`/monthly-activities/corporate-invoices-payments/print`, formData)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	onFirstDataRendered = () => {
		if (this.selectAll === true) {
			this.agGridCancelled.api.forEachNode((node) => {
				this.selectCount++;
				return node.setSelected(!!node.data);
			});
		}
	};

	onSelectAllRender = (selectAllFlag) => {
		if (selectAllFlag === true) {
			this.onFirstDataRendered()
		} else {
			this.agGridCancelled.api.forEachNode((node) => {
				return node.setSelected(false);
			});
		}
	}
}

decorate(ManageCorporatePaymentsStore, {
	setSummaryFilterValues: action,
	setOverviewFilterValues: action,
	setSettlementFilterValues: action,
	onFilterChangedOverview: action,
	onFilterChangedSettlement: action,
	per_page_summary: observable,
	per_page_overview: observable,
	per_page_cancelled: observable,
	agGridSummary: observable,
	list_data_summary: observable,
	list_data: observable,
	total_summary: observable,
	allColumnIdsSummary: observable,
	setupGridSummary: action,
	setupGridSettlement: action,
	setupGridOverview: action,
	setPageSizeSummary: action,
	getListOverview: action,
	getListSettlement: action,
	getHistoryList: action,
	onFilterChangedSummary: action,
	summary_filter: observable,
	settlement_filter: observable,
	agGridOverview: observable,
	agGridSettlement: observable,
	setOverviewFilter: action,
	setSettlementFilter: action,
	overview_filter: observable,
	setDeleteValues: action,
	deleteValues: observable,
	setSendEmailValues: action,
	sendemailValues: observable,
	ViewData: action,
	setViewValues: action,
	viewValues: observable,
	setDaliyBookingValues: action,
	daliyBookingValues: observable,
	setCancelledFilterValues: action,
	onFilterChangedCancelled: action,
	setupGridCancelled: action,
	getListCancelled: action,
	agGridCancelled: observable,
	setCancelledFilter: action,
	cancelled_filter: observable,
	selectAll: observable,
	setCheckState: action,
	onFirstDataRendered: action,
	onSelectAllRender: action,
	selectCount: observable,
	setIsEstimation: action,
	isEstimation: observable,
	getPaymentSummary: action,
	setPaymentSummaryValues: action,
	paymentSummaryValues: observable,
	setOutsandingViewValues: action,
	outsandingviewValues: observable,
	setPendingSettlementViewValues: action,
	pendingSettlementValues: observable,
	setEditValues: action,
	editValues: observable,
});