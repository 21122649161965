import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../config/ServerGridConfig";
import { approvalStoreStatus, isActiveStatusValue, isAllowedStatusValue, isExpiredStatusValue, isCompletedStatusValue, isTerminateDisplayValue, isLockDisplayValue, isKYCDisplayValue, isQuotesStatusValue } from "../utils/GlobalFunction";

export default class ManageActiveStatusStore {
    agGrid = null;
    per_page = ServerGridConfig.options.paginationPageSize;
    dropdown_status_list = null;
    dropdown_account_party_quotes_list = null;
    dropdown_device_status_list = null;
    dropdown_user_session_list = null;
    dropdown_service_status_list = null;

    //Get List of account party quotes status for dropdown
    getSetQuotesStatusList = () => {
        return this.dropdown_account_party_quotes_list = isQuotesStatusValue
    };

    //Get List of is_Active status for dropdown
    getSetFilterList = () => {
        return this.dropdown_status_list = isActiveStatusValue
    };

    //Get List of device is_Active status for dropdown
    getSetDeviceStatusList = () => {
        return this.dropdown_device_status_list = isAllowedStatusValue
    }

    //Get List of session is_Active status for dropdown
    getSetUserSessionList = () => {
        return this.dropdown_user_session_list = isExpiredStatusValue
    }

    getSetServiceStatusList = () => {
        return this.dropdown_service_status_list = approvalStoreStatus
    }

    //Get List of is_Active status for dropdown
    getSetCompletedFilterList = () => {
        return isCompletedStatusValue
    };

    getSetTerminateDisplayList = () => {
        return this.dropdown_terminate_list = isTerminateDisplayValue
    }
    getSetLockDisplayList = () => {
        return this.dropdown_lock_list = isLockDisplayValue
    }
    getSetKycDisplayList = () => {
        return this.dropdown_Kyc_list = isKYCDisplayValue
    }

}

decorate(ManageActiveStatusStore, {
    per_page: observable,
    agGrid: observable,
    dropdown_status_list: observable,
    getSetFilterList: action,
    getSetDeviceStatusList: action,
    dropdown_device_status_list: observable,
    dropdown_user_session_list: observable,
    getSetUserSessionList: action,
    dropdown_service_status_list: observable,
    getSetServiceStatusList: action,
    getSetCompletedFilterList: action,
    getSetTerminateDisplayList: action,
    dropdown_terminate_list: observable,
    getSetLockDisplayList: action,
    dropdown_lock_list: observable,
    getSetKycDisplayList: action,
    dropdown_Kyc_list: observable,
    dropdown_account_party_quotes_list: observable,
    getSetQuotesStatusList: observable,
});
