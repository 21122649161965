import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";

export default class ManageProcessListStore {
  agGrid = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  list_data = null;
  total = 0;
  allColumnIds = [];
  killValue = null;

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // set form values to kill
  setKillValue = (data) => {
    this.killValue = data;
  };
  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // call api to get records
  getList = (payload) => {
    if (this.agGrid) {
      var sort = this.agGrid.api.getSortModel();
    }
    this.list_data = null;
    return Axios.get(`/system/process/list`, payload).then(({ data }) => {
      this.list_data = data.list ? data.list : null;
      this.total = data.list.total;
      this.current_page = data.list.current_page;
      var allColumnIds = [];
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
      }
      if (this.agGrid) {
        this.agGrid.api.setSortModel(sort);
      }
    });
  };

  // Call kill api
  KillData = (formdata) => {
    return Axios.get(`/system/process/kill/` + formdata.id)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch((response) => {
		this.getList();
        return Promise.reject(response);
      });
  };
}

decorate(ManageProcessListStore, {
  per_page: observable,
  agGrid: observable,
  list_data: observable,
  total: observable,
  allColumnIds: observable,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onFilterChanged: action,
  setKillValue: action,
  killValue: observable,
});
