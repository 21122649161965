import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../../utils/GlobalFunction";

export default class ManageHubWiseInscanOutscanStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	total = 0;
	allColumnIds = [];
	isDeleted = 0;
	statusValues = null;
	proxynameValues = null;
	resetPasswordValue = null;
	viewValues = null;
	deleteValues = null;
	isActiveFilterList = null;
	advance_filter = null;

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}
	

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};
	

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	 // set form values to  customefilter
	 setSummaryFilterValues = (data = null) => {
		return new Promise((resolve) => {
			 this.summary_filter = data;
			 if (this.agGridSummary) {
				 this.setupGrid(this.agGridSummary);
			 }
			 resolve()
		 });
	 };

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// set form values to  customefilter
	setAdvanceFilterValues = (data = null) => {
		this.advance_filter = data;
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/reports/daily-activities/hubwise-inscan-outscan-detail`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}

			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["is_active"]) {
			final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
		}
		final_sort && final_sort.map((item) => {
			switch (item.colId) {
				case 'defaultCenter.name':
					return item.colId = 'mst_centers.name';
				default:
					return null
			}

		})
		return { final_filter, final_sort };
	};

	//setAdvanceFilter
	setAdvanceFilter = () => {
		let setAdvanceFilter = {};
		if (this.advance_filter) {
			if ('state' in this.advance_filter) {
				setAdvanceFilter.from_date = this.advance_filter.state.from_date;
				setAdvanceFilter.to_date = this.advance_filter.state.to_date;
			}
		}
		return setAdvanceFilter;
	}

	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var advance_filter = this.setAdvanceFilter();
				var payload = {
					advance_filter: advance_filter,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};
	
}

decorate(ManageHubWiseInscanOutscanStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	statusValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	getList: action,
	getStatesList: action,
	onFilterChanged: action,
	advance_filter: observable,
	setAdvanceFilterValues: action,
});
