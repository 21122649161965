import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManagePincodeAreasStore {
    agGrid = null;
    per_page = ServerGridConfig.options.paginationPageSize;
    current_page = 1;
    list_data = null;
    editValues = null;
    deleteValues = null;
    viewValues = null;
    statusValues = null;
    dropdown_pincodearea_list = null;
    total = 0;
    allColumnIds = [];
    advance_filter = null;
    pincodeFilterList = [];
    deliverytagsFilterList = [];

    // set form values to edit
    setEditValues = (data) => {
        this.editValues = data;
    };

    // set form values to view
    setViewValues = (data) => {
        this.viewValues = data;
    };

    // set form values to delete
    setDeleteValues = (data) => {
        this.deleteValues = data;
    };

    // set form values to status
    setStatusValues = (data) => {
        this.statusValues = data;
    };

    setPincodeFilterList = (data) => {
        this.pincodeFilterList = data;
    }
    setDeliverytagsFilterList = (data) => {
        this.deliverytagsFilterList = data.deliverytags;
    }

    setIsActiveFilterList = (data) => {
        this.isActiveFilterList = data;
    }

    // set form values to  Advance filter
    setAdvanceFilterValues = (data = null) => {
        this.advance_filter = data;
        if (this.agGrid) {
            this.setupGrid(this.agGrid);
        }
    };

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params;
        const { api } = params;
        let datasource = this.createDatasource(ServerGridConfig.options);
        api.setServerSideDatasource(datasource);
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSize = (page = this.per_page) => {
        this.per_page = page;
        if (this.agGrid) {
            this.agGrid.api.paginationSetPageSize(parseInt(page));
        }
    };


    // call api to get records
    getList = (payload) => {
        return Axios.post(`admin/pincode-areas/list`, payload).then(({ data }) => {
            if (data.list.data.length) {
                let startPage = (data.list.current_page - 1) * data.list.per_page;
                data.list.data.map((item, index) => {
                    item.srno = startPage + index + 1;
                    return null;
                });
            }

            this.list_data = data.list ? data.list.data : null;
            this.total = data.list.total;
            this.current_page = data.list.current_page;
            return data;
        });
    };

    // Filter function for no record found message
    changeFilterAndSort = (params) => {
        var final_filter = params.filterModel;
        var final_sort = params.sortModel;
        if (final_filter['pincodes.pincode_tag_id']) {
            final_filter['pincodes.pincode_tag_id'].values = convertTextToID(final_filter['pincodes.pincode_tag_id'], this.pincodeFilterList.pincodeTags, 'name', 'id')
        }
        if (final_filter['delivery_tag_id']) {
            final_filter['delivery_tag_id'].values = convertTextToID(final_filter['delivery_tag_id'], this.deliverytagsFilterList, 'name', 'id')
        }
        if (final_filter["is_active"]) {
            final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
        }

        final_sort && final_sort.map((item) => {
            switch (item.colId) {
                case 'pincodes.cities.name':
                    return item.colId = 'cities.name';
                case 'pincodes.states.name':
                    return item.colId = 'states.name';
                case 'pincodes.pincodeTags.name ':
                    return item.colId = 'pincode_tags.name';
                default:
                    return null
            }

        })
        return { final_filter, final_sort };
    };

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params;
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
            this.agGrid.api.hideOverlay();
        }
    };

    //setAdvanceFilter
    setAdvanceFilter = () => {
        let setAdvanceFilter = {};
        if (this.advance_filter) {
            if ("pincodes" in this.advance_filter) {
                setAdvanceFilter.pincode_id = this.advance_filter.pincodes.id;
            }
            if ("delivery" in this.advance_filter) {
                setAdvanceFilter.delivery_tag_id = this.advance_filter.delivery.id;
            }
            if ("pincodetag" in this.advance_filter) {
                setAdvanceFilter['pincodes.pincode_tag_id'] = this.advance_filter.pincodetag.id;
            }
            if ("is_active" in this.advance_filter) {
                setAdvanceFilter.is_active = this.advance_filter.is_active;
            }
        }
        return setAdvanceFilter;
    };

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                var filter_data = this.changeFilterAndSort(params.request);
                var advance_filter = this.setAdvanceFilter();
                var payload = {
                    advance_filter: advance_filter,
                    filter_data: filter_data.final_filter,
                    sort_data: filter_data.final_sort,
                    per_page: params.request.endRow - params.request.startRow,
                    page: Math.ceil(
                        (params.request.startRow + 1) /
                        (params.request.endRow - params.request.startRow)
                    ),
                };
                this.getList(payload).then((data) => {
                    if (data.list.total === 0) {
                        this.agGrid.api.showNoRowsOverlay();
                    } else {
                        this.agGrid.api.hideOverlay();
                    }
                    params.successCallback(data.list.data, data.list.total);
                    var allColumnIds = [];
                    if (this.agGrid && this.agGrid.columnApi && data.total) {
                        this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                            allColumnIds.push(column.col_id);
                        });
                    }
                });
            },
        };
    };

    // Call add api
    AddData = (formdata) => {
        return Axios.post(`admin/pincode-areas/new`, formdata)
            .then(({ data }) => {
                if (this.agGrid) {
                    this.setupGrid(this.agGrid);
                }
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // Call edit api
    EditData = (formdata) => {
        return Axios.post(`admin/pincode-areas/edit/` + formdata.id, formdata)
            .then(({ data }) => {
                if (this.agGrid) {
                    this.setupGrid(this.agGrid);
                }
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // call api to get records
    ViewData = (ID) => {
        return Axios.get(`admin/pincode-areas/details/${ID}`).then(({ data }) => {
            this.setViewValues(data.view);
            return data;
        });
    };

    // Call delete api
    DeleteData = (formdata) => {
        return Axios.delete(`admin/pincode-areas/destroy/` + formdata.id)
            .then(({ data }) => {
                if (this.agGrid) {
                    this.setupGrid(this.agGrid);
                }
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    TogglePublishData = (formdata) => {
        const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
        return Axios.patch("admin/pincode-areas/" + api_link + formdata.id)
            .then(({ data }) => {
                if (this.agGrid) {
                    this.setupGrid(this.agGrid);
                }
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    //Get List of Pincode Area for dropdown
    getPincodeAreasList = (conditional = {}) => {
        return Axios.post(`admin/pincode-areas/lov`, conditional)
            .then(({ data }) => {
                this.setPincodeAreaList(data.pincodeAreas);
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };

    //set List of Pincode Area for dropdown
    setPincodeAreaList = (pincodeAreas) => {
        this.dropdown_pincodearea_list = pincodeAreas;
    };

}

decorate(ManagePincodeAreasStore, {
    per_page: observable,
    agGrid: observable,
    list_data: observable,
    editValues: observable,
    deleteValues: observable,
    statusValues: observable,
    total: observable,
    allColumnIds: observable,
    dropdown_pincodearea_list: observable,
    viewValues: observable,
    advance_filter: observable,
    pincodeFilterList: observable,
    deliverytagsFilterList: observable,
    setupGrid: action,
    setPageSize: action,
    setEditValues: action,
    setDeleteValues: action,
    setStatusValues: action,
    getList: action,
    getStatesList: action,
    onFilterChanged: action,
    setViewValues: action,
    setPincodeFilterList: action,
    setDeliverytagsFilterList: action,
    setIsActiveFilterList: action
})