import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageForwardBookingStore {
	consignmentValues = null;
	dropdown_pincodes_list = null;
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	serviceTypes = null;
	nextAutoCall = false;

	setNextAutoCall = (flag) => {
		this.nextAutoCall = flag
	}

	setConsignmentValues = (data) => {
		this.consignmentValues = data
	}

	setServiceFilterList = (data) => {
		this.serviceTypes = data;
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/bookings/fwd/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`/bookings/fwd/new`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				var lock = null;
				if(data?.lock){
					lock = data.lock;
				}
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify, lock });
			});
	};

	ForwardBookingsConsignmentData = (CO_NO) => {
		return Axios.get(`/bookings/consignment/fwd/details/${CO_NO}`).then(({ data }) => {
			this.setConsignmentValues(data.view)
			return data
		})
			.catch(({ response: { data } }) => {
				this.setConsignmentValues();
				var errors = [];
				const { NOTIFICATION } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						let name = "con_no";
						errors.push({ name, errors: NOTIFICATION });
					}
				}
				return Promise.reject({ errors });
			});
	}

	getForwardPincodeList = (conditional = {}) => {
		return Axios.post(`/bookings/cash/pincode-short-details`, conditional)
			.then(({ data }) => {
				this.dropdown_pincodes_list = data.pincodeDetails.pincode;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	getAutoForwardData = (hub_id) => {
		return Axios.get(`bookings/fwd/weight-entry-details/${hub_id}`)
			.then(({ data }) => {
				// this.dropdown_pincodes_list = data.pincodeDetails.pincode;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(ManageForwardBookingStore, {
	AddData: action,
	setConsignmentValues: action,
	consignmentValues: observable,
	dropdown_pincodes_list: observable,
	getForwardPincodeList: action,
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	serviceTypes: observable,
	setServiceFilterList: action,
	nextAutoCall: observable,
	setNextAutoCall: action
});
