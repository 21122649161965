import { Suspense, useEffect, useState } from "react";
import { observer } from "mobx-react";
import useStore from "./store";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./utils/AppRouter";
import Login from "./page/Login";
import LayoutComponent from "./component/LayoutComponent";
// import { getTokenInit } from "./firebase";
import Fullscreenspinner from "./page/SpinnerPage";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { initializeApp } from 'firebase/app';
import { Notify } from "./utils/Notify";

const getFaviconEl = () => {
	return document.getElementById("favicon");
}

const App = observer(() => {
	const { AUTH, AUTH: { user, company, setAxiosInterceptors, unreadNotificationCount, setUnreadNotificationCount } } = useStore();

	const [messaging, setMessaging] = useState();

    async function setFirebaseApp() {
        try {
            const firebaseConfig = {
				apiKey: process.env.React_APP_apiKey,
				authDomain: process.env.React_APP_authDomain,
				databaseURL: process.env.React_APP_databaseURL,
				projectId: process.env.React_APP_projectId,
				storageBucket: process.env.React_APP_storageBucket,
				messagingSenderId: process.env.React_APP_messagingSenderId,
				appId: process.env.React_APP_appId,
				measurementId: process.env.React_APP_measurementId
			}

            initializeApp(firebaseConfig);
            setMessaging(getMessaging());

            if (window.isSecureContext) {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker
                        .register('firebase-messaging-sw.js')
                        .then((registration) => {
                            console.log('test Registration successful, scope is:', registration?.scope);
                        })
                        .catch((error) => {
                            console.log('test Service worker registration failed, error:', error);
                        });
                }
            }
        }
        catch(err) {
            //
        }
    }

    async function startFCM() {
        getToken(messaging, {
            vapidKey: process.env.React_APP_VapidKey,
        })
        .then((currentToken) => {
            if (currentToken) {
                localStorage.setItem("currentToken", currentToken)
            }
        }).catch((error) => {
            console.warn('An error occurred while retrieving token. ', error);
        });
    }

    messaging && onMessage(messaging, function (payload) {
		setUnreadNotificationCount(unreadNotificationCount + 1)
		Notify.custome(payload);
    });

	useEffect(() => {
		setAxiosInterceptors();
	}, [setAxiosInterceptors]);

	useEffect(() => {
        setFirebaseApp();
        messaging && startFCM();
		getSystemInfo()
		// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, messaging, setUnreadNotificationCount, unreadNotificationCount]);

	useEffect(() => {
		const favicon = getFaviconEl();
		favicon.href = company && company.branding.favicon;;
	}, [company]);

	const handleStorageEvents = (e) => {
		if (e.key === 'token') {
			if (e.oldValue && !e.newValue) {
				AUTH.resetAuthUser();
				window.location.href = "/";
			} else if (!e.oldValue && e.newValue) {
				window.location.reload();
			}
		}
	}

	const getSystemInfo = () => {
		if(window.ipcRenderer){
			window.ipcRenderer.on('setSystemInfo', function(event, data){
				localStorage.setItem("UUID",data.UUID);
				localStorage.setItem("PLATFORMOS",data.platform);
			});
			window.ipcRenderer.on('updateLoader', function (event, data) {
				AUTH.loading = data.loading;
			});
		}
	}
	
	window.addEventListener("storage", handleStorageEvents);

	return (
		<BrowserRouter basename="/">
			<Suspense fallback={<div className="fullscreen__spinner">
			<Fullscreenspinner /></div>}>
			{AUTH.open_login ? 
				<Login /> :
				<LayoutComponent>
					<AppRouter />
				</LayoutComponent>
			}
			</Suspense>
		</BrowserRouter>
	);
});

export default App;
