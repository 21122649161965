import React, { useState } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Col, Layout, Row, Timeline, Table, Collapse, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../store";
import { Constant } from "../../../config/Constant";
import { vsmCommon } from "../../../config/messages/common";
import { convertDate } from "../../../utils/GlobalFunction";
const PacketDetailcolumns = [
	{
		title: 'Consignment No.',
		dataIndex: 'con_no',
		width: '11%',
	},
	{
		title: 'Booking Location',
		dataIndex: 'booking_center',
		width: '18%',
	},
	{
		title: 'Booking Date',
		dataIndex: 'booking_date',
		width: '7%',
		render: (booking_date) => {
			return (
				<span>{convertDate(booking_date, Constant.DEFAULT_DATE_FORMAT)}</span>
			)
		},
	},
	{
		title: 'Consignee',
		dataIndex: 'receiver',
		width: '18%',
		render: (receiver) => {
			return (
				<span>{receiver ? receiver : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Pincode',
		dataIndex: 'dest_pincode',
		width: '12%',
		render: (dest_pincode) => {
			return (
				<span>{dest_pincode ? dest_pincode : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
	{
		title: 'Weight ',
		dataIndex: 'weight',
		width: '12%',
		render: (weight) => {
			return (
				<span>{weight ? weight : Constant.UNDEFINED_VALUES}</span>
			)
		},
	},
];
const PacketTrackingComponent = observer((props) => {
	const {
		ManageTrackingStore: { packetTrackingValues, getPacketTrackingData },
		ManageTrackingStore
	} = useStore();
	const { Panel } = Collapse;
	const [defaultActive, setdefaultActive] = useState([])
	// reset form and close add form
	const close = () => {
		props.close();
		setdefaultActive([]);
	};
	const onChange = (key) => {
		setdefaultActive(key)
		if (key.includes('2') && !packetTrackingValues.packet) {
			getPacketTrackingData({ packet_no: packetTrackingValues.packet_no, purpose: 2 }).then((data) => {
				ManageTrackingStore.packetTrackingValues = ({ ...packetTrackingValues, ...data });
			})
		}
	};
	return (
		packetTrackingValues && (
			<>
				<Drawer
					className="addModal"
					title={`Packet Tracking - ${packetTrackingValues.packet_no}`}
					width="70%"
					visible={props.visible}
					closeIcon={<FontAwesomeIcon icon={faTimes} />}
					onClose={close}
					zIndex={1002}
					footer={[
						<Button
							key="2"
							htmlType="button"
							className="cancelBtn mr-35"
							onClick={close}
						>
							Cancel
						</Button>,
					]}
				>
					{packetTrackingValues.traveling_info ?
						<>
							<h3>Traveling Information</h3>
							<div className="details_table_timeline">
								<Timeline mode={"left"}>
									{packetTrackingValues?.traveling_info.map(item => {
										return (<Timeline.Item label={convertDate(item.created, Constant.DEFAULT_DISPLAY_DATE_TIME_FORMAT)}>{item.result}</Timeline.Item>)
									}
									)}
								</Timeline>
							</div>
						</> : <Layout className="details_table mb-15">
										<Row gutter={6} wrap={false}>
											<Col span={24} className="form__input__textbox">
												{/* <i className="mb-0">Booking information is not available</i> */}
												<Empty
													image={Empty.PRESENTED_IMAGE_SIMPLE}
													description={
														<span>
															Traveling Information is not available
														</span>
													}
												></Empty>
											</Col>
										</Row></Layout>}
					<Collapse
						//accordion
						expandIconPosition={"end"}
						onChange={onChange}
						className="collapse_new_design"
						activeKey={defaultActive}
					>

						<Panel header="Packet Information" key="2">
							{packetTrackingValues?.packet ?
								<Layout className="details_table" style={{ margin: "0 0px 20px" }}>
									<Row gutter={6} wrap={false}>
										<Col span={6} className="form__input__textbox">
											<label>Packet No.</label>
											<span>{(packetTrackingValues?.packet?.packet_no) ? (packetTrackingValues?.packet?.packet_no) : Constant.UNDEFINED_VALUES}</span>
										</Col>
										<Col span={6} className="form__input__textbox">
											<label>Created Time : </label>
											<span>{(packetTrackingValues?.packet?.created) ? convertDate(packetTrackingValues?.packet?.created, Constant.DEFAULT_DATE_TIME_FORMAT) : Constant.UNDEFINED_VALUES}</span>
										</Col>
										<Col span={6} className="form__input__textbox">
											<label>Origin Location : </label>
											<span>{(packetTrackingValues?.packet?.org_name) ? (packetTrackingValues?.packet?.org_name) : Constant.UNDEFINED_VALUES}</span>
										</Col>
										<Col span={6} className="form__input__textbox">
											<label>Location's Phone : </label>
											<span>{(packetTrackingValues?.packet?.center_mobile) ? (packetTrackingValues?.packet?.center_mobile) : Constant.UNDEFINED_VALUES}</span>
										</Col>
									</Row>
									<Row gutter={6} wrap={false}>
										<Col span={6} className="form__input__textbox">
											<label>Next Stop : </label>
											<span>{(packetTrackingValues?.packet?.next_name) ? (packetTrackingValues?.packet?.next_name) : Constant.UNDEFINED_VALUES}</span>
										</Col>
										<Col span={6} className="form__input__textbox">
											<label>Final Destination : </label>
											<span>{(packetTrackingValues?.packet?.dest_name) ? (packetTrackingValues?.packet?.dest_name) : Constant.UNDEFINED_VALUES}</span>
										</Col>
									</Row>
								</Layout> :
								(<Layout className="details_table" style={{ margin: "0 0px 20px" }}>
									<Row gutter={6} wrap={false}>
										<Col span={24} className="form__input__textbox">
											{/* <i className="mb-0">Packet information is not available</i> */}
											<Empty
												image={Empty.PRESENTED_IMAGE_SIMPLE}
												description={
													<span>
														Packet information is not available
													</span>
												}
											></Empty>
										</Col>
									</Row></Layout>)}
							{packetTrackingValues.packet_details &&
								<>
									<h3>Packet Detail Information</h3>
									<Layout className="details_table" style={{ margin: "0 0px 20px" }}>
										<Table
											className="packetentry ViewDRSentry"
											columns={PacketDetailcolumns}
											locale={{ emptyText: vsmCommon.noRecord }}
											dataSource={packetTrackingValues?.packet_details}
											pagination={{
												pageSize: 7,
											}}
										/>
									</Layout>
								</>}
						</Panel>
					</Collapse>


				</Drawer>
			</>
		)
	);
});
export default PacketTrackingComponent;
