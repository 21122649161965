import moment from "moment";
import { Messages } from "../../../../config/messages/messages";
import { Patterns } from "../../../../config/messages/patterns";
import { mobileValidation, replaceString } from "../../../../utils/GlobalFunction";
import Axios from "axios";
import { Constant } from "../../../../config/Constant";

export const vsmLocation = {
	success: Messages.CREATED,
	error: Messages.DUPLICATED,
	validation: {
		name: [
			{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		company: [
			{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		label: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
			{
				pattern: Patterns.ALPHANUMERICSPACIAL,
				message: Messages.LABEL_INVALID_CHARACTERS,
			},
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		incharge_name:
			[{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},],
		location_contact_person:
			[{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},],
		owner_name: [
			{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		Incharge_email: [
			{
				pattern: Patterns.EMAIL,
				message: Messages.EMAIL_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 70,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 70),
			},],
		email: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.EMAIL,
				message: Messages.EMAIL_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 70,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 70),
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					const fr_owner_email = getFieldValue("fr_owner_email");
					const center_mgr_email = getFieldValue("center_mgr_email");

					if (value && fr_owner_email !== "" && center_mgr_email !== "") {
						if (value === fr_owner_email) {
							return Promise.reject(Messages.DUPLICATE_EMAIL);
						} else if (value === center_mgr_email) {
							return Promise.reject(Messages.DUPLICATE_EMAIL);
						}
					}
					return Promise.resolve();
				},
			}),
		],
		officeMobile1: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.MOBILE_INVALID_CHARACTERS,
			},
			{
				min: 10,
				message: Messages.MOBILE_LENGTH,
			},
			{
				max: 10,
				message: Messages.MOBILE_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (rule && rule.length >= 10) {
						let validate = mobileValidation(rule);
						if (!validate) {
							return Promise.reject(Messages.MOBILE_INVALID);
						}
					}
					return Promise.resolve();
				},
			}),
		],
		officeMobile2: [
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.MOBILE_INVALID_CHARACTERS,
			},
			{
				min: 10,
				message: Messages.MOBILE_LENGTH,
			},
			{
				max: 10,
				message: Messages.MOBILE_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (rule && rule.length >= 10) {
						let validate = mobileValidation(rule);
						if (!validate) {
							return Promise.reject(Messages.MOBILE_INVALID);
						}
					}
					return Promise.resolve();
				},
			}),
		],
		mobile: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.MOBILE_INVALID_CHARACTERS,
			},
			{
				min: 10,
				message: Messages.MOBILE_LENGTH,
			},
			{
				max: 10,
				message: Messages.MOBILE_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (rule && rule.length >= 10) {
						let validate = mobileValidation(rule);
						if (!validate) {
							return Promise.reject(Messages.MOBILE_INVALID);
						}
					}
					return Promise.resolve();
				},
			}),
		],
		phone1: [
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.CENTER_PHONE_INVALID_CHARACTERS,
			},
			// {
			// 	min: 6,
			// 	message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 6),
			// },
			// {
			// 	max: 13,
			// 	message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 13),
			// },
			({ getFieldValue }) => ({
				validator(_, value) {
					let stdCode = getFieldValue('phoneStdCode');
					let letStdLength = stdCode ? stdCode.length : 0
					if (value && value.length > 0) {
						let validate = Number(value.length) + Number(letStdLength);
						if (validate !== 11) {
							return Promise.reject(Messages.CENTER_PHONE_INVALID_CHARACTERS);
						}
					}
					return Promise.resolve();
				},
			}),
		],
		phone2: [
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.CENTER_PHONE_INVALID_CHARACTERS,
			},
			// {
			// 	min: 6,
			// 	message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 6),
			// },
			// {
			// 	max: 13,
			// 	message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 13),
			// },
			({ getFieldValue }) => ({
				validator(_, value) {
					let stdCode = getFieldValue('phoneStdCode');
					let letStdLength = stdCode ? stdCode.length : 0
					if (value && value.length > 0) {
						let validate = Number(value.length) + Number(letStdLength);
						if (validate !== 11) {
							return Promise.reject(Messages.CENTER_PHONE_INVALID_CHARACTERS);
						}
					}
					return Promise.resolve();
				},
			}),
		],
		whatsappno: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.WHATSAPP_INVALID_CHARACTERS,
			},
			{
				min: 10,
				message: Messages.MOBILE_LENGTH,
			},
			{
				max: 10,
				message: Messages.MOBILE_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (rule && rule.length >= 10) {
						let validate = mobileValidation(rule);
						if (!validate) {
							return Promise.reject(Messages.WHATSAPPE_INVALID);
						}
					}
					return Promise.resolve();
				},
			}),
		],
		address1: [
			{ required: true, whitespace: true, message: Messages.REQUIRED },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 200,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 200),
			},
		],
		address2: [
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		address3: [
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		country_id: [{ required: true, message: Messages.NOT_SELECTED }],
		state_id: [{ required: true, message: Messages.NOT_SELECTED }],
		city_id: [{ required: true, message: Messages.NOT_SELECTED }],
		pincode_id: [{ required: true, message: Messages.NOT_SELECTED }],
		rcp_id: [{ required: true, message: Messages.NOT_SELECTED }],
		fax: [
			{
				pattern: Patterns.PHONE,
				whitespace: true,
				message: Messages.FAX_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20),
			},
		],
		skype_id: [
			{
				min: 6,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 6),
			},
			{
				max: 100,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 100),
			},
		],
		fr_id: [{ required: true, message: Messages.NOT_SELECTED }],
		hub_id: [{ required: true, message: Messages.NOT_SELECTED }],
		ho_id: [{ required: true, message: Messages.NOT_SELECTED }],
		username: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.USERNAME,
				message: Messages.USERNAME_INVALID_CHARACTER,
			},
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
			({ getFieldValue }) => ({
				async validator(value, rule) {
					const username = getFieldValue("username");
					if (username && username.length >= 8 && username.length <= 20) {
						await Axios.post(
							`admin/users/check-avail-username`, { username }
						).then(({ data }) => {
							return Promise.resolve();
						})
							.catch(({ response: { data } }) => {
								var errors = null;
								if (data && data?.STATUS) {
									const { NOTIFICATION, ...fieldErrors } = data.STATUS;
									Object.keys(fieldErrors).forEach((name) => {
										errors = data.STATUS[name];
									});
								}
								return Promise.reject(errors);
							});
					}
					return Promise.resolve();
				},
			}),
		],
		password: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 8,
				message: Messages.PASSWORD_LENGTH,
			},
			{
				max: 20,
				message: Messages.PASSWORD_LENGTH,
			},
			{
				pattern: Patterns.PASSWORD,
				message: Messages.PASSWORD_INVALID_CHARACTERS,
			},
		],
		confirmPassword: [
			{ required: true, message: Messages.REQUIRED },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue("password") === value) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.PASSWORD_NOT_MATCH);
				},
			}),
		],
	},
	validationlo: {
		cb_series: [
			{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{ pattern: Patterns.MOBILE, message: Messages.CASH_SERIES },
			({ getFieldValue }) => ({
				validator(value, rule) {
					const cb_series = getFieldValue("cb_series");
					if (cb_series < 10000000000000 || cb_series > 99999999999999) {
						return Promise.reject(Messages.CASH_SERIES);
					} else if (getFieldValue("ab_series") === rule) {
						return Promise.reject(Messages.cb_series)
					}
					return Promise.resolve();
				},
			}),
		],
		ref_no: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (typeof value === "undefined" || value <= 0) {
						return Promise.reject(Messages.REQUIRED);
					} else if (value.length < 0) {
						return Promise.reject(Messages.REQUIRED);
					} else {
						return Promise.resolve();
					}
				}
			}),
			{
				min: 5,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 5)
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20)
			},
		],
		ab_series: [
			{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{ pattern: Patterns.MOBILE, message: Messages.CASH_SERIES },
			({ getFieldValue }) => ({
				validator(value, rule) {
					const ab_series = getFieldValue("ab_series");
					if (ab_series < 10000000000000 || ab_series > 99999999999999) {
						return Promise.reject(Messages.CASH_SERIES);
					} else if (getFieldValue("cb_series") === rule) {
						return Promise.reject(Messages.ab_series)
					}
					return Promise.resolve();
				},
			}),
		],
		code: [
			{
				pattern: Patterns.NUMBER,
				whitespace: true,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "1001-9999"),
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (value || value === 0) {
						if (value < 1001 || value > 9999) {
							return Promise.reject(replaceString(Messages.INVALID_MIN_MAX_RANGE, "#MINTOMAX#", "1001 to 9999"))
						}
					}
					return Promise.resolve();
				},
			}),
		],
		aadhar: [
			{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{
				min: 12,
				message: Messages.AADHAR_INVALID_CHARACTERS,
			},
			{
				max: 12,
				message: Messages.AADHAR_INVALID_CHARACTERS,
			},
			{
				pattern: Patterns.AADHAR,
				message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "aadhar")
			},
		],
		pan: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
			{
				pattern: Patterns.PAN,
				message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "PAN"),
			},
			{
				min: 10,
				message: Messages.PAN_INVALID_CHARACTERS,
			},
			{
				max: 10,
				message: Messages.PAN_INVALID_CHARACTERS,
			},
		],
		gst_no: [
			{ required: true, whitespace: true, message: Messages.REQUIRED },
			{
				min: 15,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 15),
			},
			{
				pattern: Patterns.GSTIN,
				whitespace: true,
				message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "GST"),
			},
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (rule && rule.length >= 15 && getFieldValue("pan") !== "") {
						let panNo = getFieldValue("pan");
						if (!(panNo === rule.substring(2, 12))) {
							return Promise.reject(Messages.GST_PAN_DOESNOT_MATCH);
						}
					}
					return Promise.resolve();
				}
			})
		],
		ct_id: [{ required: true, message: Messages.NOT_SELECTED }],
		ot_id: [{ required: true, message: Messages.NOT_SELECTED }],
		pt_id: [{ required: true, message: Messages.NOT_SELECTED }],
		sez_id: [{ required: true, message: Messages.NOT_SELECTED }],
		op_id: [{ required: true, message: Messages.NOT_SELECTED }],
		from_pincode: [{ required: true, message: Messages.NOT_SELECTED }],
		operation_note: [
			{ required: false, message: Messages.REQUIRED, whitespace: true, },
			{
				max: 400,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 400),
			},
		],
		ho_bank_acc_id: [{ required: true, message: Messages.NOT_SELECTED }],
		dep_bank_id: [{ required: true, message: Messages.NOT_SELECTED }],
		dep_mode_id: [{ required: true, message: Messages.NOT_SELECTED }],
		dep_date: [{ required: true, message: Messages.NOT_SELECTED }],
		rent_amount: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					//const op_flag = getFieldValue("op_id");
					// if (op_flag === 2 && value === null) {
					// 	return Promise.reject(
					// 		{ required: true, message: Messages.REQUIRED },
					// 	);
					// } else
					if (value || value === 0) {
						if (value < 1 || value > 9999999) {
							return Promise.reject(replaceString(Messages.INVALID_MIN_MAX_RANGE, "#MINTOMAX#", "1 to 9999999"))
						}
					}
					return Promise.resolve();
				},
			}),
		],
		amount: [
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (value || value === 0) {
						if (value < 0 || value > 9999999) {
							return Promise.reject(replaceString(Messages.INVALID_MIN_MAX_RANGE, "#MINTOMAX#", "0 to 9999999"))
						}
					}
					return Promise.resolve();
				},
			}),
		],
		rental_name: [
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					// const op_flag = getFieldValue("op_id");
					// if (op_flag === 2 && !value) {
					// 	return Promise.reject(
					// 		{ required: true, message: Messages.REQUIRED },
					// 	);
					// }
					return Promise.resolve();
				},
			}),

		],
		rental_mobile: [
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.MOBILE_INVALID_CHARACTERS,
			},
			{
				min: 10,
				message: Messages.MOBILE_LENGTH,
			},
			{
				max: 10,
				message: Messages.MOBILE_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					// const op_flag = getFieldValue("op_id");
					// if (op_flag === 2 && !value) {
					// 	return Promise.reject(
					// 		{ required: true, message: Messages.REQUIRED },
					// 	);
					// } else 
					if (value && value.length >= 10) {
						let validate = mobileValidation(value);
						if (!validate) {
							return Promise.reject(Messages.MOBILE_INVALID);
						}
					}
					return Promise.resolve();
				},
			}),
		],
		ack_date: [
			({ getFieldValue }) => ({
				validator(_) {
					const dispDate = getFieldValue("disp_date") ? moment(getFieldValue("disp_date")).utcOffset(Constant.UTCOFFSET).format("YYYY-MM-DD") : null
					const ackDate = getFieldValue("ack_date") ? moment(getFieldValue("ack_date")).utcOffset(Constant.UTCOFFSET).format("YYYY-MM-DD") : null
					if (!dispDate || dispDate > ackDate) {
						return Promise.reject(Messages.ACK_DATE_INVALID);
					}
					return Promise.resolve();
				},
			}),
		],
	},
	validationBankInfo: {
		bank: [{ required: true, message: Messages.NOT_SELECTED }],
		branch: [
			{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		account: [
			{ required: true, whitespace: true, message: Messages.REQUIRED },
			{ pattern: Patterns.ACCOUNT, message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "acc.no.") },
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_DIGIT_ERROR, "#MIN#", 8),
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_DIGIT_ERROR, "#MAX#", 20),
			},
		],
		mobile: [
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.MOBILE_INVALID_CHARACTERS,
			},
			{
				min: 10,
				message: Messages.MOBILE_LENGTH,
			},
			{
				max: 10,
				message: Messages.MOBILE_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (rule && rule.length === 10) {
						let validate = mobileValidation(rule);
						if (!validate) {
							return Promise.reject(Messages.MOBILE_INVALID);
						}
					}
					return Promise.resolve();
				}
			})
		],
		phone2: [
			{
				pattern: Patterns.PHONE,
				whitespace: true,
				message: replaceString(Messages.INVALID_ATTR, "#ATTR#", 'phone'),
			},
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20),
			},
		],
		ifsc: [
			{ required: true, whitespace: true, message: Messages.REQUIRED },
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20),
			},
		],
		micr: [
			{ pattern: Patterns.ACCOUNT, message: replaceString(Messages.INVALID_DIGIT_ATTR, "#ATTR#", "micr") },
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_DIGIT_ERROR, "#MIN#", 8),
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_DIGIT_ERROR, "#MAX#", 20),
			},
		],
		swift: [
			{ pattern: Patterns.ALPHANUMERIC, message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "swift") },
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_DIGIT_ERROR, "#MIN#", 8),
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_DIGIT_ERROR, "#MAX#", 20),
			},
		],
		address1: [
			{ required: true, whitespace: true, message: Messages.REQUIRED },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 200,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 200),
			},
		],
		address2: [
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		address3: [
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		country_id: [{ required: true, message: Messages.NOT_SELECTED }],
		state_id: [{ required: true, message: Messages.NOT_SELECTED }],
		city_id: [{ required: true, message: Messages.NOT_SELECTED }],
		pincode: [
			{ required: true, whitespace: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.PINCODE,
				message: Messages.PINCODE_INVALID_CHARACTERS,
			},
			{
				min: 6,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 6),
			},
			{
				max: 6,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 6),
			},
		],
	},
	coverImageValidation: {
		cover_photo: [
			({ getFieldValue }) => ({
				validator() {
					if (
						!getFieldValue("cover_photo") ||
						getFieldValue("cover_photo").fileList.length === 0
					) {
						return Promise.reject(Messages.REQUIRED);
					}
					return Promise.resolve();
				},
			}),
		],
	},
	validationLocation: {
		latitude: [
			{
				pattern: Patterns.LATLNG,
				message: Messages.LATLNG_RANGE,
			},
		],
		longitude: [
			{
				pattern: Patterns.LATLNG,
				message: Messages.LATLNG_RANGE,
			},
		]
	},
	validationContactInfo: {
		name: [
			{ required: true, message: Messages.REQUIRED, whitespace: true, },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		mobile: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.MOBILE,
				whitespace: true,
				message: Messages.MOBILE_INVALID_CHARACTERS,
			},
			{
				min: 10,
				message: Messages.MOBILE_LENGTH,
			},
			{
				max: 10,
				message: Messages.MOBILE_LENGTH,
			},
			({ getFieldValue }) => ({
				validator(value, rule) {
					if (rule && rule.length >= 10) {
						let validate = mobileValidation(rule);
						if (!validate) {
							return Promise.reject(Messages.MOBILE_INVALID);
						}
					}
					return Promise.resolve();
				},
			}),
		],
	}
};