import React, { useState, useEffect } from "react";
import { Form, Button, Drawer, Row, Col, Divider, Alert, Spin, Modal } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify } from "../../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faPlus, faSave, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Constant } from "../../../../../config/Constant";
import debounce from "lodash/debounce";
import InputComponent from "../../../../../component/InputComponent";
import { vsmLocation } from "../Validation";
import DefaulHeader from "./DefaulHeader";
import { properCaseString } from "../../../../../utils/GlobalFunction";
import { Messages } from "../../../../../config/messages/messages";

const ContactInfoComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageCentersStore: { AddContactData, contactInfoValues, typeMode, editValues, setDeleteContactInfoValues, deleteContactInfoValues, DeleteContactInfoData },
		AUTH
	} = useStore();
	const [saving, setSaving] = useState();
	const [isViewPurpose, setIsViewPurpose] = useState();
	const [deleteModal, setDeleteModal] = useState(false);

	// set the form values to edit
	useEffect(() => {
		let formData = [];
		if (typeMode !== Constant.VIEW) {
			formData.push({
				name: "",
				mobile: ""
			});
		}
		if (contactInfoValues) {
			let view = (typeMode === Constant.VIEW) ? true : false;
			setIsViewPurpose(view);
			contactInfoValues.length > 0 && contactInfoValues.map(obj => {
				formData.push(obj)
				return true;
			})
			form.setFieldsValue({
				details: formData,
			});
		} else {
			form.setFieldsValue({
				details: formData,
			});
		}
	}, [contactInfoValues, typeMode, form]);

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
	}, 0);


	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true);
		let formdata = {};
		formdata = data?.details?.[0];
		formdata.center_id = editValues.id;
		AddContactData(formdata, Constant.ADD)
			.then((data) => {
				if (form.getFieldValue("details")) {
					form.setFieldsValue({
						details: [null, ...form.getFieldValue("details")]
					})
				}
				else {
					form.setFieldsValue({
						details: [null]
					})
				}
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	const editContactData = (index) => {
		let details = form.getFieldValue("details");
		let formdata = {};
		formdata = details[index];
		formdata.center_id = editValues.id;

		AddContactData(formdata, Constant.EDIT)
			.then((data) => {
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => { });
	};

	// Open & Close  form for edit State
	const openDeleteModal = (data, index) => {
		let details = form.getFieldValue("details");
		data.details = details[index];
		data.index = index;
		setDeleteContactInfoValues(data);
		setDeleteModal(true);
	};
	// reset form and close add form
	const deleteClose = () => {
		//props.close();
		setDeleteModal(false)
	};
	const deleteContactInfo = () => {
		let data = {};
		data.id = deleteContactInfoValues?.details?.id;
		DeleteContactInfoData(data)
			.then((data) => {
				deleteClose();
				let currentList = form.getFieldValue("details");
				currentList = currentList.filter((item) => item.id !== deleteContactInfoValues?.details?.id);
				form.setFieldsValue({
					details: [...currentList]
				})
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => { });
	}

	// reset form and close add form
	const close = (submitValue) => {
		if (submitValue === "submit") {
			props.close();
		} else if (submitValue === "submitNext") {
			props.closeNext();
		}
		form.resetFields();
		//setDisabled(true);
	};

	return (props.visible &&
		<Drawer
			className="editModal"
			title="Contact Information"
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={() => { close("submit") }}
			footer={[
				(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="1"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={() => { close("submitNext") }}
				>
					Skip
				</Button>),
				(typeMode === Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="2"
					type="primary"
					onClick={() => {
						close("submitNext");
					}}
				>
					Continue
				</Button>)

			]}
		>
			{props.showErrorNotes && props.visible && editValues && <Alert className="mb-25"
				type="error"
				showIcon
				message={
					editValues.is_terminated ? `This location is terminated so you cannot make any changes` : (!editValues.is_active) ? `This location is inactive so you cannot make any changes` : null
				}
			/>}
			<Form
				form={form}
				id={"contactInfoForm"}
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
			//onChange={handleChange}
			>
				<DefaulHeader />
				<Divider className="mt-0" />
				<Row>
					<Col xs={{ span: 24 }}>
						<div className="insurance_table">
							<div className="insurance_head">

							</div>
							<div className="insu_table">
								<table style={{ minWidth: "780px" }}>
									{/* {props.form.getFieldValue("details") && props.form.getFieldValue("details").length > 0 && */}
									<thead>
										<tr>
											<td width="20%">Name</td>
											<td width="20%">Mobile</td>
											{!isViewPurpose && <td width="10%">Actions</td>}
										</tr>
									</thead>
									<tbody>
										{form.getFieldValue("details") && form.getFieldValue("details").length > 0 && <Form.List name="details">
											{(fields, { remove }) => {
												return fields && fields.map((field, index) => {
													return (
														<tr key={index}>
															<td>
																<InputComponent
																	type="text"
																	required={!isViewPurpose}
																	readOnly={isViewPurpose}
																	placeholder="Name"
																	rules={(isViewPurpose) ? [] : vsmLocation.validationContactInfo.name}
																	name={[field.name, "name"]}
																	onChange={(e) => {
																		if (e.target.value) {
																			let allDetails = form.getFieldValue('details');
																			allDetails[index].name = properCaseString(e.target.value);
																			form.setFieldsValue({ details: allDetails });
																		}
																	}}
																/>
															</td>
															<td>
																<InputComponent
																	type="text"
																	required={!isViewPurpose}
																	readOnly={isViewPurpose}
																	placeholder="Mobile"
																	maxLength={10}
																	rules={(isViewPurpose) ? [] : vsmLocation.validationContactInfo.mobile}
																	name={[field.name, "mobile"]}
																/>
															</td>
															{!isViewPurpose && <td valign="middle" className="text-center">
																{(index === 0) && (
																	saving ? <Spin size="small" /> : <Button
																		htmlType="submit"
																		form={props.id}
																		type="primary"
																		//disabled={disabled}
																		loading={saving}
																		className="Plus_icon_add"
																		onClick={handleChange}
																	//	onClick={() => addInsuranceRow()}
																	>
																		<FontAwesomeIcon icon={faPlus} />
																	</Button>)}

																{(index !== 0) && (
																	<>
																		<Button
																			type="text"
																			title={"Edit"}
																			className="save_insu"
																			size="large"
																			style={{ padding: 7 }}
																			onClick={() => {
																				editContactData(index);
																			}}
																		>
																			<FontAwesomeIcon icon={faSave} />
																		</Button>
																		<Button
																			type="text"
																			title={"Delete"}
																			className="del_insu"
																			size="large"
																			style={{ padding: 7 }}
																			//disabled={isView}
																			onClick={() => {
																				openDeleteModal(field, index)
																			}}
																		>
																			<FontAwesomeIcon icon={faTrashAlt} />
																		</Button></>
																)}
															</td>}
														</tr>
													)
												})
											}}
										</Form.List>
										}
										{form.getFieldValue("details") && form.getFieldValue("details").length === 0 &&
											<tr className="text-center">
												<td colSpan={2}>{Messages.NO_RECORD}</td>
											</tr>
										}
									</tbody>
								</table>
							</div>
						</div>
					</Col>
				</Row>
			</Form>
			{deleteModal &&
				<Modal
					centered
					className="deleteModal"
					title="Delete Contact Information?"
					visible={deleteModal}
					closeIcon={<FontAwesomeIcon icon={faTimes} />}
					onCancel={deleteClose}
					footer={[
						<Button
							key="2"
							htmlType="button"
							className="cancelBtn mr-35"
							onClick={deleteClose}
						>
							Cancel
						</Button>,
						<Button
							key="1"
							loading={saving}
							htmlType="button"
							type="primary"
							danger
							onClick={deleteContactInfo}
						>
							Delete
						</Button>,
					]}
				>
					<Row align="middle">
						<Col span={24} className="text-center">
							<h3>
								<span className="danger_color"><FontAwesomeIcon className="" icon={faExclamationTriangle} /></span> Would you like to delete <span>{deleteContactInfoValues.details.name}</span>?
							</h3>
						</Col>
					</Row>

				</Modal>
			}
		</Drawer>
	);
});

export default ContactInfoComponent;
