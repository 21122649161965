import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";

export default class ManageForwardingRatePolicyStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	loValues = null;
	defaultValue = null;
	typeMode = null;
	isActiveFilterList = null;
	isTexFilterList = null;
	completePendingFilterList = null;
	isTerminated = 1;
	partyTypeFilterList = null;
	stock_list_data = null;
	isActiveFilterList = null;
	ManagecommonStoreObject = null;
	formField = null;
	zoneFormField = null;
	editZoneRateValues = null;
	viewZoneValues = null;
	deleteZoneValues = null;
	editZoneValues = null;
	list_data_pincode = null;
	total_pincode = 0;
	current_page_pincode = 1;

	constructor(ManagecommonStoreObject) {
		this.ManagecommonStoreObject = ManagecommonStoreObject;
	}
	// Setup grid and set column size to autosize
	setupPincodeGrid = (params) => {
		this.agGridPincode = params;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	// set form values to view
	setZoneEditValues = (data) => {
		this.editZoneValues = data;
	};

	// Filter function for no record found message
	onPincodeFilterChanged = (params) => {
		this.agGridPincode = params;
		if (this.agGridPincode && this.agGridPincode.api.getModel().getRowCount() === 0) {
			this.agGridPincode.api.showNoRowsOverlay();
		}
		if (this.agGridPincode && this.agGridPincode.api.getModel().getRowCount() > 0) {
			this.agGridPincode.api.hideOverlay();
		}
	};

	setZoneAddMoreValues = (data) => {
		//this.zoneFormField = zoneData.pre_create;
		this.zoneFormField = data;
	}

	setZoneRateValues = (data) => {
		//this.editZoneRateValues = zoneData.view;
		this.editZoneRateValues = data;
	}

	setisTerminated = (is_terminated) => {
		this.isTerminated = is_terminated
		this.setupGrid(this.agGrid);
	}

	setAddMoreValues = (data) => {
		this.formField = data;
	}


	setTypeMode = (mode) => {
		this.typeMode = mode;
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};

	//set form values to terminate
	setTerminateValues = (data) => {
		this.terminateValues = data
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setISTexFilterList = (data) => {
		this.isTexFilterList = data
	}

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	refreshgetAllocatedStickersList = () => {
		this.getAllocatedStickersList()
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	setZoneDeleteValues = (data) => {
		this.deleteZoneValues = data;
	};

	setListData = (data) => {
		if (this.agGridPincode) {
			var filter = this.agGridPincode.api.getFilterModel();
			var sort = this.agGridPincode.api.getSortModel();
		}
		this.list_data_pincode = data ? data : null;
		this.total_pincode = data.length;
		if (this.agGridPincode) {
			this.agGridPincode.api.setFilterModel(filter);
			this.agGridPincode.api.setSortModel(sort);
		}
	}

	// set form values to view
	setZoneViewValues = (data) => {
		if (data && data.pincode) {
			this.setListData(data.pincode);
		}
		this.viewZoneValues = data;
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					purpose: this.isTerminated,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};


	// Call add api
	AddData = (formdata) => {
		return Axios.post(`business/contracts/contracts-save`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`business/piece-rate-policy/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	AddZoneData = (formdata) => {
		return Axios.post(`business/piece-rate-policy/add-zone`, formdata)
			.then(({ data }) => {
				this.getDetailsByZoneData(this.editZoneRateValues?.id);
				let currentZoneField = this.zoneFormField;

				if (currentZoneField && currentZoneField.zones) {
					currentZoneField.zones.push({
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt,
						is_completed: data.zone.is_completed
					});
					this.setZoneAddMoreValues(currentZoneField);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	WithZoneEditData = (formdata) => {
		return Axios.post(`business/piece-rate-policy/edit-by-zone/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};


	//call api to edit zone 
	EditZoneData = (formdata) => {
		return Axios.post(`business/piece-rate-policy/edit-zone/${formdata.id}`, formdata)
			.then(({ data }) => {
				if (this.editZoneRateValues) {
					this.getDetailsByZoneData(this.editZoneRateValues?.id);
				}
				let currentZoneField = this.zoneFormField;
				let viewZoneField = this.formField;
				if (currentZoneField && currentZoneField.zones) {
					var index = currentZoneField.zones.findIndex(zone => zone.id === formdata.id);
					currentZoneField.zones.splice(index, 1, {
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt
					});
					this.setZoneAddMoreValues(currentZoneField);
				}
				if (viewZoneField && viewZoneField.zones) {
					let index = viewZoneField.zones.findIndex(zone => zone.id === formdata.id);
					viewZoneField.zones.splice(index, 1, {
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt
					});
					this.setAddMoreValues(viewZoneField);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (formData) => {
		return Axios.get(`/business/piece-rate-policy/details/${formData.id}/${formData.type}`).then(({ data }) => {
			// this.setViewValues(data.view);
			this.setViewValues(data.view);
			this.setEditValues(data.view);
			this.setAddMoreValues(data.pre_create);
			return data;
		});
	};

	// call api to get zone records
	ViewZoneData = (id, prt_id) => {
		return Axios.get(`/business/piece-rate-policy/get-zone/${prt_id}/${id}`).then(({ data }) => {
			this.setZoneViewValues(data.view);
			return data;
		});
	};

	// call api to get Details By Zone	
	getDetailsByZoneData = (id) => {
		return Axios.get(`business/piece-rate-policy/details-by-zone/${id}`).then(({ data }) => {
			this.setZoneAddMoreValues(data.pre_create);
			this.setZoneRateValues(data.view);
			this.validationErrorJson = data.error_messages;
			return data;
		});
	};

	// Call delete zone api
	DeleteZoneData = (formdata) => {
		return Axios.delete(`business/piece-rate-policy/delete-zone/${formdata.fw_prp_id}/${formdata.id}`)
			.then(({ data }) => {
				if (this.editZoneRateValues) {
					this.getDetailsByZoneData(this.editZoneRateValues?.id);
				}
				let currentZoneField = this.zoneFormField;
				let viewZoneField = this.formField;

				if (currentZoneField && currentZoneField.zones) {
					currentZoneField.zones = currentZoneField.zones.filter(zone => zone.id !== formdata.id);
					this.setZoneAddMoreValues(currentZoneField);
				}

				if (viewZoneField && viewZoneField.zones) {
					viewZoneField.zones = viewZoneField.zones.filter(zone => zone.id !== formdata.id);
					this.setAddMoreValues(viewZoneField);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call add api
	ValidateZoneData = (formdata) => {
		return Axios.post(`business/piece-rate-policy/zone-validate`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

}

decorate(ManageForwardingRatePolicyStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	viewValues: observable,
	setEditValues: action,
	setDefaultValue: action,
	defaultValue: observable,
	typeMode: observable,
	setTypeMode: action,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setisTerminated: action,
	setTerminateValues: action,
	terminateValues: observable,
	isTexFilterList: observable,
	setISTexFilterList: action,
	formField: observable,
	setAddMoreValues: action,
	setZoneViewValues: action,
	viewZoneValues: observable,
	zoneFormField: observable,
	editZoneRateValues: observable,
	setZoneAddMoreValues: action,
	setZoneRateValues: action,
	deleteZoneValues: observable,
	setZoneDeleteValues: action,
	setZoneEditValues: action,
	editZoneValues: observable,
	list_data_pincode: observable,
	total_pincode: observable,
	current_page_pincode: observable,
});
