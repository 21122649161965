/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID, convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";

export default class ManageStaffStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_center_list = null;
	loValues = null;
	kycFormDetails = null;
	defaultValue = null;
	typeMode = null;
	startViewMode = false;
	isActiveFilterList = null;
	completePendingFilterList = null;
	dropdown_center_types_list = null;
	dropdown_OwnershipType_list = null;
	dropdown_partnership_types_list = null;
	staffviewValues = null;
	familyList = [];

	setStaffViewValues = (data) => {
		this.staffviewValues = data;
	};

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}

	setStartViewMode = (mode) => {
		this.startViewMode = mode;
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`/admin/staff/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					//     item.cities = item.cities && item.cities.name
					return null;
				});
			}

			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter["is_active"]) {
			final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
		}

		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`admin/staff/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`admin/staff/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`admin/staff/details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setEditValues(setData);
			return data;
		});
	};

	TogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
		return Axios.patch("admin/staff/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	StaffTogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 1 ? "activate/" : "deactivate/";
		return Axios.patch("admin/staff-family/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	CoverImageuploadData = (formdata) => {
		return Axios.post(`organization/location/upload-cover-image/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get Default KYC Form 
	getKycForm = (conditional = {}) => {
		conditional.id = this.editValues.id;
		return Axios.post(`admin/staff/kyc-details`, conditional).then(({ data }) => {
			this.kycFormDetails = data.doc_types;
			return data;
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	// Call delete api
	deleteKYCData = (formdata) => {
		return Axios.delete(`admin/staff/kyc-destroy/${formdata.doc_id}/${formdata.cr_id}`)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//finish Kyc
	finishKycData = (cr_id) => {
		return Axios.post(`admin/staff/kyc-finish`, { id: cr_id })
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//
	uploadKYCData = (formdata) => {
		return Axios.post(`admin/staff/kyc-upload`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	staffFamilyData = (ID) => {
		this.editFranchiseeId = ID;
		return Axios.get(`admin/staff/family-detail/${ID}`).then(({ data }) => {
			//this.setfrFamilyDataViewValues(data.view);
			return data;
		});
	};

	// Call add api
	AddDataFamilyInfo = (formdata) => {
		return Axios.post(`admin/staff-family/new`, formdata)
			.then(({ data }) => {
				let currentList = this.staffviewValues;
				formdata.id = data.id
				if (currentList.fpfamily) {
					currentList.fpfamily.push(formdata);
				}
				this.setStaffViewValues(currentList);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageStaffStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	statusValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setStatusValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_center_list: observable,
	loValues: observable,
	kycFormDetails: observable,
	getKycForm: action,
	finishKycData: action,
	uploadKYCData: action,
	setDefaultValue: action,
	defaultValue: observable,
	typeMode: observable,
	setTypeMode: action,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setCompletePendingFilterList: action,
	completePendingFilterList: observable,
	setStaffViewValues: action,
	staffviewValues: observable,
	familyList: observable
});
