import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID, ScheduledDeliveryWeek } from "../../../../utils/GlobalFunction";

export default class ManageServiceAreaStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	serviceAreaList = [];
	serviceAreaScheduleChecked = [];
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_my_area_list = null;
	deliverytagsFilterList = [];
	dropdown_service_area_list = null;

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};
	setDeliverytagsFilterList = (data) => {
		this.deliverytagsFilterList = data.deliverytags;
	}
	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setStatusFilterList = (data) => {
		this.isStatusFilterList = data;
	}

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`business/service-area/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}

			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter['schedule']) {
			final_filter['schedule'].values = convertTextToID(final_filter['schedule'], ScheduledDeliveryWeek, 'name', 'id')
		}
		if (final_filter['pincode_areas.delivery_tag_id']) {
			final_filter['pincode_areas.delivery_tag_id'].values = convertTextToID(final_filter['pincode_areas.delivery_tag_id'], this.deliverytagsFilterList, 'name', 'id')
		}
		if (final_filter["is_active"]) {
			final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["bm_service_pincodes.status"]) {
			final_filter['bm_service_pincodes.status'].values = convertTextToID(final_filter['bm_service_pincodes.status'], this.isStatusFilterList, 'name', 'id')
		}
		final_sort && final_sort.map((item) => {
			switch (item.colId) {
				case 'pincodes.pincodeTags.name':
					return item.colId = 'pincode_tags.name';
				case 'pincodes.states.name':
					return item.colId = 'states.name';
				case 'pincodes.cities.name':
					return item.colId = 'cities.name';
				case 'pincode_areas.delivery_tags.name':
					return item.colId = 'delivery_tags.name';
				case 'centers.mst_franchisee.company':
					return item.colId = 'mst_franchisee.company';
				case 'centers.mst_hub.name':
					return item.colId = 'mst_hub.name';
				default:
					return null;
			}

		})
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);

				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`business/service-area/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`business/service-area/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`business/service-area/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
		return Axios.patch("business/service-area/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// my-pincode-area
	getPincodeList = (conditional = {}) => {
		return Axios.get(`business/service-area/my-pincode-area`, { params: conditional })
			.then(({ data }) => {
				// this.dropdown_my_area_list = data.mst_center;
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	//Get List of Myarea for dropdown
	getServiceList = (conditional = {}) => {
		return Axios.get(`business/service-area/my-area`, { params: conditional })
			.then(({ data }) => {
				this.dropdown_my_area_list = data.mst_center;
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call add api
	AddPincodeServiceAreaData = (formdata) => {
		return Axios.post(`business/service-area/pincode-area/new`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call addService api
	AddServiceData = (formdata) => {
		return Axios.post(`business/service-area/service-pincode`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call Admin Approval api
	ApprovalAdminData = (formdata) => {
		return Axios.patch(`/business/service-area/approve/${formdata.service_pincode_id}`)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get List of service area for dropdown
	getServiceAreaList = (conditional = {}) => {
		return Axios.post(`/business/service-area/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_service_area_list = data.bm_service_areas;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

}

decorate(ManageServiceAreaStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	statusValues: observable,
	dropdown_my_area_list: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	getList: action,
	onFilterChanged: action,
	serviceAreaList: observable,
	serviceAreaScheduleChecked: observable,
	deliverytagsFilterList: observable,
	setDeliverytagsFilterList: action,
	setIsActiveFilterList: action,
	dropdown_service_area_list: observable,
	getServiceAreaList: action,
	getPincodeList: action
});
