import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import {
	convertTextToID,
	convertViewAddresspincodeobj,
} from "../../../../utils/GlobalFunction";
export default class ManageAccountPartyStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	virtual_per_page = 10;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	deleteValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_account_party_quotes_list = null;
	dropdown_account_party_list = null;
	loValues = null;
	kycFormDetails = null;
	defaultValue = null;
	typeMode = null;
	isActiveFilterList = null;
	completePendingFilterList = null;
	contactLevelFilterList = null;
	isTerminated = 0;
	advance_filter = null;
	isRateFilterList = null;
	isLockFilterList = null;
	partyTypeFilterList = null;
	stock_list_data = null;
	add_rate_data = null;
	sendEmaildata = null;
	fromBarcode = null;
	virtualStickerAgGrid = null
	virtualStickerAllColumnIds=[]
	virtual_total  = 0;
	virtual_current_page  = 1;
	virtualSticker_list_data = null

	setSendEmailData = (data) => {
		this.sendEmaildata = data;
	};

	setAddRateData = (data) => {
		this.add_rate_data = data;
	};

	setisTerminated = (is_terminated) => {
		this.isTerminated = is_terminated;
	};

	setTypeMode = (mode) => {
		this.typeMode = mode;
	};

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};
	setBookingPolicyFilterList = (data) => {
		this.bookingPolicyFilterList = data;
	};
	//set form values to terminate
	setTerminateValues = (data) => {
		this.terminateValues = data;
	};
	setIsTerminatesFilterList = (data) => {
		this.isTerminatesFilterList = data;
	};
	setIsKycFilterList = (data) => {
		this.IsKycFilterList = data;
	};
	setBillingLevelFilterList = (data) => {
		this.BillingLevelFilterList = data;
	};
	setLegalBusinessFilterList = (data) => {
		this.LegalBusinessFilterList = data;
	};
	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	};

	setCompletePendingFilterList = (data) => {
		this.completePendingFilterList = data;
	};
	setContractLevelFilterList = (data) => {
		this.contactLevelFilterList = data;
	};
	setPartyTypeFilterList = (data) => {
		this.partyTypeFilterList = data;
	};
	setIsVerifyFilterList = (data) => {
		this.isVerifyFilterList = data;
	};

	setIsLockFilterList = (data) => {
		this.isLockFilterList = data;
	};

	setRateCardFilterList = (data) => {
		this.isRateFilterList = data;
	};

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	};

	// set form Step2 values to view
	setLegalOperationsValues = (data) => {
		this.loValues = data;
	};

	// set form Step4 values to view
	setBankInfoValues = (data) => {
		this.bankInfoValues = data;
	};

	// set form Step5 values to view
	setContactInfoValues = (data) => {
		this.contactInfoValues = data;
	};

	// set form values to delete
	setDeleteContactInfoValues = (data) => {
		this.deleteContactInfoValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};	
	setFromBarcode = (data) => {
		this.fromBarcode = data;
	};	
	// set form values to  customefilter
	setAdvanceFilterValues = (data = null) => {
		this.advance_filter = data;
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	};
	refreshgetAllocatedStickersList = () => {
		this.getAllocatedStickersList();
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`organization/account-parties/list`, payload).then(
			({ data }) => {
				if (data.list.data.length) {
					let startPage = (data.list.current_page - 1) * data.list.per_page;
					data.list.data.map((item, index) => {
						item.srno = startPage + index + 1;
						//     item.cities = item.cities && item.cities.name
						return null;
					});
				}
				this.list_data = data.list ? data.list.data : null;
				this.total = data.list.total;
				this.current_page = data.list.current_page;
				return data;
			}
		);
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter["is_active"]) {
			final_filter["is_active"].values = convertTextToID(
				final_filter["is_active"],
				this.isActiveFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["allow_ap"]) {
			final_filter["allow_ap"].values = convertTextToID(
				final_filter["allow_ap"],
				this.isActiveFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["kyc"]) {
			final_filter["kyc"].values = convertTextToID(
				final_filter["kyc"],
				this.IsKycFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["is_terminated"]) {
			final_filter["is_terminated"].values = convertTextToID(
				final_filter["is_terminated"],
				this.isTerminatesFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["is_locked"]) {
			final_filter["is_locked"].values = convertTextToID(
				final_filter["is_locked"],
				this.isLockFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["is_completed"]) {
			final_filter["is_completed"].values = convertTextToID(
				final_filter["is_completed"],
				this.isVerifyFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["kyc_flag"]) {
			final_filter["kyc_flag"].values = convertTextToID(
				final_filter["kyc_flag"],
				this.completePendingFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["lo_flag"]) {
			final_filter["lo_flag"].values = convertTextToID(
				final_filter["lo_flag"],
				this.completePendingFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["ct_id"]) {
			final_filter["ct_id"].values = convertTextToID(
				final_filter["ct_id"],
				this.completePendingFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["rc_id"]) {
			final_filter["rc_id"].values = convertTextToID(
				final_filter["rc_id"],
				this.isRateFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["contract_flag"]) {
			final_filter["contract_flag"].values = convertTextToID(
				final_filter["contract_flag"],
				this.completePendingFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["ab_flag"]) {
			final_filter["ab_flag"].values = convertTextToID(
				final_filter["ab_flag"],
				this.bookingPolicyFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["contract_level_id"]) {
			final_filter["contract_level_id"].values = convertTextToID(
				final_filter["contract_level_id"],
				this.contactLevelFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["billing_level_id"]) {
			final_filter["billing_level_id"].values = convertTextToID(
				final_filter["billing_level_id"],
				this.BillingLevelFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["lb_flag"]) {
			final_filter["lb_flag"].values = convertTextToID(
				final_filter["lb_flag"],
				this.LegalBusinessFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["party_type_id"]) {
			final_filter["party_type_id"].values = convertTextToID(
				final_filter["party_type_id"],
				this.partyTypeFilterList,
				"name",
				"id"
			);
		}
		if (final_filter["is_estimation"]) {
			final_filter["is_estimation"].values = convertTextToID(
				final_filter["is_estimation"],
				this.isVerifyFilterList,
				"name",
				"id"
			);
		}
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	//setAdvanceFilter
	setAdvanceFilter = () => {
		let setAdvanceFilter = {};
		if (this.advance_filter) {
			if ("state" in this.advance_filter) {
				setAdvanceFilter.parent_id = this.advance_filter.state.id;
			}
		}
		return setAdvanceFilter;
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var advance_filter = this.setAdvanceFilter();
				var payload = {
					advance_filter: advance_filter,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					is_terminated: this.isTerminated,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`organization/account-parties/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	AddDeptData = (formdata) => {
		return Axios.post(`organization/account-parties/new-department`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(
			`organization/account-parties/edit/` + formdata.id,
			formdata
		)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`organization/account-parties/details/${ID}`).then(
			({ data }) => {
				let setData = data.view;
				if (this.editValues) {
					let oldval = this.editValues;
					setData = { ...oldval, ...data.view };
				}
				this.setEditValues(setData);
				return data;
			}
		);
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`organization/account-parties/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		return Axios.post(
			"organization/account-parties/archive/" + formdata.id,
			formdata
		)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TerminateData = (formdata) => {
		const api_link = formdata.is_terminated === 1 ? "revoke/" : "terminate/";
		return Axios.patch(
			"organization/account-parties/" + api_link + formdata.id,
			formdata
		)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	getLegalOperationData = () => {
		let ID = this.editValues.id;
		return Axios.get(
			`organization/account-parties/legal-business-detail/${ID}`
		).then(({ data }) => {
			this.setLegalOperationsValues(data.legal_business);
			return data;
		});
	};

	setLegalOperationData = (formdata) => {
		return Axios.post(
			`organization/account-parties/legal-business-edit/` + formdata.id,
			formdata
		)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get Default KYC Form
	getKycForm = (conditional = {}) => {
		conditional.id = this.editValues.id;
		return Axios.post(`organization/account-parties/kyc-details`, conditional)
			.then(({ data }) => {
				this.kycFormDetails = data.doc_types;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call delete api
	deleteKYCData = (formdata) => {
		return Axios.delete(
			`organization/account-parties/kyc-destroy/${formdata.doc_id}/${formdata.cr_id}`
		)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//finish Kyc
	finishKycData = (cr_id) => {
		return Axios.post(`organization/account-parties/kyc-finish`, { id: cr_id })
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//
	uploadKYCData = (formdata) => {
		return Axios.post(`organization/account-parties/kyc-upload`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getRateCardData = (formdata) => {
		return Axios.post(`business/rate-card-templates/get-ratecard`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get List of level for dropdown
	getLevelList = (conditional = {}) => {
		return Axios.post(`admin/levels/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_level_list = data.levels;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	getAccountPartiesList = (conditional = {}) => {
		return Axios.post(`organization/account-parties/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_account_party_list = data.account_party;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	JsonToFormData = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach((key) => {
			if (
				![
					"id",
					"contract_end_date",
					"contract_start_date",
					"is_gst_registered",
					"allow_excel",
				].includes(key)
			) {
				formData.append(
					key,
					data[key] !== undefined && data[key] !== null ? data[key] : ""
				);
			}
		});
		return formData;
	};

	/**
	 * Below all things for local grid in booking screen
	 */
	// Setup grid and set column size to autosize
	setupLocalBookingGrid = (params) => {
		this.localAgGrid = params;
		this.localAgGrid.columnApi.applyColumnState({
			state: [{ colId: "bk_date", sort: "desc" }],
			defaultState: { sort: null },
		});
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setLocalBookingPageSize = (local_page = this.local_per_page) => {
		this.local_per_page = local_page;
		if (this.localAgGrid) {
			this.localAgGrid.api.paginationSetPageSize(parseInt(local_page));
		}
	};

	// Filter function for no record found message
	onLocalFilterChanged = (params) => {
		this.localAgGrid = params;
		if (
			this.localAgGrid &&
			this.localAgGrid.api.getModel().getRowCount() === 0
		) {
			this.localAgGrid.api.showNoRowsOverlay();
		}
		if (this.localAgGrid && this.localAgGrid.api.getModel().getRowCount() > 0) {
			this.localAgGrid.api.hideOverlay();
		}
	};

	/**
	 * Below all things for local grid in booking screen
	 */
	// Setup grid and set column size to autosize
	setupVirtualStickerGrid = (params) => {
		this.virtualStickerAgGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setVirtualStickerPageSize = (virtual_page = this.virtual_per_page) => {
		this.virtual_per_page = virtual_page;
		if (this.virtualStickerAgGrid) {
			this.virtualStickerAgGrid.api.paginationSetPageSize(parseInt(virtual_page));
		}
	};

	// Filter function for no record found message
	onVirtualStickerFilterChanged = (params) => {
		this.virtualStickerAgGrid = params;
		if (this.virtualStickerAgGrid && this.virtualStickerAgGrid.api.getModel().getRowCount() === 0) {
		this.virtualStickerAgGrid.api.showNoRowsOverlay();
		}
		if (this.virtualStickerAgGrid && this.virtualStickerAgGrid.api.getModel().getRowCount() > 0) {
		this.virtualStickerAgGrid.api.hideOverlay();
		}
	};

	getAllocatedStickersList = (formdata) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.stock_list_data = null;
		return Axios.post(`stickers/allocated/list-by-party`, formdata)
			.then(({ data }) => {
				if (data.list.data.length) {
					data.list.data.map((item, index) => {
						item.srno = index + 1;
						return null;
					});
				}

				this.stock_list_data = data.list ? data.list.data : null;
				this.stock_total = data.list.stock_total;
				this.stock_current_page = data.list.stock_current_page;
				var stockAllColumnIds = [];
				if (this.stock_AgGrid && this.stock_AgGrid.columnApi) {
					this.stock_AgGrid.columnApi
						.getAllColumns()
						.forEach(function (column) {
							stockAllColumnIds.push(column.colId);
						});
				}
				if (this.stock_AgGrid) {
					this.stock_AgGrid.api.setFilterModel(filter);
					this.stock_AgGrid.api.setSortModel(sort);
				}
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	getVirtualStickersAllocatedList = (formdata) => {
		if (this.virtualStickerAgGrid) {
			var filter = this.virtualStickerAgGrid.api.getFilterModel();
			var sort = this.virtualStickerAgGrid.api.getSortModel();
		}
		this.virtualSticker_list_data = null;
		return Axios.post(`organization/account-parties/assign-virtual-stickers/list`, formdata)
			.then(({ data }) => {
				this.setFromBarcode(data.from_barcode)
				if (data.list.data.length) {
					data.list.data.map((item, index) => {
						item.srno = index + 1;
						return null;
					});
				}
				this.virtualSticker_list_data = data.list ? data.list.data : null;
				this.virtual_total = data.list.total;
				this.virtual_current_page = data.list.current_page;
				var virtualStickerAllColumnIds = [];
				if (this.virtualStickerAgGrid && this.virtualStickerAgGrid.columnApi) {
					this.virtualStickerAgGrid.columnApi
						.getAllColumns()
						.forEach(function (column) {
							virtualStickerAllColumnIds.push(column.colId);
						});
				}
				if (this.virtualStickerAgGrid) {
					this.virtualStickerAgGrid.api.setFilterModel(filter);
					this.virtualStickerAgGrid.api.setSortModel(sort);
				}				
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	
	AddVirtualStickerData = (formdata) => {
		return Axios.post(`organization/account-parties/assign-virtual-stickers/save`, formdata)
			.then(({ data }) => {
				//this.getVirtualStickersAllocatedList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	SendEmailData = (data) => {
		return Axios.post(`/organization/account-parties/send-verify-email/${data.id}`,data)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	GetContract = (id) => {
		return Axios.get(`/organization/account-parties/get-contract/${id}`)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

}

decorate(ManageAccountPartyStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	statusValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_account_party_quotes_list: observable,
	loValues: observable,
	setLegalOperationsValues: action,
	kycFormDetails: observable,
	getKycForm: action,
	finishKycData: action,
	uploadKYCData: action,
	setDefaultValue: action,
	defaultValue: observable,
	typeMode: observable,
	setTypeMode: action,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setCompletePendingFilterList: action,
	setIsKycFilterList: action,
	completePendingFilterList: observable,
	setisTerminated: action,
	dropdown_level_list: observable,
	setTerminateValues: action,
	terminateValues: observable,
	setIsTerminatesFilterList: action,
	setIsLockFilterList: action,
	setBookingPolicyFilterList: action,
	setContractLevelFilterList: action,
	contactLevelFilterList: observable,
	setBillingLevelFilterList: action,
	setLegalBusinessFilterList: action,
	setIsVerifyFilterList: action,
	advance_filter: observable,
	setAdvanceFilterValues: action,
	isRateFilterList: observable,
	setRateCardFilterList: action,
	isLockFilterList: observable,
	getAccountPartiesList: action,
	dropdown_account_party_list: observable,
	setPartyTypeFilterList: action,
	partyTypeFilterList: observable,
	stock_list_data: observable,
	add_rate_data: observable,
	setAddRateData: action,
	sendEmaildata: observable,
	setSendEmailData: action,
	setFromBarcode:action,
	fromBarcode:observable,
	setupVirtualStickerGrid:action,
	virtualStickerAgGrid:observable,
	virtualStickerAllColumnIds:observable,
	virtual_per_page:observable,
	virtual_total:observable,
	virtualSticker_list_data:observable,
	onVirtualStickerFilterChanged:action,
	
});
