import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManageComplaintStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	serviceFilterList=null;
	bookingCategories=null;
	dropdown_complaint_status_list = null
	actionFilterList = [];

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	setServiceFilterList = (data) => {
		this.serviceFilterList = data;
	}
	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}
	setBookingCategories=(data)=>{
		this.bookingCategories =data
	}
	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	setConsignmentValues=(data)=>{
		this.consignmentValues=data
	}
	setActionFilterList = (data) => {
		this.actionFilterList = data.lov;
	}
	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`bookings/complaint-manage/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["status_id"]) {
			final_filter['status_id'].values = convertTextToID(final_filter['status_id'], this.actionFilterList, 'name', 'id')
		}
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					purpose : 1,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`/bookings/complaint-manage/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	// EditData = (formdata,ID) => {
	// 	return Axios.post(`/bookings/lost-shipments/edit`,formdata)
	// 		.then(({ data }) => {
	// 			if (this.agGrid) {
	// 				this.setupGrid(this.agGrid);
	// 			}
	// 			return data;
	// 		})
	// 		.catch(({ response: { data } }) => {
	// 			var errors = [];
	// 			var notify = null;
	// 			const { NOTIFICATION, ...fieldErrors } = data.STATUS;
	// 			if (data && data.STATUS) {
	// 				if (NOTIFICATION) {
	// 					notify = NOTIFICATION[0];
	// 				}
	// 				Object.keys(fieldErrors).forEach((name) => {
	// 					errors.push({ name, errors: data.STATUS[name] });
	// 				});
	// 			}
	// 			return Promise.reject({ errors, notify });
	// 		});
	// };

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`/bookings/complaint-manage/details/${ID}`).then(({ data }) => {
			this.setViewValues(data?.view);
			return data;
		});
	};

	ConsignmentData=(CO_NO)=>{
		return Axios.get(`/bookings/consignment/details/${CO_NO}`).then(({data})=>{
			this.setConsignmentValues(data.view)
			return data
		})
	}	

	// Call edit api
	ChangeStatus = (formdata) => {
		return Axios.post(`bookings/complaint-manage/comment`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getComplaintStatusList = () => {
		return Axios.get(`bookings/complaint-manage/lov`)
			.then(({ data }) => {
				this.dropdown_complaint_status_list = data.lov;

				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	};
}

decorate(ManageComplaintStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	setViewValues: action,
	viewValues: observable,
	setIsActiveFilterList: action,
	setServiceFilterList:action,
	consignmentValues:observable,
	setConsignmentValues:action,
	getComplaintStatusList:action,
	dropdown_complaint_status_list:observable,
	setActionFilterList: action,
	actionFilterList: observable,
});
