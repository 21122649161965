/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID, convertFranchiseeViewAddress, convertParentFranchiseeViewAddress, b64toBlob } from "../../../../utils/GlobalFunction";

export default class ManageFrainchaiseeStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	deleteValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_fr_list = null;
	dropdown_latest_fr_list = null;
	kycFormDetails = null;
	terminateValues = null;
	lockValues = null;
	editFranchiseeId = 0;
	current_tab = "Kyc_type";
	frFamilyDataViewValues = null;
	familyList = [];
	advance_filter = null;

	// set form values to  customefilter
	setAdvanceFilterValues = (data = null) => {
		this.advance_filter = data;
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	};

	//setAdvanceFilter
	setAdvanceFilter = () => {
		let setAdvanceFilter = {};
		if (this.advance_filter) {
			if ('centersid' in this.advance_filter) {
				setAdvanceFilter["mst_centers.id"] = this.advance_filter.centersid.id;
			}
		}
		return setAdvanceFilter;
	}

	setCurrentTab = (data) => {
		this.current_tab = data
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;

	};

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertFranchiseeViewAddress(data);
			data.p_address = convertParentFranchiseeViewAddress(data)
		}
		this.viewValues = data;
	};

	setLatestFRList = (data) => {
		this.dropdown_latest_fr_list = data
	}

	setfrFamilyDataViewValues = (data) => {
		this.frFamilyDataViewValues = data;
	}
	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setIsVerifyFilterList = (data) => {
		this.isVerifyFilterList = data;
	}

	//set form values to terminate
	setTerminateValues = (data) => {
		this.terminateValues = data
	}
	//set form values to lock
	setLockValues = (data) => {
		this.lockValues = data
	}

	setIsTerminatesFilterList = (data) => {
		this.isTerminatesFilterList = data;
	}
	setIsLockFilterList = (data) => {
		this.IsLockFilterList = data;
	}

	setIsKycFilterList = (data) => {
		this.IsKycFilterList = data;
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`organization/franchisee/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["address1"]) {
			final_filter["address1"] = final_filter["address1"];
		}
		if (final_filter["is_verified"]) {
			final_filter['is_verified'].values = convertTextToID(final_filter['is_verified'], this.isVerifyFilterList, 'name', 'id')
		}
		if (final_filter["is_completed"]) {
			final_filter['is_completed'].values = convertTextToID(final_filter['is_completed'], this.isVerifyFilterList, 'name', 'id')
		}
		if (final_filter["kyc"]) {
			final_filter['kyc'].values = convertTextToID(final_filter['kyc'], this.IsKycFilterList, 'name', 'id')
		}
		if (final_filter["is_terminated"]) {
			final_filter['is_terminated'].values = convertTextToID(final_filter['is_terminated'], this.isTerminatesFilterList, 'name', 'id')
		}
		if (final_filter["is_locked"]) {
			final_filter['is_locked'].values = convertTextToID(final_filter['is_locked'], this.IsLockFilterList, 'name', 'id')
		}
		if (final_filter["allow_ap"]) {
			final_filter['allow_ap'].values = convertTextToID(final_filter['allow_ap'], this.isActiveFilterList, 'name', 'id')
		}
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var advance_filter = this.setAdvanceFilter();
				var payload = {
					advance_filter: advance_filter,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`organization/franchisee/new`, formdata)
			.then(({ data }) => {
				this.editFranchiseeId = data.id;
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		this.editFranchiseeId = formdata.id;
		return Axios.post(`organization/franchisee/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		this.editFranchiseeId = ID;
		return Axios.get(`organization/franchisee/detail/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		});
	};
	frFamilyData = (ID) => {
		this.editFranchiseeId = ID;
		return Axios.get(`organization/franchisee/family-detail/${ID}`).then(({ data }) => {
			//this.setfrFamilyDataViewValues(data.view);
			return data;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`organization/franchisee/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	//Get Default KYC Form 
	getKycForm = (conditional = {}) => {
		if (conditional.id) {
			this.editFranchiseeId = conditional.id;
		}
		return Axios.post(`organization/franchisee/kyc-details/`, conditional).then(({ data }) => {
			this.kycFormDetails = data.doc_types;
			return data;
		}
		).catch((response) => {
			return Promise.reject(response);
		});
	};

	//finish Kyc
	finishKycData = (FranchiseeId) => {
		return Axios.post(`organization/franchisee/kyc-finish`, { id: FranchiseeId })
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	deleteKYCData = (formdata) => {
		return Axios.delete(`organization/franchisee/kyc-destroy/${formdata.doc_id}/${formdata.agency_id}`)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	allowAccountData = (formdata) => {
		const api_link = formdata.allow_ap === 0 ? "allow-account-party/" : "deny-account-party/";
		return Axios.patch("organization/franchisee/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//
	uploadKYCData = (formdata) => {
		return Axios.post(`organization/franchisee/kyc-upload`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	TerminateData = (formdata) => {
		const api_link = formdata.is_terminated === 1 ? "revoke/" : "terminate/";
		return Axios.patch("organization/franchisee/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	LockData = (formdata) => {
		const api_link = formdata.is_locked === 0 ? "lock/" : "unlock/";
		return Axios.patch("organization/franchisee/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	//Get List of frainchaisee for dropdown
	getFrainchaiseeList = (conditional = {}) => {
		return Axios.post(`organization/franchisee/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_fr_list = data.mst_fr;
				this.dropdown_latest_fr_list = data.mst_fr;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	PrintReceiptData = (ID) => {
		return Axios.get(`/organization/franchisee/channel-partner/${ID}`).then(({ data }) => {
			const file = b64toBlob(data.channel_partner, "application/pdf");
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			window.open(fileURL);
			//pdfWindow.location.href = fileURL
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}


}

decorate(ManageFrainchaiseeStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	statusValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_fr_list: observable,
	dropdown_latest_fr_list: observable,
	kycFormDetails: observable,
	setLockValues: action,
	lockValues: observable,
	setTerminateValues: action,
	terminateValues: observable,
	getKycForm: action,
	editFranchiseeId: observable,
	finishKycData: action,
	uploadKYCData: action,
	setCurrentTab: action,
	current_tab: observable,
	setIsTerminatesFilterList: action,
	setIsLockFilterList: action,
	setIsVerifyFilterList: action,
	setIsKycFilterList: action,
	setIsActiveFilterList: action,
	setfrFamilyDataViewValues: action,
	frFamilyDataViewValues: observable,
	setDefaultValue: action,
	familyList: observable,
	advance_filter: observable,
	setAdvanceFilterValues: action,
	PrintReceiptData: action,
	setLatestFRList : action,
});
