import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class ManageCashBookingSettingsStore {
	editValues = null;
	viewValues = null;
	typeValues = null
	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};
	setTypeValues = (data)=>{
		this.typeValues=data
	}
	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`/utilities/settings/edit/` + formdata.id,formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (formdata,typeId) => {
		return Axios.get(`/utilities/settings/details/${formdata}/${typeId}`).then((data) => {
			this.setViewValues(data.data.view)
			this.setTypeValues(typeId)
			return data;
		});
	};


}

decorate(ManageCashBookingSettingsStore, {
	editValues: observable,
	setEditValues: action,
	setViewValues: action,
	viewValues: observable,
	typeValues: observable,
	setTypeValues: action
});
