
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";
//import resData from "../../../../json/init.json";

export default class ManageDraftRateCardStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	viewValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_country_list = null;
	formField = null;
	zoneFormField = null;
	editZoneRateValues = null;
	validationErrorJson = null;
	viewZoneValues = null;
	editZoneValues = null;
	deleteZoneValues = null;
	dropdown_location_cash_list = null;
	errorMessage = null;
	draftValues = null;
	addCenterDetails = null;
	isArchived = 0;
	ManagecommonStoreObject = null;
	moduleId = null;
	agGridPincode = null;
	current_page_pincode = 1;
	list_data_pincode = null;
	total_pincode = 0;
	fuelChrg = 0;

	constructor(ManagecommonStoreObject) {
		this.ManagecommonStoreObject = ManagecommonStoreObject;
	}

	setFuelChrg = (chrg) => {
		this.fuelChrg = Number(chrg);
	}

	setIsArchived = (is_archived) => {
		this.isArchived = is_archived
	}

	setAddMoreValues = (data) => {
		this.formField = data;
	}
	setModuleId = (data) => {
		this.moduleId = data
	}
	setZoneAddMoreValues = (data) => {
		this.zoneFormField = data;
	}

	setZoneRateValues = (data) => {
		this.editZoneRateValues = data;
	}

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setErrorMessage = (message) => {
		this.errorMessage = message;
	}

	setDraftValues = (data) => {
		this.draftValues = data;
	}

	setAddCenterDetails = (data) => {
		this.addCenterDetails = data
	}

	setIsArchived = (flag) => {
		this.isArchived = flag
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		if (this.isArchived) {
			payload.is_archived = this.isArchived
		}
		return Axios.post(`/business/rate-card-draft/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};
	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.ManagecommonStoreObject.dropdown_rate_card_status_list, 'name', 'id')
		}
		if (final_filter["rcp_id"]) {
			final_filter['rcp_id'].values = convertTextToID(final_filter['rcp_id'], this.ManagecommonStoreObject.dropdown_rate_card_purpose_list, 'name', 'id')
		}

		return { final_filter, final_sort }
	};

	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					is_history: this.isArchived,
					type_id: this.moduleId,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		};
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// call api to get records
	getPreRequestData = () => {
		return Axios.get(`business/rate-card-draft/pre-create`).then(({ data }) => {
			this.setAddMoreValues(data.view);
			return data;
		});
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`/business/rate-card-draft/new`, formdata)
			.then(({ data }) => {
				// if (this.agGrid) {
				// 	this.setupGrid(this.agGrid);
				// }
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//draft api
	Draft = (formdata) => {
		return Axios.post(`/business/rate-card-draft/draft`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`/business/rate-card-draft/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				// if (this.agGrid) {
				// 	this.setupGrid(this.agGrid);
				// }
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`business/rate-card-cash/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
		return Axios.patch("business/rate-card-cash/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	ViewData = (reqData) => {
		this.setViewValues(reqData);
		return Axios.get(`business/rate-card-draft/details/${reqData.id}/${reqData.purpose}`).then(({ data }) => {
			let oldval = this.viewValues;
			if (reqData.purpose === 1) {
				let oldZoneVal = this.editZoneRateValues;
				let newZoneVal = { ...oldZoneVal, ...data.view };
				this.setZoneRateValues(newZoneVal);
			}
			let newval = { ...oldval, ...data.view };
			this.setViewValues(newval);
			this.setEditValues(newval);
			if (data.mst_error) {
				this.setErrorMessage(data.mst_error)
			}

			this.setAddMoreValues(data.pre_create);
			return data;
		});
	};

	//Get List of coutries for dropdown
	getRateCardTypeList = (conditional = {}) => {
		return Axios.post(`admin/rate-card-type/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_rate_card_type_list = data.rate_card_type;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	//get value and set zone value 
	// getZoneDetailValue = (data) => {
	// 	this.setZoneRateValues(data);
	// 	this.setViewValues(data);
	// 	return Axios.get(`business/rate-card-draft/details/${data.id}/${data.purpose}`).then(({ data }) => {
	// 		this.setZoneAddMoreValues(data.pre_create);
	// 		let oldval = this.editZoneRateValues;
	// 		let newval = { ...oldval, ...data.view };
	// 		this.setZoneRateValues(newval);
	// 		this.validationErrorJson = data.error_messages;
	// 		return data;
	// 	});
	// }

	// call api to get Details By Zone	
	getDetailsByZoneData = (data) => {
		this.setZoneRateValues(data);
		// let url = data?.rc_id ? `${data.rc_id}` : `${data.id}`;
		// if (data?.rc_ver || data.ver) {
		// 	url = data?.rc_ver ? `${url}/${data.rc_ver}` : `${url}/${data.ver}`
		// }
		return Axios.get(`business/rate-card-draft/details/${data.id}/${data.purpose}`).then(({ data }) => {
			this.setZoneAddMoreValues(data.pre_create);
			let oldval = this.editZoneRateValues;
			let newval = { ...oldval, ...data.view };
			this.setViewValues(newval);
			this.setZoneRateValues(newval);
			this.validationErrorJson = data.error_messages;
			return data;
		});
	};

	// Call add api
	ValidateZoneData = (formdata) => {
		return Axios.post(`business/rate-card-draft/zone-validate`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	AddZoneData = (formdata) => {
		return Axios.post(`business/rate-card-draft/add-zone`, formdata)
			.then(({ data }) => {
				//this.getList();
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				this.getDetailsByZoneData(this.editZoneRateValues);
				let currentZoneField = this.zoneFormField;

				if (currentZoneField && currentZoneField.zones) {
					currentZoneField.zones.push({
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt,
						is_completed: data.zone.is_completed
					});
					this.setZoneAddMoreValues(currentZoneField);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditZoneData = (formdata) => {
		return Axios.post(`business/rate-card-draft/edit-zone/${formdata.id}`, formdata)
			.then(({ data }) => {
				// this.getList();
				this.getDetailsByZoneData(this.editZoneRateValues);
				let currentZoneField = this.zoneFormField;

				if (currentZoneField && currentZoneField.zones) {
					var index = currentZoneField.zones.findIndex(zone => zone.id === formdata.id);
					currentZoneField.zones.splice(index, 1, {
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt
					});
					this.setZoneAddMoreValues(currentZoneField);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// set form values to view
	setZoneViewValues = (data) => {
		if (data && data.pincode) {
			this.setListData(data.pincode);
		}
		this.viewZoneValues = data;
	};

	// set form values to view
	setZoneEditValues = (data) => {
		this.editZoneValues = data;
	};

	setZoneDeleteValues = (data) => {
		this.deleteZoneValues = data;
	};

	// call api to get records
	ViewZoneData = (id, rc_id) => {
		return Axios.get(`/business/rate-card-draft/get-zone/${rc_id}/${id}`).then(({ data }) => {
			this.setZoneViewValues(data.view);
			return data;
		});
	};

	// Call delete api
	DeleteZoneData = (formdata) => {
		return Axios.delete(`business/rate-card-draft/delete-zone/${formdata.rcd_id}/${formdata.id}`)
			.then(({ data }) => {
				//this.getList();
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				this.getDetailsByZoneData(this.editZoneRateValues);
				let currentZoneField = this.zoneFormField;

				if (currentZoneField && currentZoneField.zones) {
					currentZoneField.zones = currentZoneField.zones.filter(zone => zone.id !== formdata.id);
					this.setZoneAddMoreValues(currentZoneField);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setCompletedFilterList = (data) => {
		this.isCompletedFilterList = data;
	}

	getLocationCashRateCard = (conditional = {}) => {
		return Axios.post(`/business/rate-card-cash/centers`, conditional)
			.then(({ data }) => {
				this.dropdown_location_cash_list = data.cash_rate_card_center
				return data
			}).catch((response) => {
				return Promise.reject(response);
			})
	}

	sendApprovalData = (formdata) => {
		return Axios.post(`business/rate-card-draft/send-for-approval/${formdata.id}`, formdata)
			.then(({ data }) => {
				this.refreshList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	statusChangeData = (formdata) => {
		return Axios.post(`business/rate-card-draft/${formdata.openFrom}/${formdata.id}`, formdata)
			.then(({ data }) => {
				this.refreshList();
				return data;
			})
			.catch(({ response: { data } }) => {
				let formData = {}
				formData.id = formdata.id;
				formData.purpose = 2;
				this.ViewData(formData);
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}


	/**
	 * Zone pincode
	 */
	// Setup grid and set column size to autosize
	setupPincodeGrid = (params) => {
		this.agGridPincode = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGridPincode) {
			this.agGridPincode.api.paginationSetPageSize(parseInt(page));
		}
	};

	setListData = (data) => {
		if (this.agGridPincode) {
			var filter = this.agGridPincode.api.getFilterModel();
			var sort = this.agGridPincode.api.getSortModel();
		}
		this.list_data_pincode = data ? data : null;
		this.total_pincode = data.length;
		//this.current_page = data.list.current_page;
		// var allColumnIds = [];
		// debugger;
		// if (this.agGridPincode && this.agGridPincode.columnApi) {
		// 	this.agGridPincode.columnApi.getAllColumns().forEach(function (column) {
		// 		allColumnIds.push(column.colId);
		// 	});
		// }
		if (this.agGridPincode) {
			this.agGridPincode.api.setFilterModel(filter);
			this.agGridPincode.api.setSortModel(sort);
		}
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGridPincode = params;
		if (this.agGridPincode && this.agGridPincode.api.getModel().getRowCount() === 0) {
			this.agGridPincode.api.showNoRowsOverlay();
		}
		if (this.agGridPincode && this.agGridPincode.api.getModel().getRowCount() > 0) {
			this.agGridPincode.api.hideOverlay();
		}
	};
}

decorate(ManageDraftRateCardStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	statusValues: observable,
	dropdown_rate_card_type_list: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	getList: action,
	getStatesList: action,
	onFilterChanged: action,
	setViewValues: action,
	viewValues: observable,
	formField: observable,
	zoneFormField: observable,
	editZoneRateValues: observable,
	AddZoneData: action,
	EditZoneData: action,
	DeleteZoneData: action,
	validationErrorJson: observable,
	setZoneEditValues: action,
	viewZoneValues: observable,
	editZoneValues: observable,
	deleteZoneValues: observable,
	setIsActiveFilterList: action,
	setCompletedFilterList: action,
	dropdown_location_cash_list: observable,
	errorMessage: observable,
	draftValues: observable,
	addCenterDetails: observable,
	isArchived: observable,
	setIsArchived: action,
	setModuleId: action,
	moduleId: observable,
	setFuelChrg: action,
	fuelChrg: observable
});
