import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Button, Form, Row, Col, Drawer, Alert, Divider } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../../../store";
import { vsmNotify } from "../../../../../config/messages/common";
import MapLocationComponent from "./../../../../../component/MapLocation";
import InputComponent from "../../../../../component/InputComponent";
import debounce from "lodash/debounce";
import { vsmLocation } from "../Validation";
import { Constant } from "../../../../../config/Constant";
import DefaulHeader from "./DefaulHeader";
import { Patterns } from "../../../../../config/messages/patterns";
import greyGoogleMap from "../../../../../images/greyGoogleMap.png"

const LocationComponent = observer((props) => {
	const [form] = Form.useForm();
	const [disabled, setDisabled] = useState(true);
	const [saving, setSaving] = useState();
	const [submitValue, setSubmit] = useState();
	const [centerLatLng, setCenterLatLng] = useState({ lat: 20.5937, lng: 78.9629 });
	const [placeInfo, setPlaceInfo] = useState({});
	const [latLngBtn, setLatLngBtn] = useState(true);

	const {
		ManageCentersStore: { editValues, MapLocationData, typeMode},
		AUTH
	} = useStore();

	// set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			form.setFieldsValue({				
				latitude: editValues.latitude,
				longitude: editValues.longitude,
			})
			if(editValues.latitude && editValues.longitude){
				setPlaceInfo({geometry:{ location: {lat: Number(editValues.latitude), lng: Number(editValues.longitude)}}});
				setCenterLatLng({				
					latitude: editValues.latitude,
					longitude: editValues.longitude,
				})
			}
		}
	}, [editValues, form, props]);

	const setLatLongFromAutoSearch = (lat,lng) => {
		form.setFieldsValue({				
			latitude: lat.toFixed(6),
			longitude: lng.toFixed(6),
		})
		setCenterLatLng({				
			latitude: lat.toFixed(6),
			longitude: lng.toFixed(6),
		})
		setDisabled(false);
		setTimeout(() => {
			handleChange()
		}, 500);
	}

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);
	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true);
		data.id = editValues.id;
		MapLocationData(data)
			.then((data) => {
				close(submitValue);
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// reset form and close add form
	const close = (submitValue) => {
		setCenterLatLng({ lat: 20.5937, lng: 78.9629 });
		setPlaceInfo({});
		setDisabled(true);
		setSubmit();
		form.resetFields();
		if(submitValue === "submit"){
			props.close();
		}else if(submitValue === "submitNext"){
			props.closeNext();
		}
	};


	return (
		<>
			<Drawer
				className="editModal"
				title="Location"
				width="70%"
				visible={props.visible}
				closeIcon={<FontAwesomeIcon icon={faTimes} />}
				onClose={() => {close("submit")}}
				footer={[
					(typeMode !== Constant.VIEW && <Button
						key="1"
						disabled={disabled}
						form="editMapLocationForm"
						loading={saving}
						htmlType="submit"
						type="primary"
						className="mr-35"
						onClick={() => {
							setSubmit("submit");
						}}
					>
						Save
					</Button>),
					(typeMode === Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
						key="2"
						type="primary"
						onClick={() => {
							close("submit");
						}}
					>
						Close
					</Button>)
				]}
			>
				{props.showErrorNotes && props.visible && editValues && <Alert className="mb-25"
					type="error"
					showIcon
					message={
						editValues.is_terminated ? `This location is terminated so you cannot make any changes`: (!editValues.is_active) ? `This location is inactive so you cannot make any changes` : null
					}
				/>}
				<Form
					form={form}
					id={"editMapLocationForm"}
					onFinish={handleSubmit}
					labelCol={{ span: 24 }}
				>
					<DefaulHeader/>
					<Divider className="mt-0" />
					<Row gutter={30}>
						<Col xs={{ span: 10 }}>
							<InputComponent
								type="text"
								readOnly={typeMode === Constant.VIEW}
								label="Latitude"
								placeholder="Latitude"
								name="latitude"
								onChange={(e) => {
									if((e.target.value !== "" && Patterns.LATLNG.test(e.target.value)) && (form.getFieldValue('longitude') !== "" && Patterns.LATLNG.test(form.getFieldValue('longitude')))){
										form.setFieldsValue({latitude: e.target.value})
										setLatLngBtn(false)
									}
									handleChange()
								}}
								rules={(typeMode === Constant.VIEW)?[]:vsmLocation.validationLocation.latitude}
							/>
						</Col>
						<Col xs={{ span: 10 }}>
							<InputComponent
								type="text"
								readOnly={typeMode === Constant.VIEW}
								label="Longitude"
								placeholder="Longitude"
								name="longitude"
								onChange={(e) => {
									if((e.target.value !== "" && Patterns.LATLNG.test(e.target.value)) && (form.getFieldValue('latitude') !== "" && Patterns.LATLNG.test(form.getFieldValue('latitude')))){
										form.setFieldsValue({longitude: e.target.value})
										setLatLngBtn(false)
									}
									handleChange()
								}}
								rules={(typeMode === Constant.VIEW)?[]:vsmLocation.validationLocation.longitude}
							/>
						</Col>
						{(AUTH.company.branding && typeof AUTH.company.branding.map_api_flag !== 'undefined' && AUTH.company.branding.map_api_flag) ? 
							<Col xs={{ span: 4 }}>
								<label></label>
								<Button 
									type="primary"
									disabled={latLngBtn}
									className="mt-20"
									onClick={() => {
										setPlaceInfo({geometry:{ location: {lat: Number(form.getFieldValue('latitude')), lng: Number(form.getFieldValue('longitude'))}}});
										setCenterLatLng({ lat: form.getFieldValue('latitude'), lng: form.getFieldValue('longitude') });
										setTimeout(() => {
											handleChange()
										}, 500);
									}}
								>Set</Button>
							</Col> 
						: null}
						
					</Row>
					<Row>
						<Col xs={{span:24}} className="Info__info_text_add">
							<p className="info_text_add mb-0"><b>Note:</b></p>
							<p className="info_text_add">To set location, minimum six and maximum twelve decimal is required after pointing value ex. 23.321256</p>
						</Col>
					</Row>
					{(AUTH.company.branding && typeof AUTH.company.branding.map_api_flag !== 'undefined' && AUTH.company.branding.map_api_flag) ?
						<MapLocationComponent
							google={props.google}
							center={centerLatLng}
							height='300px'
							zoom={15}
							setLatLongFromAutoSearch={setLatLongFromAutoSearch}
							setPlaceInfo={setPlaceInfo}
							placeInfo={placeInfo}
						/> : <img src={greyGoogleMap} alt="Google MAP"/>
					}
				</Form>
			</Drawer>
		</>
	);
});
export default LocationComponent;
