import { notification } from "antd";
import { Messages } from "../../config/messages/messages";
import { replaceString } from "../../utils/GlobalFunction";
import { Patterns } from "../../config/messages/patterns";

const vsmAuth = {
	success: Messages.LOGIN,
	validation: {
		email: [
			{ required: true, message: Messages.USER_REQUIRED },
			{
				pattern: Patterns.LOGINUSERNAME,
				message: Messages.USERNAME_INVALID_CHARACTER,
			},
			{
				min: 3,
				message: replaceString(Messages.USER_MIN_LENGTH, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			}
			// {
			// 	pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
			// 	message: "Invalid email address, please enter the valid email address",
			// },
		],
		password: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 3,
				message: Messages.PASSWORD_3_LENGTH,
			},
			{
				max: 50,
				message: Messages.PASSWORD_3_LENGTH,
			},
			{
				pattern: Patterns.PASSWORD,
				message: Messages.PASSWORD_INVALID_CHARACTERS,
			},
		],
		confirmpassword: [
			{ required: true, message: Messages.REQUIRED },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if (!value || getFieldValue("new_password") === value) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.PASSWORD_NOT_MATCH);
				},
			}),
		],

		recaptcha: [
			{ required: true, message: "It cannot be blank." },
			({ getFieldValue }) => ({
				validator() {
					const captcha = getFieldValue("captcha")
					const recaptcha = parseInt(getFieldValue("re_captcha"))
					if (recaptcha && captcha) {
						if (captcha === recaptcha) {
							return Promise.resolve();
						} else {
							return Promise.reject("CAPTCHA does not match.");
						}
					}
					return Promise.reject("CAPTCHA does not match.");
				},
			}),
		],
	},
};

const vsmAuthForgot = {
	success: Messages.EMAIL,
	validation: {
		email: [
			{ required: true, message: Messages.USER_REQUIRED },
			{
				pattern: Patterns.LOGINUSERNAME,
				message: Messages.USERNAME_INVALID_CHARACTER,
			},
			{
				min: 3,
				message: replaceString(Messages.USER_MIN_LENGTH, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			}
		],
	},
};

const vsmCommon = {
	noRecord: Messages.NO_RECORD,
};

const vsmRole = {
	success: Messages.CREATED,
	edit: Messages.UPDATED,
	error: Messages.DUPLICATED,
	validation: {
		name: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
	},
};

const vsmNotify = {
	success: (data) => {
		notification.success({ placement: "bottomRight", duration: 3, ...data });
	},
	error: (data) => {
		notification.error({ placement: "bottomRight", duration: 3, ...data });
	},
};

const vsmCompanyProfileCS = {
	success: Messages.CREATED,
	edit: Messages.UPDATED,
	validation: {
		name: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		email: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.EMAIL,
				message: Messages.EMAIL_INVALID_CHARACTERS,
			},
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 100,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 100),
			},
		],
		local_number: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20),
			},
			{
				pattern: Patterns.PHONE,
				message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "phone")
			},
		],
		toll_free_number: [
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 20,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 20),
			},
			{
				pattern: Patterns.PHONE,
				message: replaceString(Messages.INVALID_ATTR, "#ATTR#", "toll free")
			},
		],
	},
};

const vsmCompanyPreference = {
	success: Messages.CREATED,
	error: Messages.DUPLICATED,
	validation: {
		bk_min_val_ins: [
			{
				pattern: Patterns.NUMBER,
				message: Messages.BOOOKING_ERROR_MIN_VAL_INS,
			},
			{
				required: true, message: Messages.REQUIRED
			},
			({ getFieldValue }) => ({
				validator() {
					const bk_min_val_ins = parseInt(getFieldValue("bk_min_val_ins"));
					if (bk_min_val_ins < 1000 || bk_min_val_ins > 999999) {
						return Promise.reject(
							Messages.BOOOKING_MIN_VAL_INS
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		bk_eway_req_vaL: [
			{
				pattern: Patterns.NUMBER,
				message: Messages.BOOOKING_ERROR_MIN_VAL_INS,
			},
			{
				required: true, message: Messages.REQUIRED
			},
			({ getFieldValue }) => ({
				validator() {
					const bk_eway_req_vaL = parseInt(getFieldValue("bk_eway_req_vaL"));
					if (bk_eway_req_vaL < 1000 || bk_eway_req_vaL > 999999) {
						return Promise.reject(
							Messages.BOOOKING_MIN_VAL_INS
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		bk_remarks: [
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3)
			},
			{
				max: 100,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 100),
			},
		],
		session_timeout: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.ACCOUNT,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "15-1440"),
			},
			({ getFieldValue }) => ({
				validator() {
					const session_timeout = parseInt(getFieldValue("session_timeout"));
					if (session_timeout < 15 || session_timeout > 1440) {
						return Promise.reject(
							Messages.SESSION_TIMEOUT_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		grace_period: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.ACCOUNT,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "1-30"),
			},
			({ getFieldValue }) => ({
				validator() {
					const grace_period = parseInt(getFieldValue("grace_period"));
					if (grace_period === 0 || grace_period > 30) {
						return Promise.reject(Messages.GEACE_PERIOD_RANGE);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		brand_name: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 3,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 3),
			},
			{
				max: 50,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 50),
			},
		],
		web: [
			{ required: true, message: Messages.REQUIRED },
			{
				min: 8,
				message: replaceString(Messages.MIN_LENGTH_ERROR, "#MIN#", 8),
			},
			{
				max: 100,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 100),
			},
		],
		frontend_url: [
			{ required: true, message: Messages.REQUIRED },
			{
				max: 100,
				message: replaceString(Messages.MAX_LENGTH_ERROR, "#MAX#", 100),
			},
		],
		customized_zone: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.ACCOUNT,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "1 to 10"),
			},
			({ getFieldValue }) => ({
				validator() {
					const customized_zone = parseInt(getFieldValue("max_cust_zones"));
					if (customized_zone < 1) {
						return Promise.reject(
							Messages.CUSTOMIZED_ZONE_RANGE
						);
					} else if (customized_zone > 10) {
						return Promise.reject(
							Messages.CUSTOMIZED_ZONE_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		cash_oda1_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const cash_oda1_chrg = parseInt(getFieldValue("cash_oda1_chrg"));
					if (cash_oda1_chrg < 0 || cash_oda1_chrg > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		branding_cess: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const branding_cess = parseInt(getFieldValue("branding_cess"));
					if (branding_cess < 0 || branding_cess > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		corp_bill_selection: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.NUMBER,
				message: Messages.INVALID_NUMERIC,
			},
			({ getFieldValue }) => ({
				validator() {
					const corp_bill_selection = parseInt(getFieldValue("corp_bill_selection"));
					if (corp_bill_selection < 1) {
						return Promise.reject(
							Messages.MIN_LENGTH_NUMBER_ERROR
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		ho_bill_selection: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.NUMBER,
				message: Messages.INVALID_NUMERIC,
			},
			({ getFieldValue }) => ({
				validator() {
					const ho_bill_selection = parseInt(getFieldValue("ho_bill_selection"));
					if (ho_bill_selection < 1) {
						return Promise.reject(
							Messages.MIN_LENGTH_NUMBER_ERROR
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		max_business_add_amt: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.NUMBER,
				message: Messages.INVALID_NUMERIC,
			},
			({ getFieldValue }) => ({
				validator() {
					const max_business_add_amt = parseInt(getFieldValue("max_business_add_amt"));
					if (max_business_add_amt < 1) {
						return Promise.reject(
							Messages.MIN_LENGTH_NUMBER_ERROR
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		max_business_amt: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.NUMBER,
				message: Messages.INVALID_NUMERIC,
			},
			({ getFieldValue }) => ({
				validator() {
					const max_business_amt = parseInt(getFieldValue("max_business_amt"));
					if (max_business_amt < 1) {
						return Promise.reject(
							Messages.MIN_LENGTH_NUMBER_ERROR
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		cash_oda2_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const cash_oda2_chrg = parseInt(getFieldValue("cash_oda2_chrg"));
					if (cash_oda2_chrg < 0 || cash_oda2_chrg > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		pod_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const pod_chrg = parseInt(getFieldValue("pod_chrg"));
					if (pod_chrg < 0 || pod_chrg > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		igst_rate: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const igst_rate = parseInt(getFieldValue("igst_rate"));
					if (igst_rate < 0 || igst_rate > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		fov_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const fov_chrg = parseInt(getFieldValue("fov_chrg"));
					if (fov_chrg < 0 || fov_chrg > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		rec_sms_chrg: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 2"),
			},
			({ getFieldValue }) => ({
				validator() {
					const rec_sms_chrg = parseInt(getFieldValue("rec_sms_chrg"));
					if (rec_sms_chrg < 0 || rec_sms_chrg > 2) {
						return Promise.reject(
							Messages.SMS_CHARGES
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		fuel: [
			{ required: true, message: Messages.REQUIRED },
			{ pattern: Patterns.FLOATVALUE, message: Messages.INSURANCE_RATE_INVALID_CHARACTERS },
			({ getFieldValue }) => ({
				validator(rule, value) {
					if ((value && (value >= 0.00) && (value <= 100.00))) {
						return Promise.resolve();
					}
					return Promise.reject(Messages.INSURANCE_RATE_RANGE);
				}
			})
		],
		sgst_rate: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const sgst_rate = parseInt(getFieldValue("sgst_rate"));
					if (sgst_rate < 0 || sgst_rate > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		cgst_rate: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const cgst_rate = parseInt(getFieldValue("cgst_rate"));
					if (cgst_rate < 0 || cgst_rate > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		ins_rate: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const ins_rate = parseInt(getFieldValue("ins_rate"));
					if (ins_rate < 0 || ins_rate > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		valuation_rate: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.FLOATVALUE,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const valuation_rate = parseInt(getFieldValue("valuation_rate"));
					if (valuation_rate < 0 || valuation_rate > 100) {
						return Promise.reject(
							Messages.CASH_ODA_CHRG_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		max_rc_pincodes: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.ACCOUNT,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "1 to 7000"),
			},
			({ getFieldValue }) => ({
				validator() {
					const max_rc_pincodes = parseInt(getFieldValue("max_rc_pincodes"));
					if (max_rc_pincodes < 1) {
						return Promise.reject(
							Messages.CUSTOMIZED_RC_PINCODE_RANGE
						);
					} else if (max_rc_pincodes > 7000) {
						return Promise.reject(
							Messages.CUSTOMIZED_RC_PINCODE_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		abr_period: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.ACCOUNT,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "1 to 6"),
			},
			({ getFieldValue }) => ({
				validator() {
					const abr_period = parseInt(getFieldValue("abr_period"));
					if (abr_period < 1) {
						return Promise.reject(
							Messages.AVERAGE_BUSINESS_RATE_FOR_SPECIFIED_PERIOD
						);
					} else if (abr_period > 6) {
						return Promise.reject(
							Messages.AVERAGE_BUSINESS_RATE_FOR_SPECIFIED_PERIOD
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		abr_per: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.ACCOUNT,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "1 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const abr_per = parseInt(getFieldValue("abr_per"));
					if (abr_per < 1) {
						return Promise.reject(
							Messages.ONE_TO_HANDED_RATE_RANGE
						);
					} else if (abr_per > 100) {
						return Promise.reject(
							Messages.ONE_TO_HANDED_RATE_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		ecr_below_avg: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.ACCOUNT,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "1 to 500"),
			},
			({ getFieldValue }) => ({
				validator() {
					const ecr_below_avg = parseInt(getFieldValue("ecr_below_avg"));
					if (ecr_below_avg < 1) {
						return Promise.reject(
							Messages.EXPRESS_COVER_RATE_BELOW_AVERAGE_BUSINESS
						);
					} else if (ecr_below_avg > 500) {
						return Promise.reject(
							Messages.EXPRESS_COVER_RATE_BELOW_AVERAGE_BUSINESS
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		ecr_above_avg: [
			{ required: true, message: Messages.REQUIRED },
			{
				pattern: Patterns.ACCOUNT,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "1 to 100"),
			},
			({ getFieldValue }) => ({
				validator() {
					const ecr_above_avg = parseInt(getFieldValue("ecr_above_avg"));
					if (ecr_above_avg < 1) {
						return Promise.reject(
							Messages.ONE_TO_HANDED_RATE_RANGE
						);
					} else if (ecr_above_avg > 100) {
						return Promise.reject(
							Messages.ONE_TO_HANDED_RATE_RANGE
						);
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
	},
};

const vsmEmailBannerCS = {
	success: Messages.UPDATED,
	validation: {
		email_banner: [
			({ getFieldValue }) => ({
				validator() {
					if (
						!getFieldValue("email_banner") ||
						getFieldValue("email_banner").fileList.length === 0
					) {
						return Promise.reject(Messages.REQUIRED);
					}
					return Promise.resolve();
				},
			}),
		],
		cover_photo: [
			({ getFieldValue }) => ({
				validator() {
					if (
						!getFieldValue("avatar") ||
						getFieldValue("avatar").fileList.length === 0
					) {
						return Promise.reject(Messages.REQUIRED);
					}
					return Promise.resolve();
				},
			}),
		]
	},
};

const vsmLogoUploadCS = {
	success: Messages.UPDATED,
	validation: {
		logo: [
			({ getFieldValue }) => ({
				validator() {
					if (
						!getFieldValue("logo") ||
						getFieldValue("logo").fileList.length === 0
					) {
						return Promise.reject(Messages.REQUIRED);
					}
					return Promise.resolve();
				},
			}),
		],
	},
};

const vsmHeroimageCS = {
	success: Messages.UPDATED,
	validation: {
		hero_image: [
			({ getFieldValue }) => ({
				validator() {
					if (
						!getFieldValue("hero_image") ||
						getFieldValue("hero_image").fileList.length === 0
					) {
						return Promise.reject(Messages.REQUIRED);
					}
					return Promise.resolve();
				},
			}),
		],
	},
};

const vsmFavIconCS = {
	success: Messages.UPDATED,
	validation: {
		favicon: [
			({ getFieldValue }) => ({
				validator() {
					if (
						!getFieldValue("favicon") ||
						getFieldValue("favicon").fileList.length === 0
					) {
						return Promise.reject(Messages.REQUIRED);
					}
					return Promise.resolve();
				},
			}),
		],
	},
};


const vsmIconCS = {
	success: Messages.UPDATED,
	validation: {
		icon: [
			({ getFieldValue }) => ({
				validator() {
					if (
						!getFieldValue("icon") ||
						getFieldValue("icon").fileList.length === 0
					) {
						return Promise.reject(Messages.REQUIRED);
					}
					return Promise.resolve();
				},
			}),
		],
	},
};

const vsmStickerPreference = {
	success: Messages.UPDATED,
	validation: {
		cash_from_barcode: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
			{
				whitespace: true,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0-9"),
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					const fromValue = value
					if (value && value.length !== 13) {
						return Promise.reject(replaceString(Messages.FIXDIGITINVALID, "#DIGIT#", 13));
					} else if (value && !(['1'].includes(fromValue.toString().split('')[4]))) {
						return Promise.reject(replaceString(Messages.INVALID_CASH_BARCODE))
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		cash_to_barcode: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
			{
				pattern: Patterns.NUMBER,
				whitespace: true,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0-9"),
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					const tocashBarcodeValue = value
					const fromcashBarcodeValue = getFieldValue("cash_from_barcode")
					if (value && (value.length !== 13)) {
						return Promise.reject(replaceString(Messages.FIXDIGITINVALID, "#DIGIT#", 13));
					} else if (value && !(['1'].includes(tocashBarcodeValue.toString().split('')[4]))) {
						return Promise.reject(replaceString(Messages.INVALID_CASH_BARCODE))
					}
					else if (value && tocashBarcodeValue < fromcashBarcodeValue) {
						return Promise.reject(replaceString(Messages.BARCODE_VALUE_ERROR))
					} else {
						return Promise.resolve();
					}
				}
			})
		],
		acc_from_barcode: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
			{
				pattern: Patterns.NUMBER,
				whitespace: true,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0-9"),
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					const fromValue = value
					if (value && value.length !== 13) {
						return Promise.reject(replaceString(Messages.FIXDIGITINVALID, "#DIGIT#", 13));
					} else if (value && !(['2'].includes(fromValue.toString().split('')[4]))) {
						return Promise.reject(replaceString(Messages.INVALID_ACC_BARCODE))
					} else {
						return Promise.resolve();
					}
				},
			}),
		],
		acc_to_barcode: [
			{ required: true, message: Messages.REQUIRED, whitespace: true },
			{
				pattern: Patterns.NUMBER,
				whitespace: true,
				message: replaceString(Messages.INVALID_RANGE_ATTR, "#RANGE#", "0-9"),
			},
			({ getFieldValue }) => ({
				validator(rule, value) {
					const toAccBarcodeValue = value
					const fromAccBarcodeValue = getFieldValue("acc_from_barcode")
					if (value && (value.length !== 13)) {
						return Promise.reject(replaceString(Messages.FIXDIGITINVALID, "#DIGIT#", 13));
					} else if (value && !(['2'].includes(toAccBarcodeValue.toString().split('')[4]))) {
						return Promise.reject(replaceString(Messages.INVALID_ACC_BARCODE))
					}
					else if (value && toAccBarcodeValue < fromAccBarcodeValue) {
						return Promise.reject(replaceString(Messages.BARCODE_VALUE_ERROR))
					} else {
						return Promise.resolve();
					}
				}
			})
		]
	},
};

export {
	vsmNotify,
	vsmAuth,
	vsmAuthForgot,
	vsmCommon,
	vsmRole,
	vsmCompanyProfileCS,
	vsmCompanyPreference,
	vsmEmailBannerCS,
	vsmFavIconCS,
	vsmHeroimageCS,
	vsmLogoUploadCS,
	vsmIconCS,
	vsmStickerPreference,

};
