import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { Constant } from "../../../../config/Constant";

export default class ManageLocationPerformanceByDeliveryReportStore {
  detail_filter = null;
  agGridDetail = null;
  per_page_detail = ServerGridConfig.options.paginationPageSize;
  current_page_detail = 1;
  list_data_detail = null;
  total_detail = 0;
  allColumnIdsDetail = [];
  //This is for summary
  summary_filter = null;
  agGridSummary = null;
  per_page_summary = ServerGridConfig.options.paginationPageSize;
  current_page_summary = 1;
  list_data_summary = null;
  total_summary = 0;
  allColumnIdsSummary = [];
  pinnedBottomTotalDataDetails = [];
  pinnedSummaryBottomTotalDataDetails = [];

  // set form values to  customefilter
  setDetailFilterValues = (data = null) => {
    return new Promise((resolve) => {
      this.detail_filter = data;
      if (this.agGridDetail && data) {
        this.setupGridDetail(this.agGridDetail);
      }
      resolve();
    });
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGridSummary = params;
    const { api } = params;
    let datasource = this.createDatasource(ServerGridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGridSummary.api.paginationSetPageSize(parseInt(page));
    }
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSizeDetail = (page = this.per_page_detail) => {
    this.per_page_detail = page;
    if (this.agGridDetail) {
      this.agGridDetail.api.paginationSetPageSize(parseInt(page));
    }
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGridSummary = params;
    if (
      this.agGridSummary &&
      this.agGridSummary.api.getModel().getRowCount() === 0
    ) {
      this.agGridSummary.api.showNoRowsOverlay();
    }
    if (
      this.agGridSummary &&
      this.agGridSummary.api.getModel().getRowCount() > 0
    ) {
      this.agGridSummary.api.hideOverlay();
    }
  };
  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;
    return { final_filter, final_sort };
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = this.changeFilterAndSort(params.request);
        var payload = {
          filter_data: filter_data.final_filter,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          advance_filter: this.detail_filter
            ? this.generatePayload(this.detail_filter)
            : this.generatePayload(this.summary_filter),
          page: Math.ceil(
            (params.request.startRow + 1) /
              (params.request.endRow - params.request.startRow)
          ),
        };
        this.detail_filter
          ? this.getList(payload).then((data) => {
              if (data.list.total === 0) {
                this.agGridDetail.api.showNoRowsOverlay();
              } else {
                this.agGridDetail.api.hideOverlay();
              }
              params.successCallback(data.list.data, data.list.total);
              var allColumnIds = [];
              if (
                this.agGridDetail &&
                this.agGridDetail.columnApi &&
                data.total
              ) {
                this.agGridDetail.columnApi
                  .getAllColumns()
                  .forEach(function (column) {
                    allColumnIds.push(column.col_id);
                  });
              }
            })
          : this.getListSummary(payload).then((data) => {
              if (data?.list?.total === 0) {
                this.agGridSummary.api.showNoRowsOverlay();
              } else {
                this.agGridSummary.api.hideOverlay();
              }
              params.successCallback(data?.list?.data, data?.list?.total);
              var allColumnIds = [];
              if (
                this.agGridSummary &&
                this.agGridSummary.columnApi &&
                data?.total
              ) {
                this.agGridSummary.columnApi
                  .getAllColumns()
                  .forEach(function (column) {
                    allColumnIds.push(column.col_id);
                  });
              }
            });
      },
    };
  };

  // set form values to  customefilter
  setSummaryFilterValues = (data = null) => {
    return new Promise((resolve) => {
      this.summary_filter = data;
      if (this.agGridSummary) {
        this.setupGridSummary(this.agGridSummary);
      }
      resolve();
    });
  };

  // Setup grid and set column size to autosize
  setupGridDetail = (params) => {
    this.pinnedBottomTotalDataDetails = [];
    this.agGridDetail = params;
    const { api } = params;
    let datasource = this.createDatasource(ServerGridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // Setup grid and set column size to autosize
  setupGridSummary = (params) => {
    this.pinnedSummaryBottomTotalDataDetails = [];
    this.agGridSummary = params;
    const { api } = params;
    let datasource = this.createDatasource(ServerGridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSizeSummary = (page = this.per_page_summary) => {
    this.per_page_summary = page;
    if (this.agGridSummary) {
      this.agGridSummary.api.paginationSetPageSize(parseInt(page));
    }
  };

  // Filter function for no record found message
  onFilterChangedSummary = (params) => {
    this.agGridSummary = params;
    if (
      this.agGridSummary &&
      this.agGridSummary.api.getModel().getRowCount() === 0
    ) {
      this.agGridSummary.api.showNoRowsOverlay();
    }
    if (
      this.agGridSummary &&
      this.agGridSummary.api.getModel().getRowCount() > 0
    ) {
      this.agGridSummary.api.hideOverlay();
    }
  };

  generatePayload = (summary_filter) => {
    if (summary_filter.from_date) {
      summary_filter.from_date = moment(summary_filter.from_date).format(
        Constant.PAYLOAD_DATE
      );
    }
    if (summary_filter.to_date) {
      summary_filter.to_date = moment(summary_filter.to_date).format(
        Constant.PAYLOAD_DATE
      );
    }
    return summary_filter;
  };

  refreshListDetail = () => {
    if (this.agGridDetail) {
      this.setupGridDetail(this.agGridDetail);
    }
  };

  refreshList = () => {
    if (this.agGridSummary) {
      this.setupGrid(this.agGridSummary);
    }
  };
  // call api to get records
  getList = (payload) => {
    return Axios.post(
      `/reports/mis/location-performance-report-details`,
      payload
    ).then(({ data }) => {
      if (data?.list?.data?.length) {
        let startPage = (data?.list?.current_page - 1) * data?.list?.per_page;
        data?.list?.data?.map((item, index) => {
          item.srno = startPage + index + 1;
          return null;
        });
      }
      this.pinnedBottomTotalDataDetails =
        data?.list?.data?.length > 0 ? [data?.footer_total] : [];
      this.list_data_detail = data?.list ? data?.list?.data : null;
      this.total_detail = data?.list?.total;
      this.current_page_detail = data?.list?.current_page;
      return data;
    });
  };
  // call api to get records
  getListSummary = (payload) => {
    return Axios.post(`/reports/mis/location-performance-report`, payload).then(
      ({ data }) => {
        if (data?.list?.data?.length) {
          let startPage = (data?.list?.current_page - 1) * data?.list?.per_page;
          data?.list?.data?.map((item, index) => {
            item.srno = startPage + index + 1;
            return null;
          });
        }
        this.pinnedSummaryBottomTotalDataDetails =
          data?.list?.data?.length > 0 ? [data?.footer_total] : [];
        this.list_data_summary = data?.list ? data?.list?.data : null;
        this.total_summary = data?.list?.total;
        this.current_page_summary = data?.list?.current_page;
        return data;
      }
    );
  };
  doExportDownload = (data) => {
    return Axios.post(`/reports/mis/location-performance-report`, {
      advance_filter: data,
    })
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };
}

decorate(ManageLocationPerformanceByDeliveryReportStore, {
  per_page_detail: observable,
  agGridDetail: observable,
  list_data_detail: observable,
  total_detail: observable,
  allColumnIdsDetail: observable,
  setSummaryFilterValues: action,
  setupGridDetail: action,
  setPageSizeDetail: action,
  getListDetail: action,
  onFilterChangedDetail: action,
  per_page_summary: observable,
  agGridSummary: observable,
  list_data_summary: observable,
  total_summary: observable,
  allColumnIdsSummary: observable,
  setupGridSummary: action,
  setPageSizeSummary: action,
  getListSummary: action,
  onFilterChangedSummary: action,
  summary_filter: observable,
  setDetailFilterValues: action,
  detail_filter: observable,
  pinnedBottomTotalDataDetails: observable,
  pinnedSummaryBottomTotalDataDetails: observable,
});
