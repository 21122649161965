import React from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Col, Layout, Row } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../../../store";
import { convertDate, isActiveStatus } from "../../../../../utils/GlobalFunction";
import { Constant } from "../../../../../config/Constant";
// import { AgGridReact } from "@ag-grid-community/react";
import LocalGridConfig from "../../../../../config/LocalGridConfig";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { vsmCommon } from "../../../../../config/messages/common";
import LocalAgGridWrapper from "../../../../../component/LocalAgGridWrapper";

const ZoneViewComponent = observer((props) => {
	const {
		ManageRateCardCashStore: { viewZoneValues, list_data_pincode, setupPincodeGrid, onPincodeFilterChanged },
		// AUTH: { checkPrivileges }
	} = useStore();

	// reset form and close add form
	const close = () => {
		props.close();
	};

	const gridOptions = {
		columnDefs: [
			{ headerName: "Pin Code", field: "pincode" },
			{ headerName: "City", field: "cities.name" },
			{ headerName: "State", field: "states.name" },
			{
				headerName: "Pincode Tag",
				field: "pincode_tags.name",
				valueGetter: (params) => params.data?.pincode_tags ? params.data.pincode_tags.name : Constant.UNDEFINED_VALUES,
				cellRendererFramework: function (data) {
					if (data.data.pincode_tags)
						return (
							<p style={{ color: `${data.data.pincode_tags.color_code}` }}>{data.data.pincode_tags.name}</p>
						);
				},
			},
			{
				headerName: "Delivery Tag",
				field: "delivery_tags.name",
				valueGetter: (params) => params.data?.delivery_tags ? params.data.delivery_tags.name : Constant.UNDEFINED_VALUES,
				cellRendererFramework: function (data) {
					if (data.data.delivery_tags)
						return (
							<p style={{ color: `${data.data.delivery_tags.color_code}` }}>{data.data.delivery_tags.name}</p>
						);
				},
			},
		],
	};

	return (
		viewZoneValues && (
			<>
				<Drawer
					className="addModal"
					title={`View Zone`}
					width="70%"
					visible={props.visible}
					closeIcon={<FontAwesomeIcon icon={faTimes} />}
					onClose={close}
					footer={[
						<Button
							key="3"
							htmlType="button"
							className="cancelBtn mr-35"
							onClick={close}
						>
							Cancel
						</Button>,
						/**
						 * Hide due to we can't edit zone
						 * If you want te edit or delete zone you need to create a draft.
						 */
						// ((((checkPrivileges("#1010082#") || checkPrivileges("#1010083#") || checkPrivileges("#1010042#")) && (props.openFrom !== "location" || props.openFrom !== "locationProfile")) && <Button
						// 	key="2"
						// 	htmlType="button"
						// 	className="mr-35"
						// 	onClick={props.openEditZoneModal}
						// 	type="primary"
						// >
						// 	Edit
						// </Button>)),
						// ((((checkPrivileges("#1010082#") || checkPrivileges("#1010083#") || checkPrivileges("#1010042#")) && (props.openFrom !== "location" || props.openFrom !== "locationProfile")) && <Button
						// 	key="1"
						// 	htmlType="button"
						// 	className="mr-35"
						// 	onClick={props.openDeleteZoneModal}
						// 	type="primary"
						// 	danger
						// >
						// 	Delete
						// </Button>)),
					]}
				>
					<Layout className="details_table" style={{ padding: "0 24px" }}>
						<Row gutter={6} wrap={false}>
							<Col span={12} className="form__input__textbox">
								<label>Name : </label>
								<span>{viewZoneValues.name}</span>
							</Col>
							<Col span={12} className="form__input__textbox">
								<label>Count : </label>
								<span>{viewZoneValues.pin_cnt
									? viewZoneValues.pin_cnt
									: Constant.UNDEFINED_VALUES}</span>
							</Col>
						</Row>
						<Row gutter={6} wrap={false}>
							<Col span={24} className="form__input__textbox">
								<label>Pin Code : </label>
								<LocalAgGridWrapper
									rowHeight={LocalGridConfig.rowHeight}
									headerHeight={LocalGridConfig.headerHeight}
									rowData={list_data_pincode}
									modules={AllModules}
									columnDefs={gridOptions.columnDefs}
									defaultColDef={LocalGridConfig.defaultColDef}
									columnTypes={LocalGridConfig.columnTypes}
									overlayNoRowsTemplate={vsmCommon.noRecord}
									onGridReady={setupPincodeGrid}
									onFilterChanged={onPincodeFilterChanged}
									onSortChanged={onPincodeFilterChanged}
									gridOptions={LocalGridConfig.options}
								/>
							</Col>
						</Row>
						<Row gutter={6} wrap={false}>
							<Col span={6} className="form__input__textbox">
								<label>Is Completed?</label>
								<div className="ant-space-item">
									{viewZoneValues?.is_completed === 1 ? (
										<div className="isactive_tag ant-tag ant-tag-success">
											{isActiveStatus[1]}{" "}
										</div>
									) : (
										<div className="isactive_tag ant-tag ant-tag-error">
											{isActiveStatus[0]}
										</div>
									)}
								</div>
							</Col>
						</Row>
						<Row gutter={6} wrap={false}>
							<Col
								span={12}
								className="form__input__textbox form__input__selectBox"
							>
								<label>Created By : </label>
								<span>
									{viewZoneValues.created_by
										? `${viewZoneValues.created_by.name}(${viewZoneValues.created_by.email})`
										: Constant.UNDEFINED_VALUES}
								</span>
							</Col>
							<Col
								span={12}
								className="form__input__textbox form__input__selectBox"
							>
								<label>Created : </label>
								<span>{convertDate(viewZoneValues.created)}</span>
							</Col>
						</Row>
						<Row gutter={6} wrap={false}>
							<Col
								span={12}
								className="form__input__textbox form__input__selectBox"
							>
								<label>Modified By : </label>
								<span>
									{viewZoneValues.modified_by
										? `${viewZoneValues.modified_by.name}(${viewZoneValues.modified_by.email})`
										: Constant.UNDEFINED_VALUES}
								</span>
							</Col>
							<Col
								span={12}
								className="form__input__textbox form__input__selectBox"
							>
								<label>Modified : </label>
								<span>{convertDate(viewZoneValues.modified)}</span>
							</Col>
						</Row>

					</Layout>
				</Drawer>
			</>
		)
	);
});
export default ZoneViewComponent;
