import React from "react";
import { Form, Row, Col, Spin, Divider, Tooltip, Switch } from "antd";
import { observer } from "mobx-react";
import InputComponent from "../../../../../component/InputComponent";
import debounce from "lodash/debounce";
import { vsmLocation } from "./../Validation";
import useStore from "../../../../../store";
import { Constant } from "../../../../../config/Constant";
import { InfoCircleOutlined } from "@ant-design/icons";
import DefaulHeader from "../../../organization/Center/component/DefaulHeader";

const GeneralFormComponent = observer((props) => {
	const {
		setDisabled = () => { },
		form,
		type,
		fetchDrtList,
		setFetchDrList,
		setFetchProduct,
		fetchProduct,
		fetchWeight,
		setFetchWeight,
	} = props;
	const {
		ManageProductsStore,
		CommonStore,
		AUTH
	} = useStore();

	const isViewPurpose = (type === Constant.VIEW) ? true : false;

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);


	const fovtext = (
		<ui>
			<li><span>- Other Delivery Area</span></li>
			<li><span>- Additional charge applied on cash booking considering type of Pincode/Area</span></li>
		</ui>
	);

	const podtext = (
		<ui>
			<li><span>- Charge to send an email notification with proof of delivery to the sender</span></li>
		</ui>
	);

	const smstext = (
		<ui>
			<li><span>- Charge to send SMS notification to the receiver</span></li>
		</ui>
	);

	const producttext = (
		<ui>
			<li><span>- Default product to be selected while new booking for this location</span></li>
		</ui>
	)

	const weighttext = (
		<ui>
			<li>- Default weight to be selected while new booking</li>
		</ui>
	)

	const text = (
		<ui>
			<li><span>- Freight On Value</span></li>
		</ui>
	);

	return (
		<>
			<Form
				form={form}
				id={props.id}
				onFinish={props.handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<DefaulHeader />
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							onChange={handleChange}
							label="Insurance Rate (%)"
							placeholder="Insurance Rate (%)"
							name="ins_rate"
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.ir}
						/>
					</Col>
					<InputComponent
						type="text"
						required={!isViewPurpose}
						readOnly={isViewPurpose}
						onChange={handleChange}
						label="Valuation Rate (%)"
						hidden
						placeholder="Valuation Rate (%)"
						name="valuation_rate"
						rules={(isViewPurpose) ? [] : vsmLocation.validation.vr}
					/>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="FOV Charge (%)"
							placeholder="FOV Charge"
							name="fov_chrg"
							suffix={
								<Tooltip title={text}>
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.fc}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							hidden={true}
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Fuel Charges (%) "
							placeholder="Fuel Charges"
							name="fuel_chrg"
						//rules={(isViewPurpose) ? [] : vsmLocation.validation.fuel}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="ODA1 Per KG"
							placeholder="ODA1 Per KG"
							name="cash_oda1_chrg"
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							suffix={
								<Tooltip title={fovtext}>
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.oda1_chrg}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="ODA2 Per KG"
							placeholder="ODA2 Per KG"
							name="cash_oda2_chrg"
							onChange={handleChange}
							suffix={
								<Tooltip title={fovtext}>
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.oda2_chrg}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="POD Charges"
							placeholder="POD Charges"
							name="pod_chrg"
							suffix={
								<Tooltip title={podtext}>
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
							onChange={handleChange}
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.pod_chrg}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="SMS Charges"
							placeholder="SMS Charges"
							name="rec_sms_chrg"
							suffix={
								<Tooltip title={smstext}>
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
							onChange={handleChange}
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.rec_sms_chrg}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				{/* <Row gutter={30}>
					<Col xs={{ span: 8 }}>
						<h2>Discount Rate</h2>
					</Col>
				</Row> */}
					<Col xs={{ span: 6 }} id="parent_disc_rate_dx">
						<InputComponent
							type="select"
							hidden
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							label="Dox (%)"
							name="disc_rate_dx"
							placeholder="Dox (%)"
							getPopupContainer={() => !isViewPurpose && document.getElementById('parent_disc_rate_dx')}
							showSearch={false}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose &&
								fetchDrtList &&
								CommonStore.getDiscountRate({ is_active: 1 }).then(() =>
									setFetchDrList(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchDrtList) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_discount_list,
								value_key: "per",
								text_key: "per",
								disabled_keys:
									CommonStore.dropdown_discount_list &&
									CommonStore.dropdown_discount_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.disc_rate}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_disc_rate_dex">
						<InputComponent
							type="select"
							hidden
							required={!isViewPurpose}
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							readOnly={isViewPurpose}
							label="Dox Express (%)"
							name="disc_rate_dex"
							placeholder="Dox Express (%)"
							getPopupContainer={() => !isViewPurpose && document.getElementById('parent_disc_rate_dex')}
							showSearch={false}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose &&
								fetchDrtList &&
								CommonStore.getDiscountRate({ is_active: 1 }).then(() =>
									setFetchDrList(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchDrtList) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_discount_list,
								value_key: "per",
								text_key: "per",
								disabled_keys:
									CommonStore.dropdown_discount_list &&
									CommonStore.dropdown_discount_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.disc_rate}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_disc_rate_ndbs">
						<InputComponent
							type="select"
							hidden
							required={!isViewPurpose}
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							readOnly={isViewPurpose}
							label="Non Dox by Surface (%)"
							name="disc_rate_ndbs"
							placeholder="Non Dox by Surface (%)"
							getPopupContainer={() => !isViewPurpose && document.getElementById('parent_disc_rate_ndbs')}
							showSearch={false}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose &&
								fetchDrtList &&
								CommonStore.getDiscountRate({ is_active: 1 }).then(() =>
									setFetchDrList(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchDrtList) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_discount_list,
								value_key: "per",
								text_key: "per",
								disabled_keys:
									CommonStore.dropdown_discount_list &&
									CommonStore.dropdown_discount_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.disc_rate}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_disc_rate_ndba">
						<InputComponent
							type="select"
							hidden
							required={!isViewPurpose}
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							readOnly={isViewPurpose}
							label="Non Dox by Air (%)"
							name="disc_rate_ndba"
							placeholder="Non Dox by Air (%)"
							getPopupContainer={() => !isViewPurpose && document.getElementById('parent_disc_rate_ndba')}
							showSearch={false}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose &&
								fetchDrtList &&
								CommonStore.getDiscountRate({ is_active: 1 }).then(() =>
									setFetchDrList(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchDrtList) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_discount_list,
								value_key: "per",
								text_key: "per",
								disabled_keys:
									CommonStore.dropdown_discount_list &&
									CommonStore.dropdown_discount_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.disc_rate}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_disc_rate_ndex">
						<InputComponent
							type="select"
							hidden
							required={!isViewPurpose}
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							readOnly={isViewPurpose}
							showSearch={false}
							label="Non Dox Express (%)"
							name="disc_rate_ndex"
							placeholder="Non Dox Express (%)"
							getPopupContainer={() => !isViewPurpose && document.getElementById('parent_disc_rate_ndex')}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose &&
								fetchDrtList &&
								CommonStore.getDiscountRate({ is_active: 1 }).then(() =>
									setFetchDrList(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchDrtList) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_discount_list,
								value_key: "per",
								text_key: "per",
								disabled_keys:
									CommonStore.dropdown_discount_list &&
									CommonStore.dropdown_discount_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.disc_rate}
						/>
					</Col>
				{/* <Divider className="mt-0" /> */}
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_def_product_id">
						<InputComponent
							type="select"
							name="def_product_id"
							label="Product"
							disabled={AUTH.user.level_id !== Constant.ADMIN_USER}
							getPopupContainer={() => !isViewPurpose ? document.getElementById('parent_def_product_id') : null}
							required={!isViewPurpose}
							showSearch={false}
							readOnly={isViewPurpose}
							tooltip={producttext}
							placeholder="Select Product"
							onChange={(e, val) => {
								handleChange(e);
								if (val) {
									//displayHubListUpdate(val)
								}
							}}
							onFocus={() =>
								!isViewPurpose && fetchProduct &&
								ManageProductsStore.getProduct({ is_active: 1 }).then(() =>
									setFetchProduct(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchProduct) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageProductsStore.dropdown_product_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageProductsStore.dropdown_product_list &&
									ManageProductsStore.dropdown_product_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.productList}
						/>
					</Col>
					<Col xs={{ span: 6 }} className="tooltipText" id="parent_def_weight">
						<InputComponent
							type="select"
							hidden={true}
							label="Weight"
							tooltip={weighttext}
							required={!isViewPurpose}
							getPopupContainer={() => !isViewPurpose ? document.getElementById('parent_def_weight') : null}
							readOnly={isViewPurpose}
							name="def_weight"
							placeholder="Select Weight"
							onChange={(e, val) => {
								handleChange(e);
								if (val) {
									//displayHubListUpdate(val)
								}
							}}
							onFocus={() =>
								!isViewPurpose && fetchWeight &&
								CommonStore.getWeight({ is_active: 1 }, "gms", "after").then(() =>
									setFetchWeight(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchWeight) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: CommonStore.dropdown_weight_list,
								value_key: "id",
								text_key: "val",
								disabled_keys:
									CommonStore.dropdown_weight_list &&
									CommonStore.dropdown_weight_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						//rules={(isViewPurpose) ? [] : vsmLocation.validation.def_weight}
						/>

					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<Form.Item
							label="Volumetric Weight"
							readOnly={isViewPurpose}
							valuePropName="checked"
							tooltip={"- Make volumetric weight (height, width and length) mandatory or optional while new booking"}
							name="vw_flag"
						>
							<Switch
								className="Switch__with_label"
								disabled={isViewPurpose ? true : false || AUTH.user.level_id !== Constant.ADMIN_USER}								
								checked={(form.getFieldValue('vw_flag') === 1) ? true : false}
								onChange={handleChange}
							/>
						</Form.Item>
					</Col>
					<Col xs={{ span: 6 }}>
						<Form.Item
							label="Tax"
							tooltip={"- Booking with/without GST"}
							name="tax_flag"
							readOnly={true}
							//readOnly={isViewPurpose}
							onChange={handleChange}
							valuePropName="checked"
						>
							<Switch
								className="Switch__with_label"
								disabled
								//disabled={isViewPurpose ? true : false}
								checked={(form.getFieldValue('tax_flag') === 1) ? true : false}
								onChange={handleChange}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</>
	);
});
export default GeneralFormComponent;
