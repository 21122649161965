import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { convertTextToID } from "../../../../utils/GlobalFunction";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageEmailStore {
  agGrid = null;
  per_page = ServerGridConfig.options.paginationPageSize;
  current_page = 1;
  list_data = null;
  editValues = null;
  viewValues = null;
  statusValues = null;
  total = 0;
  allColumnIds = [];
  filter_status_list = [];

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to view
  setViewValues = (data) => {
    this.viewValues = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let datasource = this.createDatasource(ServerGridConfig.options);
    api.setServerSideDatasource(datasource)
  };

  // change page size, default page size is ServerGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  setIsActiveFilterList = (data) => {
    this.isActiveFilterList = data;
  }

  // set form values to status
  setStatusValues = (data) => {
    this.statusValues = data;
  };

  // call api to get records
  getList = (payload) => {
    return Axios.post(`admin/emails/list`, payload).then(({ data }) => {
      if (data.list.data.length) {
        let startPage = (data.list.current_page - 1) * data.list.per_page;
        data.list.data.map((item, index) => {
          item.srno = startPage + index + 1;
          return null;
        });
      }
      this.list_data = data.list ? data.list.data : null;
      this.total = data.list.total;
      this.current_page = data.list.current_page;
      return data;
    });
  };

  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;

    if (final_filter["is_active"]) {
      final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
    }

    return { final_filter, final_sort }
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = this.changeFilterAndSort(params.request);
        var payload = {
          filter_data: filter_data.final_filter,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
        }
        this.getList(payload).then((data) => {
          if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
          else { this.agGrid.api.hideOverlay() }
          params.successCallback(data.list.data, data.list.total)
          var allColumnIds = []
          if (this.agGrid && this.agGrid.columnApi && data.total) {
            this.agGrid.columnApi.getAllColumns().forEach(function (column) {
              allColumnIds.push(column.col_id)
            })
          }
        })
      }
    }
  }

  TogglePublishData = (formdata) => {
    const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
    return Axios.patch("admin/emails/" + api_link + formdata.id)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  // Call edit api
  EditData = (formdata) => {
    return Axios.post(`admin/emails/edit/` + formdata.id, formdata)
      .then(({ data }) => {
        if (this.agGrid) {
          this.setupGrid(this.agGrid);
        }
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // call api to get records
  ViewData = (ID) => {
    return Axios.get(`admin/emails/details/${ID}`).then(({ data }) => {
      this.setViewValues(data.view);
      return data.view;
    });
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };
}

decorate(ManageEmailStore, {
  per_page: observable,
  agGrid: observable,
  list_data: observable,
  editValues: observable,
  statusValues: observable,
  total: observable,
  allColumnIds: observable,
  viewValues: observable,
  setupGrid: action,
  setPageSize: action,
  setEditValues: action,
  setViewValues: action,
  setStatusValues: action,
  getList: action,
  onFilterChanged: action,
  setIsActiveFilterList: action
});
