/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { b64toBlob, convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManageSoftwareChargeStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	deleteValues = null;
	total = 0;
	allColumnIds = [];
	editValues = null;
	dropdown_payment_status_list = null
	paymentDetails = null;
	typeMode = null;
	orderConfirmationValues = null;

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	setPaymentStatusList = (data) => {
		this.dropdown_payment_status_list = data;
	};

	setPaymentDetails = (data) => {
		this.paymentDetails = data;
		return true;
	};

	setOrderConfirmationValue = (data) => {
		this.orderConfirmationValues = data;
	};

	setTypeMode = (mode) => {
		this.typeMode = mode;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`ho-activities/software-charge/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}

			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		
		if (final_filter["payment_status"]) {
			final_filter["payment_status"].values = convertTextToID(
				final_filter["payment_status"],
				this.dropdown_payment_status_list,
				"name",
				"id"
			);
		}

		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`ho-activities/software-charge/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`ho-activities/software-charge/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// payment's API Call
	AddPaymentData = (formData) => {
		return Axios.post(`ho-activities/software-charge/payment-new`, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	ViewPaymentData = (id) => {
		return Axios.get(`ho-activities/software-charge/payment-detail/${id}`).then(({ data }) => {
			let setData = data.view;
			if (this.paymentDetails) {
				let oldval = this.paymentDetails;
				setData = { ...oldval, ...data.view };
			}
			this.setPaymentDetails(setData);
			return data;
		});
	};

	EditPaymentData = (id, formData) => {
		return Axios.post(`ho-activities/software-charge/payment-edit/${id}`, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	PaymentConfirmationData = (formData) => {
		return Axios.post(`ho-activities/software-charge/payment-confirmation`, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	getPaymentStatusList = (conditional = {}) => {
		return Axios.post(`admin/sticker-order-payment-status/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_payment_status_list = data.sticker_order_payment_status;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	};

	generateInvoiceData = (id) => {
		return Axios.get(`/ho-activities/software-charge/invoice-print/${id}`)
			.then(({ data }) => {
				const file = b64toBlob(data.print, "application/pdf");
				const fileURL = URL.createObjectURL(file);
				window.open(fileURL);
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageSoftwareChargeStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setDeleteValues: action,
	editValues: observable,
	setEditValues: action,
	dropdown_payment_status_list: observable,
	setPaymentStatusList: action,
	setPaymentDetails: action,
	paymentDetails: observable,
	typeMode: observable,
	setTypeMode: action,
	setOrderConfirmationValue: action,
	orderConfirmationValues: observable,
});
