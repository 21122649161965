/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import InputComponent from './InputComponent';
import { Col } from 'antd';
import useStore from '../store';
import { useCallback } from 'react';

const MapLocationComponent = observer((props) => {
	const {
		AUTH
	} = useStore();

	const initMap = useCallback(() => {
		const map = new google.maps.Map(
			document.getElementById("map"),
			{
				center: { lat: 20.5937, lng: 78.9629},
				zoom: 4,
				mapTypeControl: false,
			}
		);
		
		const options = {
			fields: ["formatted_address", "geometry", "name"],
			strictBounds: false,
			types: ["establishment"],
		};

		const input = document.getElementById("pac-input");
		const autocomplete = new google.maps.places.Autocomplete(input, options);

		// Bind the map's bounds (viewport) property to the autocomplete object,
		// so that the autocomplete requests use the current map bounds for the
		// bounds option in the request.
		autocomplete.bindTo("bounds", map);

		const marker = new google.maps.Marker({
			map,
			anchorPoint: new google.maps.Point(0, -29),
		});

		if(Object.entries(props.placeInfo).length !== 0){
			if (props.placeInfo.geometry.viewport) {
				map.fitBounds(props.placeInfo.geometry.viewport);
			} else {
				map.setCenter(props.placeInfo.geometry.location);
				map.setZoom(13);
			}
			if(props.placeInfo.formatted_address){
				document.getElementById("pac-input").value = props.placeInfo.formatted_address;
			}else{
				document.getElementById("pac-input").value = "";
			}
			if(props.placeInfo.geometry.location.lat && props.placeInfo.geometry.location.lng){
				const geocoder = new google.maps.Geocoder();
				const infowindow = new google.maps.InfoWindow();
				const latlng = {
					lat:Number(props.placeInfo.geometry.location.lat),
					lng:Number(props.placeInfo.geometry.location.lng),
				};
				
				geocoder.geocode({ location: latlng }).then((response) => {
					if (response.results[0]) {
							map.setZoom(13);
					
							const marker = new google.maps.Marker({
							position: latlng,
							map: map,
							});
					
							infowindow.setContent(response.results[0].formatted_address);
							infowindow.open(map, marker);
					} else {
						window.alert("No results found");
					}
				})
				.catch((e) => window.alert("Geocoder failed due to: " + e));
			}

			marker.setPosition(props.placeInfo.geometry.location);
			marker.setVisible(true);
		}

		autocomplete.addListener("place_changed", () => {
			marker.setVisible(false);
			const place = autocomplete.getPlace();
			if (!place.geometry || !place.geometry.location) {
				// User entered the name of a Place that was not suggested and
				// pressed the Enter key, or the Place Details request failed.
				window.alert("No details available for input: '" + place.name + "'");
				return;
			}
			// If the place has a geometry, then present it on a map.
			if (place.geometry.viewport) {
				map.fitBounds(place.geometry.viewport);
			} else {
				map.setCenter(place.geometry.location);
				map.setZoom(17);
			}
			marker.setPosition(place.geometry.location);
			marker.setVisible(true);
			
			props.setLatLongFromAutoSearch(place.geometry.location.lat(), place.geometry.location.lng())
			place.geometry.location = {lat: place.geometry.location.lat(), lng:place.geometry.location.lng() }
			props.setPlaceInfo(place);
		});
		
		map.addListener("click", (mapsMouseEvent) => {
			map.setCenter(mapsMouseEvent.latLng);
			map.setZoom(13);
			marker.setPosition(mapsMouseEvent.latLng);
			marker.setVisible(true);
			props.setLatLongFromAutoSearch(mapsMouseEvent.latLng.lat(), mapsMouseEvent.latLng.lng())
			props.setPlaceInfo({geometry:{ location: {lat : mapsMouseEvent.latLng.lat(), lng: mapsMouseEvent.latLng.lng()}}});
		});
	},[props]);

	useEffect(()=>{
		if(AUTH && AUTH.company.branding && typeof AUTH.company.branding.map_api_flag !== 'undefined' && AUTH.company.branding.map_api_flag){
			const script = document.createElement('script');
			script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDTQxDCpFxTn8sIzdv4TRYva8m5ZiYTxGs&callback=initMap&libraries=places&v=weekly';
			script.async = true;
			document.body.appendChild(script);
		}
	},[AUTH])

	useEffect(()=> {
		const mapTimeout = setTimeout(() => {
			initMap();
		}, 1500);
		return () => {
			clearTimeout(mapTimeout)
		}
	},[initMap])

	return (
		<>
			<div className="pac-card" id="pac-card">
				<Col xs={{ span: 24 }} id="pac-container">
					<InputComponent
						label="Locations"
						type="text"
						name="pac-input"
						placeholder="Enter a location"
					/>
				</Col>
			</div>
			<div id="map"></div>
		</>
	);
});
export default MapLocationComponent