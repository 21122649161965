import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col, Upload, message } from "antd";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import {
	vsmEmailBannerCS,
	vsmNotify,
} from "../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormItem from "antd/lib/form/FormItem";
import { UploadIcon } from "../../../../config/IconsConfig";
import { Messages } from "../../../../config/messages/messages";
import { replaceString } from "../../../../utils/GlobalFunction";
import { Constant } from "../../../../config/Constant";

const CoverImageComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		AUTH: { editAvtarValues, updateUser },
		UserSettingStore
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fileList, updateFileList] = useState([]);
	const [isImageUploaded, setisImageUploaded] = useState(false);

	// make a fuction to call to edit record
	const handleSubmit = (data) => {

		setSaving(true);
		const formData = new FormData();

		formData.user_id = editAvtarValues.id;
		if (fileList.length > 0 && isImageUploaded) {
			formData.append(
				"avatar",
				fileList[0]?.originFileObj ? fileList[0]?.originFileObj : fileList[0]
			);
		}
		UserSettingStore.CoverImageuploadData(formData)
			.then((data) => {
				updateUser("avatar", data.view.avatar)
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// set the form values to edit
	useEffect(() => {
		if (editAvtarValues && props.visible && editAvtarValues.avatar) {
			updateFileList([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: Constant.S3BUCKET_API_BASEURL + editAvtarValues?.avatar,
				},
			]);
		}
	}, [editAvtarValues, form, props]);

	// reset form and close add form
	const close = () => {
		props.close();
		form.resetFields();
		setDisabled(true);
		updateFileList([]);
	};

	// Handle on remove image
	const onRemoveImage = () => {
		form.setFields([{ avatar: "avatar", errors: [] }]);
		updateFileList([]);
		setisImageUploaded(false);
		setDisabled(true);
	};

	const uploadButton = (
		<div>
			<div className="upload_btn">
				<UploadIcon />
				<p>Drag files to upload, or browse</p>
			</div>
		</div>
	);

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};
	const handleFileChange = (info) => {
		if (info && info.length > 0) {
			if (info[0].status === 'uploading') {
				updateFileList([]);
				return;
			}
		}
	}
	const eventProps = {
		onChange: ({ fileList: newFileList }) => {
			updateFileList(newFileList);
			setisImageUploaded(true);
			handleFileChange(newFileList);
		},
		fileList,
		beforeUpload: (file) => {
			let isJpgOrPng =
				file.type === "image/png" ||
				file.type === "image/jpeg" ||
				file.type === "image/jpg";
			let size = file.size / 1024;
			if (!isJpgOrPng) {
				message.error(Messages.INVALID_AVTAR_IMAGE_FORMAT);
				setisImageUploaded(false);
				setDisabled(true);
				return true;
			} else if (size > 250) {
				message.error(
					replaceString(Messages.IMAGE_SIZE_VALIDATION, "#SIZE#", "250 KB")
				);
				setisImageUploaded(false);
				setDisabled(true);
				return true;
			} else {
				setDisabled(false);
				updateFileList([file]);
				setisImageUploaded(true);
				return false;
			}
		},
	};

	return (
		<>
			<Modal
				className="editModal"
				centered
				title="Change Avatar"
				visible={props.visible}
				closeIcon={<FontAwesomeIcon icon={faTimes} />}
				onCancel={close}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				footer={[
					<Button
						key="2"
						htmlType="button"
						className="cancelBtn mr-35"
						onClick={close}
					>
						Cancel
					</Button>,
					<Button
						key="1"
						disabled={disabled}
						form="editCoverImageForm"
						loading={saving}
						htmlType="submit"
						type="primary"
					>
						Save
					</Button>,
				]}
			>
				<Form
					form={form}
					id="editCoverImageForm"
					onFinish={handleSubmit}
					labelCol={{ span: 24 }}
				>
					<Row gutter={30}>
						<Col xs={{ span: 24 }}>
							<FormItem required labelCol={{ span: 24 }}>
								<Form.Item
									name="avatar"
									rules={vsmEmailBannerCS.validation.cover_photo}
									required
									label="Avatar"
								>
									<Upload
										accept=".png,.jpeg,.jpg"
										fileList={fileList}
										onRemove={onRemoveImage}
										onPreview={onPreview}
										listType="picture-card"
										multiple={false}
										showUploadList={true}
										name="avatar"
										{...eventProps}
									>
										{fileList.length >= 1 ? null : uploadButton}
									</Upload>
								</Form.Item>
							</FormItem>
						</Col>
						<Col xs={{ span: 24 }}>
							<ul className="help_text">
								<li>Valid formats are JPEG, JPG and PNG</li>
								<li>Image must be square, meaning width and height must be same</li>
								<li>Image dimension should be 200 width X 200 height or in proportion</li>
								<li>Image size should not exceed 250 KB</li>
							</ul>
						</Col>
					</Row>
				</Form>
			</Modal>
		</>

	)
});

export default CoverImageComponent;
