/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { convertViewAddress } from "../../../../utils/GlobalFunction";

export default class ManageHubSearchStore {
	agGrid = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];


	// set form values to view
	setViewValues = (data) => {
        if (data) {
			data.address = convertViewAddress(data);
		}
		this.viewValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.list_data = null;
		return Axios.post(`utilities/hub-search/hub-list`).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter);
				this.agGrid.api.setSortModel(sort);
			}
		});
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	
	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`organization/hub/details/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		});
	};

	
	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

}

decorate(ManageHubSearchStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	viewValues: observable,
});
