/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageTerminatedFranchiseeStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	statusValues = null;
	outstanding = null;
	current_page_view_outstanding = 1;
    total_view_outstanding = 0;
	agGridViewOutstanding = null;
	list_data_view_outstanding =null
	per_page_view_outstanding = ServerGridConfig.options.paginationPageSize;


	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options );
		api.setServerSideDatasource(datasource);
	};

	setupViewOutstandingGrid = (params) => {
		this.agGridViewOutstanding = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options );
		api.setServerSideDatasource(datasource);
	};
	
	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	setPageViewOutstandingSize = (page = this.per_page_view_outstanding) => {
		this.per_page_view_outstanding = page;
		if (this.agGridViewOutstanding) {
			this.agGridViewOutstanding.api.paginationSetPageSize(parseInt(page));
		}
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setOutstanding = (data) => {
		this.outstanding = data
	}

	// call api to get records
	getList = (payload) => {
		return Axios.post(`organization/terminated-franchisee/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	getViewOutstandingList = (payload) => {
        return Axios.post(`/reports/outstanding-reports/ho-to-fo-details`, payload).then(({ data }) => {
            if (data?.list?.data?.length) {
                let startPage = (data.list.current_page - 1) * data.list.per_page;
                data.list.data.map((item, index) => {
                    item.srno = startPage + index + 1;
                    return null;
                });
            }
            this.list_data_view_outstanding = data?.list ? data?.list?.data : null;
            this.total_view_outstanding = data?.list?.total;
            this.current_page_view_outstanding = data?.list?.current_page;
            return data;
        });
    };

	// call api for pdf preview
	pdfPreview = (centerId) => {
		return Axios.get(`organization/terminated-franchisee/pdf-preview/${centerId}`).then(({ data }) => {
			return data;
		});
	};

	// Call add api
	AddData = (formData) => {
		return Axios.post(`organization/terminated-franchisee/new`, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	onFilterChangedViewOutstanding = (params) => {
		this.agGridViewOutstanding = params;
		if (this.agGridViewOutstanding && this.agGridViewOutstanding.api.getModel().getRowCount() === 0) {
			this.agGridViewOutstanding.api.showNoRowsOverlay();
		}
		if (this.agGridViewOutstanding && this.agGridViewOutstanding.api.getModel().getRowCount() > 0) {
			this.agGridViewOutstanding.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				const advance_filter  = {
					from_date : this.outstanding?.from_date,
					to_date : this.outstanding?.to_date,
					hub_id : this.outstanding?.hub_id,
					center_id :this.outstanding?.center_id,
					report_type : this.outstanding?.report_type,
					inv_type :this.outstanding?.inv_type,
					is_gst :this.outstanding?.is_gst
				}
				if (this.outstanding) {
					payload.advance_filter = advance_filter
				} else {
					payload.is_active = this.isTerminated;
				}
				this.outstanding ? this.getViewOutstandingList(payload).then((data) => {
					if (data?.list?.total === 0) {
						this.agGridViewOutstanding.api.showNoRowsOverlay();
					} else {
						this.agGridViewOutstanding.api.hideOverlay();
					}
					params.successCallback(data?.list?.data, data?.list?.total);
					var allColumnIds = [];
					if (this.agGridViewOutstanding && this.agGridViewOutstanding.columnApi && data?.total) {
						this.agGridViewOutstanding.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				})
				: this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	ToggleTerminatedData = (formData) => {
		return Axios.patch("organization/location/revoke/" + formData.id, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	refreshListViewOutstanding = () => {
        if (this.agGridViewOutstanding) {
            this.setupViewOutstandingGrid(this.agGridViewOutstanding);
        }
    }

	setViewOutstandingValues = () => {
        return new Promise((resolve) => {
            this.outstanding = null;
            if (this.agGridViewOutstanding && null) {
                this.setupViewOutstandingGrid(this.agGridViewOutstanding);
            }
            resolve()
        });
    };
}

decorate(ManageTerminatedFranchiseeStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	statusValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setStatusValues: action,
	list_data_view_outstanding :observable,
    total_view_outstanding: observable,
	per_page_view_outstanding :observable,
    outstanding :observable ,
	setOutstanding : action,
	agGridViewOutstanding : observable,
	onFilterChangedViewOutstanding : action,
	setPageViewOutstandingSize :action,
	setupViewOutstandingGrid :action,
	setViewOutstandingValues : action
});
