import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { Button, Drawer, Col, Layout, Row, Tabs, Tooltip, Alert, Spin, InputNumber } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../../../store";
import { Constant } from "../../../../../config/Constant";
import { InfoCircleOutlined } from "@ant-design/icons";
import MoreInfoComponent from "./../../../organization/Center/component/MoreInfoComponent";
import { vsmNotify } from "../../../../../config/messages/common";
import { Messages } from "../../../../../config/messages/messages";
const { TabPane } = Tabs;

const RateCardCashViewComponent = observer((props) => {
	const {
		ManageRateCardCashStore: { viewValues, formField, errorMessage, addDiscountData },
		ManageCentersStore,
		AUTH: { checkPrivileges }
	} = useStore();
	const [productArray, setProductData] = useState()
	const [additionalProductArray, setAdditionalProductData] = useState()
	const [discountProductArray, setDiscountProductData] = useState()
	//For zone
	const [zoneProductArray, setZoneProductData] = useState()
	const [additionalZoneProductArray, setAdditionalZoneProductData] = useState()
	const [discountZoneProductArray, setDiscountZoneProductData] = useState()
	const [zoneRateExist, checkZoneRateExist] = useState(false);
	const [moreInfoDrawer, setMoreInfoDrawer] = useState(false);
	const [currentVer, setCurrentVer] = useState();
	const [loading, setLoading] = useState(false);

	const [saving, setSaving] = useState();
	const [newDiscount, setNewDiscount] = useState([]);

	// Handle submit and call function to save new record
	const handleSubmit = (data) => {
		setSaving(true);
		let formData = {};
		formData.rc_id = viewValues.id
		formData.discount = data;
		addDiscountData(formData)
			.then((data) => {
				close()
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {

			})
			.finally(() => setSaving(false));

	}

	useEffect(() => {
		if (viewValues && props.visible) {
			let productData = {};
			let additionalProductData = {};
			let discountProductData = {};
			let displayAdd = {}
			displayAdd[1] = viewValues.ar_dox_flag ? viewValues.ar_dox_flag : 0;
			displayAdd[2] = viewValues.ar_nds_flag ? viewValues.ar_nds_flag : 0;
			displayAdd[3] = viewValues.ar_nda_flag ? viewValues.ar_nda_flag : 0;
			displayAdd[4] = viewValues.ar_dex_flag ? viewValues.ar_dex_flag : 0;
			displayAdd[18] = viewValues.ar_ndex_flag ? viewValues.ar_ndex_flag : 0;

			setCurrentVer(viewValues.old_ver);
			viewValues.rate_card.products.map((item) => {
				let defaultValues = [];
				let defaultAdditionalValue = [];
				let defaultDiscountValue = [];
				item.weights.map((item) => {
					let single = {};
					single.weight = formField.weights.find((i) => i.id === item.weight).val;
					single.slab = formField.weights.find((i) => i.id === item.slab).val;
					item.rates.map((i) => {
						single[i.rate_type_id] = i.rate;
						return true;
					});
					defaultValues.push(single);
					return true;
				})
				defaultAdditionalValue.slab = formField.weights.find((i) => i.id === item.additional.slab).val;
				item.additional.rates.map((item) => {
					defaultAdditionalValue[item.rate_type_id] = item.rate;
					return true;
				});
				productData[item.id] = defaultValues;
				if (displayAdd && displayAdd[item.id] && displayAdd[item.id] === 1) {
					additionalProductData[item.id] = defaultAdditionalValue;
				}
				// additionalProductData[item.id] = defaultAdditionalValue;
				item.discount.rates.map((item) => {
					defaultDiscountValue[item.rate_type_id] = item.rate;
					return true;
				});
				discountProductData[item.id] = defaultDiscountValue;
				return true;

			})
			setProductData(productData);
			setAdditionalProductData(additionalProductData);
			setDiscountProductData(discountProductData);
			if (viewValues.zone_rate_card && viewValues.zone_rate_card.products && viewValues.zone_rate_card.products.length > 0) {
				checkZoneRateExist(true);
				let zoneProductData = {};
				let additionalZoneProductData = {};
				let discountZoneProductData = {};
				viewValues.zone_rate_card.products.map((item) => {
					let defaultZoneValues = [];
					let defaultAdditionalZoneValue = [];
					let defaultDiscountZoneValue = [];
					item.weights.map((item) => {
						let single = {};
						single.weight = formField.weights.find((i) => i.id === item.weight).val;
						single.slab = formField.weights.find((i) => i.id === item.slab).val;;
						item.rates.map((i) => {
							single[i.cz_id] = i.rate;
							return true;
						});
						defaultZoneValues.push(single);
						return true;
					})
					defaultAdditionalZoneValue.slab = formField.weights.find((i) => i.id === item.additional.slab).val;
					item.additional.rates.map((item) => {
						defaultAdditionalZoneValue[item.cz_id] = item.rate;
						return true;
					});
					zoneProductData[item.id] = defaultZoneValues;
					if (displayAdd && displayAdd[item.id] && displayAdd[item.id] === 1) {
						additionalZoneProductData[item.id] = defaultAdditionalZoneValue;
					}
					// additionalZoneProductData[item.id] = defaultAdditionalZoneValue;
					item.discount.rates.map((item) => {
						defaultDiscountZoneValue[item.cz_id] = item.rate;
						return true;
					});
					discountZoneProductData[item.id] = defaultDiscountZoneValue;
					return true;

				})
				setZoneProductData(zoneProductData);
				setAdditionalZoneProductData(additionalZoneProductData);
				setDiscountZoneProductData(discountZoneProductData);
			}
		}
	}, [props, viewValues, formField])
	// reset form and close add form
	const close = () => {
		setProductData();
		setAdditionalProductData();
		setDiscountProductData();
		setZoneProductData();
		setAdditionalZoneProductData();
		setDiscountZoneProductData();
		checkZoneRateExist(false);
		setCurrentVer();
		setLoading(false);
		setNewDiscount([]);
		setSaving();
		props.close();
	};
	const openMoreInfo = () => {
		setMoreInfoDrawer(true)
	}
	const closeMoreInfo = () => {
		setMoreInfoDrawer(false)
	}

	return (
		(viewValues && props.visible) && (
			<>
				<Drawer
					className="addModal"
					title={`Rate Card - Cash - View`}
					width="90%"
					visible={props.visible}
					closeIcon={<FontAwesomeIcon icon={faTimes} />}
					onClose={close}
					footer={[
						<Button
							key="2"
							htmlType="button"
							className="cancelBtn mr-35"
							onClick={close}
						>
							Cancel
						</Button>,
						(checkPrivileges("#1010087#") && <Button
							key="1"
							htmlType="button"
							className="cancelBtn mr-35"
							loading={saving}
							onClick={() => { handleSubmit(newDiscount) }}
						>
							Save Discount
						</Button>)
					]}
				>
					{errorMessage && props.visible && viewValues && <Alert className="mb-25"
						type="error"
						showIcon
						message={errorMessage}
					/>}
					{currentVer && <Layout className="details_table" >
						<Row gutter={6} wrap={false} className="align-items-center">
							<Col span={6} className="form__input__textbox">
								<label>Hub</label>
								<span>{(viewValues.hub_name) ? viewValues.hub_name : (ManageCentersStore.editValues?.mst_hub) ? ManageCentersStore.editValues.mst_hub.name : Constant.UNDEFINED_VALUES}</span>
							</Col>
							<Col span={6} className="form__input__textbox">
								<label>Location</label>
								<span>{(viewValues.location_name) ? viewValues.location_name : (ManageCentersStore.editValues?.name) ? ManageCentersStore.editValues.name : Constant.UNDEFINED_VALUES}</span>
							</Col>
							<Col span={6} className="form__input__textbox">
								<label>Franchisee</label>
								<span >{(viewValues.fr_owner_name) ? viewValues.fr_owner_name : (ManageCentersStore.editValues?.mst_franchisee) ? ManageCentersStore.editValues.mst_franchisee.owner_name : Constant.UNDEFINED_VALUES}</span>
							</Col>

							<Col span={2} className="form__input__textbox">
								<label>Version</label>
								<span>{(viewValues?.ver) ? (viewValues?.ver) : Constant.UNDEFINED_VALUES}</span>
								{/* <Select defaultValue={currentVer} onChange={(e) => {
									setLoading(true);
									let oldVersion = viewValues.ver;
									let data = viewValues;
									data.type = Constant.VIEW;
									data.ver = e;
									ViewData(data, oldVersion).then((data) => {
										ManageCentersStore.setEditValues(data.center);
										setLoading(false)
									});
								}}>
									{(viewValues && viewValues.ver) ? [...Array(viewValues.ver).keys()].reverse().map((item) => { return (<Select.Option key={item} value={item + 1}>{item + 1}</Select.Option>) }) : null}
								</Select> */}
							</Col>
							<Col xs={{ span: 4 }} className="moreInfoBtn text-right mt-20">
								<Button type="primary" onClick={openMoreInfo}>
									<InfoCircleOutlined /> More Info
								</Button>
							</Col>
						</Row>
					</Layout>
					}
					{loading ? <Spin size="large" className="mt-20 text-center" /> :
						<Layout className="details_table" >
							<Row gutter={6} wrap={false} style={{ borderTop: 0 }}>
								<Col span={24}>
									<Tabs defaultActiveKey="1" style={{ marginBottom: 32 }} className="custom-tabs custom-tabs-zone-view">{
										formField.products.map((val, index) => (
											<TabPane tab={val.name} key={val.id}>
												<div name={val.id}>
													{productArray && productArray[val.id] && productArray[val.id].length > 0 ? (
														<>
															<Row className="rate__types__label">
																<Col>
																	<Tooltip placement="top" title="Weight"><label>Weight</label></Tooltip>
																</Col>
																{/* <Col>
																	<Tooltip placement="top" title="Slab"><label>Slab</label></Tooltip>
																</Col> */}
																{formField.rate_types.map((item, i) => (
																	<Col>
																		<Tooltip placement="top" title={`${item.name}`}><label>{[`${item.name}`]}</label></Tooltip>
																	</Col>
																))}
															</Row>
															{productArray[val.id].map((product, index) => (
																<Row className="rate__types__label">
																	<Col>
																		{/* <label>Up to {product.weight} gms</label> */}
																		<label>Up to {(product.weight >= 1000 && Constant.PARCEL_PRODUCTS.includes(val.id)) ? `${(product.weight / 1000).toFixed(2)} kgs` : `${product.weight} gms`} </label>
																	</Col>
																	{/* <Col>
																		<label>{product.slab} gms</label>
																	</Col> */}
																	{formField.rate_types.map((item, i) => (
																		<Col>
																			<label>{product[`${item.id}`]}</label>
																		</Col>
																	))}

																</Row>
															))
															}
														</>
													) : <Row className="rate__types__label_last_nr"><Col span={24}>There is no rates added for this Product</Col></Row>
													}

													{additionalProductArray && additionalProductArray[val.id] && (
														<>
															<Col className="mt-15">
																<label>Additional Weight Slab</label>
															</Col>
															<Row className="rate__types__label">
																<Col>
																	{/* <label>{additionalProductArray[val.id].slab} gms</label> */}
																	<label>{(additionalProductArray[val.id].slab >= 1000 && Constant.PARCEL_PRODUCTS.includes(val.id)) ? `${(additionalProductArray[val.id].slab / 1000).toFixed(2)} kgs` : `${additionalProductArray[val.id].slab} gms`} </label>
																</Col>
																{formField.rate_types.map((item, i) => (
																	<>
																		<Col>
																			<label>
																				{additionalProductArray[val.id][`${item.id}`]}</label>
																		</Col>
																	</>
																))}


															</Row>
														</>
													)
													}
													{(checkPrivileges("#1010086#") || checkPrivileges("#1010087#")) && discountProductArray && discountProductArray[val.id] && (
														<>
															<Col className="mt-15">
																<label></label>
															</Col>
															<Row className="rate__types__label">
																<Col>
																	<label>Discount</label>
																</Col>
																{formField.rate_types.map((item, i) => (
																	<Col key={i}>
																		{checkPrivileges("#1010087#") ?
																			<InputNumber
																				step={0.01}
																				min={0.00}
																				max={100.00}
																				defaultValue={discountProductArray[val.id][item.id]}
																				onBlur={(e) => {
																					let existingDis = viewValues.rate_card.products[index].discount.rates[i];
																					let currentVal = e.target.value;
																					setNewDiscount(prevState => {
																						const index = prevState && prevState.length > 0 ? prevState.findIndex(i => i.id === existingDis.id) : -1;
																						if (index !== -1) {
																							const updatedItem = { ...prevState[index], rate: currentVal };
																							const newState = [...prevState];
																							newState[index] = updatedItem;
																							return newState;
																						} else {
																							existingDis.rate = currentVal;
																							return [...prevState, existingDis];
																						}
																					});
																				}}
																				stringMode
																				style={{ margin: "0 10px" }}
																			/> : <label>{discountProductArray[val.id][item.id]}</label>
																		}
																	</Col>
																))}
															</Row>
														</>
													)}
												</div>
											</TabPane>
										)
										)}
									</Tabs>
								</Col>
							</Row>
							{zoneRateExist &&
								<Row gutter={6} wrap={false}>
									<Col span={24}>
										<Tabs defaultActiveKey="1" style={{ marginBottom: 32 }} className="custom-tabs custom-tabs-zone custom-tabs-zone-view">{
											formField.products.map((val, index) => (
												<TabPane tab={val.name} key={val.id}>
													<div name={val.id}>
														{zoneProductArray && zoneProductArray[val.id] && zoneProductArray[val.id].length > 0 ? (
															<>
																<Row className="rate__types__label">
																	<Col>
																		<Tooltip placement="top" title="Weight"><label>Weight</label></Tooltip>
																	</Col>
																	{/* <Col>
																		<Tooltip placement="top" title="Slab"><label>Slab</label></Tooltip>
																	</Col> */}
																	{formField.zones.map((item, i) => (
																		<Col key={i} onClick={() => { props.openViewZoneModal(item.id, viewValues.id) }}>
																			<Tooltip placement="top" title={`${item.name}`}><label className="link">{[`${item.name}`]}</label></Tooltip>
																		</Col>
																	))}
																</Row>
																{
																	zoneProductArray[val.id].map((product, index) => (
																		<Row className="rate__types__label">
																			<Col>
																				{/* <label>Up to {product.weight} gms</label> */}
																				<label>Up to {(product.weight >= 1000 && Constant.PARCEL_PRODUCTS.includes(val.id)) ? `${(product.weight / 1000).toFixed(2)} kgs` : `${product.weight} gms`} </label>
																			</Col>
																			{/* <Col>
																				<label>{product.slab} gms</label>
																			</Col> */}
																			{formField.zones.map((item, i) => (
																				<Col>
																					<label>{product[`${item.id}`]}</label>
																				</Col>
																			))}

																		</Row>
																	))}
															</>
														) : <Row className="rate__types__label_last_nr"><Col span={24}>There is no rates added for this Product</Col></Row>
														}

														{additionalZoneProductArray && additionalZoneProductArray[val.id] && (
															<>
																<Col className="mt-15">
																	<label>Additional Weight Slab</label>
																</Col>
																<Row className="rate__types__label">
																	<Col>
																		{/* <label>{additionalZoneProductArray[val.id].slab} gms</label> */}
																		<label>{(additionalZoneProductArray[val.id].slab >= 1000 && Constant.PARCEL_PRODUCTS.includes(val.id)) ? `${(additionalZoneProductArray[val.id].slab / 1000).toFixed(2)} kgs` : `${additionalZoneProductArray[val.id].slab} gms`} </label>
																	</Col>
																	{formField.zones.map((item, i) => (
																		<>
																			<Col>
																				<label>
																					{additionalZoneProductArray[val.id][`${item.id}`]}
																				</label>
																			</Col>
																		</>
																	))}
																</Row>
															</>
														)}
														{(checkPrivileges("#1010086#") || checkPrivileges("#1010087#")) && discountZoneProductArray && discountZoneProductArray[val.id] && (
															<>
																<Col className="mt-15">
																	<label></label>
																</Col>
																<Row className="rate__types__label">
																	<Col>
																		<label>Discount</label>
																	</Col>
																	{formField.zones.map((item, i) => (
																		<Col key={i}>
																			{checkPrivileges("#1010087#") ?
																				<InputNumber
																					step={0.01}
																					min={0.00}
																					max={100.00}
																					defaultValue={discountZoneProductArray[val.id][item.id]}
																					onBlur={(e) => {
																						let existingDis = viewValues.zone_rate_card.products[index].discount.rates[i];
																						let currentVal = (e.target.value) ? Number(e.target.value).toFixed(2) : "0.00";
																						setNewDiscount(prevState => {
																							const index = prevState && prevState.length > 0 ? prevState.findIndex(i => i.id === existingDis.id) : -1;
																							if (index !== -1) {
																								const updatedItem = { ...prevState[index], rate: currentVal };
																								const newState = [...prevState];
																								newState[index] = updatedItem;
																								return newState;
																							} else {
																								existingDis.rate = currentVal;
																								return [...prevState, existingDis];
																							}
																						});
																					}}
																					stringMode
																					rules={[
																						{ required: true, message: Messages.REQUIRED, whitespace: true }
																					]}
																					style={{ margin: "0 10px" }}
																				/> : <label>{discountZoneProductArray[val.id][`${item.id}`]}</label>
																			}
																		</Col>
																	))}
																</Row>
															</>
														)}
													</div>
												</TabPane>
											))}
										</Tabs>
									</Col>
								</Row>
							}
						</Layout>
					}
				</Drawer>
				<MoreInfoComponent close={closeMoreInfo} visible={moreInfoDrawer} from="rateCardCash" />
			</>
		)
	);
});
export default RateCardCashViewComponent;
