import { createContext, useContext } from "react";
import AuthStore from "./AuthStore";
import ManageCountryStore from "../page/module/administration/Country/ManageCountryStore";
import ManageContinentsStore from "../page/module/administration/Continents/ManageContinentsStore";
import ManageStateStore from "../page/module/administration/State/ManageStateStore";
import ManageCityStore from "../page/module/administration/City/ManageCityStore";
import ManageBankStore from "../page/module/administration/Bank/ManageBankStore";
import ManageBankAccountStore from "../page/module/administration/BankAccounts/ManageBankAccountStore";
import ManagePinCodeStore from "../page/module/administration/PinCode/ManagePinCodeStore";
import ManageRoleStore from "../page/module/administration/ManageRole/ManageRoleStore";
import ManageUsersStore from "../page/module/administration/Users/ManageUsersStore";
import ManageUsersHubStore from "../page/module/administration/UsersHub/ManageUsersHubStore";
import ManageUsersClientStore from "../page/module/administration/UserClient/ManageUsersClientStore";
import CompanySettingStore from "../page/module/system/CompanySettings/CompanySettingsStore";
import ManageClientApiTrackingStore from "../page/module/system/Config/ClientApiTracking/ManageClientApiTrackingStore";
import ManageEmailStore from "../page/module/administration/Email/ManageEmailStore";
import ManageSMSStore from "../page/module/administration/ManageSMS/ManageSMSStore";
import ManageZonesStore from "../page/module/administration/Zones/ManageZonesStore";
import ManageLevelsStore from "../page/module/system/Levels/ManageLevelsStore";
import ManageFinancialYearsStore from "../page/module/system/FinancialYears/ManageFinancialYearsStore";
import ManageApiClientsStore from "../page/module/system/APIClients/ManageApiClientsStore"
import ManageDocumnetTypesStore from "../page/module/administration/DocumentTypes/ManageDocumentTypesStore";
import ManageDeliverylocationTagStore from "../page/module/administration/DeliveryLocationTag/ManageDeliveryLocationTagStore";
import ManagePincodeAreasStore from "../page/module/administration/PincodeAreas/ManagePincodeAreasStore";
import ManageDeliveryReturnResonStore from "../page/module/administration/DeliveryReturnReason/ManageDeliveryReturnResonStore";
import OwnerShipTypeStore from "../page/module/administration/OwnershipTypes/OwnerShipTypesStore";
import PartnerShipTypeStore from "../page/module/administration/PartnerShipTypes/PartnerShipTypesStore"
import ManageProductsStore from "../page/module/administration/ManageProducts/ManageProductsStore"
import BillingTypeStore from "../page/module/administration/BillingTypes/BillingTypesStore";
import UserSessionStore from "../page/module/system/UserSession/UserSessionsStore";
import ActivityLogStore from "../page/module/system/ActivityLog/ActivitylogStore";
import UserDeviceStore from "../page/module/administration/UserDevices/UserDeviceStore";
import ManagePincodeTagStore from "../page/module/administration/PincodeTags/ManagePincodeTagStore";
import ManageHeadOfficeStore from "../page/module/organization/HeadOffice/ManageHeadOfficeStore";
import ManageHubsStore from "../page/module/organization/Hubs/ManageHubsStore";
import ManageServiceAreaStore from "../page/module/business/ServiceArea/ManageServiceAreaStore";
import ManageActiveStatusStore from "./ActiveStore";
import ManageUsersFranchiseeStore from "../page/module/administration/UserFranchisee/ManageUsersFranchiseeStore";
import ManageUsersCenterStore from "../page/module/administration/UserCenter/ManageUsersCenterStore";
import ManageFrainchaiseeStore from "../page/module/organization/Frainchaisee/ManageFrainchaiseeStore";
import ManageTerminatedFranchiseeStore from "../page/module/organization/TerminatedFranchisee/ManageTerminatedFranchiseeStore";
import ManageCentersStore from "../page/module/organization/Center/ManageCenterStore";
import ManageRateCardStore from "../page/module/business/RateCard/ManageRateCardStore";
import ManageBankAccountHoStore from "../page/module/organization/BankAccountsHo/ManageBankAccountHoStore";
import ManageBankAccountLocationStore from "../page/module/organization/BankAccountsLocation/ManageBankAccountLocationStore";
import ManageBankAccountAliasStore from "../page/module/organization/BankAlias/ManageBankAccountAliasStore";
import AppReleasesStore from "../page/module/system/AppReleases/AppReleasesStore";
import ApplicationStore from "../page/module/system/Application/ApplicationStore";
import ManagePaymentModeStore from "../page/module/administration/PaymentMode/ManagePaymentModeStore";
import UserSettingStore from "../page/module/system/UserSetting/ManageProfileStore";
import ManagePartnersStore from "../page/module/organization/Partners/ManagePartnersStore";
import ManagePieceRateStore from "../page/module/business/PieceRate/ManagePieceRateStore";
import ManageCircularStore from "../page/module/communication/ManageCirculars/ManageCircularStore";
import ManageGreetingsStore from "../page/module/communication/ManageGreetings/ManageGreetingsStore";
import RateTypeStore from "../page/module/administration/RateTypes/RateTypeStore";
import ManageNewsStore from "../page/module/communication/ManageNews/ManageNewsStore";
import ManageNotesStore from "../page/module/communication/ManageNote/ManageNotesStore";
import ManageIndustrySegmentStore from "../page/module/administration/ManageIndustrySegment/ManageIndustrySegmentStore";
import ManageSlabRateStore from "../page/module/business/SlabRate/ManageSlabRateStore";
import ManagePopupMessagesStore from "../page/module/communication/ManagePopupMessages/ManagePopupMessagesStore";
import ManageNotificationStore from "../page/module/communication/ManageNotification/ManageNotificationStore";
import ManageDriversStore from "../page/module/administration/Drivers/ManageDriversStore";
import ManageVehiclesStore from "../page/module/administration/Vehicles/ManageVehiclesStore";
import CommonStore from "./CommonStore";
import ManageCentersGroupsStore from "../page/module/organization/LocationGroups/ManageLocationGroupsStore";
import ManageCashBookingPolicyStore from "../page/module/business/CashBookingPolicy/ManageCashBookingPolicyStore";
import ManageRateCardCashStore from "../page/module/business/RateCardCash/ManageRateCardCashStore";
import ManageFuelPoliciesStore from "../page/module/business/FuelPolicies/ManageFuelPoliciesStore";
import ManageAccountPartyQuotesStore from "../page/module/organization/AccountPartyQuotes/ManageAccountPartyQuotesStore";
import ManageAccountPartyStore from "../page/module/organization/AccountParty/ManageAccountPartyStore";
import ManageAccountPartyApprovalStore from "../page/module/organization/AccountPartyApproval/ManageAccountPartyApprovalStore";
import ManageCorporateQuotesStore from "../page/module/organization/CorporateQuotes/ManageCorporateQuotesStore";
import ManageCorporatesStore from "../page/module/organization/Corporates/ManageCorporatesStore";
import ManageRateCardAccountStore from "../page/module/business/RateCardAccount/ManageRateCardAccountStore";
import ManageBookingPolicyAccountStore from "../page/module/business/BookingPolicyAccount/ManageBookingPolicyAccountStore";
import ManageRateCardCorporatesStore from "../page/module/business/RateCardCorporates/ManageRateCardCorporatesStore";
import ManageBookingPolicyCorporateStore from "../page/module/business/BookingPolicyCorporate/ManageBookingPolicyCorporateStore";
import ManageStockStore from "../page/module/Stickers/Stock/ManageStockStore";
import ManageStockTransactionStore from "../page/module/Stickers/StockTransaction/ManageStockTransactionStore";
import ManageStickerRatesByHubStore from "../page/module/Stickers/StickerRatesByHub/ManageStickerRatesByHubStore";
import ManageStickerRatesByLocationStore from "../page/module/Stickers/StickerRatesByLocation/ManageStickerRatesByLocationStore";
import ManageAllocatedStickerStore from "../page/module/Stickers/AllocatedSticker/ManageAllocatedStickerStore";
import ManageStickerOrderStore from "../page/module/Stickers/StickerOrder/ManageStickerOrderStore";
import ManageTradersStore from "../page/module/organization/Traders/ManageTradersStore";
import ManageCashBookingSettingsStore from "../page/module/Booking/CashBookingSettings/ManageCashBookingSettingsStore"
import ManageDeliveryBoyStore from "../page/module/administration/DeliveryBoy/ManageDeliveryBoyStore"
import ManageCashBookingStore from "../page/module/Booking/CashBooking/ManageCashBookingStore"
import ManageExpressCoversOrderStore from "../page/module/Stickers/ExpressCoversOrder/ManageExpressCoverOrderStore";
import ManageBagStickerOrderStore from "../page/module/Stickers/BagStickerOrder/ManageBagStickerOrderStore";
import ManageScrollingMessagesStore from "../page/module/communication/ManageScrollingMessages/ManageScrollingMessagesStore";
import ManageExpressStickerOrderStore from "../page/module/Stickers/ExpressStickerOrder/ManageExpressStickerOrderStore";
import ManageAccountBookingStore from "../page/module/Booking/AccountBooking/ManageAccountBookingStore";
import ManageCoLoaderStore from "../page/module/administration/CoLoaders/ManageCoLoaderStore";
import ManagePacketEntryStore from "../page/module/Booking/PacketEntry/ManagePacketEntryStore";
import ManageBagEntryStore from "../page/module/Booking/BagEntry/ManageBagEntryStore";
import ManageDrsAreaStore from "../page/module/administration/DrsArea/ManageDrsAreaStore";
import ManageDrsWebStore from "../page/module/Booking/DrsWeb/ManageDrsWebStore";
import ManageOutwardEntryStore from "../page/module/Booking/OutwardEntry/ManageOutwardEntryStore";
import ManageInwardEntryStore from "../page/module/Booking/InwardEntry/ManageInwardEntryStore";
import ManageInscanClientBookingAPIStore from "../page/module/Booking/InscanClientBookingAPI/ManageInscanClientBookingAPIStore";
import ManageStickerVendorStore from "../page/module/administration/StickerVendors/ManageStickerVendorStore";
import ManageReturnToOriginEntryStore from "../page/module/Booking/ReturnToOriginEntry/ManageReturnToOriginEntryStore";
import ManageReturnToOriginStore from "../page/module/Booking/ManageReturnToOrigin/ManageReturnToOriginStore";
import ManageNonServiceableEntryStore from "../page/module/Booking/NonServiceableEntry/ManageNonServiceableEntryStore";
import ManageWrongSortingStore from "../page/module/Booking/WrongSorting/ManageWrongSortingStore";
import ManageWrongSortingForMeStore from "../page/module/Booking/WrongSortingForMe/ManageWrongSortingForMeStore";
import ManageLostShipmentStore from "../page/module/Booking/LostShipment/ManageLostShipmentStore";
import ManageLostShipmentForMeStore from "../page/module/Booking/LostShipmentForMe/ManageLostShipmentForMeStore";
import ManageTrackingStore from "../page/module/Tracking/ManageTrackingStore";
import WidgetStore from "./../../src/component/widget/WidgetStore";
import ManageStaffStore from "../page/module/administration/ManageStaff/ManageStaffStore";
import ManageStickerOrderPacketHubStore from "../page/module/Stickers/StickerOrderPacketHub/ManageStickerOrderPacketHubStore";
import ManageAuditWeightStore from "../page/module/Booking/AuditWeight/ManageAuditWeightStore";
import ManageTrainingVideosStore from "../page/module/Help/TrainingVideos/ManageTrainingVideosStore";
import ManageDownloadsStore from "../page/module/Help/Downloads/ManageDownloadsStore";
import ManageDraftRateCardStore from "../page/module/business/DraftRateCard/ManageDraftRateCardStore";
import ManageDepositsStore from "../page/module/Account/Deposits/ManageDepositsStore";
import ManageInscanForLocationStore from "../page/module/Booking/InscanForLocation/ManageInscanForLocationStore";
import ManageCourierVendorsStore from "../page/module/administration/CourierVendors/ManageCourierVendorsStore";
import ManageCopyAccountPartyQuotationStore from "../page/module/business/CopyAccountPartyQuotation/ManageCopyAccountPartyQuotationStore";
import BagTagStickerOrdersStore from "../page/module/Stickers/BagTagStickerOrders/BagTagStickerOrdersStore";
import StickerStore from "./StickerStore";
import ManageAccountEmailPoolStore from "../page/module/communication/ManageAccountEmailPool/ManageAccountEmailPoolStore";
import ManageContractStore from "../page/module/business/ManageContract/ManageContractStore"
import ManageDrsSettingsStore from "../page/module/Booking/DrsSettings/ManageDrsSettingsStore";
import ManagePiceRatePolicyStore from "../page/module/business/PieceRatePolicy/ManagePiceRatePolicyStore";
import ManageSlabRatePolicyStore from "../page/module/business/SlabRatePolicy/ManageSlabRatePolicyStore";
import ManageLocationsSearchStore from "../page/module/Utilities/LocationSearch/LocationSearchStore";
import ManageSatellitePointsStore from "../page/module/administration/SatellitePoints/ManageSatellitePointsStore";
import ManageForwardBookingStore from "../page/module/Booking/ForwardBooking/ManageForwardBookingStore";
import ManageTransferEntryStore from "../page/module/Booking/TransferEntry/ManageTransferEntryStore";
import ManageVirtualOrderStore from "../page/module/Stickers/VirtualOrder/ManageVirtualOrderStore";
import AccountBookingSendEmailStore from "../page/module/Utilities/AccountBookingSendEmail/AccountBookingSendEmailStore"
import ManageUpdateBookingRateStore from "../page/module/Booking/UpdateBookingRate/ManageUpdateBookingRateStore";
import BillingStore from "../page/module/MonthlyActivities/Billing/BillingStore";
import EstimationStore from "../page/module/MonthlyActivities/Estimation/EstimationStore";
import ManagePincodeSearch from "../page/module/Utilities/PincodeSearch/PincodeSearchStore";
import ManageClientGroupStore from "../page/module/organization/ClientGroup/ManageClientGroupStore";
import ManageCorporatesBillingStore from "../page/module/HOActivities/CorporatesBilling/CorporatesBillingStore";
import ManageCorporateInvoices from "../page/module/HOActivities/CorporatesInvoices/CorporatesInvoicesStore";
import ManageForwardingRatePolicyStore from "../page/module/business/ForwardingRatePolicy/ManageForwardingRatePolicyStore"
import ManageHOBillingStore from "../page/module/HOActivities/HOBilling/HOBillingStore";
import ManageHOBillingInvoices from "../page/module/HOActivities/HOInvoices/HOInvoicesStore";
import ManageBillInvoicesStore from "../page/module/MonthlyActivities/BillingInvoices/BillingInvoicesStore";
import ManageEstimationInvoicesStore from "../page/module/MonthlyActivities/EstimationInvoices/EstimationInvoicesStore";
import ManageWeightMachineStore from "../page/module/administration/WeightMachine/ManageWeightMachineStore";
import ManageClientPaymentsStore from "../page/module/MonthlyActivities/ClientPayments/ClientPaymentsStore";
import ManageConfigPendingDrsStore from "../page/module/Locks/ConfigPendingDRS/ManageConfigPendingDrsStore";
import ManageConfigPendingDrsByLocationStore from "../page/module/Locks/ConfigPendingDRSByLocation/ManageConfigPendingDrsByLocationStore";
import ManageConfigMissingConNoStore from "../page/module/Locks/ConfigMissingConNo/ManageConfigMissingConNoStore";
import ManageConfigZeroDrsStore from "../page/module/Locks/ConfigZeroDRS/ManageConfigZeroDrsStore";
import ManageConfigManualLockStore from "../page/module/Locks/ConfigManualLock/ManageConfigManualLockStore";
import ManageBookingByPincodeStore from "../page/module/Locks/BookingByPincode/ManageBookingByPincodeStore";
import ManageBookingLockStore from "../page/module/Locks/Bookings/ManageBookingLockStore";
import ManageTrackingLockStore from "../page/module/Locks/Tracking/ManageTrackingStore";
import ManageCorporatePaymentsStore from "../page/module/HOActivities/CorporatesPayments/CorporatePaymentsStore";
import ManageHOPaymentsStore from "../page/module/HOActivities/HOPayments/HOPaymentsStore";
import ManageHOSettlementStore from "../page/module/HOActivities/HOSettlement/HOSettlementStore";
import ManageReverseInvoicesStore from "../page/module/HOActivities/ReverseInvoices/ReverseInvoicesStore";
import ManageReversePaymentsStore from "../page/module/HOActivities/ReversePayments/ReversePaymentsStore";
import CircularStore from "../page/module/Utilities/Circulars/CircularStore"
import ManageHoEditForwardBookingStore from "../page/module/Booking/ForwardBookingEditHo/ManageHoEditForwardBookingStore";
import ManageICICIDataSyncStore from "../page/module/Booking/ICICIDataSync/ManageICICIDataSyncStore";
import ManageInternationalBookingStore from "../page/module/International/Booking/ManageInternationalBookingStore";
import ManageAccountPartySearchStore from "../page/module/Utilities/AccountPartySearch/MangeAccountPartySearchStore";
import ManagePendingBillsStore from "../page/module/MonthlyActivities/PendingBills/ManagePendingBillsStore";
import ManageCorporatesPendingBillsStore from "../page/module/HOActivities/CorporatesPendingBills/ManageCorporatesPendingBillsStore";
import ManageSoftwareChargeStore from "../page/module/HOActivities/SoftwareCharge/ManageSoftwareChargeStore";
import ManageBookingMessageStore from "../page/module/communication/ManageBookingMessage/ManageBookingMessageStore";
import ManageComplaintStore from "../page/module/Booking/ManagComplaints/ManageComplaintStore";
import ViewComplaintStore from "../page/module/Notifications/ViewComplaints/ViewComplaintStore";
import ManageBookingEditHoApproveStore from "../page/module/Booking/BookingEditHoApprove/ManageBookingEditHoApproveStore"
import ManageDisturbPincodesStore from "../page/module/administration/DisturbPincodes/ManageDisturbPincodesStore"
import ManageHubSearchStore from "../page/module/Utilities/HubSearch/HubSearchStore";
import LocationWalletStore from "../page/module/organization/LocationWallet/ManageLocationWalletStore"
import ManageEwayUpdateStore from "../page/module/Eway/EwayUpdate/ManageEwayUpdateStore";
import ManageStaffAttendanceStore from "../page/module/Booking/StaffAttendance/ManageStaffAttendanceStore";
import ManageProcessListStore from "../page/module/system/ProcessList/ManageProcessListStore";
import ManageSalaryProcessingStore from "../page/module/Booking/SalaryProcessing/ManageSalaryProcessingStore";
import GenerateAccountPartyLabelStore from "../page/module/MonthlyActivities/GenerateAccountPartyLabel/GenerateAccountPartyLabelStore";
import ManageAdvancedPaymentStore from "../page/module/organization/AdvancedPayment/ManageAdvancedPaymentStore";
import ManageClientMessageStore from "../page/module/communication/ManageClientMessage/ManageClientMessageStore";
import IncomingBagStore from "../page/module/Notifications/IncomingBag/IncomingBagStore";
import ReceivedBagStore from "../page/module/Notifications/ReceivedBag/ReceivedBagStore";
import ManageConfigMissingConNoByLocationStore from "../page/module/Locks/ConfigMissingConNoByLocation/ManageConfigMissingConNoByLocationStore";

import ManageAutoHOSettlementStore from "../page/module/HOActivities/AutoHOSettlement/AutoHOSettlementStore";
import NewLocationStore from "../page/module/Notifications/NewLocation/NewLocationStore";


/**
 * Report store import after this
 */
import ManageNewClientReportStore from "../page/module/Report/NewClientReport/ManageNewClientReportStore"
import ManageClientReportStore from "../page/module/Report/ClientReport/ManageClientReportStore"
import ManageLocationCreationInformationReportStore from "../page/module/Report/LocationCreationInformation/ManageLocationCreationInformationReportStore"
import ManageInscanReportStore from "../page/module/Report/DailyActivities/InscanReport/ManageInscanReportStore";
import ManageInsuranceStore from "../page/module/Report/DailyActivities/Insurance/ManageInsuranceStore"
import ManageBookingTypeStore from "../page/module/Report/DailyActivities/BookingType/ManageBookingTypeStore"
import ManageOutwardMenifestStore from "../page/module/Report/OutwardManifest/ManageOutwardManifestStore";
import ManageClientBookingStore from "../page/module/Report/ClientBooking/ManageClientBookingStore";
import ManageForwardingBookingStore from "../page/module/Report/ForwardingBooking/ManageForwardingBookingStore"
import ManageUnregisteredPacketStore from "../page/module/Report/DailyActivities/UnRegisteredPacket/ManageUnregisteredPacketStore";
import ManageUnregisteredBagStore from "../page/module/Report/DailyActivities/UnRegisteredBag/ManageUnregisteredBagsStore";
import ManageUnregisteredBookingsStore from "../page/module/Report/DailyActivities/UnregisteredBookings/ManageUnregisteredBookingsStore";
import ManageOutscanvsMissingInscanStore from "../page/module/Report/DailyActivities/OutscanvsMissingInscan/ManageOutscanvsMissingInscanStore";
import ManageDailyBookingsStore from "../page/module/Report/DailyActivities/DailyBookings/ManageDailyBookingsStore";
import ManageHubIncomingLoadStore from "../page/module/Report/DailyActivities/HubIncomingLoad/ManageHubIncomingLoadStore";
import ManageHubPendingLoadStore from "../page/module/Report/DailyActivities/HubPendingLoad/ManageHubPendingLoadStore"
import ManageLocationIncomingLoadStore from "../page/module/Report/DailyActivities/LocationIncomingLoad/ManageLocationIncomingLoadStore"
import ManageDrsPendingLoadStore from "../page/module/Report/DailyActivities/DrsPendingLoad/ManageDrsPendingLoadStore"
import ManageBookingPendingLoadStore from "../page/module/Report/DailyActivities/BookingPendingLoad/ManageBookingPendingLoadStore"
import ManageInscanAndOutscanActivitiesStore from "../page/module/Report/DailyActivities/InscanAndOutscanActivities/ManageInscanAndOutscanActivitiesStore";
import ManageFranchiseeLoginStore from "../page/module/Report/FranchiseeLogin/ManageFranchiseeLoginStore"
import ManageLocationLoginStore from "../page/module/Report/LocationLogin/ManageLocationLoginStore"
import ManageLocationServiceAreaDetailsStore from "../page/module/Report/LocationServicePincodeAreaDetails/ManageLocationServicePincodeAreaStore"
import ManageHubWiseInscanOutscanStore from "../page/module/Report/DailyActivities/HubWiseInscanOutscan/ManageHubWiseInscanOutscanStore"
import ManageLoctionCollectionStore from "../page/module/Report/LocationCollection/ManageLocationCollectionStore"
import ManageBookingDeliveryStore from "../page/module/Report/BookingDelivery/ManageBookingDeliveryStore";
import ManageOnDemandReportStore from "../page/module/Report/OnDemandReport/ManageOnDemandReportStore";
import ManageHoEditBookingStore from "../page/module/Booking/BookingEditHo/ManageHoEditBookingStore";
import ManageLoctionGSTStore from "../page/module/Report/LocationGST/ManageLocationGSTStore"
import ManageHOGSTStore from "../page/module/Report/HOGST/ManageHOGSTStore"
import ManageOutInScanReportStore from "../page/module/Report/OutInScanReport/ManageOutInScanReportStore";
import ManageEwayTransitStore from "../page/module/Booking/EwayTransit/ManageEwayTransitStore";
import ManageEwayFirstMileStore from "../page/module/Booking/EwayFirstMile/ManageEwayFirstMileStore";
import ManageInvoiceHoToFoStore from "../page/module/Report/Invoice/HoToFo/ManageInvoiceHoToFoStore";
import ManageInvoiceFoToHoReverseStore from "../page/module/Report/Invoice/FoToHoReverse/ManageInvoiceFoToHoReverse"
import ManageInvoiceCorporateStore from "../page/module/Report/Invoice/Corporate/ManageInvoiceCorporateStore";
import ManageInvoiceStickerStore from "../page/module/Report/Invoice/Sticker/ManageInvoiceStickerStore";
import ManageExpenseReportStore from "../page/module/Report/Expense/ManageExpenseReportStore";
import ManageExpenseSummaryReportStore from "../page/module/Report/ExpenseSummary/ManageExpenseSummaryReportStore";
import ManageExpenseLedgerStore from "../page/module/Report/ExpenseLedger/ManageExpenseLedgerStore";
import ManageInvoiceAccountPartyStore from "../page/module/Report/Invoice/AccountParty/ManageInvoiceAccountPartyStore";
import ManagePaymentStickerStore from "../page/module/Report/Payment/Sticker/ManagePaymentStickerStore";
import ManagePaymentAccountPartyStore from "../page/module/Report/Payment/AccountParty/ManagePaymentAccountPartyStore";
import ManagePaymentCorporateStore from "../page/module/Report/Payment/Corporate/ManagePaymentCorporateStore";
import ManagePaymentHoToFoStore from "../page/module/Report/Payment/HoToFo/ManagePaymentHoToFoStore";
import ManagePaymentFoToHoReverseStore from "../page/module/Report/Payment/FoToHoReverse/ManagePaymentHoToFoStore"
import ManageInvoicePaymentClientStore from "../page/module/Report/invoiceVsPayment/AccountParty/ManageInvoicePaymentClientStore";
import ManageInvoicePaymentCorporateStore from "../page/module/Report/invoiceVsPayment/Corporate/ManageInvoicePaymentCorporateStore";
import ManageStickerReportStore from "../page/module/Report/StickerReport/ManageStickerReportStore";
import ManageCashMobileReportStore from "../page/module/Report/DailyActivities/CashMobileReport/ManageCashMobileReportStore";
import ManageOutstandingHoToFoStore from "../page/module/Report/OutstandingReports/OutstandingHoToFo/ManageOutstandingHoToFoStore";
import ManageOutstandingFoToHoReverseStore from "../page/module/Report/OutstandingReports/OutstandingFoToHoReverse/ManageOutstandingFoToHoReverseStore"
import ManageOutstandingCorporateStore from "../page/module/Report/OutstandingReports/OutstandingCorporate/ManageOutstandingCorporateStore"
import ManageOutstandingAccountPartyStore from "../page/module/Report/OutstandingReports/OutstandingAccountParty/ManageOutstandingAccountPartyStore"
import ManageInvoicePaymentHoToFoStore from "../page/module/Report/invoiceVsPayment/HoToFo/ManageInvoicePaymentHoToFoStore";
import ManageDrsStatisticsReportStore from "../page/module/Report/DrsStatisticsReport/ManageDrsStatisticsReportStore";
import ManageIncomingSearchStore from "../page/module/Booking/IncomingSearch/ManageIncomingSearchStore";
import ManageOutgoingSearchStore from "../page/module/Booking/OutgoingSearch/ManageOutgoingSearchStore";
import ManageIncomingLoadWithEwayStore from "../page/module/Report/DailyActivities/IncomingLoadWithEway/ManageIncomingLoadWithEwayStore";
import ManageOutScanPendingStore from "../page/module/Booking/EwayOutscanPending/ManageOutScanPendingStore";
import ManageOverheadExceptionByLocationStore from "../page/module/business/OverheadExceptionByLocation/ManageOverheadExceptionByLocationStore";
import ManageOverheadExceptionByHubStore from "../page/module/business/OverheadExceptionByHub/ManageOverheadExceptionByHubStore";
import ManageTransportationChargeStore from "../page/module/International/TransportationCharge/ManageTransportationChargeStore";
import ManageFrainchaiseeEnquiryStore from "../page/module/organization/FrainchaiseeEnquiry/ManageFrainchaiseeEnquiryStore";
import ManageOutInScanStatisticsReportStore from "../page/module/Report/OutInScanReportStatistics/ManageOutInScanStatisticsReportStore";
import ManageLocationProfitRateStore  from "../page/module/International/LocationProfitRate/ManageLocationProfitRateStore"
import ManageCityWiseBookingStore from "../page/module/Report/DailyActivities/CityWiseBooking/ManageCityWiseBookingStore";
import ManageInternationalDailyBookingsStore from "../page/module/Report/DailyActivities/InternationalDailyBookings/ManageInternationalDailyBookingsStore"
import ManageInternationalBookingsInvoiceStore from "../page/module/Report/DailyActivities/InternationalBookingsInvoiceReport/ManageInternationalBookingsInvoiceReportStore"
import ManageWalletTransactionsReportStore from "../page/module/Report/DailyActivities/WalletTransactions/ManageWalletTransactionsReportStore"
import ManageInvoiceInternationalStore from "../page/module/Report/Invoice/International/ManageInvoiceInternationalStore"
import ManageInvoiceFoToHoCreditStore from "../page/module/Report/Invoice/FoToHoCredit/ManageInvoiceFoToHoCreditStore";
import ManageFranchiseeInformationStore from "../page/module/Report/FranchiseeInformation/ManageFranchiseeInformationStore";
import ManageLocationPerformanceByDeliveryReportStore from "../page/module/Report/LocationPerformanceByDeliveryReport/ManageLocationPerformanceByDeliveryReportStore";
import ManageTravelingVsCancelBookingStore from "../page/module/Report/TravelingVsCancelBooking/ManageTravelingVsCancelBookingStore";

/**
 * Expense store import after this
 */
import ManageExpenseHeadStore from "../page/module/Expense/ExpenseHead/ManageExpenseHeadStore";
import ManageTransChargeByLocationStore from "../page/module/International/TransportationChargeByLocation/ManageTransChargeByLocationStore";
import ManageExpenseStore from "../page/module/Expense/ManageExpense/ManageExpenseStore";

const AUTHStore = new AuthStore();
const ManagePincodeTagStoreObject = new ManagePincodeTagStore();
const ManagelevelStoreObject = new ManageLevelsStore()
const ManageCommonStoreObject = new CommonStore()

const AppContext = createContext({
	AUTH: AUTHStore,
	ManageActiveStatusStore: new ManageActiveStatusStore(),
	ManageCountryStore: new ManageCountryStore(),
	ManageContinentsStore: new ManageContinentsStore(),
	ManageStateStore: new ManageStateStore(),
	ManageCityStore: new ManageCityStore(),
	ManageBankStore: new ManageBankStore(),
	ManageBankAccountStore: new ManageBankAccountStore(),
	ManagePinCodeStore: new ManagePinCodeStore(ManagePincodeTagStoreObject),
	ManageRoleStore: new ManageRoleStore(),
	ManageUsersStore: new ManageUsersStore(),
	CompanySettingStore: new CompanySettingStore(),
	ManageClientApiTrackingStore: new ManageClientApiTrackingStore(),
	ManageEmailStore: new ManageEmailStore(),
	ManageSMSStore: new ManageSMSStore(),
	ManageZonesStore: new ManageZonesStore(),
	ManageFinancialYearsStore: new ManageFinancialYearsStore(),
	ManageApiClientsStore: new ManageApiClientsStore(),
	ManageLevelsStore: ManagelevelStoreObject,
	ManagePincodeAreasStore: new ManagePincodeAreasStore(),
	ManageDocumnetTypesStore: new ManageDocumnetTypesStore(),
	ManageDeliverylocationTagStore: new ManageDeliverylocationTagStore(),
	ManageDeliveryReturnResonStore: new ManageDeliveryReturnResonStore(),
	OwnerShipTypeStore: new OwnerShipTypeStore(),
	PartnerShipTypeStore: new PartnerShipTypeStore(),
	ManageProductsStore: new ManageProductsStore(),
	BillingTypeStore: new BillingTypeStore(),
	UserSessionStore: new UserSessionStore(),
	ActivityLogStore: new ActivityLogStore(),
	UserDeviceStore: new UserDeviceStore(),
	ManagePincodeTagStore: ManagePincodeTagStoreObject,
	ManageHeadOfficeStore: new ManageHeadOfficeStore(),
	ManageUsersHubStore: new ManageUsersHubStore(),
	ManageUsersClientStore: new ManageUsersClientStore(),
	ManageHubsStore: new ManageHubsStore(),
	ManageServiceAreaStore: new ManageServiceAreaStore(),
	ManageUsersFranchiseeStore: new ManageUsersFranchiseeStore(),
	ManageUsersCenterStore: new ManageUsersCenterStore(),
	ManageFrainchaiseeStore: new ManageFrainchaiseeStore(),
	ManageTerminatedFranchiseeStore: new ManageTerminatedFranchiseeStore(),
	ManageCentersStore: new ManageCentersStore(),
	ManageRateCardStore: new ManageRateCardStore(),
	ManageBankAccountHoStore: new ManageBankAccountHoStore(),
	ManageBankAccountLocationStore: new ManageBankAccountLocationStore(),
	ManageBankAccountAliasStore: new ManageBankAccountAliasStore(),
	AppReleasesStore: new AppReleasesStore(),
	ApplicationStore: new ApplicationStore(),
	ManagePaymentModeStore: new ManagePaymentModeStore(),
	UserSettingStore: new UserSettingStore(),
	ManagePartnersStore: new ManagePartnersStore(),
	ManagePieceRateStore: new ManagePieceRateStore(),
	ManageCircularStore: new ManageCircularStore(),
	ManageGreetingsStore: new ManageGreetingsStore(),
	RateTypeStore: new RateTypeStore(),
	ManageNewsStore: new ManageNewsStore(),
	ManageNotesStore: new ManageNotesStore(),
	ManageIndustrySegmentStore: new ManageIndustrySegmentStore(),
	ManageSlabRateStore: new ManageSlabRateStore(),
	ManagePopupMessagesStore: new ManagePopupMessagesStore(),
	ManageNotificationStore: new ManageNotificationStore(),
	ManageDriversStore: new ManageDriversStore(),
	ManageVehiclesStore: new ManageVehiclesStore(),
	CommonStore: ManageCommonStoreObject,
	ManageCashBookingPolicyStore: new ManageCashBookingPolicyStore(),
	ManageCentersGroupsStore: new ManageCentersGroupsStore(),
	ManageRateCardCashStore: new ManageRateCardCashStore(ManageCommonStoreObject),
	ManageFuelPoliciesStore: new ManageFuelPoliciesStore(),
	ManageAccountPartyQuotesStore: new ManageAccountPartyQuotesStore(),
	ManageAccountPartyStore: new ManageAccountPartyStore(),
	ManageAccountPartyApprovalStore: new ManageAccountPartyApprovalStore(),
	ManageCorporateQuotesStore: new ManageCorporateQuotesStore(),
	ManageCorporatesStore: new ManageCorporatesStore(),
	ManageRateCardAccountStore: new ManageRateCardAccountStore(ManageCommonStoreObject),
	ManageBookingPolicyAccountStore: new ManageBookingPolicyAccountStore(),
	ManageRateCardCorporatesStore: new ManageRateCardCorporatesStore(ManageCommonStoreObject),
	ManageBookingPolicyCorporateStore: new ManageBookingPolicyCorporateStore(),
	ManageStockStore: new ManageStockStore(),
	ManageStockTransactionStore: new ManageStockTransactionStore(),
	ManageStickerRatesByHubStore: new ManageStickerRatesByHubStore(),
	ManageStickerRatesByLocationStore: new ManageStickerRatesByLocationStore(),
	ManageAllocatedStickerStore: new ManageAllocatedStickerStore(),
	ManageStickerOrderStore: new ManageStickerOrderStore(),
	ManageTradersStore: new ManageTradersStore(),
	ManageCashBookingSettingsStore: new ManageCashBookingSettingsStore(),
	ManageDeliveryBoyStore: new ManageDeliveryBoyStore(),
	ManageCashBookingStore: new ManageCashBookingStore(),
	ManageExpressCoversOrderStore: new ManageExpressCoversOrderStore(),
	ManageBagStickerOrderStore: new ManageBagStickerOrderStore(),
	ManageScrollingMessagesStore: new ManageScrollingMessagesStore(),
	ManageExpressStickerOrderStore: new ManageExpressStickerOrderStore(),
	ManageAccountBookingStore: new ManageAccountBookingStore(),
	ManageCoLoaderStore: new ManageCoLoaderStore(),
	ManagePacketEntryStore: new ManagePacketEntryStore(ManagelevelStoreObject),
	ManageBagEntryStore: new ManageBagEntryStore(),
	ManageDrsAreaStore: new ManageDrsAreaStore(),
	ManageDrsWebStore: new ManageDrsWebStore(),
	ManageOutwardEntryStore: new ManageOutwardEntryStore(ManageCommonStoreObject),
	ManageInwardEntryStore: new ManageInwardEntryStore(),
	ManageInscanClientBookingAPIStore: new ManageInscanClientBookingAPIStore(),
	ManageStickerVendorStore: new ManageStickerVendorStore(),
	ManageReturnToOriginEntryStore: new ManageReturnToOriginEntryStore(),
	ManageReturnToOriginStore: new ManageReturnToOriginStore(),
	ManageNonServiceableEntryStore: new ManageNonServiceableEntryStore(),
	ManageWrongSortingStore: new ManageWrongSortingStore(),
	ManageWrongSortingForMeStore: new ManageWrongSortingForMeStore(),
	ManageLostShipmentStore: new ManageLostShipmentStore(),
	ManageLostShipmentForMeStore: new ManageLostShipmentForMeStore(),
	ManageTrackingStore: new ManageTrackingStore(),
	WidgetStore: new WidgetStore(),
	ManageStaffStore: new ManageStaffStore(),
	ManageUnregisteredPacketStore: new ManageUnregisteredPacketStore(),
	ManageUnregisteredBagStore: new ManageUnregisteredBagStore(),
	ManageUnregisteredBookingsStore: new ManageUnregisteredBookingsStore(),
	ManageOutscanvsMissingInscanStore: new ManageOutscanvsMissingInscanStore(),
	ManageDailyBookingsStore: new ManageDailyBookingsStore(),
	ManageHubIncomingLoadStore: new ManageHubIncomingLoadStore(),
	ManageHubPendingLoadStore: new ManageHubPendingLoadStore(),
	ManageLocationIncomingLoadStore: new ManageLocationIncomingLoadStore(),
	ManageDrsPendingLoadStore: new ManageDrsPendingLoadStore(),
	ManageBookingPendingLoadStore: new ManageBookingPendingLoadStore(),
	ManageStickerOrderPacketHubStore: new ManageStickerOrderPacketHubStore(),
	ManageInscanAndOutscanActivitiesStore: new ManageInscanAndOutscanActivitiesStore(),
	ManageAuditWeightStore: new ManageAuditWeightStore(),
	ManageTrainingVideosStore: new ManageTrainingVideosStore(),
	ManageDownloadsStore: new ManageDownloadsStore(),
	ManageDraftRateCardStore: new ManageDraftRateCardStore(ManageCommonStoreObject),
	ManageFranchiseeLoginStore: new ManageFranchiseeLoginStore(),
	ManageLocationLoginStore: new ManageLocationLoginStore(),
	ManageLocationServiceAreaDetailsStore: new ManageLocationServiceAreaDetailsStore(),
	ManageDepositsStore: new ManageDepositsStore(),
	ManageInscanForLocationStore: new ManageInscanForLocationStore(),
	ManageHubWiseInscanOutscanStore: new ManageHubWiseInscanOutscanStore(),
	ManageCourierVendorsStore: new ManageCourierVendorsStore(),
	ManageSatellitePointsStore: new ManageSatellitePointsStore(),
	ManageCopyAccountPartyQuotationStore: new ManageCopyAccountPartyQuotationStore(),
	BagTagStickerOrdersStore: new BagTagStickerOrdersStore(),
	StickerStore: new StickerStore(),
	ManageAccountEmailPoolStore: new ManageAccountEmailPoolStore(),
	ManageContractStore: new ManageContractStore(ManageCommonStoreObject),
	ManageDrsSettingsStore: new ManageDrsSettingsStore(),
	ManagePiceRatePolicyStore: new ManagePiceRatePolicyStore(),
	ManageSlabRatePolicyStore: new ManageSlabRatePolicyStore(),
	ManageLocationsSearchStore: new ManageLocationsSearchStore(),
	ManageForwardBookingStore: new ManageForwardBookingStore(),
	ManageLoctionCollectionStore: new ManageLoctionCollectionStore(),
	ManageTransferEntryStore: new ManageTransferEntryStore(),
	ManageVirtualOrderStore: new ManageVirtualOrderStore(),
	AccountBookingSendEmailStore: new AccountBookingSendEmailStore(),
	ManageBookingDeliveryStore: new ManageBookingDeliveryStore(),
	ManageOnDemandReportStore: new ManageOnDemandReportStore(),
	BillingStore: new BillingStore(),
	ManageUpdateBookingRateStore: new ManageUpdateBookingRateStore(),
	ManageForwardingBookingStore: new ManageForwardingBookingStore(),
	ManageEstimationStore: new EstimationStore(),
	ManagePincodeSearch: new ManagePincodeSearch(),
	ManageClientGroupStore: new ManageClientGroupStore(),
	ManageClientBookingStore: new ManageClientBookingStore(),
	ManageCorporatesBillingStore: new ManageCorporatesBillingStore(),
	ManageCorporateInvoices: new ManageCorporateInvoices(),
	ManageForwardingRatePolicyStore: new ManageForwardingRatePolicyStore(),
	HOBillingStore: new ManageHOBillingStore(),
	ManageHOBillingInvoices: new ManageHOBillingInvoices(),
	ManageHoEditBookingStore: new ManageHoEditBookingStore(),
	ManageBillInvoicesStore: new ManageBillInvoicesStore(),
	ManageEstimationInvoicesStore: new ManageEstimationInvoicesStore(),
	ManageOutwardMenifestStore: new ManageOutwardMenifestStore(),
	ManageBookingTypeStore: new ManageBookingTypeStore(),
	ManageInsuranceStore: new ManageInsuranceStore(),
	ManageWeightMachineStore: new ManageWeightMachineStore(),
	ManageLoctionGSTStore: new ManageLoctionGSTStore(),
	ManageHOGSTStore: new ManageHOGSTStore(),
	ManageOutInScanReportStore: new ManageOutInScanReportStore(),
	ManageClientPaymentsStore: new ManageClientPaymentsStore(),
	ManageConfigMissingConNoStore: new ManageConfigMissingConNoStore(),
	ManageConfigManualLockStore: new ManageConfigManualLockStore(),
	ManageBookingByPincodeStore: new ManageBookingByPincodeStore(),
	ManageConfigPendingDrsStore: new ManageConfigPendingDrsStore(),
	ManageConfigPendingDrsByLocationStore: new ManageConfigPendingDrsByLocationStore(),
	ManageBookingLockStore: new ManageBookingLockStore(),
	ManageConfigZeroDrsStore: new ManageConfigZeroDrsStore(),
	ManageTrackingLockStore: new ManageTrackingLockStore(),
	ManageCorporatePaymentsStore: new ManageCorporatePaymentsStore(),
	ManageInscanReportStore: new ManageInscanReportStore(),
	ManageHOPaymentsStore: new ManageHOPaymentsStore(),
	ManageHOSettlementStore: new ManageHOSettlementStore(),
	ManageEwayTransitStore: new ManageEwayTransitStore(),
	ManageEwayFirstMileStore: new ManageEwayFirstMileStore(),
	ManageReverseInvoicesStore: new ManageReverseInvoicesStore(),
	ManageReversePaymentsStore: new ManageReversePaymentsStore(),
	ManageLocationCreationInformationReportStore: new ManageLocationCreationInformationReportStore(),
	ManageClientReportStore: new ManageClientReportStore(),
	ManageInvoiceHoToFoStore: new ManageInvoiceHoToFoStore(),
	ManageInvoiceFoToHoReverseStore: new ManageInvoiceFoToHoReverseStore(),
	ManageInvoiceCorporateStore: new ManageInvoiceCorporateStore(),
	ManageInvoiceStickerStore: new ManageInvoiceStickerStore(),
	ManageExpenseReportStore: new ManageExpenseReportStore(),
	ManageExpenseSummaryReportStore: new ManageExpenseSummaryReportStore(),
	ManageExpenseLedgerStore: new ManageExpenseLedgerStore(),
	ManageInvoiceAccountPartyStore: new ManageInvoiceAccountPartyStore(),
	ManagePaymentStickerStore: new ManagePaymentStickerStore(),
	ManagePaymentAccountPartyStore: new ManagePaymentAccountPartyStore(),
	ManagePaymentCorporateStore: new ManagePaymentCorporateStore(),
	CircularStore: new CircularStore(),
	ManageNewClientReportStore: new ManageNewClientReportStore(),
	ManagePaymentHoToFoStore: new ManagePaymentHoToFoStore(),
	ManagePaymentFoToHoReverseStore: new ManagePaymentFoToHoReverseStore(),
	ManageHoEditForwardBookingStore: new ManageHoEditForwardBookingStore(),
	ManageInvoicePaymentClientStore: new ManageInvoicePaymentClientStore(),
	ManageInvoicePaymentCorporateStore: new ManageInvoicePaymentCorporateStore(),
	ManageStickerReportStore: new ManageStickerReportStore(),
	ManageCashMobileReportStore: new ManageCashMobileReportStore(),
	ManageOutstandingHoToFoStore: new ManageOutstandingHoToFoStore(),
	ManageOutstandingFoToHoReverseStore: new ManageOutstandingFoToHoReverseStore(),
	ManageOutstandingCorporateStore: new ManageOutstandingCorporateStore(),
	ManageOutstandingAccountPartyStore: new ManageOutstandingAccountPartyStore(),
	ManageInvoicePaymentHoToFoStore: new ManageInvoicePaymentHoToFoStore(),
	ManageDrsStatisticsReportStore: new ManageDrsStatisticsReportStore(),
	ManageIncomingSearchStore: new ManageIncomingSearchStore(),
	ManageOutgoingSearchStore: new ManageOutgoingSearchStore(),
	ManageIncomingLoadWithEwayStore: new ManageIncomingLoadWithEwayStore(),
	ManageOutScanPendingStore: new ManageOutScanPendingStore(),
	ManageOverheadExceptionByLocationStore: new ManageOverheadExceptionByLocationStore(),
	ManageOverheadExceptionByHubStore: new ManageOverheadExceptionByHubStore(),
	ManageICICIDataSyncStore: new ManageICICIDataSyncStore(),
	ManageInternationalBookingStore:new ManageInternationalBookingStore(ManageCommonStoreObject),
	ManageTransportationChargeStore: new ManageTransportationChargeStore(),
	ManageFrainchaiseeEnquiryStore: new ManageFrainchaiseeEnquiryStore(),
	ManageOutInScanStatisticsReportStore: new ManageOutInScanStatisticsReportStore(),
	ManageAccountPartySearchStore: new ManageAccountPartySearchStore(),
	ManagePendingBillsStore:new ManagePendingBillsStore(),
	ManageCorporatesPendingBillsStore:new ManageCorporatesPendingBillsStore(),
	ManageSoftwareChargeStore:new ManageSoftwareChargeStore(),
	ManageLocationProfitRateStore:new ManageLocationProfitRateStore(),
	ManageCityWiseBookingStore : new ManageCityWiseBookingStore(),
	ManageInternationalDailyBookingsStore:new ManageInternationalDailyBookingsStore(),
	ManageInternationalBookingsInvoiceStore:new ManageInternationalBookingsInvoiceStore(),
	ManageBookingMessageStore:new ManageBookingMessageStore(),
	ManageExpenseHeadStore: new ManageExpenseHeadStore(),
	ManageTransChargeByLocationStore:new ManageTransChargeByLocationStore(),
	ManageExpenseStore : new ManageExpenseStore(),
	ManageComplaintStore:new ManageComplaintStore(),
	ViewComplaintStore:new ViewComplaintStore(),
	ManageBookingEditHoApproveStore:new ManageBookingEditHoApproveStore(),
	ManageDisturbPincodesStore:new ManageDisturbPincodesStore(),	
	ManageHubSearchStore : new ManageHubSearchStore(),
	LocationWalletStore:new LocationWalletStore(),
	ManageWalletTransactionsReportStore:new ManageWalletTransactionsReportStore(),
	ManageEwayUpdateStore : new ManageEwayUpdateStore(),
	ManageInvoiceInternationalStore:new ManageInvoiceInternationalStore(),
	ManageProcessListStore : new ManageProcessListStore(),
	ManageStaffAttendanceStore : new ManageStaffAttendanceStore(),
	ManageSalaryProcessingStore : new ManageSalaryProcessingStore(),
	GenerateAccountPartyLabelStore : new GenerateAccountPartyLabelStore(),
	AdvancedPaymentStore:new ManageAdvancedPaymentStore(),
	ManageClientMessageStore:new ManageClientMessageStore(),
	ManageInvoiceFoToHoCreditStore:new ManageInvoiceFoToHoCreditStore(),
	ManageFranchiseeInformationStore:new ManageFranchiseeInformationStore(),
	IncomingBagStore: new IncomingBagStore(),
	ReceivedBagStore: new ReceivedBagStore(),
	ManageConfigMissingConNoByLocationStore: new ManageConfigMissingConNoByLocationStore(),
	ManageLocationPerformanceByDeliveryReportStore: new ManageLocationPerformanceByDeliveryReportStore(),
	ManageAutoHOSettlementStore:new ManageAutoHOSettlementStore(),
	NewLocationStore: new NewLocationStore(),
	ManageTravelingVsCancelBookingStore : new ManageTravelingVsCancelBookingStore()
});

const useStore = () => useContext(AppContext);

export default useStore;
