import Axios from "axios";
import { decorate } from "mobx";
export default class UserSettingStore {

    CoverImageuploadData = (formdata) => {
        return Axios.post(
            `general/auth/change-avtar?user_id=` + formdata.user_id, formdata
        )
            .then(({ data }) => {
               
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // Call add api
    changeName = (formdata) => {
        return Axios.post(`general/auth/change-name`, formdata)
            .then(({ data }) => {
                if (this.agGrid) {
                    this.setupGrid(this.agGrid);
                }
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                if (data && data?.STATUS) {
                    const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // call api for verify otp
    verifyEmailOTP = (data) => {
        return Axios.post("general/auth/send-email-otp", data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                if (data && data?.STATUS) {
                    const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // call api for change user name
    changeUserName = (data) => {
        return Axios.post("general/auth/change-username", data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                if (data && data?.STATUS) {
                    const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // call api for change mobile
    ChangeMobile = (data) => {
        return Axios.post("general/auth/change-mobile", data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                if (data && data?.STATUS) {
                    const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // call api for verify otp MOBILE
    verifyMobileOTP = (data) => {
        return Axios.post("general/auth/send-mobile-otp", data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                if (data && data?.STATUS) {
                    const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // call api for change email
    ChangeEmail = (data) => {
        return Axios.post("general/auth/change-email", data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                if (data && data?.STATUS) {
                    const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    changePassword = (data) => {
        return Axios.post("general/auth/change-password", data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                if (data && data?.STATUS) {
                    const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

}
decorate(UserSettingStore, {

});
