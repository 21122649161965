import React from "react";
import { Button, Drawer } from "antd";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ViewOutstandingList from "./ViewOutstandingList";

const ViewOutstanding = observer((props) => {
  // // reset form and close add form
  const close = () => {
    props.close();
  };
  return (
    <Drawer
      className="addModal"
      title="View Outstanding"
      width="80%"
      visible={props.visible}
      closeIcon={<FontAwesomeIcon icon={faTimes} />}
      onClose={close}
      destroyOnClose={true}
      footer={[
        <Button
          key="2"
          htmlType="button"
          className="cancelBtn mr-35"
          onClick={close}
        >
          Cancel
        </Button>,
      ]}
    >
      <ViewOutstandingList />
    </Drawer>
  );
});

export default ViewOutstanding;
