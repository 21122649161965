import axios from "axios";
import { decorate,  action,observable } from "mobx";
import { convertBillingViewAddresscenterobj } from "../../../../utils/GlobalFunction";

export default class ManageAccountPartySearchStore {
    viewValues = null;

    // set form values to view
	setViewValues = (data) => {
            if (data) {
                data.address = convertBillingViewAddresscenterobj(data);
            }
		this.viewValues = data;
	};

    // call api to get records
    getAccountPartyData = (payload) => {
        return axios.post(`utilities/account-parties-search/list`, payload)
            .then(({ data }) => {
                return data.list;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                if (data && data?.STATUS) {
                    const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    getAccountPartyView = (ID) => {
        return axios.get(`utilities/account-parties-search/view/${ID}`).then(({ data }) => {
            this.setViewValues(data.view);
            return data.view;
        });
    };
}
decorate(ManageAccountPartySearchStore, {
    getAccountPartyData: action,
    getAccountPartyView: action,
    viewValues: observable,
})