import React from "react";
import { AgGridReact } from "@ag-grid-community/react";

const LocalAgGridWrapper = ({ customParentClass, groupIncludeFooter = false, groupIncludeTotalFooter = false, suppressAggFuncInHeader = false, ...rest }) => (
	<div className={`ag-theme-alpine grid_wrapper ${customParentClass}`}>
		<AgGridReact
			groupIncludeFooter={groupIncludeFooter}
			groupIncludeTotalFooter={groupIncludeTotalFooter}
			suppressAggFuncInHeader={suppressAggFuncInHeader}
			{...rest}
		/>
	</div>
);

export default LocalAgGridWrapper;
