import { faPencilAlt, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Button, Col, PageHeader, Row } from "antd";
import userPic from "../../../../images/user.png";
import { observer } from "mobx-react";
import useStore from "../../../../store";
import { useEffect, useState } from "react";
import CoverImageComponent from "./CoverImageComponent";
import ProfileChangePassword from "./ChangePasswordComponent";
import ChangeMobileComponent from "./ChangeMobileComponent";
import ChangeEmailComponent from "./ChangeEmailComponent";
import UserNameComponent from "./UserNameComponents";
import ChangeNameComponent from "./ChangeNameComponent";
//import { isActiveStatus } from "../../../../utils/GlobalFunction";
import { Constant } from "../../../../config/Constant";

const UserInfo = observer((props) => {
    const {
        AUTH: { avtarValues, user, setEditAvtarValues, checkPrivileges, setAvtarValues }
    } = useStore();

    const [userModal, setUserModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);
    const [changeNameModal, setChangeNameModal] = useState(false);
    const [changeEmailModal, setChangeEmailModal] = useState(false);
    const [changeMobileModal, setChangeMobileModal] = useState(false);
    const [coverImageModal, setCoverImageModal] = useState(false);
    const [roleData, setRoleData] = useState()


    useEffect(() => {
        setAvtarValues(user)
    })

    const openUserModal = (data) => {
        setEditAvtarValues(data)
        setUserModal(true);
    }
    const closeUserModal = () => setUserModal(false);

    const openChangeNameModal = (data) => {
        setEditAvtarValues(data)
        setChangeNameModal(true);
    }
    const closeChangeNameModal = () => setChangeNameModal(false);

    const openChangePasswordModal = (data) => {
        setEditAvtarValues(data)
        setChangePasswordModal(true);
    }
    const closeChangePasswordModal = () => setChangePasswordModal(false);

    const openChangeEmailModal = (data) => {
        setEditAvtarValues(data)
        setChangeEmailModal(true);
    }
    const closeChangeEmailModal = () => setChangeEmailModal(false);

    const openChangeMobileModal = (data) => {
        setEditAvtarValues(data)
        setChangeMobileModal(true);
    }
    const closeChangeMobileModal = () => setChangeMobileModal(false);

    // Open & Close  form for Cover Image
    const openCoverImageModal = (data) => {
        setEditAvtarValues(data)
        setCoverImageModal(true);
    };
    // const openViewModal = () => setViewModal(true);
    const closeCoverImageModal = () => setCoverImageModal(false);

    useEffect(() => {
        setRoleData(user.role)
    }, [user])

    let dataroles = roleData && roleData?.find((item) => item?.name);

    const hidePopover = () => {
        props.setVisible(false);
    };

    return (
        avtarValues && (
            <>
                <PageHeader
                    className="userInfo_Modal"
                >
                    <Row gutter={25} className="avtar_row align-center">
                        <Col span={12} className="form__input__textbox">
                            <Avatar src={avtarValues ? Constant.S3BUCKET_API_BASEURL + avtarValues.avatar : userPic} />
                            {checkPrivileges('#13002#') && (<Button
                                className="userInfo_Icon"
                                onClick={() => {
                                    openCoverImageModal(user);
                                    setCoverImageModal(true)
                                    hidePopover()
                                }}
                            >
                                <FontAwesomeIcon icon={faPencilAlt} />
                            </Button>)}
                        </Col>
                        <Col span={12} className="form__input__textbox text-left form__input__textbox_left">
                            <div className="form__input__textbox_textToText" style={{fontSize:"16px"}}>Role : <label>{dataroles?.name}</label></div>
                            <div className="form__input__textbox_textToText" ><label>Name : {user?.name}</label></div>
                            <div className="form__input__textbox_textToText"><label>Level : {avtarValues?.level?.name}</label></div>
                            {avtarValues.otp_on_login === 1 && <div className="form__input__textbox_textToText"><label>OTP on Login</label></div>}
                            {/* {avtarValues.is_restricted === 1 ? (
                                        <div className="isactive_tag">
                                            <div className="form__input__textbox_textToText"><label>Restricted Profile</label></div>
                                        </div>
                                    ) : (
                                        <div className="isactive_tag">
                                           <div className="form__input__textbox_textToText"><label>Not Restricted</label></div>
                                        </div>
                                    )} */}
                            
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12} className="form__input__textbox">
                            <label>Username</label>
                            <div className="form__input__textbox_inner">
                                <span>{avtarValues.username}</span>
                                {checkPrivileges('#13002#') && (<Button
                                    className="userInfo_Icon"
                                    onClick={() => {
                                        openUserModal(user);
                                        setUserModal(true)
                                        hidePopover()
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>)}
                            </div>
                        </Col>
                        <Col span={12} className="form__input__textbox">

                            <label>Password </label>
                            <div className="form__input__textbox_inner">
                                <span>*********</span>
                                {checkPrivileges('#13002#') && (<Button
                                    className="userInfo_Icon"
                                    onClick={() => {
                                        openChangePasswordModal(user);
                                        setChangePasswordModal(true)
                                        hidePopover()
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>)}
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={12} className="form__input__textbox">
                            <label>Name</label>
                            <div className="form__input__textbox_inner">
                                <span>{avtarValues.name}</span>
                                {checkPrivileges('#13002#') && (<Button
                                    className="userInfo_Icon"
                                    onClick={() => {
                                        openChangeNameModal(user);
                                        setChangeNameModal(true)
                                        hidePopover()
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>)}
                            </div>
                        </Col>
                        <Col span={12} className="form__input__textbox">
                            <label>Email </label>
                            <div className="form__input__textbox_inner">
                                <span>{avtarValues.email}</span>
                                {checkPrivileges('#13002#') && (<Button
                                    className="userInfo_Icon"
                                    onClick={() => {
                                        openChangeEmailModal(user);
                                        setChangeEmailModal(true)
                                        hidePopover()
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>)}
                            </div>
                        </Col>
                    </Row>
                    {/* <Row gutter={20}>
                        <Col span={12} className="form__input__textbox">
                            <label>Role</label>
                            <div className="form__input__textbox_inner">
                                <span>{dataroles?.name}</span>
                            </div>
                        </Col>
                        <Col span={12} className="form__input__textbox">
                            <label>Level </label>
                            <div className="form__input__textbox_inner">
                                <span>{avtarValues?.level?.name}</span>
                            </div>
                        </Col>
                    </Row> */}
                    <Row gutter={20}>
                        <Col span={12} className="form__input__textbox">
                            <label>Phone</label>
                            <div className="form__input__textbox_inner">
                                <span>{avtarValues.mobile}</span>
                                {checkPrivileges('#13002#') && (<Button
                                    className="userInfo_Icon"
                                    onClick={() => {
                                        openChangeMobileModal(user);
                                        setChangeMobileModal(true)
                                        hidePopover()
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPencilAlt} />
                                </Button>)}
                            </div>
                        </Col>
                        {/* <Col span={12} className="form__input__textbox">
                            <label>OTP on Login </label>
                            <div className="form__input__textbox_inner">
                                <div className="ant-space-item">
                                    {avtarValues.otp_on_login === 1 ? (
                                        <div className="isactive_tag">
                                            {isActiveStatus[1]}{" "}
                                        </div>
                                    ) : (
                                        <div className="isactive_tag">
                                            {isActiveStatus[0]}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col> */}
                    </Row>
                    {/* <Row gutter={20}>
                        <Col span={24} className="form__input__textbox">
                            <label>Is_restricted?</label>
                            <div className="form__input__textbox_inner">
                                <div className="ant-space-item">
                                    {avtarValues.is_restricted === 1 ? (
                                        <div className="isactive_tag">
                                            {isActiveStatus[1]}{" "}
                                        </div>
                                    ) : (
                                        <div className="isactive_tag">
                                            {isActiveStatus[0]}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    </Row> */}
                    <CoverImageComponent visible={coverImageModal} close={closeCoverImageModal} />
                    <UserNameComponent visible={userModal} close={closeUserModal} />
                    <ProfileChangePassword visible={changePasswordModal} close={closeChangePasswordModal} />
                    <ChangeNameComponent visible={changeNameModal} close={closeChangeNameModal} />
                    <ChangeEmailComponent visible={changeEmailModal} close={closeChangeEmailModal} />
                    <ChangeMobileComponent visible={changeMobileModal} close={closeChangeMobileModal} />
                </PageHeader>
            </>
        )
    )
})
export default UserInfo;