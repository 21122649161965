import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Modal, Row, Spin } from "antd";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import InputComponent from "../../../../component/InputComponent";
import { vsmNotify } from "../../../../config/messages/common";
import useStore from "../../../../store";
import { DRSDocumentsPerPage } from "../../../../utils/GlobalFunction";
import { vsmDrsSettings } from "./Validation";
import debounce from "lodash/debounce";

const ManageDrsSettings = observer((props) => {
	const [form] = Form.useForm();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [docPrPage, setDocPrPage] = useState()
	const {
		ManageDrsSettingsStore: { UpdateDrsSettings },
		AUTH
	} = useStore();
	// Handle submit and call function to save new record
	const handleSubmit = (formData) => {
		setSaving(true);
		formData.center_id = AUTH.user.default_location;
		UpdateDrsSettings(formData)
			.then((data) => {
				AUTH.user.drs_docs_per_page = formData.drs_docs_per_page
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	useEffect(() => {
		if (AUTH.user && AUTH.user.drs_docs_per_page && props.visible) {
			form.setFieldsValue({
				drs_docs_per_page: AUTH.user.drs_docs_per_page
			})
		}
	},[AUTH, form, props]);

	 // check for valid form values then accordingly make save button disable / enable
	 const handleChange = debounce(() => {
        form
            .validateFields()
            .then((d) => {
                setDisabled(false);
            })
            .catch((d) => {
                setDisabled(true);
            });
    }, 500);

	const close = () => {
        props.close()
        form.resetFields();
		setDisabled(true)
    }

	return  props.visible && (
		<Modal
			className="addModal"
			centered
			title="DRS Settings"
			width={534}
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onCancel={close}
			cancelButtonProps={{ style: { display: "none" } }}
			okButtonProps={{ style: { display: "none" } }}
			footer={[
				<Button
					key="2"
					htmlType="button"
					className="cancelBtn mr-35"
					onClick={close}
				>
					Cancel
				</Button>,
				<Button
					key="1"
					disabled={disabled}
					form="DrsSettingsForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>,
			]}
		>
			<Form
				form={form}
				id="DrsSettingsForm"
				onFinish={handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<Row>
					<Col xs={{ span: 24 }}>
						<InputComponent
							type="select"
							required
							label="Select Documents Per Page"
							name="drs_docs_per_page"
							placeholder="Select Documents Per Page"
							onChange={(e) => {
								setDocPrPage(e)								
								handleChange(e);
							}}
							notFoundContent={
								docPrPage ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: DRSDocumentsPerPage,
								value_key: "id",
								text_key: "name",
							}}
							rules={vsmDrsSettings.validation.drs_docs_per_page}
						/>
					</Col>

				</Row>
			</Form>
		</Modal>
	);
});

export default ManageDrsSettings;