import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManageReturnToOriginStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	serviceFilterList = null;
	bookingCategories = null;
	confirmReceiptValues = null;
	isActiveFilterList = null;
	isReturnResFilterList = null;
	isServiceFilterList = null;
	isBookingFilter = null;

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// set form values to confirm receipt
	setConfirmRecValues = (data) => {
		this.confirmReceiptValues = data;
	};

	setServiceFilterList = (data) => {
		this.isServiceFilterList = data;
	}

	setBookingFilterList = (data) => {
		this.isBookingFilter = data;
	}

	setIsReturnResFilterList = (data) => {
		this.isReturnResFilterList = data;
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	// call api to get records
	getList = (payload) => {
		return Axios.post(`bookings/return-to-origin/for-me`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["is_received"]) {
			final_filter['is_received'].values = convertTextToID(final_filter['is_received'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["product_id"]) {
			final_filter['product_id'].values = convertTextToID(final_filter['product_id'], this.isServiceFilterList, 'name', 'id')
		}
		if (final_filter["catg_id"]) {
			final_filter['catg_id'].values = convertTextToID(final_filter['catg_id'], this.isBookingFilter, 'name', 'id')
		}
		if (final_filter['drr_id']) {
			final_filter['drr_id'].values = convertTextToID(final_filter['drr_id'], this.isReturnResFilterList, 'name', 'id')
		}
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	//call api to confirm receipt
	ConfirmReceiptData = (ID) => {
		return Axios.patch(`/bookings/return-to-origin/confirm-receipt/${ID}`)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(ManageReturnToOriginStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	setServiceFilterList: action,
	confirmReceiptValues: observable,
	setConfirmRecValues: action,
	ConfirmReceiptData: action,
	isActiveFilterList: observable,
	setIsActiveFilterList: action,
	isReturnResFilterList: observable,
	setIsReturnResFilterList: action,
	isBookingFilter: observable,
	isServiceFilterList: observable,
	setBookingFilterList: action
});
