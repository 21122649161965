import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { Constant } from "../../../../config/Constant";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID, convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";

export default class ManageAccountPartyQuotesStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	rateCardAccEditValues = null;
	viewValues = null;
	deleteValues = null;
	statusValues = null;
	customNoteValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_account_party_quotes_list = null;
	loValues = null;
	kycFormDetails = null;
	defaultValue = null;
	rcId = null;
	typeMode = null;
	bankInfoValues = null;
	contactInfoValues = null;
	deleteContactInfoValues = null;
	isActiveFilterList = null;
	contactNoteFilterList = null;
	isArchived = 0;
	isRateFilterList = null;
	bookingPolicyFilterList = null;
	rateCardAccViewValues = null;
	formField = null;
	errorMessage = null;
	zoneFormField = null;
	editZoneRateValues = null;
	viewZoneValues = null;
	editZoneValues = null;
	deleteZoneValues = null;

	setAddMoreValues = (data) => {
		this.formField = data;
	}

	setZoneAddMoreValues = (data) => {
		this.zoneFormField = data;
	}

	setZoneRateValues = (data) => {
		this.editZoneRateValues = data;
	}

	setIsArchived = (is_archived) => {
		this.isArchived = is_archived
	}

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	setRateCardAccEditValues = (data) => {
		this.rateCardAccEditValues = data;
	}

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};

	setRateCardAccViewValues = (data) => {
		this.rateCardAccViewValues = data
	}

	setErrorMessage = (message) => {
		this.errorMessage = message;
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}
	setRateCardFilterList = (data) => {
		this.isRateFilterList = data;
	}
	setContactNoteFilterList = (data) => {
		this.contactNoteFilterList = data;
	}
	setBookingPolicyFilterList = (data) => {
		this.bookingPolicyFilterList = data;
	}
	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}
	setRcId = (id) => {
		this.rcId = id;
	}

	// set form Step2 values to view
	setLegalOperationsValues = (data) => {
		this.loValues = data;
	};

	// set form Step4 values to view
	setBankInfoValues = (data) => {
		this.bankInfoValues = data;
	};

	// set form Step5 values to view
	setContactInfoValues = (data) => {
		this.contactInfoValues = data;
	};

	// set form values to delete
	setDeleteContactInfoValues = (data) => {
		this.deleteContactInfoValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// set form values to custom note
	setCustomNoteValues = (data) => {
		this.customNoteValues = data;
	};

	setZoneDeleteValues = (data) => {
		this.deleteZoneValues = data;
	};	

	// set form values to view
	setZoneEditValues = (data) => {
		this.editZoneValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};
	

	// set form values to view
	setZoneViewValues = (data) => {
		if (data && data.pincode) {
			this.setListData(data.pincode);
		}
		this.viewZoneValues = data;
	};

	// Setup grid and set column size to autosize
	setupPincodeGrid = (params) => {
		this.agGridPincode = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGridPincode) {
			this.agGridPincode.api.paginationSetPageSize(parseInt(page));
		}
	};

	setListData = (data) => {
		if (this.agGridPincode) {
			var filter = this.agGridPincode.api.getFilterModel();
			var sort = this.agGridPincode.api.getSortModel();
		}
		this.list_data_pincode = data ? data : null;
		this.total_pincode = data.length;
		if (this.agGridPincode) {
			this.agGridPincode.api.setFilterModel(filter);
			this.agGridPincode.api.setSortModel(sort);
		}
	}


	// call api to get records
	getList = (payload) => {
		return Axios.post(`organization/account-party-quotes/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					//     item.cities = item.cities && item.cities.name
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};


	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["rc_id"]) {
			final_filter['rc_id'].values = convertTextToID(final_filter['rc_id'], this.isRateFilterList, 'name', 'id')
		}
		if (final_filter["cn_flag"]) {
			final_filter['cn_flag'].values = convertTextToID(final_filter['cn_flag'], this.contactNoteFilterList, 'name', 'id')
		}
		if (final_filter["ab_flag"]) {
			final_filter['ab_flag'].values = convertTextToID(final_filter['ab_flag'], this.bookingPolicyFilterList, 'name', 'id')
		}
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					is_archived: this.isArchived,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};


	// Call add api
	AddData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	// Call edit api
	BookingPolicyEditData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/booking-policy-save/` + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call add api
	RateCardAccAddData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/rate-card-new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	RateCardAccEditData = (formData) => {
		return Axios.post(`organization/account-party-quotes/rate-card-edit/` + formData.id, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	RateCardAccZoneEditData = (formData) => {
		return Axios.post(`organization/account-party-quotes/rate-card-zone-edit/` + formData.id, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	getPreRequestData = () => {
		return Axios.get(`business/rate-cards/pre-create`).then(({ data }) => {
			this.setRateCardAccViewValues(data.view)
			this.setAddMoreValues(data.view);
			return data;
		});
	};
	RateCardAccViewData = (ID) => {
		return Axios.get(`organization/account-party-quotes/rate-card-details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view};
			}
			/**
			 * Before remove this function ask keval once.
			 */
			if(data?.pre_create){
				this.setAddMoreValues(data.pre_create);
			}
			this.setRateCardAccEditValues(setData);
			return data;
		});
	}

	RateCardAccZoneData = (ID) => {
		return Axios.get(`organization/account-party-quotes/rate-card-zone-details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			/**
			 * Before remove this function ask keval once.
			 */
			if(data?.pre_create){
				this.setZoneAddMoreValues(data.pre_create);
			}
			this.setZoneRateValues(setData);
			return data;
		});
	}

	// Call add api
	ValidateZoneData = (formdata) => {
		return Axios.post(`business/rate-card-cash/zone-validate`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	AddZoneData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/add-zone`, formdata)
			.then(({ data }) => {
				this.getList();
				this.RateCardAccZoneData(this.editZoneRateValues.id);
				let currentZoneField = this.zoneFormField;

				if (currentZoneField && currentZoneField.zones) {
					currentZoneField.zones.push({
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt,
						is_completed: data.zone.is_completed
					});
					this.setZoneAddMoreValues(currentZoneField);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	EditZoneData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/edit-zone/${formdata.id}`, formdata)
			.then(({ data }) => {
				this.getList();
				if (this.editZoneRateValues) {
					this.RateCardAccZoneData(this.editZoneRateValues.id);
				}
				let currentZoneField = this.zoneFormField;
				let viewZoneField = this.formField;

				if (currentZoneField && currentZoneField.zones) {
					var index = currentZoneField.zones.findIndex(zone => zone.id === formdata.id);
					currentZoneField.zones.splice(index, 1, {
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt
					});
					this.setZoneAddMoreValues(currentZoneField);
				}

				if (viewZoneField && viewZoneField.zones) {
					let index = viewZoneField.zones.findIndex(zone => zone.id === formdata.id);
					viewZoneField.zones.splice(index, 1, {
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt
					});
					this.setAddMoreValues(viewZoneField);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewZoneData = (id, rcq_id) => {
		return Axios.get(`organization/account-party-quotes/get-zone/${rcq_id}/${id}`).then(({ data }) => {
			this.setZoneViewValues(data.view);
			return data;
		});
	};
	// call api to get Details By Zone	
	getDetailsByZoneData = (data) => {
		return Axios.get(`organization/account-party-quotes/details-by-zone/${data.id}`).then(({ data }) => {
			this.setZoneAddMoreValues(data.pre_create);
			this.setZoneRateValues(data.view);
			this.validationErrorJson = data.error_messages;
			return data;
		});
	};		

	// Call delete api
	DeleteZoneData = (formdata) => {
		return Axios.delete(`organization/account-party-quotes/zone-rates-delete/${formdata.rcq_id}/${formdata.id}`)
			.then(({ data }) => {
				this.getList();
				if (this.editZoneRateValues) {
					this.getDetailsByZoneData(this.editZoneRateValues);
				}
				let currentZoneField = this.zoneFormField;
				let viewZoneField = this.formField;

				if (currentZoneField && currentZoneField.zones) {
					currentZoneField.zones = currentZoneField.zones.filter(zone => zone.id !== formdata.id);
					this.setZoneAddMoreValues(currentZoneField);
				}

				if (viewZoneField && viewZoneField.zones) {
					viewZoneField.zones = viewZoneField.zones.filter(zone => zone.id !== formdata.id);
					this.setAddMoreValues(viewZoneField);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`organization/account-party-quotes/details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setEditValues(setData);
			return data;
		});
	};

	BookingViewData = (ID, client_id) => {
		return Axios.get(`organization/account-party-quotes/booking-policy-details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setEditValues(setData);
			return data;
		});
	};


	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`organization/account-party-quotes/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		return Axios.post("organization/account-party-quotes/archive/" + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};


	RestoreData = (formdata) => {
		return Axios.patch("organization/account-party-quotes/restore/" + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	GenerateContractData = (formdata) => {
		return Axios.get("organization/account-party-quotes/generate-contract/" + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ToggleTerminatedData = (formdata) => {
		const api_link = formdata.is_terminated === 1 ? "revoke/" : "terminate/";
		return Axios.patch("organization/account-party-quotes/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	HoApproveData = (formdata) => {
		return Axios.post("organization/account-party-quotes/ho-approval/" + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	ApproveData = (formdata) => {
		return Axios.patch("organization/account-party-quotes/quote-approval/" + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	CoverImageuploadData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/upload-cover-image/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	MapLocationData = (formdata) => {
		return Axios.post(
			`organization/account-party-quotes/location/` + formdata.id,
			formdata
		)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get List of center for dropdown
	getAccountPatyQuotesList = (conditional = {}) => {
		return Axios.post(`organization/account-party-quotes/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_account_party_quotes_list = data.account_party_quotes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	getLegalOperationData = () => {
		let ID = this.editValues.id;
		return Axios.get(`organization/account-party-quotes/legal-operations-detail/${ID}`).then(({ data }) => {
			this.setLegalOperationsValues(data.view);
			return data;
		});
	};

	setLegalOperationData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/legal-operations-edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call add api
	AddCustomNotes = (formData) => {
		return Axios.post(`organization/account-party-quotes/add-custom-notes`, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getCustomNoteDetails = (clientId) => {
		return Axios.get(`organization/account-party-quotes/custom-notes-details/${clientId}`).then(({ data }) => {
			return data;
		});
	};

	//Get Default KYC Form 
	getKycForm = (conditional = {}) => {
		conditional.id = this.editValues.id;
		return Axios.post(`organization/account-party-quotes/kyc-details`, conditional).then(({ data }) => {
			this.kycFormDetails = data.doc_types;
			return data;
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	// Call delete api
	deleteKYCData = (formdata) => {
		return Axios.delete(`organization/account-party-quotes/kyc-destroy/${formdata.doc_id}/${formdata.cr_id}`)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//finish Kyc
	finishKycData = (cr_id) => {
		return Axios.post(`organization/account-party-quotes/kyc-finish`, { id: cr_id })
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//
	uploadKYCData = (formdata) => {
		return Axios.post(`organization/account-party-quotes/kyc-upload`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getBankInfoForm = () => {
		let ID = this.editValues.center_bank_acc.id;
		let center_id = this.editValues.id;
		return Axios.get(`organization/bank-accs-loc/detail/${ID}/${center_id}`).then(({ data }) => {
			this.setBankInfoValues(data.view);
			return data;
		});
	};

	setBankInfoData = (formdata) => {
		let apiUrl = "";
		if (this.bankInfoValues && this.bankInfoValues.id) {
			apiUrl = `/organization/bank-accs-loc/edit/${this.bankInfoValues.id}`
		} else {
			apiUrl = "/organization/bank-accs-loc/new"
		}
		return Axios.post(`${apiUrl}`, formdata)
			.then(({ data }) => {
				// let oldval = this.editValues;
				// let newval = {...oldval,...formdata};
				// this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getContactInfoForm = () => {
		let ID = this.editValues.id;
		return Axios.get(`organization/account-party-quotes/contact-info/details/${ID}`).then(({ data }) => {
			this.setContactInfoValues(data.contact_details);
			return data;
		});
	};

	AddContactData = (formdata, type) => {
		return Axios.post(`organization/account-party-quotes/contact-info/new`, formdata)
			.then(({ data }) => {
				if (type === Constant.ADD) {
					let currentList = this.contactInfoValues;
					formdata.id = data.id
					if (currentList) {
						currentList.push(formdata);
					}
					this.setContactInfoValues(currentList);
				}
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}
	DeleteContactInfoData = (formdata) => {
		return Axios.delete(`organization/account-party-quotes/contact-info/destroy/` + formdata.id)
			.then(({ data }) => {
				let currentList = this.contactInfoValues;
				if (currentList) {
					currentList = currentList.filter((item) => item.id !== formdata.id);
				}
				this.setContactInfoValues(currentList);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(ManageAccountPartyQuotesStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	statusValues: observable,
	customNoteValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	setCustomNoteValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_account_party_quotes_list: observable,
	loValues: observable,
	setLegalOperationsValues: action,
	kycFormDetails: observable,
	getKycForm: action,
	finishKycData: action,
	uploadKYCData: action,
	setDefaultValue: action,
	defaultValue: observable,
	setRcId: action,
	rcId: observable,
	typeMode: observable,
	setTypeMode: action,
	setBankInfoValues: action,
	bankInfoValues: observable,
	setContactInfoValues: action,
	contactInfoValues: observable,
	setDeleteContactInfoValues: action,
	deleteContactInfoValues: observable,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setIsArchived: action,
	setRateCardFilterList: action,
	setContactNoteFilterList: action,
	isRateFilterList: observable,
	contactNoteFilterList: observable,
	booingPolicyFilterList: observable,
	setBookingPolicyFilterList: action,
	rateCardAccEditValues: observable,
	setRateCardAccEditValues: action,
	setRateCardAccViewValues: action,
	formField: observable,
	errorMessage: observable,
	setAddMoreValues: action,
	getPreRequestData: action,
	zoneFormField: observable,
	editZoneRateValues: observable,
	AddZoneData: action,
	EditZoneData: action,
	DeleteZoneData: action,
	validationErrorJson: observable,
	setZoneEditValues: action,
	viewZoneValues: observable,
	editZoneValues: observable,
	deleteZoneValues: observable,
	setZoneDeleteValues: action,
});
