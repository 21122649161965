import { observer } from "mobx-react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Unauthorized from "../../../../commonpage/Unauthorized";
import RecordPerPage from "../../../../../component/RecordPerPage";
import ServerGridConfig from "../../../../../config/ServerGridConfig";
import ServerAgGridWrapper from "../../../../../component/ServerAgGridWrapper";
import { Constant } from "../../../../../config/Constant";
import { isActiveStatus } from "../../../../../utils/GlobalFunction";
import useStore from "../../../../../store";
import { vsmCommon } from "../../../../../config/messages/common";
import { PageHeader } from "antd";
import { useEffect } from "react";

const ViewOutstandingList = observer((props) => {
  const { AUTH, ManageTerminatedFranchiseeStore, ManageTerminatedFranchiseeStore:{setViewOutstandingValues , refreshListViewOutstanding}} = useStore();

  useEffect(() => {
	return () => {
		if (ManageTerminatedFranchiseeStore.agGridViewOutstanding) {
			ManageTerminatedFranchiseeStore.agGridViewOutstanding.api.destroy();
			ManageTerminatedFranchiseeStore.agGridViewOutstanding = null;
		}
		setViewOutstandingValues();
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
}, [refreshListViewOutstanding]);

  const gridOptions = {
    columnDefs: [
      {
        headerName: "# ID",
        field: "srno",
        sortable: false,
        filter: false,
        pinned: "left",
        minWidth: 85,
        width: 85,
      },
      {
        headerName: "Issue Date",
        field: "issue_date",
        filter: false,
        valueGetter: (params) =>
          params.data?.issue_date
            ? params.data.issue_date
            : Constant.UNDEFINED_VALUES,
      },
      {
        headerName: "Invoice No.",
        filter: false,
        field: "invoice_no",
        width: 200,
        minWidth: 200,
        valueGetter: (params) =>
          params.data?.invoice_no
            ? params.data?.invoice_no
            : Constant.UNDEFINED_VALUES,
      },
      {
        headerName: "Hub Name",
        filter: false,
        field: "hub_name",
        valueGetter: (params) =>
          params.data?.hub_name
            ? params.data.hub_name
            : Constant.UNDEFINED_VALUES,
      },
      {
        headerName: "Location/Group",
        filter: false,
        field: "location_group",
        valueGetter: (params) =>
          params.data?.location_group
            ? params.data.location_group
            : Constant.UNDEFINED_VALUES,
      },
      {
        headerName: "From Date",
        field: "from_date",
        filter: false,
        valueGetter: (params) =>
          params.data?.from_date
            ? params.data.from_date
            : Constant.UNDEFINED_VALUES,
      },
      {
        headerName: "To Date",
        field: "to_date",
        filter: false,
        valueGetter: (params) =>
          params.data?.to_date
            ? params.data.to_date
            : Constant.UNDEFINED_VALUES,
      },
      {
        headerName: "Is Performa?",
        field: "is_performa",
        filter: false,
        width: 150,
        minWidth: 150,
        cellRendererFramework: function (params) {
          return <p>{isActiveStatus[params?.data?.is_performa]}</p>;
        },
      },
      {
        headerName: "With GST?",
        field: "with_gst",
        filter: false,
        width: 150,
        minWidth: 150,
        cellRendererFramework: function (params) {
          return <p>{isActiveStatus[params?.data?.with_gst]}</p>;
        },
      },
      {
        headerName: "Net",
        field: "net",
        filter: false,
        valueGetter: (params) =>
          params.data?.net ? params.data.net : Constant.UNDEFINED_VALUES,
      },
      {
        headerName: "Payments",
        filter: false,
        field: "payment_received",
        valueGetter: (params) =>
          params.data?.payment_received
            ? params.data.payment_received
            : Constant.UNDEFINED_VALUES,
      },
      {
        headerName: "Outstanding",
        filter: false,
        pinned: "right",
        width: 150,
        minWidth: 150,
        field: "outstanding",
        valueGetter: (params) =>
          params.data?.outstanding
            ? params.data.outstanding
            : Constant.UNDEFINED_VALUES,
      },
    ],
  };

  return (
    <>
      {!AUTH.checkPrivileges("#1993404#") ? (
        <Unauthorized />
      ) : (
        <>
          <PageHeader className="tableAreaSec">
            <div
              className="listCountNew listCountNewTwo"
              style={{ justifyContent: "end" }}
            >
              <div>
                <RecordPerPage
                  key="1"
                  style={{ width: "150px" }}
                  defaultValue={
                    ManageTerminatedFranchiseeStore.per_page_view_outstanding +
                    " per page"
                  }
                  onChange={
                    ManageTerminatedFranchiseeStore.setPageViewOutstandingSize
                  }
                />
              </div>
            </div>
            <ServerAgGridWrapper
              rowHeight={ServerGridConfig.rowHeight}
              headerHeight={ServerGridConfig.headerHeight}
              modules={AllModules}
              columnDefs={gridOptions.columnDefs}
              defaultColDef={{
                ...ServerGridConfig.defaultColDef,
                floatingFilter: false,
              }}
              columnTypes={ServerGridConfig.columnTypes}
              overlayNoRowsTemplate={vsmCommon.noRecord}
              onGridReady={
                ManageTerminatedFranchiseeStore.setupViewOutstandingGrid
              }
              onFilterChanged={
                ManageTerminatedFranchiseeStore.onFilterChangedViewOutstanding
              }
              onSortChanged={
                ManageTerminatedFranchiseeStore.onFilterChangedViewOutstanding
              }
              gridOptions={ServerGridConfig.options}
            />
          </PageHeader>
        </>
      )}
    </>
  );
});

export default ViewOutstandingList;
