/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Form, Button, Modal, Col, Row, Divider, Space } from "antd";
import { vsmNotify } from "../../../../config/messages/common";
import useStore from "../../../../store";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import InputComponent from "../../../../component/InputComponent";
import { debounce } from "@syncfusion/ej2-base";
import OtpInput from "react-otp-input";
import { Constant } from "../../../../config/Constant";
import { useHistory } from "react-router-dom";
import { vsmProfile } from "./Validation";
import { lowercaseString, stringDecryption } from "../../../../utils/GlobalFunction";

const ChangeEmailComponent = observer((props) => {
    const [form] = Form.useForm();
    const {
        AUTH: { editAvtarValues, user, updateUser },
        UserSettingStore
    } = useStore();
    const [saving, setSaving] = useState();
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    //const [otp, setOtp] = useState("");
    const [sendButton, setSendDisable] = useState(false);
    const [resendDisable, setResendDisable] = useState(true);
    const history = useHistory();
    const [isActive, setIsActive] = useState(false);
    const [seconds, setSeconds] = useState(Constant.RESEND_OTP_TIMER);
    const [resendButton, setResendButton] = useState(false)
    const [button, setButton] = useState(true)

    useEffect(() => {
        if (props.visible && editAvtarValues) {
            if (!form.getFieldValue('email')) {
                form.setFieldsValue({
                    email: editAvtarValues?.email
                })
            }
        }
        let timer = null;
        if (isActive) {
            if (seconds > 0) {
                timer = setInterval(() => {
                    setSeconds((seconds) => seconds - 1);
                }, 1000);
                setSendDisable(true)
                setButton(true)
            } else {
                setSeconds(0)
                setResendButton(true)
                setSendDisable(false)
                setButton(false)
            }
        }
        return () => {
            clearInterval(timer);
        };
    }, [seconds, isActive, resendButton, editAvtarValues, props, form]);

    const handleResendOTP = () => {
        // setIsActive(true);
        setLoading(false);
        let user_id = user.id;
        const data = { email: form.getFieldValue("email"), password: form.getFieldValue("password") };
        data.user_id = user_id;
        UserSettingStore.verifyEmailOTP(data)
            .then((data) => {
                setSeconds(Constant.RESEND_OTP_TIMER);
                setIsActive(true);
                setLoading(false);
                setResendDisable(true);
                vsmNotify.success({
                    message: data.STATUS.NOTIFICATION[0],
                });
            })
            .catch((e) => {
                if (e.errors) {
                    form.setFields(e.errors);
                }
                setLoading(false);
            });

    };

    const emailText = (
        <ui>
            <li>
                <span>- You will received an OTP on {(editAvtarValues?.email) ? (editAvtarValues.email) : form.getFieldValue('email')}</span>
            </li>
        </ui>
    );

    // check for valid form values then accordingly make save button disable / enable
    const handleChange = debounce(() => {
        form
            .validateFields()
            .then((d) => {
                let currentOtp = form.getFieldValue('otp');
                if (!currentOtp && resendButton === false) {
                    setDisabled(true);
                    setButton(false);
                } else {
                    setDisabled(false);
                    setButton(false)
                }
            })
            .catch((d) => {
                setButton(true)
                setDisabled(true);
            });

    }, 500);

    // Handle submit and call function to save new record
    const handleSubmit = (data) => {
        setSaving(true);
        let user_id = user.id;
        data.user_id = user_id;
        let currentEmail = data.email
        UserSettingStore.ChangeEmail(data)
            .then((data) => {
                close();
                updateUser("email", currentEmail)
                vsmNotify.success({
                    message: data.STATUS.NOTIFICATION[0],
                });
            })
            .catch((e) => {
                if (e.errors) {
                    form.setFields(e.errors);
                }
            })
            .finally(() => setSaving(false));
    };

    // reset form and close add form
    const close = () => {
        props.close();
        form.resetFields();
        setIsActive(false);
        setButton(true);
        setSeconds(Constant.RESEND_OTP_TIMER);
        setDisabled(true);
    };

    return (editAvtarValues && props.visible) && (
        <Modal
            className="addModal change_profile"
            centered
            title="Change Email"
            width={534}
            visible={props.visible}
            closeIcon={<FontAwesomeIcon icon={faTimes} />}
            onCancel={close}
            cancelButtonProps={{ style: { display: "none" } }}
            okButtonProps={{ style: { display: "none" } }}
            footer={[
                <Button
                    key="2"
                    htmlType="button"
                    className="cancelBtn mr-35"
                    onClick={close}
                >
                    Cancel
                </Button>,
                <Button
                    key="1"
                    disabled={disabled}
                    form="changeEmailForm"
                    loading={saving}
                    htmlType="submit"
                    type="primary"
                >
                    Save
                </Button>,
            ]}
        >
            <Form
                form={form}
                id={"changeEmailForm"}
                onFinish={handleSubmit}
                labelCol={{ span: 24 }}
                onChange={handleChange}
            >
                <Row gutter={30}>
                    <Col xs={{ span: 24 }}>
                        <InputComponent
                            type="password"
                            required
                            label="Current Password"
                            placeholder="Current Password"
                            name="password"
                            onChange={handleChange}
                            rules={vsmProfile.validation.password}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} className="tooltipText">
                        <InputComponent
                            type="text"
                            required
                            label="New Email Id"
                            placeholder="New Email Id"
                            name="email"
                            onChange={(e) => {
								form.setFieldsValue({
									email: lowercaseString(e.target.value)
								})
                                handleChange()
							}}
                            tooltip={emailText}
                            rules={vsmProfile.validation.email}
                        />
                    </Col>
                    <Col xs={{ span: 24 }} className="tooltipText">
                        <InputComponent
                            type="text"
                            required
                            label="Confirm Email Id"
                            placeholder="Confirm Email Id"
                            name="confirm_email"
                            onChange={(e) => {
								form.setFieldsValue({
									confirm_email: lowercaseString(e.target.value)
								})
                                handleChange()
							}}
                            tooltip={emailText}
                            rules={vsmProfile.validation.confirm_email}
                        />
                    </Col>
                    <Col xs={{ span: 4 }}>
                        <Space
                            className="back_resendotp_link"
                            align="right"
                            split={<Divider type="vertical" />}
                        >
                            <div className="change_profile_otp_button">
                                <div>
                                    {seconds} Sec.
                                </div>
                                {resendButton ? <Button
                                    type="link"
                                    className="resend_otp_btn"
                                    disabled={button}
                                    onClick={() => { handleResendOTP() }}
                                >
                                    Resend OTP
                                </Button> : <Button
                                    type="link"
                                    className="resend_otp_btn"
                                    disabled={button}
                                    onClick={() => { handleResendOTP() }}
                                >
                                    Send OTP
                                </Button>}
                            </div>
                        </Space>
                    </Col>

                    {
                        isActive === true && (
                            <Form.Item label="OTP" name="otp" autoComplete="chrome-off" onChange={handleChange} className="change_profile_otp" required rules={vsmProfile.validation.otp} >
                                <OtpInput
                                    numInputs={6}
                                    containerStyle="otpInput"
                                    // value={otp}
                                    // onChange={(otp) => { setOtp(otp).then(() => handleChange()); }}
                                    onChange={handleChange}
                                    isInputNum
                                    autoComplete="chrome-off"
                                />

                            </Form.Item>
                        )

                    }
                </Row>
            </Form>
        </Modal >
    );
});

export default ChangeEmailComponent;
