import Axios from "axios";
import { action, decorate, observable } from "mobx";

export default class ManageConfigMissingConNoStore {
	list_data = [];
	dropdown_lock_list = null;

	// call api to get records
	getList = (payload) => {
		return Axios.post(`locks/missing-consignment/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				this.list_data = data.list ? data.list.data : null;
			}
			return data;
		});
	};

	//Get List of Locks for dropdown
	getLockList = (conditional = {}) => {
		return Axios.post(`locks/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_lock_list = data.lock;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call add api
	EditData = (formdata) => {
		return Axios.post(`locks/missing-consignment/update`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageConfigMissingConNoStore, {
	list_data: observable,
	dropdown_lock_list: observable,
	getList: action,
	getLockList: action
});