import Axios from "axios";
import { action, decorate, observable } from "mobx";
export default class StickerStore {
    stickerLotValues = null;
    bankDetailData = null

    setStickerLotData = (data) => {
        this.stickerLotValues = data;
    }

    setBankDetailData  = (data) => {
        this.bankDetailData = data;
    }
    // get sticker lots data
    GetStickerLots = (formdata) => {
        return Axios.post(`stickers/sticker-orders/sticker-lots`, formdata)
            .then(({ data }) => {
                this.setStickerLotData(data.view)
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            })
    }

    PrintStickerLots = (formdata) => {
        return Axios.post(`stickers/sticker-orders/print-lot`, formdata)
            .then(({ data }) => {
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            })
    }

    BankDetails = (center_id) => {
        return Axios.get(`stickers/sticker-orders/bank-details-location/${center_id}`)
            .then(({ data }) => {
                this.setBankDetailData(data.bankDetails);
                return data
            })
            .catch((response) => {
                return Promise.reject(response);
            })
    }

}
decorate(StickerStore, {
    GetStickerLots: action,
    PrintStickerLots: action,
    stickerLotValues: observable,
    setStickerLotData: action,
    BankDetails: action,
    setBankDetailData:action,
    bankDetailData: observable
})