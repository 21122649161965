import { Button, Drawer } from "antd";
import useStore from "../../../../store";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { vsmCommon } from "../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import ServerAgGridWrapper from "../../../../component/ServerAgGridWrapper";
import RecordPerPage from "../../../../component/RecordPerPage";
import { useEffect } from "react";

const Notification = (props) => {
    const {
        close, visible,
    } = props;
    const {
        ManageNotificationStore,
        AUTH: { setUnreadNotificationCount,unreadNotificationCount,notificationMenu,setNotificationMenu,activeNotificationMenu }
    } = useStore();

    useEffect(() => {
        if (props.visible) {
            const notificationIndex = notificationMenu.findIndex(notification => notification.id === activeNotificationMenu?.id);
            setUnreadNotificationCount(unreadNotificationCount - activeNotificationMenu?.count)
            if (notificationIndex!== -1) {
                const updatedNotifications = [...notificationMenu];
                updatedNotifications[notificationIndex] = {...updatedNotifications[notificationIndex], count: 0 };
                setNotificationMenu(updatedNotifications);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.visible])

    const getRowStyle = params => {
        if (params.data && params.data.is_read === 0) {
            return { background: '#6bcb79' };
        } else if (params.data && params.data.is_read === 1) {
            return { background: '#fdbebe' };
        }
    };

    const gridOptions = {
        columnDefs: [
            {
                headerName: "# ID",
                field: "srno",
                sortable: false,
                filter: false,
                pinned: "left",
                minWidth: 85,
                width: 85,
            },
            {
                headerName: "Message",
                field: "message",
                filter: false,
            },
        ],

    };

    return (
        <>
            <Drawer
                className="addModal"
                title={`Notifications`}
                width="60%"
                visible={visible}
                closeIcon={<FontAwesomeIcon icon={faTimes} />}
                onClose={close}
                destroyOnClose={true}
                footer={[
                    <Button
                        key="2"
                        htmlType="button"
                        className="cancelBtn mr-35"
                        onClick={close}
                    >
                        Cancel
                    </Button>,
                ]}
            >

                <div className="listCountNew drawerInList listCountNewTwo">
                    <div>
                        <RecordPerPage
                            key="1"
                            style={{ width: "150px" }}
                            defaultValue={ManageNotificationStore.per_page + " per page"}
                            onChange={ManageNotificationStore.setPageSize}
                        />
                    </div>
                </div>

                <ServerAgGridWrapper
                    rowHeight={ServerGridConfig.rowHeight}
                    headerHeight={ServerGridConfig.headerHeight}
                    modules={AllModules}
                    columnDefs={gridOptions.columnDefs}
                    defaultColDef={{ ...ServerGridConfig.defaultColDef, floatingFilter: false, }}
                    columnTypes={ServerGridConfig.columnTypes}
                    overlayNoRowsTemplate={vsmCommon.noRecord}
                    onGridReady={ManageNotificationStore.setupGrid}
                    onFilterChanged={ManageNotificationStore.onFilterChanged}
                    onSortChanged={ManageNotificationStore.onFilterChanged}
                    gridOptions={ServerGridConfig.options}
                    getRowStyle={getRowStyle}
                />
            </Drawer>
        </>
    )
}

export default Notification;   