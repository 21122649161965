/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID, convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";

export default class ManageCorporateQuotesStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	deleteValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_corporate_quotes_list = null;
	loValues = null;
	defaultValue = null;
	typeMode = null;
	bankInfoValues = null;
	contactInfoValues = null;
	deleteContactInfoValues = null;
	isActiveFilterList = null;
	isArchived = 0;
	contactNoteFilterList = null;
	isRateFilterList = null;
	bookingPolicyFilterList = null;
	formField = null;
	rateCardAccViewValues = null;

	setAddMoreValues = (data) => {
		this.formField = data;
	}


	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}
	setIsArchived = (is_archived) => {
		this.isArchived = is_archived
	}

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	setRateCardAccEditValues = (data) => {
		this.rateCardAccEditValues = data;
	}

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};

	setRateCardAccViewValues = (data) => {
		this.rateCardAccViewValues = data
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setRateCardFilterList = (data) => {
		this.isRateFilterList = data;
	}
	setContactNoteFilterList = (data) => {
		this.contactNoteFilterList = data;
	}
	setBookingPolicyFilterList = (data) => {
		this.bookingPolicyFilterList = data;
	}

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}

	// set form Step2 values to view
	setLegalOperationsValues = (data) => {
		this.loValues = data;
	};

	// set form Step4 values to view
	setBankInfoValues = (data) => {
		this.bankInfoValues = data;
	};

	// set form Step values to view
	setContactInfoValues = (data) => {
		this.contactInfoValues = data;
	};

	// set form values to delete
	setDeleteContactInfoValues = (data) => {
		this.deleteContactInfoValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`organization/corporate-quotes/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					//     item.cities = item.cities && item.cities.name
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["ab_flag"]) {
			final_filter['ab_flag'].values = convertTextToID(final_filter['ab_flag'], this.bookingPolicyFilterList, 'name', 'id')
		}
		if (final_filter["rc_id"]) {
			final_filter['rc_id'].values = convertTextToID(final_filter['rc_id'], this.isRateFilterList, 'name', 'id')
		}
		if (final_filter["cn_flag"]) {
			final_filter['cn_flag'].values = convertTextToID(final_filter['cn_flag'], this.contactNoteFilterList, 'name', 'id')
		}
		
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					is_archived: this.isArchived,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`organization/corporate-quotes/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`organization/corporate-quotes/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`organization/corporate-quotes/details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setEditValues(setData);
			return data;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`organization/corporate-quotes/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		return Axios.post("organization/corporate-quotes/archive/" + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	RestoreData = (formdata) => {
		return Axios.patch("organization/corporate-quotes/restore/" + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	GenerateContractData = (formdata) => {
		return Axios.get("organization/corporate-quotes/generate-contract/" + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ToggleTerminatedData = (formdata) => {
		const api_link = formdata.is_terminated === 1 ? "revoke/" : "terminate/";
		return Axios.patch("organization/account-party-quotes/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	HoApproveData = (formdata) => {
		return Axios.post("organization/corporate-quotes/ho-approval/" + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ApproveData = (formdata) => {
		return Axios.patch("organization/corporate-quotes/quote-approval/" + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of corporate quotes for dropdown
	getCorporateQuotesList = (conditional = {}) => {
		return Axios.post(`organization/corporate-quotes/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_corporate_quotes_list = data.account_party_quotes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	BookingPolicyEditData = (formdata) => {
		return Axios.post(`organization/corporate-quotes/booking-policy-edit/` + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call add api
	RateCardAccAddData = (formdata) => {
		return Axios.post(`organization/corporate-quotes/rate-card-new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	RateCardAccEditData = (formdata) => {
		return Axios.post(`organization/corporate-quotes/rate-card-edit/` + formdata.rc_id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	// call api to get records
	getPreRequestData = () => {
		return Axios.get(`business/rate-cards/pre-create`).then(({ data }) => {
			this.setRateCardAccViewValues(data.view)
			this.setAddMoreValues(data.view);
			return data;
		});
	};
	RateCardAccViewData = (ID) => {
		return Axios.get(`organization/corporate-quotes/rate-card-details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setRateCardAccEditValues(setData);
			this.setRateCardAccViewValues(data.pre_create);
			this.setAddMoreValues(data.pre_create);
			return data;
		});
	}
	BookingViewData = (ID, client_id) => {
		return Axios.get(`organization/corporate-quotes/booking-policy-details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setEditValues(setData);
			return data;
		});
	};
	getBankInfoForm = () => {
		let ID = this.editValues.center_bank_acc.id;
		let center_id = this.editValues.id;
		return Axios.get(`organization/bank-accs-loc/detail/${ID}/${center_id}`).then(({ data }) => {
			this.setBankInfoValues(data.view);
			return data;
		});
	};
}

decorate(ManageCorporateQuotesStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	statusValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_corporate_quotes_list: observable,
	loValues: observable,
	setDefaultValue: action,
	defaultValue: observable,
	typeMode: observable,
	setTypeMode: action,
	setContactInfoValues: action,
	contactInfoValues: observable,
	setDeleteContactInfoValues: action,
	deleteContactInfoValues: observable,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setCompletePendingFilterList: action,
	completePendingFilterList: observable,
	setIsArchived: action,
	setRateCardFilterList: action,
	setContactNoteFilterList: action,
	isRateFilterList: observable,
	contactNoteFilterList: observable,
	bookingPolicyFilterList: observable,
	setBookingPolicyFilterList: action,
	rateCardAccEditValues: observable,
	setRateCardAccEditValues: action,
	setRateCardAccViewValues: action,	
	setLegalOperationsValues: action,	
	
	booingPolicyFilterList: observable,
	
	formField: observable,
	errorMessage: observable,
	setAddMoreValues:action
});
