import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManagePinCodeStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	viewValues = null;
	statusValues = null;
	dropdown_pincodes_list = null;
	dropdown_clone_pincodes_list = null;
	total = 0;
	allColumnIds = [];
	pincodeFilterList = [];
	advance_filter = null;
	ManagePincodeTagStoreObject = null;

	constructor(ManagePincodeTagStoreObject) {
		this.ManagePincodeTagStoreObject = ManagePincodeTagStoreObject;
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};


	setDeliverytagsFilterList = (data) => {
		this.deliverytagsFilterList = data.deliverytags;
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	// set form values to  Advance filter
	setAdvanceFilterValues = (data = null) => {
		this.advance_filter = data;
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`admin/pincodes/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}

			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter['pincode_tag_id']) {
			final_filter['pincode_tag_id'].values = convertTextToID(final_filter['pincode_tag_id'], this.ManagePincodeTagStoreObject.dropdown_pincodetags_list, 'name', 'id')
		}

		if (final_filter['delivery_tag_id']) {
			final_filter['delivery_tag_id'].values = convertTextToID(final_filter['delivery_tag_id'], this.deliverytagsFilterList, 'name', 'id')
		}

		if (final_filter["is_active"]) {
			final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
		}

		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	//setAdvanceFilter
	setAdvanceFilter = () => {
		let setAdvanceFilter = {};
		if (this.advance_filter) {
			if ("cities" in this.advance_filter) {
				setAdvanceFilter.city_id = this.advance_filter.cities.id;
			}
			if ("state" in this.advance_filter) {
				setAdvanceFilter.state_id = this.advance_filter.state.id;
			}
			if ("delivery" in this.advance_filter) {
				setAdvanceFilter.delivery_tag_id = this.advance_filter.delivery.id;
			}
			if ("pincodetag" in this.advance_filter) {
				setAdvanceFilter.pincode_tag_id = this.advance_filter.pincodetag.id;
			}
			if ("is_active" in this.advance_filter) {
				setAdvanceFilter.is_active = this.advance_filter.is_active;
			}
			if ("pincode_tag_id" in this.advance_filter) {
				setAdvanceFilter.pincode_tag_id = this.advance_filter.pincode_tag_id;
			}
		}
		return setAdvanceFilter;
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var advance_filter = this.setAdvanceFilter();

				var payload = {
					advance_filter: advance_filter,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {

					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`admin/pincodes/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`admin/pincodes/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`admin/pincodes/details/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`admin/pincodes/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
		return Axios.patch("admin/pincodes/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of Pincode for dropdown
	getPincodeList = (conditional = {}, clone = false) => {
		return Axios.post(`admin/pincodes/lov`, conditional)
			.then(({ data }) => {
				// if (conditional && conditional?.purpose === 1) {
				// 	data.pincodes = data.pincodes.map((item) => {
				// 		item.pincode_city = `${(item.cities && item.cities.name) ? `${item.pincode} - ${item.cities.name}` : item.pincode}`;
				// 		return item;
				// 	})
				// }
				if (clone) {
					this.dropdown_clone_pincodes_list = data.pincodes;
				} else {
					this.dropdown_pincodes_list = data.pincodes;
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(ManagePinCodeStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	statusValues: observable,
	total: observable,
	allColumnIds: observable,
	dropdown_pincodes_list: observable,
	dropdown_clone_pincodes_list: observable,
	viewValues: observable,
	advance_filter: observable,
	deliverytagsFilterList: observable,
	isActiveFilterList: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	getList: action,
	getStatesList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeliverytagsFilterList: action,
	setIsActiveFilterList: action
});
