import Axios from "axios";
import { decorate } from "mobx";

export default class GenerateAccountPartyLabelStore {
  generateLabel = (data) => {
    return Axios.post(`/monthly-activities/account-party/generate-label`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };
}

decorate(GenerateAccountPartyLabelStore, {});
