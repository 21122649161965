/*global scanner*/
import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Button, Modal, Upload } from 'antd';
import FormItem from 'antd/lib/form/FormItem'
import Webcam from "react-webcam";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faFolder, faTimes } from "@fortawesome/free-solid-svg-icons";
let plateform = localStorage.getItem("PLATFORMOS");
if (plateform === "win32") {
	require('scanner-js/dist/scanner.js');
	require('scanner-js/dist/scanner.css');
}

const MultipleUploadComponent = observer((props) => {

	const [hasCamera, setHasCamera] = useState();
	const [cameraPermission, setCameraPermission] = useState();
	const [cameraModelVisible, setCameraModelVisible] = useState(false);

	const webcamRef = useRef(null);

	const capture = useCallback(async () => {
		const imageSrc = webcamRef.current.getScreenshot();
		const blob = await fetch(imageSrc).then((res) => res.blob());

		setCameraModelVisible(false);
		props.uploadCameraCapture(blob, props.id, props.identifier);
	},
		[webcamRef, props]
	);

	useEffect(() => {
		navigator.getWebcam = (navigator.getUserMedia || // use the proper vendor prefix
			navigator.webkitGetUserMedia ||
			navigator.mozGetUserMedia ||
			navigator.msGetUserMedia);
		if (navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia({ video: true })
				.then(function (stream) { setHasCamera(true); })
				.catch(function (e) { console.log(e.name + ": " + e.message); });
		}
		else {
			navigator.getWebcam({ video: true }, function () {
				setHasCamera(true);
			}, function () {
				setHasCamera(false);
			});
		}
		let permission = navigator.permissions.query({ name: "camera" });
		permission.then(data => {
			setCameraPermission(data.state);
		})
	}, [])

	const eventProps = {
		onChange: (info) => {
			const { response } = info.file;
			if (info.file.status !== 'uploading') {
			}
			if (info.file.status === 'done') {
			} else if (info.file.status === 'error') {
				console.log("object e  : ", response);
			}
		},
	};

	const onPreview = async (file) => {
		let src = file.url;
		if (file.fullimage) {
			src = file.fullimage;
		}
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		else {
			window.open(src, '_blank');
		}
		// if (src.includes(".pdf") || src.includes(".PDF")) {
		// 	window.open(src, '_blank');
		// } else {
		// 	const image = new Image();
		// 	image.src = src;
		// 	const imgWindow = window.open(src);
		// 	imgWindow.document.write(image.outerHTML);
		// }
	};
	const scanDocument = (async () => {
		scanner.scan(displayScanDocument, {
			use_asprise_dialog: true, // Whether to use Asprise Scanning Dialog
			show_scanner_ui: true, //
			output_settings: [
				{
					type: "return-base64",
					format: "jpg"
				}
			]
		});
	});
	const displayScanDocument = async (successful, mesg, response) => {
		if (!successful) {
			console.error("Failed: " + mesg);
			return;
		}

		if (successful && mesg != null && mesg.toLowerCase().indexOf("user cancel") >= 0) {
			console.info("User cancelled");
			return;
		}

		var scannedImages = scanner.getScannedImages(response, true, false); // returns an array of ScannedImage
		if (scannedImages.length > 0) {
			let fileName = `${props.identifier}.png`, mimeType = { type: "image/png" };
			// fileType = "png", 
			let blobData = scannedImages[0].src;
			console.log("Blob PDF", blobData);

			const blob = await fetch(blobData).then(function (res) { return res.arrayBuffer(); })
				.then(function (buf) {
					return new File([buf], fileName, mimeType);
				})

			// props.uploadCameraCapture(blob, props.id, fileType);
			props.uploadCameraCapture(blob, props.id, props.identifier);
		}
	};
	return (
		<>
			<FormItem label={props.name} required={props.is_mandatory} labelCol={{ span: 24 }} className="kycFileUpload">
				{hasCamera && cameraPermission !== "denied" &&
					<Button type="button" className="capture_images" disabled={(props.fileList && props.fileList[0] && props.fileList[0][props.identifier] && props.fileList[0][props.identifier].length >= props.max_uploads) ? true : false} icon={<FontAwesomeIcon icon={faCamera} />} onClick={() => {
						setCameraModelVisible(true);
					}}>{`Capture ${(props.max_uploads <= 1) ? `` : `(Max: ${props.max_uploads})`}`}</Button>
				}
				{plateform === "win32" &&
					<Button
						type="button"
						className="capture_images"
						disabled={(props.fileList && props.fileList[0] && props.fileList[0][props.identifier] && props.fileList[0][props.identifier].length >= props.max_uploads) ? true : false}
						icon={<FontAwesomeIcon icon={faCamera} />}
						onClick={() => {
							scanDocument();
						}}
					>{`Scanner ${props.max_uploads <= 1 ? `` : `(Max: ${props.max_uploads})`
						}`}</Button>
				}
				<Upload
					name="doc_file"
					// accept=".png,.jpeg,.jpg,.pdf"
					accept={!props.type ? ((props.allowType && props.allowType === "pdf") ? ".pdf" : ".png,.jpeg,.jpg") : ".pdf,.png,.jpeg,.jpg"}
					fileList={props.fileList && props.fileList[0] && props.fileList[0][props.identifier]}
					onRemove={(e) => { props.onRemoveImage(e, props.identifier) }}
					onPreview={onPreview}
					listType="picture"
					multiple={false}
					// showUploadList={true}
					showUploadList={ props?.hideRemoveIcon ? {showRemoveIcon: false} : true }
					customRequest={(e) => { props.customUpload(e, props.id, props.identifier) }}
					{...eventProps}
					className="upload-list-inline"
				>
					<Button className="upload_file" disabled={(props.fileList && props.fileList[0] && props.fileList[0][props.identifier] && props.fileList[0][props.identifier].length >= props.max_uploads) ? true : false} icon={<FontAwesomeIcon icon={faFolder} />}>{`Upload ${(props.max_uploads <= 1) ? `` : `(Max: ${props.max_uploads})`}`}</Button>
				</Upload>
			</FormItem>
			<Modal
				centered
				className="primaryModal"
				title="Capture Image"
				visible={cameraModelVisible}
				closeIcon={<FontAwesomeIcon icon={faTimes} />}
				onCancel={() => { setCameraModelVisible(false) }}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={"auto"}
				footer={[
					<Button
						key="2"
						htmlType="button"
						className="cancelBtn mr-35"
						onClick={() => { setCameraModelVisible(false) }}
					>
						Cancel
					</Button>,
					<Button
						key="1"
						htmlType="button"
						type="primary"
						onClick={capture}
					>
						Capture
					</Button>,
				]}
			>
				<Webcam
					audio={false}
					ref={webcamRef}
					screenshotFormat="image/png"
					videoConstraints={{
						facingMode: "user"
					}}

				/>
			</Modal>
		</>
	)
});

export default MultipleUploadComponent
