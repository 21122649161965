import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";

export default class ManageTrackingLockStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	viewModalValues = null;
	total = 0;
	allColumnIds = [];
	loValues = null;
	defaultValue = null;
	typeMode = null;
	isActiveFilterList = null;
	isTexFilterList = null;
	completePendingFilterList = null;
	isTerminated = 1;
	partyTypeFilterList = null;
	stock_list_data = null;
	isActiveFilterList = null;
	contract_id = null;
	isLockFilterList = null;
	isLockCashFilterList = null;

	setContractId = (data) => {
		this.contract_id = data
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setisTerminated = (is_terminated) => {
		this.isTerminated = is_terminated
		this.setupGrid(this.agGrid);
	}

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	// set form values to view
	setViewModalValues = (data) => {
		this.viewModalValues = data;
	};

	//set form values to terminate
	setTerminateValues = (data) => {
		this.terminateValues = data
	}

	setISLockFilterList = (data) => {
		this.isLockFilterList = data
	}

	setISLockCashFilterList = (data) => {
		this.isLockCashFilterList = data
	}

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	refreshgetAllocatedStickersList = () => {
		this.getAllocatedStickersList()
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`locks/tracking/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["is_locked"]) {
			final_filter["is_locked"].values = convertTextToID(final_filter['is_locked'], this.isLockFilterList, 'name', 'id')
		}
		if (final_filter["is_completed"]) {
			final_filter['is_completed'].values = convertTextToID(final_filter['is_completed'], this.isVerifyFilterList, 'name', 'id')
		}
		return { final_filter, final_sort };
	};


	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					is_active: this.isTerminated,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`locks/tracking/details/${ID}`).then(({ data }) => {
			let setData = data.view
			this.setViewModalValues(setData);
			return data;
		});
	};


	// Call Relaxation api
	RelaxationData = (formdata) => {
		return Axios.post(`locks/tracking/relaxation`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call Release api
	ReleaseData = (formdata) => {
		return Axios.post(`locks/tracking/release`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

}

decorate(ManageTrackingLockStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	viewValues: observable,
	setEditValues: action,
	setDefaultValue: action,
	defaultValue: observable,
	typeMode: observable,
	setTypeMode: action,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setisTerminated: action,
	setTerminateValues: action,
	terminateValues: observable,
	isTexFilterList: observable,
	setISTexFilterList: action,
	setISLockFilterList: action,
	isLockFilterList: observable,
	setISLockCashFilterList: action,
	isLockCashFilterList: observable,
	setViewModalValues: action,
	viewModalValues: observable
});
