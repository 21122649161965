import React, { useEffect, useState } from "react";
import { Form, Row, Col, Spin, Divider, Tooltip, Button, Switch } from "antd";
import { observer } from "mobx-react";
import InputComponent from "../../../../../component/InputComponent";
import debounce from "lodash/debounce";
import { vsmLocation } from "./../Validation";
import { getFirstRecordValue, isCreatLocation, lowercaseString, properCaseString, properUpperCaseString, upperCaseString } from "../../../../../utils/GlobalFunction";
import useStore from "../../../../../store";
import { Constant } from "../../../../../config/Constant";
import { InfoCircleOutlined } from "@ant-design/icons";
import MoreInfoComponent from "./MoreInfoComponent";

const GeneralFormComponent = observer((props) => {
	const {
		setDisabled = () => { },
		form,
		type,
		setFetchState,
		fetchState,
		setFetchCountry,
		fetchCountry,
		fetchCity,
		setFetchCity,
		fetchFrList,
		setFetchFrList,
		fetchHubsList,
		setFetchHubsList,
		fetchpincode,
		setFetchPincode,
		gstDisplay,
		setGstDisplay,
		labelData,
		setLabelData,
		setStdCode,
		stdCode
	} = props;

	const {
		ManageCountryStore: { getCountriesList, dropdown_country_list },
		ManageStateStore,
		ManageCityStore,
		//ManageFrainchaiseeStore: { getFrainchaiseeList, dropdown_fr_list },
		ManageFrainchaiseeStore,
		ManagePinCodeStore,
		ManageCountryStore,
		ManageHeadOfficeStore,
		ManageHubsStore,
		CommonStore,
		AUTH: { user }
	} = useStore();

	const isActive = type === Constant.ADD ? "1" : "100";

	const isViewPurpose = (type === Constant.VIEW) ? true : false;

	const [moreInfoModal, setMoreInfoModal] = useState(false);
	const [id, setId] = useState(1)
	const openMoreInfoModal = () => {
		setMoreInfoModal(true);
	}
	const closeMoreInfoModal = () => setMoreInfoModal(false);

	const panText = (
		<ui>
			<li>
				<span>- The first five characters are letters (in uppercase by default), followed by four numerals, and the last (tenth) character is a letter.</span>
			</li>
			<li>
				<span>- Exact length is 10 characters</span>
			</li>
			<li>
				<span>- It will allow alphanumeric values</span>
			</li>
		</ui>
	);

	const gstnoinfo = (
		<ul>
			<li>
				<span>
					Verify GST No. here -{" "}
					<a
						rel="noreferrer"
						href="https://services.gst.gov.in/services/searchtp"
						target="_blank"
					>
						{" "}
						https://services.gst.gov.in/services/searchtp
					</a>
				</span>
			</li>
			<li>
				<span>Exact length is 15 characters</span>
			</li>
			<li>
				<span>It will allow alphanumeric values</span>
			</li>
		</ul>
	);

	// check for valid form values then accordingly make save button disable / enable
	const handleChange = debounce(() => {
		form
			.validateFields()
			.then((d) => {
				setDisabled(false);
			})
			.catch((d) => {
				setDisabled(true);
			});
	}, 500);

	const handleCountryChange = () => {
		form.setFieldsValue({
			state_id: null,
		});
		form.setFieldsValue({
			pincode_id: null,
		});
		setFetchState(true);
		setFetchPincode(true);
		ManageStateStore.dropdown_state_list = null;
		ManagePinCodeStore.dropdown_pincodes_list = null;
	};
	const handleStateChange = () => {
		form.setFieldsValue({
			city_id: null,
		});
		form.setFieldsValue({
			pincode_id: null,
		});
		setFetchCity(true);
		setFetchPincode(true);
		ManageCityStore.dropdown_city_list = null;
		ManagePinCodeStore.dropdown_pincodes_list = null;
	};

	const handleCityChange = () => {
		form.setFieldsValue({
			pincode_id: null,
		});
		setFetchPincode(true);
		ManagePinCodeStore.dropdown_pincodes_list = null;
	}


	const text = (
		<ui>
			<li><span>- Allow A-Z, a-z, 0-9, Underscore, Dash and Dot</span></li>
		</ui>
	);

	useEffect(() => {
		let create_location = isCreatLocation[0]
		if (ManageCountryStore.dropdown_country_list) {
			let country_id = getFirstRecordValue(ManageCountryStore.dropdown_country_list, "id")
			form.setFieldsValue({
				country_id: country_id,
				is_gst_registered: 1,
				create_location: create_location.name
			});
		}
	}, [dropdown_country_list, form, ManageCountryStore, CommonStore]);

	return (
		<>
			<Form
				form={form}
				id={props.id}
				onFinish={props.handleSubmit}
				labelCol={{ span: 24 }}
				onChange={handleChange}
			>
				<Row gutter={30}>
					{type === Constant.ADD && (

						<Col xs={{ span: 6 }} id="parent_create_location">
							<InputComponent
								type="select"
								required={!isViewPurpose}
								readOnly={isViewPurpose}
								showSearch={!isViewPurpose}
								label="Create Location"
								name="create_location"
								getPopupContainer={() => document.getElementById('parent_create_location')}
								placeholder="Select Create Location"
								rules={(isViewPurpose) ? [] : vsmLocation.validation.country_id}
								onChange={(e) => {
									handleChange();
									setId(e)
									ManageFrainchaiseeStore.dropdown_latest_fr_list = null;
									ManageHubsStore.dropdown_latest_hub_list = null;
									ManageHeadOfficeStore.dropdown_ho_list = null;
									form.setFieldsValue({ 'fr_id': null, 'company': null, 'aadhar': null, 'pan': null, 'owner_name': null, hub_id: null, ho_id: null })
									setFetchFrList(true)
								}}
								onFocus={() =>
									!isViewPurpose && isCreatLocation
								}
								notFoundContent={
									(!isViewPurpose) ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: isCreatLocation,
									value_key: "id",
									text_key: "name",
								}}
							/>
						</Col>
					)}
					{((form.getFieldValue('create_location') === 1) || (id === 1)) ?
						<Col xs={{ span: 6 }} id="parent_fr_id">
							<InputComponent
								type="select"
								required={!isViewPurpose}
								readOnly={isViewPurpose}
								disabled={type === Constant.EDIT}
								label="Franchisee"
								name="fr_id"
								getPopupContainer={() => document.getElementById('parent_fr_id')}
								placeholder="Select Franchisee"
								onChange={(e) => {
									setFetchHubsList(true)
									if (e) {
										if (ManageFrainchaiseeStore.dropdown_latest_fr_list) {
											let selected = ManageFrainchaiseeStore.dropdown_latest_fr_list.find(element => element?.id === e);
											ManageHubsStore.dropdown_latest_hub_list = ([selected.hub]);
											ManageHeadOfficeStore.dropdown_ho_list = ([selected.ho]);
											form.setFieldsValue({
												ho_id: selected.ho_id,
												hub_id: selected.hub_id,
												company: selected.company,
												pan: selected.pan,
												aadhar: selected.aadhar,
												owner_name: selected.owner_name
											})
										}
									}
									else {
										form.setFieldsValue({ hub_id: null, ho_id: null })
									}
									handleChange()
								}}
								onFocus={() => {
									!isViewPurpose &&
										fetchFrList &&
										ManageFrainchaiseeStore.getFrainchaiseeList({
											is_active: 1,
											purpose: id === 1 ? 1 : 2
										}).then(() => setFetchFrList(false));
								}}
								notFoundContent={
									(!isViewPurpose && fetchFrList) ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: ManageFrainchaiseeStore.dropdown_latest_fr_list,
									value_key: "id",
									text_key: "company",
									disabled_keys:
										ManageFrainchaiseeStore.dropdown_latest_fr_list &&
										ManageFrainchaiseeStore.dropdown_latest_fr_list
											.filter(
												(item) => item?.is_active === 0 || item?.is_deleted === 1
											)
											.map((item) => item.id),
								}}
								rules={(isViewPurpose) ? [] : vsmLocation.validation.fr_id}
							/>
						</Col> :
						<Col xs={{ span: 6 }} id="parent_fr_id">
							<InputComponent
								type="select"
								required={!isViewPurpose}
								readOnly={isViewPurpose}
								showSearch={!isViewPurpose}
								disabled={type === Constant.EDIT}
								label="Franchisee"
								name="fr_id"
								getPopupContainer={() => document.getElementById('parent_fr_id')}
								placeholder="Select Franchisee"
								onChange={(e) => {
									setFetchHubsList(true)
									if (e) {
										handleChange(e)
										if (ManageFrainchaiseeStore.dropdown_latest_fr_list) {
											let selected = ManageFrainchaiseeStore.dropdown_latest_fr_list.find(element => element?.id === e);
											ManageHubsStore.dropdown_latest_hub_list = ([selected.hub]);
											ManageHeadOfficeStore.dropdown_ho_list = ([selected.ho]);
											form.setFieldsValue({
												ho_id: selected.ho_id,
												hub_id: selected.hub_id,
												company: selected.company,
												pan: selected.pan,
												aadhar: selected.aadhar,
												owner_name: selected.owner_name
											})
										}
									}
									else {
										form.setFieldsValue({ hub_id: null, ho_id: null })
									}
								}}
								onSearch={(e) => {
									if (e.length >= 3 && !isViewPurpose) {
										if (e.length >= 5) {
											e = e.substring(0, 5);
										}
										ManageFrainchaiseeStore.getFrainchaiseeList({
											is_active: 1,
											company: e,
											purpose: id === 1 ? 1 : 2
										}).then(() => setFetchFrList(false));
									}
								}}
								notFoundContent={
									(!isViewPurpose && fetchFrList) ? <Spin size="small" /> : "No Record Found."
								}
								options={{
									values: ManageFrainchaiseeStore.dropdown_latest_fr_list,
									value_key: "id",
									text_key: "company",
									disabled_keys:
										ManageFrainchaiseeStore.dropdown_latest_fr_list &&
										ManageFrainchaiseeStore.dropdown_latest_fr_list
											.filter(
												(item) => item?.is_active === 0 || item?.is_deleted === 1
											)
											.map((item) => item.id),
								}}
								rules={(isViewPurpose) ? [] : vsmLocation.validation.fr_id}
							/>
						</Col>
					}
					<Col xs={{ span: 6 }} id="parent_hub_id">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							disabled={type === Constant.EDIT}
							label="Hub"
							name="hub_id"
							getPopupContainer={() => document.getElementById('parent_hub_id')}
							placeholder="Select Hub"
							onChange={(e, val) => {
								handleChange(e);
								if (val) {
									//displayHubListUpdate(val)
								}
							}}
							onFocus={() =>
								!isViewPurpose && fetchHubsList &&
								ManageHubsStore.getHubList({ is_active: isActive }).then(() =>
									setFetchHubsList(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchHubsList) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageHubsStore.dropdown_latest_hub_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageHubsStore.dropdown_latest_hub_list &&
									ManageHubsStore.dropdown_latest_hub_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.hub_id}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_ho_id">
						<InputComponent
							type="select"
							disabled={isViewPurpose ? false : true}
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							label="HO"
							name="ho_id"
							getPopupContainer={() => document.getElementById('parent_ho_id')}
							placeholder="Select HO"
							rules={(isViewPurpose) ? [] : vsmLocation.validation.ho_id}
							options={{
								values: ManageHeadOfficeStore.dropdown_ho_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageHeadOfficeStore.dropdown_ho_list &&
									ManageHeadOfficeStore.dropdown_ho_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					{isViewPurpose && <Col xs={{ span: 6 }} className="mt-20 moreInfoBtn">
						<Form.Item>
							<Button type="primary" onClick={openMoreInfoModal}><InfoCircleOutlined /> More Info</Button>
						</Form.Item>
					</Col>}
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Location Name"
							placeholder="Location Name"
							disabled={(type === Constant.EDIT && user.level_id !== Constant.ADMIN_USER) ? true : false}
							name="name"
							onBlur={(e) => {
								if (!labelData) {
									form.setFieldsValue({ label: e.target.value })
								}
								handleChange()
							}}
							onChange={(e) => {
								form.setFieldsValue({
									name: properUpperCaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.name}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Company Name"
							placeholder="Company Name"
							name="company"
							rules={(isViewPurpose) ? [] : vsmLocation.validation.company}
							onChange={(e) => {
								form.setFieldsValue({
									company: properCaseString(e.target.value)
								})
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Sticker Label"
							placeholder="Sticker Label"
							name="label"
							rules={(isViewPurpose) ? [] : vsmLocation.validation.label}
							onChange={(e) => {
								setLabelData(e.target.value)
								form.setFieldsValue({
									label: properUpperCaseString(e.target.value)
								})
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Code"
							disabled={isViewPurpose ? false : true}
							placeholder="Code"
							name="code"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.code}
						/>
					</Col>

				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Franchisee Owner Name"
							placeholder="Franchisee Owner Name"
							name="owner_name"
							disabled
							rules={(isViewPurpose) ? [] : vsmLocation.validation.owner_name}
							onChange={(e) => {
								form.setFieldsValue({
									owner_name: properCaseString(e.target.value)
								})
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }} className="tooltipText">
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="PAN Card"
							placeholder="PAN Card"
							disabled
							maxLength="10"
							name="pan"
							onChange={(e) => {
								form.setFieldsValue({
									pan: upperCaseString(e.target.value),
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.pan}
							tooltip={(!isViewPurpose) ? panText : ''}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Aadhar Card"
							disabled
							placeholder="Aadhar Card"
							name="aadhar"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.aadhar}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<Form.Item name="is_gst_registered" label="Is GST Registered?" valuePropName="checked" >
							{!isViewPurpose ? <Switch
								className="Switch__with_label oly"
								checked={(form.getFieldValue('is_gst_registered')) ? true : true}
								onChange={(e) => {
									setGstDisplay(e)
									handleChange()
									form.setFieldsValue({ is_gst_registered: (e) ? 1 : 0 })
								}}
							/> : <Switch
								className="Switch__with_label"
								checked={(form.getFieldValue('is_gst_registered') === 1) ? true : false}
								disabled
							/>
							}
						</Form.Item>
					</Col>
					{gstDisplay && <Col xs={{ span: 6 }} className="tooltipText">
						<InputComponent
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							type="text"
							label="GST No."
							placeholder="GST No."
							name="gst_no"
							maxLength={15}
							onChange={(e) => {
								form.setFieldsValue({
									gst_no: upperCaseString(e.target.value),
								})
							}}
							tooltip={(!isViewPurpose) ? gstnoinfo : ''}
							rules={(isViewPurpose) ? [] : vsmLocation.validationlo.gst_no}
						/>
					</Col>}
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col span={6}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Location Contact Person"
							placeholder="Location Contact Person"
							name="location_contact_person"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.incharge_name}
						/>
					</Col>
				</Row>
				<Row gutter={30}>
					<Col span={24}>
						<div className="Info__info_text_add">
							<span className="info_text_add mb-0"><b>Notes : </b> This Location Contact Person name will show on Pincode and Location search in Website, Mobile Application, Web-Software.</span>
						</div>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<h2>Location Address</h2>
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Shop/Apartment"
							placeholder="Shop/Apartment"
							name="address1"
							onChange={(e) => {
								form.setFieldsValue({
									address1: properCaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.address1}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Area Name"
							placeholder="Area Name"
							name="address2"
							onChange={(e) => {
								form.setFieldsValue({
									address2: properCaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.address2}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Landmark"
							placeholder="Landmark"
							name="address3"
							onChange={(e) => {
								form.setFieldsValue({
									address3: properCaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.address3}
						/>
					</Col>
				</Row>
				<Row gutter={30}>
					<Col xs={{ span: 6 }} id="parent_country_id">
						<InputComponent
							type="select"
							allowClear={!isViewPurpose}
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							label="Country"
							name="country_id"
							getPopupContainer={() => document.getElementById('parent_country_id')}
							placeholder="Select Country"
							rules={(isViewPurpose) ? [] : vsmLocation.validation.country_id}
							disabled
							onChange={() => {
								handleChange();
								handleCountryChange();
							}}
							onFocus={() =>
								!isViewPurpose && fetchCountry &&
								getCountriesList({ is_active: isActive }).then(() =>
									setFetchCountry(false)
								)
							}
							notFoundContent={
								(!isViewPurpose && fetchCountry) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: dropdown_country_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									dropdown_country_list &&
									dropdown_country_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_state_id">
						<InputComponent
							type="select"
							allowClear={!isViewPurpose}
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							label="State"
							name="state_id"
							getPopupContainer={() => document.getElementById('parent_state_id')}
							placeholder="Select State"
							rules={(isViewPurpose) ? [] : vsmLocation.validation.state_id}
							onChange={() => {
								handleChange();
								handleStateChange();
							}}
							onFocus={() =>
								!isViewPurpose && fetchState &&
								form?.getFieldValue("country_id") &&
								ManageStateStore.getStateList({
									country_id: form.getFieldValue("country_id"),
									is_active: isActive,
								}).then(() => setFetchState(false))
							}
							notFoundContent={
								(!isViewPurpose && fetchState) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageStateStore.dropdown_state_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageStateStore.dropdown_state_list &&
									!fetchState &&
									ManageStateStore.dropdown_state_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_city_id">
						<InputComponent
							type="select"
							allowClear={!isViewPurpose}
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							showSearch={!isViewPurpose}
							label="City"
							name="city_id"
							getPopupContainer={() => document.getElementById('parent_city_id')}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.city_id}
							placeholder="Select City"
							onChange={(e) => {
								let FindStdCode = ManageCityStore.dropdown_city_list.find((element => element?.id === e))
								setStdCode(FindStdCode?.std_code)
								form.setFieldsValue({
									phoneStdCode: FindStdCode?.std_code
								})
								handleChange(e);
								handleCityChange();
							}}
							onFocus={() =>
								!isViewPurpose && fetchCity &&
								form?.getFieldValue("state_id") &&
								ManageCityStore.getCityList({
									state_id: form.getFieldValue("state_id"),
									is_active: isActive,
								}).then(() => setFetchCity(false))
							}
							notFoundContent={
								(!isViewPurpose && fetchCity) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManageCityStore.dropdown_city_list,
								value_key: "id",
								text_key: "name",
								disabled_keys:
									ManageCityStore.dropdown_city_list &&
									!fetchCity &&
									ManageCityStore.dropdown_city_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
					<Col xs={{ span: 6 }} id="parent_pincode_id">
						<InputComponent
							type="select"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							allowClear={!isViewPurpose}
							showSearch={!isViewPurpose}
							label="Pin Code"
							placeholder="Pin Code"
							name="pincode_id"
							getPopupContainer={() => document.getElementById('parent_pincode_id')}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.pincode_id}
							suffix={
								<Tooltip title={text}>
									<InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
								</Tooltip>
							}
							onChange={handleChange}
							onFocus={() =>
								!isViewPurpose && fetchpincode &&
								form?.getFieldValue("city_id") &&
								ManagePinCodeStore.getPincodeList({
									city_id: form.getFieldValue("city_id"),
									is_active: isActive,
								}).then(() => setFetchPincode(false))
							}
							notFoundContent={
								(!isViewPurpose && fetchpincode) ? <Spin size="small" /> : "No Record Found."
							}
							options={{
								values: ManagePinCodeStore.dropdown_pincodes_list,
								value_key: "id",
								text_key: "pincode",
								disabled_keys:
									ManagePinCodeStore.dropdown_pincodes_list &&
									ManagePinCodeStore.dropdown_pincodes_list
										.filter(
											(item) => item?.is_active === 0 || item?.is_deleted === 1
										)
										.map((item) => item.id),
							}}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Office Mobile 1"
							placeholder="Office Mobile 1"
							maxLength={10}
							name="mobile"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.officeMobile1}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Office Mobile 2"
							placeholder="Office Mobile 2"
							maxLength={10}
							name="mobile2"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.officeMobile2}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Landline 1"
							placeholder="Landline 1"
							addonBefore={stdCode}
							maxLength={20}
							name="phone1"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.phone1}
						/>
						<input type={text} hidden={true} name="phoneStdCode" />
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							maxLength={20}
							label="Landline 2"
							placeholder="Landline 2"
							addonBefore={stdCode}
							name="phone2"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.phone2}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Whatsapp Number"
							placeholder="Whatsapp Number"
							maxLength={10}
							name="whatsappno"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.whatsappno}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Fax"
							placeholder="Fax"
							name="fax"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.fax}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Email"
							placeholder="Email"
							name="email"
							onChange={(e) => {
								form.setFieldsValue({
									email: lowercaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.email}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Skype Id"
							placeholder="Skype Id"
							name="skype_id"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.skype_id}
						/>
					</Col>
				</Row>
				<Divider className="mt-0" />
				<Row gutter={30}>
					<Col span={24}>
						<h3>Location Incharge</h3>
					</Col>
					<Col span={6}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Incharge Name"
							placeholder="Incharge Name"
							name="incharge_name"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.incharge_name}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							required={!isViewPurpose}
							readOnly={isViewPurpose}
							label="Incharge Mobile"
							placeholder="Incharge Mobile"
							maxLength={10}
							name="incharge_mobile"
							onChange={handleChange}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.mobile}
						/>
					</Col>
					<Col xs={{ span: 6 }}>
						<InputComponent
							type="text"
							readOnly={isViewPurpose}
							label="Incharge Email"
							placeholder="Incharge Email"
							name="incharge_email"
							onChange={(e) => {
								form.setFieldsValue({
									incharge_email: lowercaseString(e.target.value)
								})
							}}
							rules={(isViewPurpose) ? [] : vsmLocation.validation.Incharge_email}
						/>
					</Col>
				</Row>
			</Form>
			<MoreInfoComponent visible={moreInfoModal} close={closeMoreInfoModal} />
		</>
	);
});
export default GeneralFormComponent;
