/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { Constant } from "../../../../config/Constant";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { b64toBlob, convertTextToID, convertViewAddresscenterobj, convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";

export default class ManageCentersStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	deleteValues = null;
	statusValues = null;
	internationalValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_center_list = null;
	loValues = null;
	kycFormDetails = null;
	defaultValue = null;
	typeMode = null;
	startViewMode = false;
	bankInfoValues = null;
	contactInfoValues = null;
	deleteContactInfoValues = null;
	isActiveFilterList = null;
	completePendingFilterList = null;
	dropdown_center_types_list = null;
	dropdown_OwnershipType_list = null;
	dropdown_partnership_types_list = null;
	locationWalletValues = null
	isTerminated = 0;

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}

	setStartViewMode = (mode) => {
		this.startViewMode = mode;
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		// console.log("centervalue", data)
		if (data) {
			if (data.city) {
				data.address = convertViewAddresspincodeobj(data);
			} else {
				data.address = convertViewAddresscenterobj(data)
			}
		}
		this.viewValues = data;
	};

	setIsTerminated = (isTerminated) => {
		this.isTerminated = isTerminated
		this.setupGrid(this.agGrid);
	}

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setCompletePendingFilterList = (data) => {
		this.completePendingFilterList = data;
	}

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}

	// set form Step2 values to view
	setLegalOperationsValues = (data) => {
		this.loValues = data;
	};

	// set form Step4 values to view
	setBankInfoValues = (data) => {
		this.bankInfoValues = data;
	};

	// set form Step5 values to view
	setContactInfoValues = (data) => {
		this.contactInfoValues = data;
	};

	// set form values to delete
	setDeleteContactInfoValues = (data) => {
		this.deleteContactInfoValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setInternationalValues = (data) => {
		this.internationalValues = data;
	};
	setLocationWalletValues = (data) => {
		this.locationWalletValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setCenterTypeList = (data) => {
		this.dropdown_center_types_list = data;
	}

	setOwnerShipTypeList = (data) => {
		this.dropdown_OwnershipType_list = data;
	}

	setPartnerShipTypeList = (data) => {
		this.dropdown_partnership_types_list = data;
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`organization/location/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					//     item.cities = item.cities && item.cities.name
					return null;
				});
			}

			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter["ct_id"]) {
			final_filter['ct_id'].values = convertTextToID(final_filter['ct_id'], this.dropdown_center_types_list, 'name', 'id')
		}

		if (final_filter["ot_id"]) {
			final_filter['ot_id'].values = convertTextToID(final_filter['ot_id'], this.dropdown_OwnershipType_list, 'name', 'id')
		}

		if (final_filter["pt_id"]) {
			final_filter['pt_id'].values = convertTextToID(final_filter['pt_id'], this.dropdown_partnership_types_list, 'name', 'id')
		}

		if (final_filter["is_active"]) {
			final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_gst_registered"]) {
			final_filter['is_gst_registered'].values = convertTextToID(final_filter['is_gst_registered'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["allow_ap"]) {
			final_filter['allow_ap'].values = convertTextToID(final_filter['allow_ap'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_computerized"]) {
			final_filter['is_computerized'].values = convertTextToID(final_filter['is_computerized'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_terminated"]) {
			final_filter['is_terminated'].values = convertTextToID(final_filter['is_terminated'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_locked"]) {
			final_filter['is_locked'].values = convertTextToID(final_filter['is_locked'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["lock_cash_rc"]) {
			final_filter['lock_cash_rc'].values = convertTextToID(final_filter['lock_cash_rc'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_completed"]) {
			final_filter['is_completed'].values = convertTextToID(final_filter['is_completed'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["kyc_flag"]) {
			final_filter['kyc_flag'].values = convertTextToID(final_filter['kyc_flag'], this.completePendingFilterList, 'name', 'id')
		}
		if (final_filter["lo_flag"]) {
			final_filter['lo_flag'].values = convertTextToID(final_filter['lo_flag'], this.completePendingFilterList, 'name', 'id')
		}
		if (final_filter["cb_flag"]) {
			final_filter['cb_flag'].values = convertTextToID(final_filter['cb_flag'], this.completePendingFilterList, 'name', 'id')
		}
		if (final_filter["contract_flag"]) {
			final_filter['contract_flag'].values = convertTextToID(final_filter['contract_flag'], this.completePendingFilterList, 'name', 'id')
		}
		if (final_filter["is_international"]) {
			final_filter['is_international'].values = convertTextToID(final_filter['is_international'], this.isActiveFilterList, 'name', 'id')
		}

		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					is_terminated: this.isTerminated,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`organization/location/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`organization/location/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`organization/location/details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setEditValues(setData);
			return data;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`organization/location/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
		return Axios.patch("organization/location/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ToggleComputerizedData = (formdata) => {
		const api_link = formdata.is_computerized === 1 ? "offline/" : "online/";
		return Axios.patch("organization/location/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ToggleTerminatedData = (formdata) => {
		const api_link = formdata.is_terminated === 1 ? "revoke/" : "terminate/";
		return Axios.patch("organization/location/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ToggleLockedData = (formdata) => {
		const api_link = formdata.is_locked === 1 ? "unlock/" : "lock/";
		return Axios.patch("organization/location/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	ToggleLockedCashRCData = (formdata) => {
		const api_link = formdata.lock_cash_rc === 1 ? "unlock-ratecard/" : "lock-ratecard/";
		return Axios.patch("organization/location/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ToggleAllowAPData = (formdata) => {
		const api_link = formdata.allow_ap === 1 ? "disallow/" : "allow/";
		return Axios.patch("organization/location/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ToggleInternationData = (formdata) => {
		if (!formdata.is_international) {
			return Axios.post("international/allow", formdata)
				.then(({ data }) => {
					if (this.agGrid) {
						this.setupGrid(this.agGrid);
					}
					return data;
				})
				.catch(({ response: { data } }) => {
					var errors = [];
					var notify = null;
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (data && data.STATUS) {
						if (NOTIFICATION) {
							notify = NOTIFICATION[0];
						}
						Object.keys(fieldErrors).forEach((name) => {
							errors.push({ name, errors: data.STATUS[name] });
						});
					}
					return Promise.reject({ errors, notify });
				});
		} else {
			return Axios.post("international/terminate", formdata)
				.then(({ data }) => {
					if (this.agGrid) {
						this.setupGrid(this.agGrid);
					}
					return data;
				})
				.catch(({ response: { data } }) => {
					var errors = [];
					var notify = null;
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (data && data.STATUS) {
						if (NOTIFICATION) {
							notify = NOTIFICATION[0];
						}
						Object.keys(fieldErrors).forEach((name) => {
							errors.push({ name, errors: data.STATUS[name] });
						});
					}
					return Promise.reject({ errors, notify });
				});
		}
	};

	ToggleAllowLocationWalletData = (formdata) => {
		const api_link = formdata.allow_wallet === 1 ? "terminate" : "allow";
		return Axios.post("organization/location-wallet/" + api_link, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	CoverImageuploadData = (formdata) => {
		return Axios.post(`organization/location/upload-cover-image/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	MapLocationData = (formdata) => {
		return Axios.post(
			`organization/location/location/` + formdata.id,
			formdata
		)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get List of center for dropdown
	getCentersList = (conditional = {}) => {
		return Axios.post(`organization/center/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_center_list = data.mst_center;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	getLegalOperationData = () => {
		let ID = this.editValues.id;
		return Axios.get(`organization/location/legal-operations-detail/${ID}`).then(({ data }) => {
			this.setLegalOperationsValues(data.view);
			return data;
		});
	};

	setLegalOperationData = (formdata) => {
		return Axios.post(`organization/location/legal-operations-edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get Default KYC Form 
	getKycForm = (conditional = {}) => {
		conditional.id = this.editValues.id;
		return Axios.post(`organization/location/kyc-details`, conditional).then(({ data }) => {
			this.kycFormDetails = data.doc_types;
			return data;
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	// Call delete api
	deleteKYCData = (formdata) => {
		return Axios.delete(`organization/location/kyc-destroy/${formdata.doc_id}/${formdata.cr_id}`)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//finish Kyc
	finishKycData = (cr_id) => {
		return Axios.post(`organization/location/kyc-finish`, { id: cr_id })
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//
	uploadKYCData = (formdata) => {
		return Axios.post(`organization/location/kyc-upload`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getBankInfoForm = () => {
		let ID = this.editValues.center_bank_acc.id;
		let center_id = this.editValues.id;
		return Axios.get(`organization/bank-accs-loc/detail/${ID}/${center_id}`).then(({ data }) => {
			this.setBankInfoValues(data.view);
			return data;
		});
	};

	setBankInfoData = (formdata) => {
		let apiUrl = "";
		if (this.bankInfoValues && this.bankInfoValues.id) {
			apiUrl = `/organization/bank-accs-loc/edit/${this.bankInfoValues.id}`
		} else {
			apiUrl = "/organization/bank-accs-loc/new"
		}
		return Axios.post(`${apiUrl}`, formdata)
			.then(({ data }) => {
				// let oldval = this.editValues;
				// let newval = {...oldval,...formdata};
				// this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	getContactInfoForm = () => {
		let ID = this.editValues.id;
		return Axios.get(`organization/location/contact-info/details/${ID}`).then(({ data }) => {
			this.setContactInfoValues(data.contact_details);
			return data;
		});
	};

	AddContactData = (formdata, type) => {
		return Axios.post(`organization/location/contact-info/new`, formdata)
			.then(({ data }) => {
				if (type === Constant.ADD) {
					let currentList = this.contactInfoValues;
					formdata.id = data.id
					if (currentList) {
						currentList.push(formdata);
					}
					this.setContactInfoValues(currentList);
				}
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
		// .catch(({ response: { data } }) => {
		// 	var errors = [];
		// 	var notify = null;
		// 	if (data && data?.STATUS) {
		// 		const { NOTIFICATION, ...fieldErrors } = data.STATUS;
		// 		if (NOTIFICATION) {
		// 			notify = NOTIFICATION[0];
		// 		}
		// 		Object.keys(fieldErrors).forEach((name) => {
		// 			errors.push({ name, errors: data.STATUS[name] });
		// 		});
		// 	}
		// 	return Promise.reject({ errors, notify });
		// });
	}
	DeleteContactInfoData = (formdata) => {
		return Axios.delete(`organization/location/contact-info/destroy/` + formdata.id)
			.then(({ data }) => {
				let currentList = this.contactInfoValues;
				if (currentList) {
					currentList = currentList.filter((item) => item.id !== formdata.id);
				}
				this.setContactInfoValues(currentList);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	updateProfile = (field, value) => {
		if (this.viewValues[field]) {
			this.viewValues[field] = value;
		}
	};

	PrintReceiptData = (ID) => {
		return Axios.get(`/organization/location/print/${ID}`).then(({ data }) => {
			const file = b64toBlob(data.print, "application/pdf");
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			window.open(fileURL);
			//pdfWindow.location.href = fileURL
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	InternationalAgencyData = (ID) => {
		return Axios.get(`/international/agency/details/${ID}`).then(({ data }) => {
			this.setInternationalValues(data.view);
			return data;
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	// Call Send Certificat api
	SendCertificateData = (ID) => {
		return Axios.get(`/organization/location/send-certificate/${ID}`)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}				
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	GenerateCertificate = (formData) => {
		return Axios.post(`/organization/location/generate-certificate`, formData)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}				
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
}

decorate(ManageCentersStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	statusValues: observable,
	internationalValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	setInternationalValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_center_list: observable,
	loValues: observable,
	setLegalOperationsValues: action,
	kycFormDetails: observable,
	getKycForm: action,
	finishKycData: action,
	uploadKYCData: action,
	setDefaultValue: action,
	defaultValue: observable,
	typeMode: observable,
	setTypeMode: action,
	setBankInfoValues: action,
	bankInfoValues: observable,
	setContactInfoValues: action,
	contactInfoValues: observable,
	setDeleteContactInfoValues: action,
	deleteContactInfoValues: observable,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setCompletePendingFilterList: action,
	completePendingFilterList: observable,
	PrintReceiptData: action,
	InternationalAgencyData: action,
	setLocationWalletValues: action,
	locationWalletValues: observable,
});
