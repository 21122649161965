import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import moment from "moment";
import { Constant } from "../../../../config/Constant";

export default class ManageICICIDataSyncStore {
	agGrid = null;
	per_page = Constant.PER_PAGE_REPORT_COUNT;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	apiRes = null;
	formPayload = null;
	selectAll = false;
	selectCount = 0;
	delivery_booking_list = null;
	buttonValue = true


	setButtonValue = (data) => {
		this.buttonValue = data
	}
	setCheckState = (data) => {
		this.selectAll = data
	}

	setFormPayload = data => {
		this.formPayload = data
	}

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		this.agGrid.api.setFilterModel({
			bk_date: { dateFrom: moment().format("YYYY-MM-DD"), dateTo: moment().add(1, 'day').endOf('day').format("YYYY-MM-DD"), type: "inRange", filterType: "date" }
		});
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	doExportDownload = (data) => {
        return Axios.post(`/sync/icici/get-dispatch-details`, data)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

	VerifyImport = (formdata) => {
		this.delivery_booking_list = null
		return Axios.post(`sync/icici/send-delivery-status-file-check`, formdata)
			.then(({ data }) => {
				if (data && data.import_data) {
					this.delivery_booking_list = data.import_data
				}
				return true;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	ImportBulk = (formdata) => {
		return Axios.post(`sync/icici/send-delivery-status`, formdata)
			.then(({ data }) => {			
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		return { final_filter, final_sort }
	}


	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				if (this.formPayload) {
					payload = { ...payload, ...this.formPayload };
					this.getList(payload).then((data) => {
						if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
						else { this.agGrid.api.hideOverlay() }
						params.successCallback(data.list.data, data.list.total)
						var allColumnIds = []
						if (this.agGrid && this.agGrid.columnApi && data.total) {
							this.agGrid.columnApi.getAllColumns().forEach(function (column) {
								allColumnIds.push(column.col_id)
							})
						}
					}).then(() => this.onFirstDataRendered())
				} else {
					this.agGrid.api.showNoRowsOverlay();
					params.successCallback([], 0)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && 0) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				}
			}
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`/bookings/transfer-booking-entries/transfer`, formdata)
			.then(({ data }) => {
				return data
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	onFirstDataRendered = () => {
		if (this.selectAll === true) {
			this.agGrid.api.forEachNode((node) => {
				this.selectCount++;
				return node.setSelected(!!node.data);
			});
		}
	};

	onSelectAllRender = (selectAllFlag) => {
		if (selectAllFlag === true) {
			this.onFirstDataRendered()
		} else {
			this.agGrid.api.forEachNode((node) => {
				return node.setSelected(false);
			});
		}
	}
}


decorate(ManageICICIDataSyncStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	statusValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setStatusValues: action,
	setPageSize: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	setViewValues: action,
	viewValues: observable,
	setIsActiveFilterList: action,
	setupLocalBookingGrid: action,
	onLocalFilterChanged: action,
	apiRes: observable,
	setFormPayload: action,
	formPayload: observable,
	selectAll: observable,
	onFirstDataRendered: action,
	setCheckState: action,
	buttonValue: observable,
	setButtonValue: action,
	delivery_booking_list: observable,
});
