import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../../utils/GlobalFunction";

export default class ManageFinancialYearsStore {
	agGrid = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	dropdown_financial_year_list = null;

	//set grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;

	}

	//change page size, default page size is localGridConfig.option.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = () => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.list_data = null;
		return Axios.get(`admin/financial-year/list`).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					item.is_active_display =
						item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter);
				this.agGrid.api.setSortModel(sort);
			}
		})
	}

	//Filtaer function for no record found massage
	onFilterChanged = (params) => {
		this.agGrid = params;
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	//Get List of finacialyear for dropdown
	getFinancialYearList = (conditional = {}) => {
		return Axios.post(`admin/financial-year/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_financial_year_list = data.financial_year;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(ManageFinancialYearsStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	dropdown_financial_year_list: observable,
})