import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { db } from "../db";
export default class CommonStore {
	dropdown_center_types_list = null;
	dropdown_sez_list = null;
	dropdown_weight_list = null;
	dropdown_discount_list = null;
	dropdown_client_types_list = null;
	dropdown_client_list = null;
	dropdown_order_status_list = null;
	dropdown_payment_status_list = null;
	dropdown_trader_list = null;
	dropdown_stock_product_list = null;
	dropdown_Type_list = null;
	clientValues = null;
	dropdown_bank_account_type_list = null;
	dropdown_booking_types_list = null;
	dropdown_office_premises_list = null;
	dropdown_transporatation_list = null;
	dropdown_delivery_status_list = null;
	dropdown_service_pincode_list = null;
	dropdown_party_types_list = null;
	dropdown_booking_category = null;
	dropdown_user_list = null;
	dropdown_tracking_shipment_list = null;
	dropdown_audit_weight_status = null;
	dropdown_rate_card_purpose_list = null;
	dropdown_rate_card_status_list = null;
	dropdown_serving_location = null;
	dropdown_billing_mode_Status_list = null;
	dropdown_biling_cycle_list = null;
	dropdown_slab_rate_temp = null;
	dropdown_pice_rate_temp = null;
	dropdown_billing_policy_list = null;
	dropdown_billing_type_list = null;
	dropdown_drs_status_list = null;
	dropdown_intCountries_list = null;
	dropdown_intCountries_zip_list = null;
	dropdown_intShipmentTypes_list = null;
	dropdown_intNondg_list = null;
	dropdown_intInvoiceTypes_list = null;
	dropdown_intFwdChargesTypes_list = null;
	dropdown_intCurrency_list = null;
	dropdown_intKycTypes_list = null;

	//get billing policy 
	getBillingTypeList = (conditional = {}) => {
		return Axios.post('/admin/client-bill-types/lov', conditional)
			.then(({ data }) => {
				this.dropdown_billing_type_list = data.clientBilTypes
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	//get billing policy 
	getBillingPolicyList = (conditional = {}) => {
		return Axios.post('/admin/billing-policy/lov', conditional)
			.then(({ data }) => {
				this.dropdown_billing_policy_list = data.lov_rate_policies
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	//get pice rate templte 
	getPiceRateTempList = (conditional = {}) => {
		return Axios.post('/admin/piece-rate-templates/lov', conditional)
			.then(({ data }) => {
				this.dropdown_pice_rate_temp = data.piece_rate
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	//get slab rate templete 
	getSlabRateTempList = (conditional = {}) => {
		return Axios.post('/admin/slab-rate-templates/lov', conditional)
			.then(({ data }) => {
				this.dropdown_slab_rate_temp = data.slab_rate
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	//get billing cycle 
	getBillingCycleList = (conditional = {}) => {
		return Axios.post('/admin/billing-cycle/lov', conditional)
			.then(({ data }) => {
				this.dropdown_biling_cycle_list = data.lov_billing_cycle
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	getBillingModalStatusList = (conditional = {}) => {
		return Axios.post('/admin/billing-mode/lov', conditional)
			.then(({ data }) => {
				this.dropdown_billing_mode_Status_list = data.billing_policy
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	getRateCardStatusList = (conditional = {}) => {
		return Axios.post('/admin/rate-card-status/lov', conditional)
			.then(({ data }) => {
				this.dropdown_rate_card_status_list = data.rc_status
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	getRateCardPurposeList = (conditional = {}) => {
		return Axios.post('/admin/rate-card-purpose/lov', conditional)
			.then(({ data }) => {
				this.dropdown_rate_card_purpose_list = data.rc_purpose
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	getUserList = (conditional = {}) => {
		return Axios.post('/admin/users/lov', conditional)
			.then(({ data }) => {
				this.dropdown_user_list = data.users
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	// set form values to edit
	setClientValues = (data) => {
		this.clientValues = data;
	};

	getBookingCategory = (conditional = {}) => {
		return Axios.post('/admin/booking-categories/lov', conditional)
			.then(({ data }) => {
				this.dropdown_booking_category = data.lov_booking_categories
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}
	//get delivery status info
	getDeliveryStatus = (conditional = {}) => {
		return Axios.post('/admin/delivery-status/lov', conditional)
			.then(({ data }) => {
				this.dropdown_delivery_status_list = data.delivery_status
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}


	/**	
	 * This function is deperacted for below modules.	
	 * Rate Card Template Zone	
	 */
	getServicePincodeList = (conditional = {}) => {
		return Axios.post('business/service-pincode/lov', conditional)
			.then(({ data }) => {
				this.dropdown_service_pincode_list = data.service_pincodes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}
	/**	
	 * This is new function of getServicePincodeList	
	 */
	getServicePincodeByCityList = (conditional = {}) => {
		return Axios.post('business/service-pincode/pincode-by-cities', conditional)
			.then(({ data }) => {
				this.dropdown_service_pincode_list = data.service_pincodes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	//get Transportation info 
	getTransporatationList = (conditional = {}) => {
		return Axios.post('admin/trans-modes/lov', conditional)
			.then(({ data }) => {
				this.dropdown_transporatation_list = data.trans_modes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	//Get Bank info 
	getBankInfo = (data) => {
		return Axios.post(`admin/banks/get-bank-info`, data)
			.then(({ data }) => {
				return data.bank_info;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	//Get List of center type for dropdown
	getCenterTypesList = (conditional = {}) => {
		return Axios.post(`admin/center-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_center_types_list = data.lov_center_types;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of office premises type for dropdown
	getOfficePremisesList = (conditional = {}) => {
		return Axios.post(`admin/office-premises/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_office_premises_list = data.lov_office_premises;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of client type for dropdown
	getClientTypesList = (conditional = {}) => {
		return Axios.post(`admin/client-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_client_types_list = data.lov_client_type;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of weight for dropdown
	getWeight = (conditional = {}, appendText = null, appendTextPosition = null) => {
		return Axios.post(`business/weights/lov `, conditional)
			.then(({ data }) => {
				if (appendText) {
					this.dropdown_weight_list = data.weights.map((item) => {
						let newName = `${item.val}`
						if (appendTextPosition === "after") {
							newName = `${item.val} ${appendText}`;
						} else if (appendTextPosition === "after") {
							newName = `${appendText} ${item.val}`;
						}
						item.val = newName;
						return item;
					});
				} else {
					this.dropdown_weight_list = data.weights;
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	//Get List of discount for dropdown
	getDiscountRate = (conditional = {}) => {
		return Axios.post(`admin/discount-rate/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_discount_list = data.lov_cash_bk_disc;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	//Get List of client for dropdown
	getClient = (conditional = {}) => {
		return Axios.post(`/organization/account-party-quotes/rate-card-clients`, conditional)
			.then(({ data }) => {
				this.dropdown_client_list = data.rate_card;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	//Get List of sez for dropdown
	getSezList = (conditional = {}) => {
		return Axios.post(`admin/sez/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_sez_list = data.lov_sez;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	getStockProductList = (conditional = {}) => {
		return Axios.post(`stickers/stock/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_stock_product_list = data.stock;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	};
	getTypeList = (conditional = {}) => {
		return Axios.post(`admin/transaction-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_Type_list = data.inv_trans_types;

				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	};

	getStickerOrderStatusList = (conditional = {}) => {
		return Axios.post(`admin/sticker-order-status/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_order_status_list = data.sticker_order_status;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	};

	getStickerOrderPaymentStatusList = (conditional = {}) => {
		return Axios.post(`admin/sticker-order-payment-status/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_payment_status_list = data.sticker_order_payment_status;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	};
	getTradersList = (conditional = {}) => {
		return Axios.post(`admin/trader-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_trader_list = data.lov_trader_types;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	getBankAccountType = (conditional = {}) => {
		return Axios.post(`admin/bank-acc-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_bank_account_type_list = data.bank_acc_types
				return data
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	//Get List of booking type for dropdown
	getBookingTypesList = (conditional = {}) => {
		return Axios.post(`admin/booking-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_booking_types_list = data.mst_booking_types;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of Drs Areas for dropdown
	getDrsAreasTypesList = (conditional = {}) => {
		return Axios.post(`admin/drs-area/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_drs_areas_list = data.drs_area;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of Drs Areas for dropdown
	getDeliveryBoyTypesList = (conditional = {}) => {
		return Axios.post(`admin/users/delivery-boy-lov`, conditional)
			.then(({ data }) => {
				this.dropdown_delivery_boy_types_list = data.users;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	getPartyTypeList = (conditional = {}) => {
		return Axios.post('/admin/party-type/lov', conditional)
			.then(({ data }) => {
				this.dropdown_party_types_list = data.party_types
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	getTrackingShipmentList = (conditional = {}) => {
		return Axios.post('/admin/tracking-shipment/lov', conditional)
			.then(({ data }) => {
				this.dropdown_tracking_shipment_list = data.trackingShipment
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}

	getAuditWeightStatusList = (conditional = {}) => {
		return Axios.post(`admin/approval-status/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_audit_weight_status = data.approval_status;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	};
	getServingLocationList = (conditional = {}) => {
		return Axios.post(`organization/center/serving-lov`, conditional)
			.then(({ data }) => {
				this.dropdown_serving_location = data.mst_center;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	};

	/***
	 * This function is use when location create a new booking then check hub already create a forwading entry or not
	 * then need to set this booking.
	 */
	getForwadingBookingEdit = (con_no, cashOrAcc) => {
		return Axios.get(`/bookings/consignment/forwardingdetails/${con_no}/${cashOrAcc}`)
			.then(({ data }) => {
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			});
	}

	getInsuranceDetails = async (conditional = {}) => {
		let insurance = {};
		insurance = await db.insurance_details.where("is_active").equals(conditional.is_active).first();
		if (insurance) {
			return insurance;
		}
		return false;
	}

	validateEwayNumber = (eway_no) => {
		return Axios.get(`/bookings/eway/validate/${eway_no}`)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	//Get List of Drs Status for dropdown
	getDrsStatusList = (conditional = {}) => {
		return Axios.post('/admin/drs-status/lov', conditional)
			.then(({ data }) => {
				this.dropdown_drs_status_list = data.drs_status
				return data;
			})
			.catch((response) => {
				return Promise.reject(response)
			})
	}	

	//Get List of International coutries for dropdown
	getIntCountriesList = (conditional = {}) => {
		return Axios.post(`international/country-list`, conditional)
			.then(({ data }) => {
				this.dropdown_intCountries_list = data.intCountries;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of International coutries zip for dropdown
	getIntCountriesZipList = (conditional = {}) => {
		return Axios.post(`international/zipcode-list`, conditional)
			.then(({ data }) => {
				this.dropdown_intCountries_zip_list = data.intCountriesZip;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of International shipment types for dropdown
	getIntShipmentTypesList = (conditional = {}) => {
		return Axios.post(`admin/int-shipment-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_intShipmentTypes_list = data.intShipmentTypes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of International nondg for dropdown
	getIntNondgList = (conditional = {}) => {
		return Axios.post(`admin/int-nondg/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_intNondg_list = data.intNondg;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of International Invoice Types for dropdown
	getIntInvoiceTypesList = (conditional = {}) => {
		return Axios.post(`admin/int-invoice-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_intInvoiceTypes_list = data.intInvoiceTypes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of International fwd charge for dropdown
	getintFwdChargesTypesList = (conditional = {}) => {
		return Axios.post(`admin/int-fwd-charges/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_intFwdChargesTypes_list = data.intFwdChargesTypes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of International Currency for dropdown
	getintCurrencyList = (conditional = {}) => {
		return Axios.post(`admin/int-currency/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_intCurrency_list = data.intCurrency;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of International Kyc Types for dropdown
	getintKycTypesList = (conditional = {}) => {
		return Axios.post(`admin/int-kyc-types/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_intKycTypes_list = data.intKycTypes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
}

decorate(CommonStore, {
	getCenterTypesList: action,
	dropdown_center_types_list: observable,
	getSezList: action,
	dropdown_sez_list: observable,
	dropdown_weight_list: observable,
	getWeight: action,
	dropdown_discount_list: observable,
	getDiscountRate: action,
	dropdown_client_types_list: observable,
	getClientTypesList: action,
	getClient: action,
	setClientValues: action,
	clientValues: observable,
	getStickerOrderStatusList: action,
	dropdown_order_status_list: observable,
	getStickerOrderPaymentStatusList: action,
	dropdown_payment_status_list: observable,
	dropdown_trader_list: observable,
	getTradersList: action,
	dropdown_Type_list: observable,
	getTypeList: action,
	getStockProductList: action,
	dropdown_stock_product_list: observable,
	dropdown_bank_account_type_list: observable,
	getBankAccountType: action,
	getCreateLocation: action,
	getBookingTypesList: action,
	getDrsAreasTypesList: action,
	dropdown_booking_types_list: observable,
	dropdown_drs_areas_list: observable,
	dropdown_office_premises_list: observable,
	getOfficePremisesList: action,
	getBankInfo: action,
	getTransporatationList: action,
	dropdown_transporatation_list: observable,
	dropdown_delivery_status_list: observable,
	getDeliveryStatus: action,
	dropdown_delivery_boy_types_list: observable,
	getDeliveryBoyTypesList: action,
	getServicePincodeList: action,
	dropdown_service_pincode_list: observable,
	getServicePincodeByCityList: action,
	getPartyTypeList: action,
	dropdown_party_types_list: observable,
	dropdown_booking_category: observable,
	getBookingCategory: action,
	dropdown_user_list: observable,
	getUserList: action,
	dropdown_tracking_shipment_list: observable,
	getAuditWeightStatusList: action,
	dropdown_audit_weight_status: observable,
	dropdown_rate_card_purpose_list: observable,
	getRateCardPurposeList: action,
	dropdown_rate_card_status_list: observable,
	getRateCardStatusList: action,
	dropdown_serving_location: observable,
	getServingLocationList: action,
	dropdown_billing_mode_Status_list: observable,
	getBillingModalStatusList: action,
	dropdown_biling_cycle_list: observable,
	dropdown_slab_rate_temp: observable,
	getSlabRateTempList: action,
	dropdown_pice_rate_temp: observable,
	getPiceRateTempList: action,
	dropdown_billing_policy_list: observable,
	getBillingTypeList: action,
	dropdown_billing_type_list: observable,
	validateEwayNumber: action,
	dropdown_drs_status_list: observable,
	getDrsStatusList: action,
	getIntCountriesList: action,
	dropdown_intCountries_list:observable,
	getIntCountriesZipList:action,
	dropdown_intCountries_zip_list:observable,
	getIntShipmentTypesList:action,
	dropdown_intShipmentTypes_list:observable,
	getIntNondgList:action,
	dropdown_intNondg_list:observable,
	getIntInvoiceTypesList:action,
	dropdown_intInvoiceTypes_list:observable,
	getintFwdChargesTypesList:action,
	dropdown_intFwdChargesTypes_list:observable,
	getintCurrencyList:action,
	dropdown_intCurrency_list:observable,
	getintKycTypesList:action,
	dropdown_intKycTypes_list:observable,

});
