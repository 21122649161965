import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isActiveStatus, isCompletedStatus, isFinilized } from "../../../../utils/GlobalFunction";

export default class ManagePieceRateStore {
	agGrid = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	viewValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_country_list = null;
	formField = null;
	zoneFormField = null;
	editZoneRateValues = null;
	validationErrorJson = null;
	viewZoneValues = null;
	editZoneValues = null;
	deleteZoneValues = null;
	activeList = 1;
	current_page_pincode = 1;
	list_data_pincode = null;
	total_pincode = 0;

	// Setup grid and set column size to autosize
	setupPincodeGrid = (params) => {
		this.agGridPincode = params;
	};

	setActiveList = (status) => {
		this.activeList = status;
	}

	setAddMoreValues = (data) => {
		this.formField = data;
	}

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}

	setZoneAddMoreValues = (data) => {
		//this.zoneFormField = zoneData.pre_create;
		this.zoneFormField = data;
	}

	setZoneRateValues = (data) => {
		//this.editZoneRateValues = zoneData.view;
		this.editZoneRateValues = data;
	}

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	setListData = (data) => {
		if (this.agGridPincode) {
			var filter = this.agGridPincode.api.getFilterModel();
			var sort = this.agGridPincode.api.getSortModel();
		}
		this.list_data_pincode = data ? data : null;
		this.total_pincode = data.length;
		//this.current_page = data.list.current_page;
		// var allColumnIdsPincode = [];
		// if (this.agGridPincode && this.agGridPincode.columnApi) {
		// 	this.agGridPincode.columnApi.getAllColumns().forEach(function (column) {
		// 		allColumnIdsPincode.push(column.colId);
		// 	});
		// }
		if (this.agGridPincode) {
			this.agGridPincode.api.setFilterModel(filter);
			this.agGridPincode.api.setSortModel(sort);
		}
	}

	// Filter function for no record found message
	onPincodeFilterChanged = (params) => {
		this.agGridPincode = params;
		if (this.agGridPincode && this.agGridPincode.api.getModel().getRowCount() === 0) {
			this.agGridPincode.api.showNoRowsOverlay();
		}
		if (this.agGridPincode && this.agGridPincode.api.getModel().getRowCount() > 0) {
			this.agGridPincode.api.hideOverlay();
		}
	};



	// call api to get records
	getList = (payload) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.list_data = null;
		if (payload) {
			payload.is_active = this.activeList;
		} else {
			payload = {};
			payload.is_active = this.activeList;
		}
		return Axios.get(`/business/piece-rate-card-templates/list`, { params: payload }).then(({ data }) => {
			if (data.list.data.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					item.is_active_display = item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
					item.is_completed_display = item.is_completed === 1 ? isCompletedStatus[1] : isCompletedStatus[0];
					item.is_draft_display = item.is_draft === 1 ? isFinilized[1] : isFinilized[0];
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter);
				this.agGrid.api.setSortModel(sort);
			}
		});
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// call api to get records
	getPreRequestData = () => {
		return Axios.get(`business/piece-rate-cards/pre-create`).then(({ data }) => {
			this.setAddMoreValues(data.view);
			return data;
		});
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`business/piece-rate-card-templates/piece-rates-template-save`, formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`business/piece-rate-card-templates/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`business/piece-rate-card-templates/destroy/` + formdata.id)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
		return Axios.patch("business/piece-rate-card-templates/" + api_link + formdata.id)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	FinalizeTemplate = (formdata) => {
		return Axios.patch("business/piece-rate-card-templates/finalize/" + formdata.id)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	UpdateEffectiveDate = (formdata) => {
		return Axios.post(`business/piece-rate-card-templates/change-effective-date`, formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (formData) => {
		return Axios.get(`/business/piece-rate-card-templates/details/${formData.id}/${formData.type}`).then(({ data }) => {
			this.setViewValues(data.view);
			this.setEditValues(data.view);
			this.setAddMoreValues(data.pre_create);
			return data;
		});
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	//Get List of coutries for dropdown
	// getRateCardTypeList = (conditional = {}) => {
	// 	return Axios.post(`admin/rate-card-type/lov`, conditional)
	// 		.then(({ data }) => {
	// 			this.dropdown_rate_card_type_list = data.rate_card_type;
	// 			return data;
	// 		})
	// 		.catch((response) => {
	// 			return Promise.reject(response);
	// 		});
	// };

	// call api to get Details By Zone	
	getDetailsByZoneData = (data) => {
		return Axios.get(`business/piece-rate-card-templates/details-by-zone/${data.id}`).then(({ data }) => {
			this.setZoneAddMoreValues(data.pre_create);
			this.setZoneRateValues(data.view);
			this.validationErrorJson = data.error_messages;
			return data;
		});
	};

	// Call add api
	ValidateZoneData = (formdata) => {
		return Axios.post(`business/piece-rate-card-templates/zone-validate`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	AddZoneData = (formdata) => {
		return Axios.post(`business/piece-rate-card-templates/add-zone`, formdata)
			.then(({ data }) => {
				this.getList();
				this.getDetailsByZoneData(this.editZoneRateValues);
				let currentZoneField = this.zoneFormField;

				if (currentZoneField && currentZoneField.zones) {
					currentZoneField.zones.push({
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt,
						is_completed: data.zone.is_completed
					});
					this.setZoneAddMoreValues(currentZoneField);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	// Call add api
	CopyZoneData = (formdata) => {
		return Axios.post(`business/piece-rate-card-templates/piece-rates-template-copy`, formdata)
			.then(({ data }) => {
				this.getList();
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};


	EditZoneData = (formdata) => {
		return Axios.post(`business/piece-rate-card-templates/edit-zone/${formdata.id}`, formdata)
			.then(({ data }) => {
				this.getList();
				if (this.editZoneRateValues) {
					this.getDetailsByZoneData(this.editZoneRateValues);
				}
				let currentZoneField = this.zoneFormField;
				let viewZoneField = this.formField;

				if (currentZoneField && currentZoneField.zones) {
					var index = currentZoneField.zones.findIndex(zone => zone.id === formdata.id);
					currentZoneField.zones.splice(index, 1, {
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt
					});
					this.setZoneAddMoreValues(currentZoneField);
				}

				if (viewZoneField && viewZoneField.zones) {
					let index = viewZoneField.zones.findIndex(zone => zone.id === formdata.id);
					viewZoneField.zones.splice(index, 1, {
						id: data.zone.id,
						name: data.zone.name,
						pin_cnt: data.zone.pin_cnt
					});
					this.setAddMoreValues(viewZoneField);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// set form values to view
	setZoneViewValues = (data) => {
		if (data && data.pincode) {
			this.setListData(data.pincode);
		}
		this.viewZoneValues = data;
	};

	// set form values to view
	setZoneEditValues = (data) => {
		this.editZoneValues = data;
	};

	setZoneDeleteValues = (data) => {
		this.deleteZoneValues = data;
	};

	// call api to get records
	ViewZoneData = (id, prt_id) => {
		return Axios.get(`/business/piece-rate-card-templates/get-zone/${prt_id}/${id}`).then(({ data }) => {
			this.setZoneViewValues(data.view);
			return data;
		});
	};

	// Call delete api
	DeleteZoneData = (formdata) => {
		return Axios.delete(`business/piece-rate-card-templates/zone-rates-delete/${formdata.prt_id}/${formdata.id}`)
			.then(({ data }) => {
				this.getList();
				if (this.editZoneRateValues) {
					this.getDetailsByZoneData(this.editZoneRateValues);
				}
				let currentZoneField = this.zoneFormField;
				let viewZoneField = this.formField;

				if (currentZoneField && currentZoneField.zones) {
					currentZoneField.zones = currentZoneField.zones.filter(zone => zone.id !== formdata.id);
					this.setZoneAddMoreValues(currentZoneField);
				}

				if (viewZoneField && viewZoneField.zones) {
					viewZoneField.zones = viewZoneField.zones.filter(zone => zone.id !== formdata.id);
					this.setAddMoreValues(viewZoneField);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	doExportDownload = (data) => {
        return Axios.get(`business/piece-rate-card-templates/print/${data.id}`)
            .then(({ data }) => {
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setCompletedFilterList = (data) => {
		this.isCompletedFilterList = data;
	}
}

decorate(ManagePieceRateStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	statusValues: observable,
	dropdown_rate_card_type_list: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	getList: action,
	getStatesList: action,
	onFilterChanged: action,
	setViewValues: action,
	viewValues: observable,
	formField: observable,
	zoneFormField: observable,
	editZoneRateValues: observable,
	AddZoneData: action,
	EditZoneData: action,
	DeleteZoneData: action,
	validationErrorJson: observable,
	setZoneEditValues: action,
	viewZoneValues: observable,
	editZoneValues: observable,
	deleteZoneValues: observable,
	setIsActiveFilterList: action,
	setCompletedFilterList: action,
	activeList: observable,
	setDefaultValue: action
});
