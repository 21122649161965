export const Patterns = {
    PHONE: /^[0-9 /()-]*$/,
    MOBILE: /^[0-9]*$/,
    PINCODE: /^[1-9]{1}[0-9]{5}$/,
    USERNAME: /^[A-Za-z0-9_.-]*$/,
    ACCOUNT: /^([0-9]+)$/,
    EMAIL: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
    PASSWORD: /^[a-zA-Z0-9!@#$%^&*()-.+,/_\\]*$/,
    PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
    GSTIN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z1-9]{1}[Z]{1}[A-Z0-9]{1}$/,
    FLOATVALUE: /^\d*(\.\d{2})?$/,
    NUMBER: /^[0-9\b]+$/,
    ALPHANUMERIC: /^[A-Za-z0-9]*$/,
    LOGINUSERNAME: /^[A-Za-z0-9_. \-()]*$/,
    AADHAR: /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
    DRIVINGLICENSE: /^[A-Z](?:\d[- ]*){14}$/,
    ALPHANUMERICSPACIAL: /^[A-Za-z0-9_. \-()]*$/,
    SECRETE_CODE: /^[a-zA-Z0-9!@#$%^&*()-.+,/_\\]*$/,
    TAN: /^([A-Z]){4}[0-9]{5}([A-Z]){1}$/,
    LICENSE: /^[A-Za-z0-9 -]*$/,
    LATLNG: /^(\+|-)?(?:90(?:(?:\.0{6,12})?)|(?:[0-9]|[1-8][0-9]|[1-8][0-9][0-9])(?:(?:\.[0-9]{6,12})?))$/,
    SERVICEAREA: /^[0-9 ]*$/,
    WEB: /^[a-z0-9_.@-]*$/,
    EWAYNUMBER: /^[0-9\b]{12}$/,
    CONNUMBER: /^(\d{11}|\d{13})$/,
    ALPHABATE: /^[A-Z]*$/,
    CONSIGNMENTNUMBER:/^[A-Za-z0-9/-]*$/,
    NUMBERWITHMINUS: /^[0-9-+]+$/,
    CON_NO_MIN_MAX_LIMIT:/^(?:[1-9][0-9]?|100)$/
}