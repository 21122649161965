import { convertCaseBookingSenderAddress, convertTextToID } from "../../../../utils/GlobalFunction";
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageInscanForLocationStore {
    agGrid = null;
    per_page = ServerGridConfig.options.paginationPageSize;
    current_page = 1;
    list_data = null;
    editValues = null;
    deleteValues = null;
    viewValues = null;
    total = 0;
    allColumnIds = [];
    serviceFilterList = null;
    isActiveFilterList = null;
    // set form values to edit
    setEditValues = (data) => {
        this.editValues = data;
    };

    // set form values to view
    setViewValues = (data) => {
        if (data) {
            data.address = convertCaseBookingSenderAddress(data);
        }
        this.viewValues = data;
    };

    // set form values to delete
    setDeleteValues = (data) => {
        this.deleteValues = data;
    };

    // set form values to status
    setStatusValues = (data) => {
        this.statusValues = data;
    };

    setIsActiveFilterList = (data) => {
        this.isActiveFilterList = data;
    }
    setServiceFilterList = (data) => {
        this.serviceFilterList = data;
    }

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params;
        const { api } = params;
        this.agGrid.api.setFilterModel({
            doi_sr: { dateFrom: moment().subtract(7, "day").format("YYYY-MM-DD"), dateTo: moment().format("YYYY-MM-DD"), type: "inRange", filterType: "date" }
        });
        let datasource = this.createDatasource(ServerGridConfig.options);
        api.setServerSideDatasource(datasource)
    };

    // change page size, default page size is ServerGridConfig.options.paginationPageSize
    setPageSize = (page = this.per_page) => {
        this.per_page = page;
        if (this.agGrid) {
            this.agGrid.api.paginationSetPageSize(parseInt(page));
        }
    };

    // call api to get records
    getList = (payload) => {
        return Axios.post(`/bookings/external/list`, payload).then(({ data }) => {
            if (data.list.data.length) {
                let startPage = (data.list.current_page - 1) * data.list.per_page;
                data.list.data.map((item, index) => {
                    item.srno = startPage + index + 1;
                    return null;
                });
            }
            this.list_data = data.list ? data.list.data : null;
            this.total = data.list.total;
            this.current_page = data.list.current_page;
            return data;
        });
    };
    // Filter function for no record found message
    changeFilterAndSort = (params) => {
        var final_filter = params.filterModel;
        var final_sort = params.sortModel;
        if (final_filter["status"]) {
            final_filter['status'].values = convertTextToID(final_filter['status'], this.isActiveFilterList, 'name', 'id')
        }
        if (final_filter["product_id"]) {
            final_filter["product_id"].values = convertTextToID(final_filter["product_id"], this.serviceFilterList, "name", "id")
        }
        return { final_filter, final_sort }
    }
    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params;
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
            this.agGrid.api.hideOverlay();
        }
    };

    // Create data source to display record in table
    createDatasource = (gridOptions) => {
        return {
            gridOptions,
            getRows: (params) => {
                var filter_data = this.changeFilterAndSort(params.request);
                var payload = {
                    filter_data: filter_data.final_filter,
                    sort_data: filter_data.final_sort,
                    per_page: params.request.endRow - params.request.startRow,
                    page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
                }
                this.getList(payload).then((data) => {
                    if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
                    else { this.agGrid.api.hideOverlay() }
                    params.successCallback(data.list.data, data.list.total)
                    var allColumnIds = []
                    if (this.agGrid && this.agGrid.columnApi && data.total) {
                        this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                            allColumnIds.push(column.col_id)
                        })
                    }
                })
            }
        }
    }

    // Call add api
    AddData = (formdata) => {
        return Axios.post(`/bookings/external/new`, formdata)
            .then(({ data }) => {
                if (this.agGrid) {
                    this.setupGrid(this.agGrid);
                }
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };

    // Call edit api
    EditData = (formdata) => {
        return Axios.post(`/bookings/external/edit/${formdata.id}`, formdata)
            .then(({ data }) => {
                if (this.agGrid) {
                    this.setupGrid(this.agGrid);
                }
                return data;
            })
            .catch(({ response: { data } }) => {
                var errors = [];
                var notify = null;
                const { NOTIFICATION, ...fieldErrors } = data.STATUS;
                if (data && data.STATUS) {
                    if (NOTIFICATION) {
                        notify = NOTIFICATION[0];
                    }
                    Object.keys(fieldErrors).forEach((name) => {
                        errors.push({ name, errors: data.STATUS[name] });
                    });
                }
                return Promise.reject({ errors, notify });
            });
    };
    // Call delete api
    DeleteData = (formdata) => {
        return Axios.delete(`/bookings/external/destroy/` + formdata.id)
            .then(({ data }) => {
                if (this.agGrid) {
                    this.setupGrid(this.agGrid);
                }
                return data;
            })
            .catch((response) => {
                return Promise.reject(response);
            });
    };
    // call api to get records
    ViewData = (ID) => {
        return Axios.get(`/bookings/external/details/${ID}`).then(({ data }) => {
            this.setViewValues(data.view);
            return data;
        })
    };
}
decorate(ManageInscanForLocationStore, {
    per_page: observable,
    agGrid: observable,
    list_data: observable,
    editValues: observable,
    deleteValues: observable,
    total: observable,
    allColumnIds: observable,
    setupGrid: action,
    setPageSize: action,
    setEditValues: action,
    setDeleteValues: action,
    getList: action,
    changeFilterAndSort: action,
    onFilterChanged: action,
    createDatasource: action,
    setViewValues: action,
    viewValues: observable,
    setIsActiveFilterList: action,
    setServiceFilterList: action,
});