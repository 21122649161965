import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import { Constant } from "../../../../config/Constant";
import ServerGridConfig from "../../../../config/ServerGridConfig";

export default class ManageClientMessageStore {
  filter = null;
  agGridList = null;
  per_page_list = ServerGridConfig.options.paginationPageSize;
  current_page_list = 1;
  list_data = null;
  total_list = 0;
  allColumnIdsList = [];
  selectAll = false;
  selectCount = 0;

  setCheckState = (data) => {
    this.selectAll = data;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGridList = params;
    const { api } = params;
    let datasource = this.createDatasource(ServerGridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGridList.api.paginationSetPageSize(parseInt(page));
    }
  };

  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;
    return { final_filter, final_sort };
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = this.changeFilterAndSort(params.request);
        var payload = {
          filter_data: filter_data.final_filter,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          advance_filter: this.generatePayload(this.filter),
          page: Math.ceil(
            (params.request.startRow + 1) /
              (params.request.endRow - params.request.startRow)
          ),
        };
        this.getList(payload).then((data) => {
          if (data.list.total === 0) {
            this.agGridList.api.showNoRowsOverlay();
          } else {
            this.agGridList.api.hideOverlay();
          }
          params.successCallback(data.list.data, data.list.total);
          var allColumnIds = [];
          if (this.agGridList && this.agGridList.columnApi && data.total) {
            this.agGridList.columnApi
              .getAllColumns()
              .forEach(function (column) {
                allColumnIds.push(column.col_id);
              });
          }
        });
      },
    };
  };

  // set form values to  customefilter
  setListFilterValues = (data = null) => {
    return new Promise((resolve) => {
      this.filter = data;
      if (this.agGridList) {
        this.setupGridList(this.agGridList);
      }
      resolve();
    });
  };

  // Setup grid and set column size to autosize
  setupGridList = (params) => {
    this.agGridList = params;
    const { api } = params;
    let datasource = this.createDatasource(ServerGridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSizeList = (page = this.per_page_list) => {
    this.per_page_list = page;
    if (this.agGridList) {
      this.agGridList.api.paginationSetPageSize(parseInt(page));
    }
  };

  // Filter function for no record found message
  onFilterChangedList = (params) => {
    this.agGridList = params;
    if (this.agGridList && this.agGridList.api.getModel().getRowCount() === 0) {
      this.agGridList.api.showNoRowsOverlay();
    }
    if (this.agGridList && this.agGridList.api.getModel().getRowCount() > 0) {
      this.agGridList.api.hideOverlay();
    }
  };

  generatePayload = (filter) => {
    if (filter?.from_date) {
      filter.from_date = moment(filter.from_date).format(Constant.PAYLOAD_DATE);
    }
    if (filter?.to_date) {
      filter.to_date = moment(filter.to_date).format(Constant.PAYLOAD_DATE);
    }
    return filter;
  };

  refreshList = () => {
    if (this.agGridList) {
      this.setupGrid(this.agGridList);
    }
  };
  // call api to get records
  getList = (payload) => {
    return Axios.post(`/communication/client-messages/list`, payload).then(
      ({ data }) => {
        if (data.list?.data?.length) {
          let startPage = (data.list.current_page - 1) * data.list.per_page;
          data.list.data.map((item, index) => {
            item.srno = startPage + index + 1;
            return null;
          });
        }
        this.list_data = data.list ? data.list.data : null;
        this.total_list = data.list.total;
        this.current_page_list = data.list.current_page;
        return data;
      }
    );
  };

  sendMessage = (data) => {
    return Axios.post("/communication/client-messages/send", data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  onFirstDataRendered = () => {
    if (this.selectAll === true && this.agGridList) {
      this.agGridList.api.forEachNode((node) => {
        this.selectCount++;
        return node.setSelected(!!node.data);
      });
    }
  };

  onSelectAllRender = (selectAllFlag) => {
    if (selectAllFlag === true && this.agGridList) {
      this.onFirstDataRendered();
    } else if (this.agGridList) {
      this.agGridList.api.forEachNode((node) => {
        return node.setSelected(false);
      });
    }
  };

  // call api to image upload
  uploadImage = (data) => {
    return Axios.post(`communication/client-messages/img-upload`, data)
      .then(({ data }) => {
        return data.file;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // call api to delete image
  deleteImage = (data) => {
    return Axios.post(`/communication/client-messages/img-delete`, data)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        const { NOTIFICATION, ...fieldErrors } = data.STATUS;
        if (data && data.STATUS) {
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };
}

decorate(ManageClientMessageStore, {
  per_page_list: observable,
  agGridList: observable,
  list_data: observable,
  total_list: observable,
  allColumnIdsList: observable,
  setupGridList: action,
  setPageSizeList: action,
  getList: action,
  setListFilterValues: action,
  onFilterChangedList: action,
  filter: observable,
  selectAll: observable,
  selectCount: observable,
  onFirstDataRendered: action,
  onSelectAllRender: action,
  setCheckState: action,
});
