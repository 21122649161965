/* eslint-disable no-self-assign */
import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID, convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";

export default class ManageDrsWebStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	deleteValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	stockRates = null;
	paymentDetails = null;
	listPurpose = 1;
	orderConfirmationValues = null;
	dropdown_order_status_list = null;
	dropdown_payment_status_list = null;
	viewConValues = null;
	DrspageFormDetails = null;
	deleteConValues = null;

	// set form values to delete Item
	setViewConValues = (data) => {
		this.viewConValues = data;
	};

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}
	setOrderConfirmationValue = (data) => {
		this.orderConfirmationValues = data
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setIsPlatformFilterList = (data) => {
		this.isPlatformFilterList = data;
	}

	setIsStatusFilterList = (data) => {
		this.isStatusFilterList = data;
	}

	//set list purpose
	setListPurpose = (purpose) => {
		this.listPurpose = purpose;
		this.agGrid.api.setFilterModel(null)
		this.agGrid.api.setSortModel(null)
		this.agGrid.columnApi.resetColumnState()
		this.agGrid.api.onFilterChanged(null)
		this.refreshList();
	};

	setOrderStatusList = (data) => {
		this.dropdown_order_status_list = data;
	};

	setPaymentStatusList = (data) => {
		this.dropdown_payment_status_list = data;
	};

	// set form values to delete Item
	setDeleteConValues = (data) => {
		this.deleteConValues = data;
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`bookings/drs/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					//     item.cities = item.cities && item.cities.name
					return null;
				});
			}

			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.isStatusFilterList, 'name', 'id')
		}
		if (final_filter["payment_status"]) {
			final_filter['payment_status'].values = convertTextToID(final_filter['payment_status'], this.dropdown_payment_status_list, 'name', 'id')
		}
		if (final_filter["platform"]) {
			final_filter['platform'].values = convertTextToID(final_filter['platform'], this.isPlatformFilterList, 'name', 'id')
		}
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					purpose: this.listPurpose,
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	AddItem = (formdata) => {
		return Axios.post(`bookings/drs/add-item`, formdata)
			.then(({ data }) => {
				if (this.viewValues && this.viewValues.drs_details) {
					let view = this.viewValues;
					let currentCon = view.drs_details.slice();
					let res = data?.view?.drs_details;
					currentCon.push(res);
					view.drs_details = currentCon;
					this.setViewValues(view)
				}
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				var resultData = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				if (data?.resultData) {
					resultData = data?.resultData;
				}
				return Promise.reject({ errors, notify, resultData });
			});
	};

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`bookings/drs/drs-save`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				this.setViewValues(data.view)
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				var resultData = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}

					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				if (data?.resultData) {
					resultData = data?.resultData;
				}
				return Promise.reject({ errors, notify, resultData });
			});
	};

	AddRTOData = (formdata) => {
		return Axios.post(`bookings/return-to-origin/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				var resultData = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}

					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				if (data?.resultData) {
					resultData = data?.resultData;
				}
				return Promise.reject({ errors, notify, resultData });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`bookings/drs/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`bookings/drs/details/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		});
	};

	ConViewData = (ID) => {
		return Axios.get(`bookings/drs/con-details/${ID}`).then(({ data }) => {
			this.setViewConValues(data.view);
			return data;
		});
	};

	// call api to get records
	PrintDetails = (formdata) => {
		return Axios.get(`bookings/drs/print-details/${formdata.id}/${formdata.preprinted}`).then(({ data }) => {
			return data;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`bookings/drs/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call delete api
	DeleteConData = (formdata) => {
		return Axios.post(`/bookings/drs/delete-item`, formdata)
			.then(({ data }) => {
				if (this.viewValues && this.viewValues.drs_details && this.viewValues.drs_details.length > 0) {
					let view = this.viewValues;
					let currentCon = []
					currentCon = view.drs_details.filter(item => item.id !== formdata.drs_detail_id);
					view.drs_details = currentCon ?? [];
					this.setViewValues(view)
					// this.setEditValues(view)
				}
				// if (this.viewValues && this.viewValues.drs_details && this.viewValues.drs_details.total_items > 0) {
				// 	this.setViewValues(data.view)
				// }
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call sticker cancellation api
	CancellationData = (formdata) => {
		return Axios.post(`bookings/drs/cancellation`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get Default KYC Form 
	getDrspageForm = (conditional = {}) => {
		//conditional.id = this.editValues.id;
		return Axios.post(`bookings/drs/page-details`, conditional).then(({ data }) => {
			this.DrspageFormDetails = data.view;
			return data;
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	// Call delete api
	deleteDrspageData = (formdata) => {
		return Axios.post(`bookings/drs/page-destroy`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	//
	uploadDrspageData = (formdata) => {
		return Axios.post(`bookings/drs/page-upload`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	UnlockPlatformData = (formdata) => {
		return Axios.patch("bookings/drs/unlock/" + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get Default KYC Form 
	MarksAsDelivered = (payload) => {
		//conditional.id = this.editValues.id;
		return Axios.post(`bookings/drs/mark-as-delivered`, payload).then(({ data }) => {
			//this.setViewValues(data.view)
			if (this.agGrid) {
				this.setupGrid(this.agGrid);
			}
			return data;
		}).catch((response) => {
			return Promise.reject(response);
		});
	};
	//Get Default KYC Form 
	ChangeItemStatus = (payload) => {
		//conditional.id = this.editValues.id;
		return Axios.post(`bookings/drs/change-item-status`, payload).then(({ data }) => {
			if (this.viewValues && this.viewValues.drs_details) {
				let view = this.viewValues;
				let res = data?.view?.drs_details;
				let currentCon = view.drs_details
				currentCon.push(res);
				view.drs_details = currentCon;
				this.setViewValues(view)
			}
			if (this.agGrid) {
				this.setupGrid(this.agGrid);
			}
			return data;
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	//Get Default KYC Form 
	ChangeConsigneeName = (payload) => {
		return Axios.post(`/bookings/drs/change-consignee`, payload).then(({ data }) => {
			if (this.viewValues && this.viewValues.drs_details) {
				let view = this.viewValues;
				let currentCon = view.drs_details.filter(item => item.id === payload.id);
				if (currentCon[0].receiver) {
					currentCon[0].receiver = payload.consignee_name;
				}
				this.setViewValues(view)
			}
			if (this.agGrid) {
				this.setupGrid(this.agGrid);
			}
			return data;
		}).catch((response) => {
			return Promise.reject(response);
		});
	};
		
}

decorate(ManageDrsWebStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	statusValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_center_list: observable,
	paymentDetails: observable,
	typeMode: observable,
	setTypeMode: action,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setCompletePendingFilterList: action,
	completePendingFilterList: observable,
	getStockRate: action,
	stockRates: observable,
	setListPurpose: action,
	listPurpose: observable,
	setOrderConfirmationValue: action,
	orderConfirmationValues: observable,
	GetStickerOrderData: action,
	dropdown_order_status_list: observable,
	dropdown_payment_status_list: observable,
	viewConValues: observable,
	setViewConValues: action,
	DrspageFormDetails: observable,
	getDrspageForm: action,
	setIsPlatformFilterList: action,
	isPlatformFilterList: observable,
	setDeleteConValues: action,
	deleteConValues: observable,
	setIsStatusFilterList:action,
	isStatusFilterList:observable
});