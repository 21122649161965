import Axios from "axios"
import { action, decorate, observable } from "mobx"
export default class WidgetStore {
	list_public_holidays = null;
	list_marriage_anniversaries = null;
	list_birthdays = null;
	list_zform = null;
	list_zform_post = null;
	list_inquiries_by_ratings = null;
	list_inquiries_by_status = null;
	list_active_status = null;
	list_detail_status = null;
	list_by_status = null;
	list_zform_pending_fund = null;
	list_insurance_active = null;
	list_insurance_active_fund = null;
	list_insurance_quotation = null;
	list_active_finance = null;
	unregisteredBookings = null;
	unregisteredPackets = null;
	unregisteredBag = null;
	pendinBookingLoad = null;
	pendinDrsLoad = null;
	pendinIncomingLoad = null

	getUnregisteredBookingsDetail = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				localStorage.setItem("unregistered-bookings", JSON.stringify(data));
				this.unregisteredBookings = data		
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

	getUnregisteredPacketsDetail = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				localStorage.setItem("unregistered-packets", JSON.stringify(data));
				this.unregisteredPackets = data	
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

	getUnregisteredBagDetail = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				localStorage.setItem("unregistered-bag", JSON.stringify(data));
				this.unregisteredBag = data	
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

	geIncomingLoadDetail = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				localStorage.setItem("incoming-Load", JSON.stringify(data.view));
				this.pendinIncomingLoad = data.view	
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}
	gePendingLoadDetail = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				localStorage.setItem("Pending-Load", JSON.stringify(data.view));
				this.pendinPendingLoad = data.view	
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}
	gePendingDRSLoadDetail = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				localStorage.setItem("pendin-drs-load", JSON.stringify(data.view));
				this.pendinDrsLoad = data.view	
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

	gePendinBookingLoadDetail = (api_url, list_name, formdata = {}) => {
		this[list_name] = null
		return Axios.post(api_url, formdata)
			.then(({ data }) => {
				localStorage.setItem("pendin-booking-load", JSON.stringify(data.view));
				this.pendinBookingLoad = data.view	
				return data;
			}).catch((response) => {
				if (response) {
					return Promise.reject(response);
				}
				return Promise.reject(false);
			});
	}

}

decorate(WidgetStore, {
	list_public_holidays: observable,
	list_marriage_anniversaries: observable,
	list_birthdays: observable,
	list_zform: observable,
	list_zform_post: observable,
	list_inquiries_by_ratings: observable,
	list_inquiries_by_status: observable,
	list_active_status: observable,
	list_detail_status: observable,
	list_by_status: observable,
	list_zform_pending_fund: observable,
	list_insurance_active: observable,
	list_insurance_active_fund: observable,
	list_insurance_quotation: observable,
	list_active_finance: observable,
	getInquiriesList: action,
	getListData: action,
	resetOnLogin: action,
	unregisteredBookings:observable,
	unregisteredPackets:observable,
	unregisteredBag:observable,
	pendinBookingLoad:observable,
	pendinDrsLoad:observable,
	pendinIncomingLoad:observable
});
