import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isBillingJobstatus } from "../../../../utils/GlobalFunction";
import moment from "moment";
import { Constant } from "../../../../config/Constant";

export default class ManageHOBillingStore {
	agGrid = null;
	billingJobsagGrid = null
	agGridBillingRequests = null;
	agGridBillingNotRequests = null;
	agGridBillingDueDetail = null;
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	total = 0;
	allColumnIds = [];
	dropdown_state_list = null;
	deleteValues = null;
	createJobValues = null
	startEndDate = null
	viewValues = null;
	businessValues = null
	restartValues = null;
	editValues = null;
	editBusinessValues = null;
	fromToDate = moment().subtract(1, 'month')
	dateRange = moment().format(Constant.DEFAULT_MONTH_YEAR_FORMAT)
	findHub  = null;
	viewDetailsValues = null

	setFromToDate = (data) => {
		this.fromToDate = data;
	};
	setDateRange = (data) => {
		this.dateRange = data.format(Constant.DEFAULT_MONTH_YEAR_FORMAT);
	};
	setFindHub = (data) => {
		this.findHub = data;
	};
	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
	};

	// HO Billing Jobs Setup grid and set column size to autosize
	setupBillingJobsGrid = (params) => {
		this.billingJobsagGrid = params;
	};

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to edit
	setEditBusinessValues = (data) => {
		this.editBusinessValues = data;
	};
	// Billing Requests Setup grid and set column size to autosize
	setupGridBillingRequests = (params) => {
		this.agGridBillingRequests = params;
	};

	// Billing not Requests Setup grid and set column size to autosize
	setupGridBillingNotRequests = (params) => {
		this.agGridBillingNotRequests = params;
	};

	// Billing Due detail Setup grid and set column size to autosize
	setupGridBillingDueDetail = (params) => {
		this.agGridBillingDueDetail = params;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to View
	setViewValues = (data) => {
		this.viewValues = data;
	};

	setViewDetailsValues = (data) => {
		this.viewDetailsValues = data;
	}

	// set form values to business
	setBusinessValues = (data) => {
		this.businessValues = data;
	};

	setRestartValues = (data) => {
		this.restartValues = data;
	};

	// set form values to delete
	setCreateJobeValues = (data) => {
		this.createJobValues = data;
	};

	setStartEndDate = (data) => {
		this.startEndDate = data;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeBillingJob = (page = this.per_page) => {
		this.per_page = page;
		if (this.billingJobsagGrid) {
			this.billingJobsagGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeBillingJobRequests = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGridBillingRequests) {
			this.agGridBillingRequests.api.paginationSetPageSize(parseInt(page));
		}
	};
	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSizeBillingJobNotRequests = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGridBillingNotRequests) {
			this.agGridBillingNotRequests.api.paginationSetPageSize(parseInt(page));
		}
	};

	setPageSizeBillingJobDueDetail = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGridBillingDueDetail) {
			this.agGridBillingDueDetail.api.paginationSetPageSize(parseInt(page));
		}
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}
	refreshBillingJobsList = () => {
		if (this.billingJobsagGrid) {
			this.setupBillingJobsGrid(this.billingJobsagGrid);
		}
	}
	refreshRequestJobsList = () => {
		if (this.agGridBillingRequests) {
			this.setupGridBillingRequests(this.agGridBillingRequests);
		}
	}
	refreshNotRequestJobsList = () => {
		if (this.agGridBillingNotRequests) {
			this.setupGridBillingNotRequests(this.agGridBillingNotRequests);
		}
	}
	refreshDueDetailJobsList = () => {
		if (this.agGridBillingDueDetail) {
			this.setupGridBillingDueDetail(this.agGridBillingDueDetail);
		}
	}

	// call api to get records
	previewByHubgetList = (payload) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.list_data = null;
		return Axios.post(`ho-activities/jobs/preview-by-hub`, { advance_filter: payload }).then(({ data }) => {
			this.setStartEndDate([data.start, data.end])
			if (data.list.length) {
				data.list.map((item, index) => {
					item.srno = index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter);
				this.agGrid.api.setSortModel(sort);
			}
		});
	};

	// call api to get records
	previewByGroupgetList = (payload) => {
		if (this.agGrid) {
			var filter = this.agGrid.api.getFilterModel();
			var sort = this.agGrid.api.getSortModel();
		}
		this.list_data = null;
		return Axios.post(`ho-activities/jobs/preview-by-group`, { advance_filter: payload }).then(({ data }) => {
			this.setStartEndDate([data.start, data.end])
			if (data.list.length) {
				data.list.map((item, index) => {
					item.srno = index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGrid && this.agGrid.columnApi) {
				this.agGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGrid) {
				this.agGrid.api.setFilterModel(filter);
				this.agGrid.api.setSortModel(sort);
			}
		});
	};

	HOBillingDueDetailGetList = () => {
		if (this.agGridBillingDueDetail) {
			var filter = this.agGridBillingDueDetail.api.getFilterModel();
			var sort = this.agGridBillingDueDetail.api.getSortModel();
		}		
		this.list_data = null;
		return Axios.get(`/ho-activities/jobs/requested-bill-details`).then(({ data }) => {
			if (data.list.data?.length) {
				data.list.data.map((item, index) => {
					item.srno = index + 1;
					item.completed = item.is_completed === 1 ? "Yes" : "No"
					return null;
				});
			}
			this.list_data = data.list?.data ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGridBillingDueDetail && this.agGridBillingDueDetail.columnApi) {
				this.agGridBillingDueDetail.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGridBillingDueDetail) {
				this.agGridBillingDueDetail.api.setFilterModel(filter);
				this.agGridBillingDueDetail.api.setSortModel(sort);
			}
		});
	};

	generateDueBillsSummary = (data) => {
		return Axios.post(`/ho-activities/jobs/generate-due-bills-summary`, data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	doExportDownloadCSV = (id) => {
		return Axios.get(`/ho-activities/jobs/export-requested-bill-details/${id}`)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	jobCreateByHub = (data) => {
		return Axios.post(`/ho-activities/jobs/create-by-hub`, data)
			.then(({ data }) => {
				this.previewByHubgetList({ rp_id: this.createJobValues.rp_id,from_to_date: this.fromToDate.format(Constant.DEFAULT_MONTH_YEAR_FORMAT) });
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	jobCreateByLocationGroup = (data) => {
		return Axios.post(`/ho-activities/jobs/create-by-group`, data)
			.then(({ data }) => {
				this.previewByGroupgetList({from_to_date: this.fromToDate.format(Constant.DEFAULT_MONTH_YEAR_FORMAT)});
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	jobCreateBySpecificHubLocation = (data) => {
		return Axios.post(`/ho-activities/jobs/create-for-center`, data)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	HOBillingJobsGetList = (data) => {
		if (this.billingJobsagGrid) {
			var filter = this.billingJobsagGrid.api.getFilterModel();
			var sort = this.billingJobsagGrid.api.getSortModel();
		}
		this.list_data = null;
		return Axios.post(`/ho-activities/jobs/list`, data).then(({ data }) => {
			this.setStartEndDate([data.start, data.end])
			if (data.list.length) {
				data.list.map((item, index) => {
					// item.srno = index + 1;
					item.is_status = isBillingJobstatus[item.status]
					return null;
				});
			}
			this.list_data = data.list ? data.list : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.billingJobsagGrid && this.billingJobsagGrid.columnApi) {
				this.billingJobsagGrid.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.billingJobsagGrid) {
				this.billingJobsagGrid.api.setFilterModel(filter);
				this.billingJobsagGrid.api.setSortModel(sort);
			}
		});
	};
	HOBillingRequestsGetList = (data) => {
		if (this.agGridBillingRequests) {
			var filter = this.agGridBillingRequests.api.getFilterModel();
			var sort = this.agGridBillingRequests.api.getSortModel();
		}		
		this.list_data = null;
		return Axios.post(`/ho-activities/jobs/list-threads`, data).then(({ data }) => {
			this.setStartEndDate([data.start, data.end])
			if (data.list.length) {
				data.list.map((item, index) => {
					item.srno = index + 1;
					item.is_status = isBillingJobstatus[item.status]
					return null;
				});
			}
			this.list_data = data.list ? data.list : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGridBillingRequests && this.agGridBillingRequests.columnApi) {
				this.agGridBillingRequests.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGridBillingRequests) {
				this.agGridBillingRequests.api.setFilterModel(filter);
				this.agGridBillingRequests.api.setSortModel(sort);
			}
		});
	};

	HOBillingNotRequestsGetList = (data) => {
		if (this.agGridBillingNotRequests) {
			var filter = this.agGridBillingNotRequests.api.getFilterModel();
			var sort = this.agGridBillingNotRequests.api.getSortModel();
		}		
		this.list_data = null;
		return Axios.post(`/ho-activities/jobs/requested-locations`, data).then(({ data }) => {
			this.setStartEndDate([data.start, data.end])
			if (data.list.length) {
				data.list.map((item, index) => {
					item.srno = index + 1;
					item.is_status = isBillingJobstatus[item.status]
					return null;
				});
			}
			this.list_data = data.list ? data.list : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			var allColumnIds = [];
			if (this.agGridBillingNotRequests && this.agGridBillingNotRequests.columnApi) {
				this.agGridBillingNotRequests.columnApi.getAllColumns().forEach(function (column) {
					allColumnIds.push(column.colId);
				});
			}
			if (this.agGridBillingNotRequests) {
				this.agGridBillingNotRequests.api.setFilterModel(filter);
				this.agGridBillingNotRequests.api.setSortModel(sort);
			}
		});
	};

	// Call delete api
	DeleteHOBillingRequests = (formdata, openFrom) => {
		return Axios.post(`/ho-activities/jobs/cancellation`, formdata)
			.then(({ data }) => {
				if (openFrom === "HOBillingJobsView" || openFrom === "BillingJobList") {
					this.HOBillingJobsGetList();
				}
				else {
					this.HOBillingRequestsGetList({ from_to_date:this.dateRange, hub_id:this.findHub});
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// Call delete api
	RestartHOBillingRequests = (formdata, openFrom) => {
		return Axios.post(`/ho-activities/jobs/restart`, formdata)
			.then(({ data }) => {
				if (openFrom === "HOBillingJobsView" || openFrom === "BillingJobList") {
					this.HOBillingJobsGetList();
				}
				else {
					this.HOBillingRequestsGetList({ from_to_date:this.dateRange, hub_id:this.findHub});
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	jobsViewData = (ID) => {
		return Axios.get(`/ho-activities/jobs/details/${ID}`).then(({ data }) => {
			this.setViewValues(data);
			return data;
		});
	};
	// call api to get records
	BusinessViewData = (data) => {
		return Axios.get(`/ho-activities/jobs/preview-confirm-business/${data.thread_id}`).then(({ data }) => {
			this.setBusinessValues(data.preview);
			return data;
		});
	};

	// call api to get records
	ViewDetailsData = (formdata) => {
		return Axios.post(`/ho-activities/jobs/requested-locations/details`, formdata).then(({ data }) => {
		this.setViewDetailsValues(data.details);
			return data;
		});
	};

	// Call delete api
	BusinessDataConfirm = (formdata, openFrom) => {
		return Axios.post(`/ho-activities/jobs/confirm-business`, formdata)
			.then(({ data }) => {
				if (openFrom === "HOBillingJobsView") {
					this.HOBillingJobsGetList();
				}				
				this.HOBillingRequestsGetList({ from_to_date:this.dateRange, hub_id:this.findHub});
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	doFinalization = (data) => {
		return Axios.post(`/ho-activities/ho-invoice/finalization`, {invoice_ids : data.thread_id })
			.then(({ data }) => {
				this.agGridBillingRequests.api.deselectAll();
				this.HOBillingRequestsGetList({ from_to_date:this.dateRange, hub_id:this.findHub});
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	doRevalidate = (formdata) => {
		return Axios.post(`/ho-activities/jobs/revalidate-job`, formdata)
			.then(({ data }) => {
				this.HOBillingRequestsGetList({ from_to_date:this.dateRange, hub_id:this.findHub});
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};
	onBillingRequestsFilterChanged = (params) => {
		if (this.agGridBillingRequests && this.agGridBillingRequests.api.getModel().getRowCount() === 0) {
			this.agGridBillingRequests.api.showNoRowsOverlay();
		}
		if (this.agGridBillingRequests && this.agGridBillingRequests.api.getModel().getRowCount() > 0) {
			this.agGridBillingRequests.api.hideOverlay();
		}
	};
	onBillingNotRequestsFilterChanged = (params) => {
		if (this.agGridBillingNotRequests && this.agGridBillingNotRequests.api.getModel().getRowCount() === 0) {
			this.agGridBillingNotRequests.api.showNoRowsOverlay();
		}
		if (this.agGridBillingNotRequests && this.agGridBillingNotRequests.api.getModel().getRowCount() > 0) {
			this.agGridBillingNotRequests.api.hideOverlay();
		}
	};
	onBillingDueDetailFilterChanged = (params) => {
		if (this.agGridBillingDueDetail && this.agGridBillingDueDetail.api.getModel().getRowCount() === 0) {
			this.agGridBillingDueDetail.api.showNoRowsOverlay();
		}
		if (this.agGridBillingDueDetail && this.agGridBillingDueDetail.api.getModel().getRowCount() > 0) {
			this.agGridBillingDueDetail.api.hideOverlay();
		}
	};

}

decorate(ManageHOBillingStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	previewByHubgetList: action,
	onFilterChanged: action,
	setDeleteValues: action,
	deleteValues: observable,
	setCreateJobeValues: action,
	createJobValues: observable,
	setStartEndDate: action,
	startEndDate: observable,
	setViewValues: action,
	viewValues: observable,
	setupGridBillingRequests: action,
	agGridBillingRequests: observable,
	setRestartValues: action,
	restartValues: observable,
	editValues: observable,
	setEditValues: action,
	setupBillingJobsGrid: action,
	billingJobsagGrid: observable,
	setFromToDate: action,
	fromToDate: observable,
	setBusinessValues: action,
	businessValues: observable,
	setPageSizeBillingJob: action,
	setPageSizeBillingJobRequests: action,
	editBusinessValues: observable,
	setEditBusinessValues: action,
	setDateRange: action,
	dateRange: observable,
	setFindHub: action,
	findHub: observable,
	ViewDetailsData:action,
	setViewDetailsValues:action,
	viewDetailsValues: observable,
});
