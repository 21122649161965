import Axios from "axios"
import { action, decorate, observable } from "mobx"
import LocalGridConfig from "../../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../../utils/GlobalFunction";

export default class BillingTypeStore {
    agGrid = null;
    per_page = LocalGridConfig.options.paginationPageSize;
    current_page = 1;
    list_data = null;
    total = 0;
    allColumnIds = []

    //setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params;
    }

    setIsActiveFilterList = (data) => {
        this.isActiveFilterList = data;
    }

    //change page size // change page size, default page size is LocalGridConfig.options.paginationPageSize 
    setPageSize = (page = this.per_page) => {
        this.per_page = page;
        if (this.agGrid) {
            this.agGrid.api.paginationSetPageSize(parseInt(page));
        }
    }

    //call api to get records
    getList = (payload) => {
        if (this.agGrid) {
            var filter = this.agGrid.api.getFilterModel();
            var sort = this.agGrid.api.getSortModel();
        }
        this.list_data = null;
        return Axios.get(`admin/billing-types/list`, payload).then(({ data }) => {
            if (data.list.data.length) {
                data.list.data.map((item, index) => {
                    item.srno = index + 1;
                    item.is_active_display =
                        item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
                    return null;
                });
            }
            this.list_data = data.list ? data.list.data : null;
            this.total = data.list.total;
            this.current_page = data.list.current_page;
            var allColumnIds = [];
            if (this.agGrid && this.agGrid.columnApi) {
                this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIds.push(column.colId);
                });
            }
            if (this.agGrid) {
                this.agGrid.api.setFilterModel(filter);
                this.agGrid.api.setSortModel(sort);
            }
        })
    }

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params;
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
            this.agGrid.api.hideOverlay();
        }
    };

}
decorate(BillingTypeStore, {
    agGrid: observable,
    per_page: observable,
    list_data: observable,
    total: observable,
    allColumnIds: observable,
    setupGrid: action,
    setPageSize: action,
    getList: action,
    setIsActiveFilterList: action
})