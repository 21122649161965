import React, { useState, useEffect } from "react";
import { Form, Button, Drawer, Alert } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify } from "../../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import GeneralFormComponent from "../component/FormComponent";
import { Constant } from "../../../../../config/Constant";

const EditComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageCashBookingPolicyStore: { EditData, editValues, typeMode, refreshList },
		ManageProductsStore,
		CommonStore,
		ManageCentersStore,
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fetchDrtList, setFetchDrList] = useState(true);
	const [fetchProduct, setFetchProduct] = useState(true);
	const [fetchWeight, setFetchWeight] = useState(true)

	// set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {
			if (editValues.def_weight) {
				CommonStore.dropdown_weight_list = [{ id: editValues.def_weight?.id, val: (editValues.def_weight?.val) ? `${editValues.def_weight.val} gms` : null }];
			}
			ManageProductsStore.dropdown_product_list = [editValues.def_product]
			form.setFieldsValue({
				company: editValues?.client?.mst_center?.owner_name,
				location: editValues?.client?.mst_center?.name,
				fr_id: editValues.fr_id,
				hub_id: editValues.hub_id,
				ho_id: editValues?.ho_id,
				center_id: editValues?.center_id,
				username: editValues?.mst_users?.username,
				ins_rate: editValues?.ins_rate,
				valuation_rate: editValues?.valuation_rate,
				fov_chrg: editValues?.fov_chrg,
				pod_chrg: editValues?.pod_chrg,
				fuel_chrg: editValues?.fuel_chrg,
				cash_oda1_chrg: editValues?.oda1_chrg,
				cash_oda2_chrg: editValues.oda2_chrg,
				rec_sms_chrg: editValues?.rec_sms_chrg,
				disc_rate_dx: editValues?.disc_rate_dx,
				disc_rate_dex: editValues?.disc_rate_dex,
				disc_rate_ndex:editValues?.disc_rate_ndex,
				disc_rate_ndbs:editValues?.disc_rate_ndbs,
				disc_rate_ndba:editValues?.disc_rate_ndba,
				vw_flag: editValues?.vw_flag ? true : false,
				tax_flag: editValues?.tax_flag ? true : false,
				def_product_id: editValues.def_product_id,
				def_weight: editValues.def_weight?.id,
				disc_rate:0
			});
		}
	}, [CommonStore, ManageProductsStore, editValues, form, props.visible, props]);



	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true);
		data.id = editValues.id;
		data.tax_flag = form.getFieldValue('tax_flag') ? '1' : '0';
		data.vw_flag = form.getFieldValue('vw_flag') ? '1' : '0';
		data.center_id = editValues?.center_id;
		data.disc_rate = 0
		EditData(data)
			.then((data) => {
				if (props.openFrom === "location") {
					ManageCentersStore.refreshList();
				} else if (props.openFrom === "cashBooking") {
					refreshList();
				}
				close();
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// reset form and close add form
	const close = () => {
		props.close();
		setFetchDrList(true);
		setFetchProduct(true);
		setFetchWeight(true);
		CommonStore.dropdown_weight_list = null;
		CommonStore.dropdown_discount_list = null;
		ManageProductsStore.dropdown_product_list = null;
		form.resetFields();
		setDisabled(true);
	};

	return (
		<Drawer
			className="editModal"
			title={"Cash Booking Policy"}
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={() => { close() }}
			destroyOnClose={true}
			footer={[
				(typeMode !== Constant.VIEW && <Button
					key="1"
					disabled={disabled}
					className="mr-35"
					form="editCashBookingForm"
					loading={saving}
					htmlType="submit"
					type="primary"
				>
					Save
				</Button>),
				<Button
					key="3"
					type="primary"
					onClick={() => {
						close();
					}}
				>
					Cancel
				</Button>
			]}
		>
			{props.showErrorNotes && props.visible && editValues && <Alert className="mb-25"
				type="error"
				showIcon
				message={
					editValues.is_terminated ? `This location is terminated so you cannot make any changes` : (!editValues.is_active) ? `This location is inactive so you cannot make any changes` : null
				}
			/>}
			<GeneralFormComponent
				form={form}
				type={typeMode}
				setDisabled={setDisabled}
				handleSubmit={handleSubmit}
				id="editCashBookingForm"
				fetchDrtList={fetchDrtList}
				setFetchDrList={setFetchDrList}
				fetchProduct={fetchProduct}
				setFetchProduct={setFetchProduct}
				fetchWeight={fetchWeight}
				setFetchWeight={setFetchWeight}
			/>
		</Drawer>
	);
});

export default EditComponent;
