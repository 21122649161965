import Axios from "axios";
import Dexie from "dexie";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import ServerGridConfig from "../../../../config/ServerGridConfig";
//import LocalGridConfig from "../../../../config/LocalGridConfig";
import { db } from "../../../../db";
import { b64toBlob, convertCaseBookingReceiverAddress, convertCaseBookingSenderAddress, convertTextToID } from "../../../../utils/GlobalFunction";
import AuthStore from "../../../../store/AuthStore";
import { vsmNotify } from "../../../../config/messages/common";
//import pastUserDetails from "../../../../json/init.json";

export default class ManageCashBookingStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	statusValues = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_city_list = null;
	pinCodeDetails = null;
	rateCardDetails = null;
	localAgGrid = null;
	local_per_page = 3;
	local_current_page = 1;
	local_list_data = null;
	local_total = 0;
	local_allColumnIds = [];
	local_booking_list = {};
	today_booking_weight = 0;
	today_booking_amount = 0;
	localStateList = [];
	serverFilter = null;
	ewayValues = null
	viewEwayValues = null
	AUTH = new AuthStore();

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertCaseBookingSenderAddress(data);
			data.receiverAddress = convertCaseBookingReceiverAddress(data);
		}
		this.viewValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// Set pincode data for booking type out of state
	setPinCodeDetails = (data) => {
		this.pinCodeDetails = data
	}

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	setServerFilter = (data) => {
		this.serverFilter = data;
	}

	setEwayValues = (data) => {
		this.ewayValues = data
	}

	SetViewEwayValues =(data) => {
		this.viewEwayValues = data
	}
	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		if (this.serverFilter || localStorage.getItem('cash_server_grid_filter')) {
			let cash_server_grid_filter = localStorage.getItem('cash_server_grid_filter') ? JSON.parse(localStorage.getItem('cash_server_grid_filter')) : this.serverFilter;
			this.agGrid.api.setFilterModel(cash_server_grid_filter);
		} else {
			this.agGrid.api.setFilterModel({
				bk_date: { dateFrom: moment().format("YYYY-MM-DD"), dateTo: moment().add(1, 'day').endOf('day').format("YYYY-MM-DD"), type: "inRange", filterType: "date" }
			});
		}

		let datasource = this.createDatasource(ServerGridConfig.options);

		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get cash
	getList = (payload) => {
		return Axios.post(`bookings/cash/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.isActiveFilterList, 'name', 'id')
		}
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				let account_filter = this.agGrid.api.getFilterModel();
				localStorage.setItem('cash_server_grid_filter', JSON.stringify(account_filter));
				// this.serverFilter = account_filter;
				this.setServerFilter(account_filter);
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`/bookings/cash/cash-booking-save`, formdata)
			.then(({ data }) => {
				// if (this.agGrid) {
				// 	this.setupGrid(this.agGrid);
				// }
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;var lock = null;
				if(data?.lock){
					lock = data.lock;
				}
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify, lock });
			});
	};

	// Call edit api
	EditData = (ID, formdata) => {
		return Axios.post(`/bookings/cash/edit/` + ID, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get cash
	ViewData = (ID) => {
		return Axios.get(`bookings/cash/details/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		}).catch(() => {
			return Promise.reject();
		});
	};

	PrintReceiptData = (ID) => {
		return Axios.get(`/bookings/cash/print-receipt/${ID}`).then(({ data }) => {
			const file = b64toBlob(data.view, "application/pdf");
			//Build a URL from the file
			const fileURL = URL.createObjectURL(file);
			//Open the URL on new Window
			window.open(fileURL);
			//pdfWindow.location.href = fileURL
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	MultiPrintReceiptData = (formdata) => {
		return Axios.post(`/bookings/cash/multi-receipt`, formdata).then(({ data }) => {
			return data;
		})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}


	// Call eway api
	EwayData = (ID, formdata) => {
		return Axios.post(`/bookings/eway/edit/` + ID, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//ViewEwayData api

	ViewEwayData = (formdata) => {
		return Axios.post(`bookings/eway/eway-no-details` ,formdata)
			.then(({ data }) => {
	            this.SetViewEwayValues(data);
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteData = (formdata, openFrom) => {
		return Axios.delete(`/bookings/cash/destroy/` + formdata.id)
			.then(async ({ data }) => {
				await db.bk_cash.where("id").equals(formdata.id)
					.delete()
					.then(function (deleteCount) {
						console.log("Deleted " + deleteCount + " objects");
					}).catch(Dexie.BulkError, function (e) {
						console.error("Some accountList did not succeed.");
					});
				if (openFrom === "form") {
					this.getLocalBookingList();
				} else if (openFrom === "list" && this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				if (data?.delete_restrict && data.delete_restrict === 1) {
					this.AUTH.setBooking().then(() => {
						this.local_booking_list = {};
						this.getLocalBookingList();
					});
				}
				if (data && data.STATUS) {
					if (data.STATUS?.NOTIFICATION[0]) {
						vsmNotify.error({
							message: data.STATUS.NOTIFICATION[0],
						});
					}
				}
				return Promise.reject(data);
			});
	};

	getPincodeDetails = (conditional = {}) => {
		return Axios.post(`bookings/cash/pincode-details`, conditional)
			.then(({ data }) => {
				this.rateCardDetails = data.pincodeDetails.rate_card;
				return data.pincodeDetails;
			})
			.catch(response => {
				return Promise.reject(response)
			})
		// return Axios.post(`admin/countries/lov`, conditional)
		// 	.then(({ data }) => {
		// 		this.rateCardDetails = pincodeRes.pincodeDetails.rate_card;
		// 		return pincodeRes.pincodeDetails;
		// 	})
		// 	.catch(response => {
		// 		return Promise.reject(response)
		// 	})
	};

	JsonToFormData = (data) => {
		const formData = new FormData();
		if (data) {
			Object.keys(data).forEach(key => {
				if (!['info', 'city', 'doc_file', 'bk_date', 'igst_chrg', 'sgst_chrg', 'cgst_chrg', 'valuation_chrg', 'fov_chrg', 'display_weight', 'display_v_weight', 'display_c_weight', 'location_gst_no', 'eway_details', 'is_city_oda1'].includes(key)) {
					formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
				} else if (key === "city") {
					formData.append("r_city_id", (data[key] !== undefined && data[key] !== null) ? data[key] : '')
				}
			})
		}
		return formData
	}

	getPastUserDetails = async (conditional = {}) => {
		let pastUser = {};
		if (conditional.is_who === 1) {
			pastUser = await db.senders.where("s_mobile").equals(conditional.mobile).first();
			if (pastUser) {
				pastUser.mst_states = [{ id: pastUser.s_state_id, name: pastUser.s_state }]
				pastUser.mst_cities = [{ id: pastUser.s_city_id, name: pastUser.s_city }]
				pastUser.mst_pincodes = [{ id: pastUser.s_pincode_id, pincode: pastUser.s_pincode }]
				pastUser.name = pastUser.s_name;
				pastUser.email = pastUser.s_email;
				pastUser.whatsappno = pastUser.s_whatsappno;
				pastUser.address1 = pastUser.s_address1;
				pastUser.address2 = pastUser.s_address2;
				pastUser.address3 = pastUser.s_address3;
				pastUser.state_id = pastUser.s_state_id;
				pastUser.city_id = pastUser.s_city_id;
				pastUser.pincode_id = pastUser.s_pincode_id;
				return pastUser;
			}
		} else if (conditional.is_who === 2) {
			pastUser = await db.receivers_cash.where("r_mobile").equals(conditional.mobile).first();
			if (pastUser) {
				pastUser.name = pastUser.r_name;
				pastUser.email = pastUser.r_email;
				pastUser.whatsappno = pastUser.r_whatsappno;
				pastUser.address1 = pastUser.r_address1;
				pastUser.address2 = pastUser.r_address2;
				pastUser.address3 = pastUser.r_address3;
				return pastUser;
			}
		}
		return false;
	}

	/**
	 * Below all things for local grid in booking screen
	 */
	// Setup grid and set column size to autosize
	setupLocalBookingGrid = (params) => {
		this.localAgGrid = params;
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setLocalBookingPageSize = (local_page = this.local_per_page) => {
		this.local_per_page = local_page;
		if (this.localAgGrid) {
			this.localAgGrid.api.paginationSetPageSize(parseInt(local_page));
		}
	};

	// call api to get records
	getLocalBookingList = async () => {
		if (this.localAgGrid) {
			var filter = this.localAgGrid.api.getFilterModel();
			var sort = this.localAgGrid.api.getSortModel();
		}
		this.local_list_data = null;
		let cashBooking = await db.bk_cash.toArray();

		let result = {}, bookingWeight = 0, bookingAmount = 0;
		if (cashBooking.length) {
			cashBooking.map((item, index) => {
				item.srno = index + 1;
				return null;
			});
			await db.bk_cash.orderBy('product_id').eachKey(product_id => {
				result[product_id] = (result[product_id] || 0) + 1;
			});
			await db.bk_cash.each(item => {
				bookingWeight += Number(item.c_weight);
				bookingAmount += Number(item.net);
			});
		}
		this.local_booking_list = result;
		this.today_booking_weight = bookingWeight;
		this.today_booking_amount = bookingAmount;
		this.local_list_data = cashBooking ? cashBooking : null;
		this.local_total = (cashBooking) ? cashBooking.length : 0;
		//this.local_current_page = data.list.current_page;
		var local_allColumnIds = [];
		if (this.localAgGrid && this.localAgGrid.columnApi) {
			this.localAgGrid.columnApi.getAllColumns().forEach(function (column) {
				local_allColumnIds.push(column.colId);
			});
		}
		if (this.localAgGrid) {
			this.localAgGrid.api.setFilterModel(filter);
			this.localAgGrid.api.setSortModel(sort);
		}
		this.localAgGrid?.api?.setSortModel([{ colId: 'con_no', sort: 'desc' }]);
	};

	// Filter function for no record found message
	onLocalFilterChanged = (params) => {
		this.localAgGrid = params;
		if (this.localAgGrid && this.localAgGrid.api.getModel().getRowCount() === 0) {
			this.localAgGrid.api.showNoRowsOverlay();
		}
		if (this.localAgGrid && this.localAgGrid.api.getModel().getRowCount() > 0) {
			this.localAgGrid.api.hideOverlay();
		}
	};

	addBookingLocal = async (params) => {
		await db.bk_cash.add({
			"id": params.id,
			"con_no": params.con_no,
			"bk_date": params.bk_date,
			"product_id": params.product_id,
			"product_name": params.product_name,
			"r_name": params.r_name,
			"r_city": params.r_city,
			"to_pincode": params.to_pincode,
			"s_name": params.s_name,
			"bk_type": params.bk_type,
			"c_weight": params.c_weight,
			"net": params.net,
			"status": params.status
		});
		this.getLocalBookingList();
	}

	updateSender = async (params) => {
		let existingSender = await db.senders.where("s_mobile").equals(params.s_mobile).count();
		if (existingSender) {
			// await db.senders.put(params, params.s_mobile).then(() => {
			// 	console.log("Last Sender Exist id was: ");
			// }).catch(Dexie.BulkError, function (e) {
			// 	console.error("Some Sender Exist did not succeed.");
			// });
			await db.senders.where("s_mobile").equals(params.s_mobile).modify(params).then(() => {
				console.log("Last Sender Exist id was: ");
			}).catch(Dexie.BulkError, function (e) {
				console.error("Some Sender Exist did not succeed.");
			});
		} else {
			await db.senders.add(params).then(() => {
				console.log("Last Sender id was: ");
			}).catch(Dexie.BulkError, function (e) {
				console.error("Some Sender did not succeed.");
			});
		}
	}

	updateReceiver = async (params) => {
		let existingReceiver = await db.receivers_cash.where("r_mobile").equals(params.r_mobile).count();
		if (existingReceiver) {
			// await db.receivers_cash.put(params, params.r_mobile).then(() => {
			// 	console.log("Last receiversCash id was: ");
			// }).catch(Dexie.BulkError, function (e) {
			// 	console.error("Some receiversCash did not succeed.");
			// });
			await db.receivers_cash.where("r_mobile").equals(params.r_mobile).modify(params).then(() => {
				console.log("Last receiversCash id was: ");
			}).catch(Dexie.BulkError, function (e) {
				console.error("Some receiversCash did not succeed.");
			});
		} else {
			await db.receivers_cash.add(params).then(() => {
				console.log("Last receiversCash id was: ");
			}).catch(Dexie.BulkError, function (e) {
				console.error("Some receiversCash did not succeed.");
			});
		}
	}

	setLocalStateList = async () => {
		this.localStateList = await db.states.where({ is_active: 1, is_deleted: 0 }).toArray();
	}
}

decorate(ManageCashBookingStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	statusValues: observable,
	total: observable,
	allColumnIds: observable,
	dropdown_city_list: observable,
	setupGrid: action,
	setStatusValues: action,
	setPageSize: action,
	setEditValues: action,
	setDeleteValues: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	setViewValues: action,
	viewValues: observable,
	setIsActiveFilterList: action,
	local_per_page: observable,
	localAgGrid: observable,
	local_list_data: observable,
	local_total: observable,
	lovcla_allColumnIds: observable,
	setupLocalBookingGrid: action,
	onLocalFilterChanged: action,
	local_booking_list: observable,
	today_booking_weight: observable,
	today_booking_amount: observable,
	setLocalStateList: action,
	localStateList: observable,
	serverFilter: observable,
	setServerFilter: action,
	ewayValues : observable,
	setEwayValues : action,
	viewEwayValues : observable,
	SetViewEwayValues : action
});
