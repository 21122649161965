import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Upload, message, Drawer, Divider, Image as PreviewImage, Alert } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import {
	vsmNotify,
} from "../../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormItem from "antd/lib/form/FormItem";
import { UploadIcon } from "../../../../../config/IconsConfig";
import { Messages } from "../../../../../config/messages/messages";
import { replaceString } from "../../../../../utils/GlobalFunction";
import { Constant } from "../../../../../config/Constant";
import { vsmLocation } from "../Validation";
import DefaulHeader from "./DefaulHeader";

const CoverImageComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageCentersStore: { editValues, CoverImageuploadData, typeMode, updateProfile },
		AUTH
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fileList, updateFileList] = useState([]);
	const [isImageUploaded, setisImageUploaded] = useState(false);
	const [submitValue, setSubmit] = useState();

	// set the form values to edit
	useEffect(() => {
		if (editValues && props.visible && editValues.cover_photo) {
			updateFileList([
				{
					uid: "-1",
					name: "image.png",
					status: "done",
					url: Constant.S3BUCKET_API_BASEURL+ editValues?.cover_photo,
				},
			]);
		}
	}, [editValues, form, props]);


	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true);		
		const formData = new FormData();
		formData.id = editValues.id;
		if (fileList.length > 0 && isImageUploaded) {
			formData.append(
				"cover_photo",
				fileList[0]?.originFileObj ? fileList[0]?.originFileObj : fileList[0]
			);
		}
		CoverImageuploadData(formData)
			.then((data) => {
				close(submitValue);
				updateProfile("cover_photo", data.view.cover_photo)
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// reset form and close add form
	const close = (submitValue) => {
		form.resetFields();
		setDisabled(true);
		updateFileList([]);
		if(submitValue === "submit"){
			props.close();
		}else if(submitValue === "submitNext"){
			props.closeNext();
		}
		setSubmit();
	};

	// Handle on remove image
	const onRemoveImage = () => {
		form.setFields([{ name: "cover_photo", errors: [] }]);
		updateFileList([]);
		setisImageUploaded(false);
		setDisabled(true);
	};

	const uploadButton = (
		<div>
			<div className="upload_btn">
				<UploadIcon />
				<p>Drag files to upload, or browse</p>
			</div>
		</div>
	);

	const onPreview = async (file) => {
		let src = file.url;
		if (!src) {
			src = await new Promise((resolve) => {
				const reader = new FileReader();
				reader.readAsDataURL(file.originFileObj);
				reader.onload = () => resolve(reader.result);
			});
		}
		const image = new Image();
		image.src = src;
		const imgWindow = window.open(src);
		imgWindow.document.write(image.outerHTML);
	};
	const handleFileChange = (info) => {
		if (info && info.length > 0) {
			if (info[0].status === 'uploading') {
				updateFileList([]);
				return;
			}
		}
	}
	const eventProps = {
		onChange: ({ fileList: newFileList }) => {
			updateFileList(newFileList);
			setisImageUploaded(true);
			handleFileChange(newFileList);
		},
		fileList,
		beforeUpload: (file) => {
			let isJpgOrPng =
				file.type === "image/png" ||
				file.type === "image/jpeg" ||
				file.type === "image/jpg";
			let size = file.size / 1024;
			if (!isJpgOrPng) {
				message.error(Messages.IMAGE_VALIDATION);
				setisImageUploaded(false);
				setDisabled(true);
				return true;
			} else if (size > 300) {
				message.error(
					replaceString(Messages.IMAGE_SIZE_VALIDATION, "#SIZE#", "300 KB")
				);
				setisImageUploaded(false);
				setDisabled(true);
				return true;
			} else {
				setDisabled(false);
				updateFileList([file]);
				setisImageUploaded(true);
				return false;
			}
		},
	};

	return (
			<Drawer
				className="editModal"
				title="Office Front Photo"
				width="70%"
				visible={props.visible}
				closeIcon={<FontAwesomeIcon icon={faTimes} />}
				onClose={() => {close("submit")}}
				zIndex={1001}
				footer={[
					(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
						key="1"
						htmlType="button"
						className="cancelBtn mr-35"
						onClick={() => {close("submitNext")}}
					>
						Skip
					</Button>),
					(typeMode !== Constant.VIEW && <Button
						key="2"
						disabled={disabled}
						form="editCoverImageForm"
						loading={saving}
						htmlType="submit"
						type="primary"
						className="mr-35"
						onClick={() => {
							setSubmit("submit");
						}}
					>
						Save
					</Button>),
					(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
						key="3"
						disabled={disabled}
						form="editCoverImageForm"
						loading={saving}
						htmlType="submit"
						type="primary"
						onClick={() => {
							setSubmit("submitNext");
						}}
					>
						Save & Continue
					</Button>),
					(typeMode === Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
						key="4"
						type="primary"
						onClick={() => {
							close("submitNext");
						}}
					>
						Continue
					</Button>)
				]}
			>
				{props.showErrorNotes && props.visible && editValues && <Alert className="mb-25"
					type="error"
					showIcon
					message={
						editValues.is_terminated ? `This location is terminated so you cannot make any changes`: (!editValues.is_active) ? `This location is inactive so you cannot make any changes` : null
					}
				/>}
				<Form
					form={form}
					id="editCoverImageForm"
					onFinish={handleSubmit}
					labelCol={{ span: 24 }}
				>
					<DefaulHeader/>
					<Divider className="mt-0" />
					<Row gutter={30}>
						<Col xs={{ span: 24 }}>
							{(typeMode === Constant.VIEW) ?((fileList && fileList.length > 0)?<PreviewImage width={150} src={fileList[0].url}/>:"File not uploaded"):null}
							{typeMode !== Constant.VIEW && 
							<FormItem required labelCol={{ span: 24 }}>
								<Form.Item
									name="cover_photo"
									rules={vsmLocation.coverImageValidation.cover_photo}
								>
									
									<Upload
										accept=".png,.jpeg,.jpg"
										fileList={fileList}
										onRemove={onRemoveImage}
										onPreview={onPreview}
										listType="picture-card"
										multiple={false}
										showUploadList={true}
										name="cover_photo"
										{...eventProps}
									>
										{fileList.length >= 1 ? null : uploadButton}
									</Upload>
								</Form.Item>
							</FormItem>
						}
						</Col>
						{typeMode !== Constant.VIEW && <Col xs={{ span: 24 }}>
							<ul className="help_text">
								<li>Valid formats are JPEG, JPG and PNG</li>
								<li>Image must be a landscape.</li>
								<li>Minimum image width should be 500</li>
								<li>Minimum image height should be 500</li>
								<li>Image size should not exceed 300 KB</li>
							</ul>
						</Col>}
					</Row>
				</Form>
			</Drawer>
		)
});

export default CoverImageComponent;
