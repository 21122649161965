import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import { Constant } from "../../../../config/Constant";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID } from "../../../../utils/GlobalFunction";
// import addData from "../../../../json/init.json";

export default class ManageBagEntryStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	deleteValues = null;
	statusValues = null;
	viewValues = null;
	total = 0;
	allColumnIds = [];
	deleteConValues = null;
	isActiveFilterList = null;
	isDraftFilterList = null;
	offloadValues = null;
	bag_details = []

	// set form values to offloads 
	setOffLoadsValues = (data) => {
		this.offloadValues = data;
	}

	//set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		this.viewValues = data;
	};

	//set set filter values to status 
	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	//set filter values to is_draft
	setIsDraftFilterList = (data) => {
		this.isDraftFilterList = data;
	}

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// set form values to delete Item
	setDeleteConValues = (data) => {
		this.deleteConValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		this.agGrid.api.setFilterModel({
			entry_date: { dateFrom: moment().format("YYYY-MM-DD"), dateTo: moment().add(1, 'day').endOf('day').format("YYYY-MM-DD"), type: "inRange", filterType: "date" }
		});
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource)
	};

	// change page size, default page size is ServerGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`bookings/bags/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;
		if (final_filter["status"]) {
			final_filter['status'].values = convertTextToID(final_filter['status'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_draft"]) {
			final_filter["is_draft"].values = convertTextToID(final_filter['is_draft'], this.isDraftFilterList, 'name', 'id')
		}
		if (final_filter["bag_no"]) {
			if (final_filter["bag_no"].filter === Constant.BAG_NO_NOT) {
				final_filter["bag_no"].filter = Constant.UNDEFINED_VALUES
			}
		}
		return { final_filter, final_sort }
	}

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					page: Math.ceil((params.request.startRow + 1) / (params.request.endRow - params.request.startRow))
				}
				this.getList(payload).then((data) => {
					if (data.list.total === 0) { this.agGrid.api.showNoRowsOverlay() }
					else { this.agGrid.api.hideOverlay() }
					params.successCallback(data.list.data, data.list.total)
					var allColumnIds = []
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id)
						})
					}
				})
			}
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`bookings/bags/bag-enrty-save`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				this.setViewValues(data.view)
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`bookings/bags/details/${ID}`).then(({ data }) => {
			this.setViewValues(data.view);
			return data;
		});
	};

	// Call add api
	AddItem = (formdata) => {
		return Axios.post(`bookings/bags/add-item`, formdata)
			.then(({ data }) => {
				if (this.viewValues && this.viewValues.bag_details) {
					let view = this.viewValues;
					let currentCon;
					if (data?.view) {
						let res = data?.view?.bag_details;
						if (res) {
							currentCon = [...res, ...view.bag_details];
						} else {
							currentCon = view.bag_details;
						}
						const uniquebag = currentCon.filter((element) => {
							const isDuplicate = currentCon.includes(element.id);
							if (!isDuplicate) {
								return currentCon.push(element.id);
							}
							return null
						});
						view.bag_details = uniquebag;
						this.setViewValues(view)
					} else {
						this.setViewValues(view)
					}
				} else if (data?.view?.bag_details) {
					let view = this.viewValues;
					view.bag_details = data?.view?.bag_details
					this.setViewValues(view)
				}
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call delete api
	DeleteConData = (formdata) => {
		return Axios.post(`/bookings/bags/delete-item`, formdata)
			.then(({ data }) => {
				if (this.viewValues && this.viewValues.bag_details && this.viewValues.bag_details.length > 0) {
					let view = this.viewValues;
					let currentCon = []
					currentCon = view.bag_details.filter(item => item.id !== formdata.bag_detail_id);
					view.bag_details = currentCon ?? [];
					this.setViewValues(view)
					// this.setEditValues(view)
				}
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return this.viewValues;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	ChangeWeight = (formdata) => {
		return Axios.post(`bookings/bags/edit-weight`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

	BagOffload = (formdata) => {
		return Axios.post('bookings/bags/bag-delete-or-offload', formdata)
			.then(({ data }) => {
				if (!formdata.reason) {
					if (this.viewValues && this.viewValues.bag_details && this.viewValues.bag_details.length > 0) {
						let view = this.viewValues;
						let currentCon = []
						let formValue;
						formdata.bag_detail.map((item) => { return formValue = item.id })
						currentCon = view.bag_details.filter(item => item.id !== formValue);
						view.bag_details = currentCon ?? [];
						this.setViewValues(view)
					}
				}
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			})
	}

	GetOutwardDetails = (formdata) => {
		return Axios.post(`bookings/outward/get-ios-details`, formdata)
			.then(({ data }) => {
				let payload = []
				data.data.forEach((_x) => {
					_x.key = _x.item_no
					payload.push(_x)
				})
				this.bag_details = payload;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	}

	BagFinalize = (formdata) => {
		return Axios.post('bookings/bags/finalize', formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	}

}

decorate(ManageBagEntryStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	deleteValues: observable,
	statusValues: observable,
	deleteConValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setStatusValues: action,
	setPageSize: action,
	setDeleteValues: action,
	setDeleteConValues: action,
	getList: action,
	changeFilterAndSort: action,
	onFilterChanged: action,
	createDatasource: action,
	setViewValues: action,
	viewValues: observable,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	ChangeWeight: action,
	setOffLoadsValues: action,
	offloadValues: observable,
	getCoLoadersList: action,
	dropdown_coloaders_list: observable,
	isDraftFilterList: observable,
	setIsDraftFilterList: action,
	bag_details: observable
});
