import { action, decorate, observable } from "mobx";
import Axios from "axios";
import {
  convertTextToID,
  isActiveStatus,
} from "../../../../utils/GlobalFunction";
import LocalGridConfig from "../../../../config/LocalGridConfig";

export default class ManageExpenseHeadStore {
  agGrid = null;
  per_page = LocalGridConfig.options.paginationPageSize;
  current_page = 1;
  list_data = null;
  editValues = null;
  deleteValues = null;
  statusValues = null;
  total = 0;
  allColumnIds = [];

  // set form values to edit
  setEditValue = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValue = (data) => {
    this.deleteValues = data;
  };

  setIsActiveFilterList = (data) => {
    this.isActiveFilterList = data;
  };

  // set form values to status
  setStatusValues = (data) => {
    this.statusValues = data;
  };

  // change page size, default page size is LocalGridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
  };

  // call api to get records
  getList = () => {
    if (this.agGrid) {
      var filter = this.agGrid.api.getFilterModel();
      var sort = this.agGrid.api.getSortModel();
    }
    this.list_data = null;
    return Axios.post(`expense/expense-head/list`).then(({ data }) => {
      if (data.list.data.length) {
        data.list.data.map((item, index) => {
          item.srno = index + 1;
          item.is_active_display =
            item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0];
          return null;
        });
      }
      this.list_data = data.list ? data.list.data : null;
      this.total = data.list.total;
      this.current_page = data.list.current_page;
      var allColumnIds = [];
      if (this.agGrid && this.agGrid.columnApi) {
        this.agGrid.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
      }
      if (this.agGrid) {
        this.agGrid.api.setFilterModel(filter);
        this.agGrid.api.setSortModel(sort);
      }
    });
  };

  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;

    if (final_filter["is_active"]) {
      final_filter["is_active"].values = convertTextToID(
        final_filter["is_active"],
        this.isActiveFilterList,
        "name",
        "id"
      );
    }

    return { final_filter, final_sort };
  };

  // Filter function for no record found message
  onFilterChanged = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };


  // Call add api
  AddData = (formdata) => {
    return Axios.post(`expense/expense-head/new`, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data && data?.STATUS) {
          const { NOTIFICATION, ...fieldErrors } = data.STATUS;
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // Call edit api
  EditData = (formdata) => {
    return Axios.post(`expense/expense-head/edit/` + formdata.id, formdata)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data && data?.STATUS) {
          const { NOTIFICATION, ...fieldErrors } = data.STATUS;
          if (NOTIFICATION) {
            notify = NOTIFICATION[0];
          }
          Object.keys(fieldErrors).forEach((name) => {
            errors.push({ name, errors: data.STATUS[name] });
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  // Call delete api
  DeleteData = (formdata) => {
    return Axios.delete(`expense/expense-head/destroy/` + formdata.id)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  TogglePublishData = (formdata) => {
    const api_link = formdata.is_active === 1 ? "deactivate/" : "activate/";
    return Axios.patch("expense/expense-head/" + api_link + formdata.id)
      .then(({ data }) => {
        this.getList();
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };
}

decorate(ManageExpenseHeadStore, {
  agGrid: observable,
  per_page: observable,
  list_data: observable,
  setPageSize: action,
  editValues: observable,
  setEditValue: action,
  deleteValues: observable,
  setDeleteValue: action,
  statusValues: observable,
  setStatusValues: action,
  total: observable,
  allColumnIds: observable,
  setIsActiveFilterList: action,
  setupGrid: action,
  getList: action,
  onFilterChanged: action,
});
