import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../../config/LocalGridConfig";
import moment from "moment";
import { Constant } from "../../../../config/Constant";

export default class ManageOutScanPendingStore {
    agGrid = null;
    per_page = LocalGridConfig.options.paginationPageSize;
    current_page = 1;
    list_data = null;
    total = 0;
    allColumnIds = [];
    selectedConNo = [];
    openOutScanAddModalFromEway = false;

    // Setup grid and set column size to autosize
    setupGrid = (params) => {
        this.agGrid = params;
    };

    // change page size, default page size is LocalGridConfig.options.paginationPageSize
    setPageSize = (page = this.per_page) => {
        this.per_page = page;
        if (this.agGrid) {
            this.agGrid.api.paginationSetPageSize(parseInt(page));
        }
    };

    // Select con no for outscan
    setSelectedConNo = (conNo) => {
        this.selectedConNo = conNo;
    }

    // Outscan add modal is open from eway or not flag
    setOpenOutScanAddModalFromEway = (flag) => {
        this.openOutScanAddModalFromEway = flag;
    }

    // call api to get records
    getList = (payload) => {
        if (this.agGrid) {
            var filter = this.agGrid.api.getFilterModel();
            var sort = this.agGrid.api.getSortModel();
        }
        this.list_data = null;
        let formData = {
            advance_filter: this.generatePayload(payload)
        }
        return Axios.post(`bookings/eway/outscan_pending`, formData).then(({ data }) => {
            if (data.list.data.length) {
                data.list.data.map((item, index) => {
                    item.srno = index + 1;
                    return null;
                });
            }
            this.list_data = data.list ? data.list.data : null;
            this.total = data.list.total;
            this.current_page = data.list.current_page;
            var allColumnIds = [];
            if (this.agGrid && this.agGrid.columnApi) {
                this.agGrid.columnApi.getAllColumns().forEach(function (column) {
                    allColumnIds.push(column.colId);
                });
            }
            if (this.agGrid) {
                this.agGrid.api.setFilterModel(filter);
                this.agGrid.api.setSortModel(sort);
            }
        });
    };

    generatePayload = (summary_filter) => {
        if (summary_filter.from_date) {
            summary_filter.from_date = moment(summary_filter.from_date).format(Constant.PAYLOAD_DATE);
        }
        if (summary_filter.to_date) {
            summary_filter.to_date = moment(summary_filter.to_date).format(Constant.PAYLOAD_DATE);
        }
        return summary_filter;
    }

    // Filter function for no record found message
    onFilterChanged = (params) => {
        this.agGrid = params;
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
            this.agGrid.api.showNoRowsOverlay();
        }
        if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
            this.agGrid.api.hideOverlay();
        }
    };
}

decorate(ManageOutScanPendingStore, {
    per_page: observable,
    agGrid: observable,
    list_data: observable,
    total: observable,
    dropdown_bank_list: observable,
    allColumnIds: observable,
    selectedConNo: observable,
    openOutScanAddModalFromEway: observable,
    setupGrid: action,
    setPageSize: action,
    getList: action,
    onFilterChanged: action,
    setSelectedConNo: action,
    setOpenOutScanAddModalFromEway: action
});
