import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertViewAddressCityNobj } from "../../../../utils/GlobalFunction";

export default class ManagePartnersStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	deleteValues = null;
	deletefamilyinfoValues = null;
	editValues = null;
	partnerviewValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	getListValues = null
	dropdown_bloodgrp_list = null
	editFranchiseeId = 0;
	familyList = [];
	// set form values to view
	setPartnerViewValues = (data) => {
		if (data) {
			data.address = convertViewAddressCityNobj(data);
		}
		this.partnerviewValues = data;
	};
	// set form values to view
	setGetListValues = (data) => {
		this.getListValues = data;
	};

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};
	// set form values to delete
	setDeleteFamilyInfoValues = (data) => {
		this.deletefamilyinfoValues = data;
	};
	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	setViewLiteValues = (data) => {
		this.viewliteValues = data;
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`organization/fr-partners/list`, payload).then(({ data }) => {
			this.setGetListValues(data.data);
			return data.data;
		});
	};
	// Call add api
	AddData = (formdata) => {
		return Axios.post(`organization/fr-partners/new`, formdata)
			.then(({ data }) => {
				let listData = this.getListValues;
				listData.push(data.fr_partners)
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata, id) => {
		return Axios.post(`organization/fr-partners/edit/` + id, formdata)
			.then(({ data }) => {
				let listData = this.getListValues;
				let listDataIndex = listData.findIndex((item) => item.id === id);
				data.fr_partners.id = id;
				listData[listDataIndex] = data.fr_partners;
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	PartnerViewData = (ID) => {
		this.editAgencyId = ID;
		return Axios.get(`organization/fr-partners/details/${ID}`).then(({ data }) => {
			this.setPartnerViewValues(data.view);
			return data;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`organization/fr-partners/destroy/` + formdata.id)
			.then(({ data }) => {
				let listData = this.getListValues.filter((item) => item.id !== formdata.id);
				this.setGetListValues(listData);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	TogglePublishData = (formdata) => {
		const api_link = formdata.is_active === 0 ? "deactivate/" : "activate/";
		return Axios.patch("organization/fr-partner-family/" + api_link + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	// Call add api
	AddDataFamilyInfo = (formdata) => {
		return Axios.post(`organization/fr-partner-family/new`, formdata)
			.then(({ data }) => {
				let currentList = this.partnerviewValues;
				formdata.id = data.id
				if(currentList.fpfamily){
					currentList.fpfamily.push(formdata);
				}
				this.setPartnerViewValues(currentList);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditDataFamilyInfo = (formdata) => {
		return Axios.post(`organization/fr-partner-family/edit/${formdata.id}`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};
	// Call delete api
	DeleteFamilyInfoData = (formdata) => {
		return Axios.delete(`organization/fr-partner-family/destroy/` + formdata.id)
			.then(({ data }) => {
				let currentList = this.partnerviewValues;
				if(currentList.fpfamily){
					currentList.fpfamily = currentList.fpfamily.filter((item)=> item.id !== formdata.id);
				}
				this.setPartnerViewValues(currentList);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of frainchaisee for dropdown
	getBloodGrpList = (conditional = {}) => {
		return Axios.post(`/admin/blood-group/lov `, conditional)
			.then(({ data }) => {
				this.dropdown_bloodgrp_list = data.bloodGroups;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	//Get List of frainchaisee for dropdown
	getRelationList = (conditional = {}) => {
		return Axios.post(`/admin/relations/lov `, conditional)
			.then(({ data }) => {
				this.dropdown_relations_list = data.relations;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};
	JsonToFormData = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach(key => {
			if (!['fr_id', 'id', 'pan_image', 'aadhar_image', 'driving_license_image', "birthdate", "anniversarydate"].includes(key)) {
				formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
			}
		})
		return formData
	}
}

decorate(ManagePartnersStore, {
	deleteValues: observable,
	deletefamilyinfoValues: observable,
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	setupGrid: action,
	setPageSize: action,
	setDeleteValues: action,
	setDeleteFamilyInfoValues: action,
	setStatusValues: action,
	setEditValues: action,
	getList: action,
	onFilterChanged: action,
	setPartnerViewValues: action,
	partnerviewValues: observable,
	setIsActiveFilterList: action,
	statusValues: observable,
	getListValues: observable,
	setGetListValues: action,
	dropdown_bloodgrp_list: observable,
	dropdown_relations_list: observable,
	JsonToFormData: action,
	editFranchiseeId:observable,
	familyList:observable
});
