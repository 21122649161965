import React, { useState, useEffect } from "react";
import { Form, Button, Drawer, Alert } from "antd";
import useStore from "../../../../../store";
import { observer } from "mobx-react";
import { vsmNotify } from "../../../../../config/messages/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import GeneralFormComponent from "../component/FormComponent";
import { Constant } from "../../../../../config/Constant";

const EditComponent = observer((props) => {
	const [form] = Form.useForm();
	const {
		ManageCentersStore: { EditData, editValues, typeMode },
		ManageCountryStore,
		ManageStateStore,
		ManageCityStore,
		ManagePinCodeStore,
		ManageHeadOfficeStore,
		ManageHubsStore,
		ManageHubsStore: { setLatestHubList },
		ManageFrainchaiseeStore,
		ManageFrainchaiseeStore: { setLatestFRList },
		AUTH
	} = useStore();
	const [saving, setSaving] = useState();
	const [disabled, setDisabled] = useState(true);
	const [fetchCity, setFetchCity] = useState(true);
	const [fetchState, setFetchState] = useState(true);
	const [fetchCountry, setFetchCountry] = useState(true);
	const [fetchholist, setFetcHoList] = useState(true);
	const [fetchpincode, setFetchPincode] = useState(true);
	const [fetchFrList, setFetchFrList] = useState(true);
	const [fetchHubsList, setFetchHubsList] = useState(true);
	const [submitValue, setSubmit] = useState();
	const [gstDisplay, setGstDisplay] = useState(true);
	const [labelData, setLabelData] = useState()
	const [stdCode, setStdCode] = useState()

	// set the form values to edit
	useEffect(() => {
		if (editValues && props.visible) {

			//ManageCountryStore.dropdown_country_list = [editValues.countries];
			// ManageStateStore.dropdown_state_list = [...new Set(stateList.map(obj => obj.id))].map(id => {
			// 	return {id:id, name: stateList.find(s => s.id === id).name}
			// });

			ManageStateStore.dropdown_state_list = [editValues.states];
			ManageCityStore.dropdown_city_list = [editValues.cities];
			ManagePinCodeStore.dropdown_pincodes_list = [editValues.pincode];

			// ManageFrainchaiseeStore.dropdown_fr_list = [editValues.mst_franchisee];
			// ManageHubsStore.dropdown_hub_list = [editValues.mst_hub];
			setLatestHubList([editValues.mst_hub]);
			setLatestFRList([editValues.mst_franchisee]);
			ManageHeadOfficeStore.dropdown_ho_list = [editValues.mst_ho];

			setGstDisplay(editValues.is_gst_registered === 1 ? true : false)
			if (editValues.label) {
				setLabelData(editValues.label)
			}
			if (editValues.cities) {
				setStdCode(editValues?.cities?.std_code)
			}
			form.setFieldsValue({
				name: editValues.name,
				company: editValues.company,
				label: editValues.label,
				aadhar: editValues.aadhar,
				pan: editValues.pan,
				is_gst_registered: editValues.is_gst_registered,
				gst_no: editValues.gst_no,
				owner_name: editValues.owner_name,
				address1: editValues.address1,
				address2: editValues.address2,
				address3: editValues.address3,
				//country_id: editValues.countries?.id,
				state_id: editValues.states?.id,
				city_id: editValues.cities?.id,
				pincode_id: editValues.pincode?.id,
				mobile: editValues.mobile,
				phone1: editValues.phone1,
				phone2: editValues.phone2,
				whatsappno: editValues.whatsappno,
				fax: editValues.fax,
				email: editValues.email,
				skype_id: editValues.skype_id,
				fr_id: editValues.fr_id,
				hub_id: editValues.hub_id,
				ho_id: editValues?.ho_id,
				username: editValues?.mst_users?.username,
				incharge_name: editValues?.incharge_name,
				incharge_mobile: editValues?.incharge_mobile,
				incharge_email: editValues?.incharge_email,
				code: editValues?.code,
				phoneStdCode: editValues?.cities?.std_code,
				mobile2:editValues?.mobile2,
				location_contact_person:editValues?.location_contact_person
			});
		}
	}, [editValues, form, props, ManageCityStore, ManagePinCodeStore, ManageStateStore, ManageCountryStore, ManageHeadOfficeStore, setLatestFRList, setLatestHubList]);



	// make a fuction to call to edit record
	const handleSubmit = (data) => {
		setSaving(true);
		data.id = editValues.id;
		EditData(data)
			.then((data) => {
				close(submitValue);
				vsmNotify.success({
					message: data.STATUS.NOTIFICATION[0],
				});
			})
			.catch((e) => {
				if (e.errors) {
					form.setFields(e.errors);
				}
			})
			.finally(() => setSaving(false));
	};

	// reset form and close add form
	const close = (submitValue) => {
		if (submitValue === "submit") {
			props.close();
		} else if (submitValue === "submitNext") {
			props.closeNext();
		}
		setFetchState(true);
		setFetchCity(true);
		setFetchCountry(true);
		setFetcHoList(true);
		setFetchPincode(true);
		setFetchFrList(true);
		setFetchHubsList(true);
		setLabelData();
		setGstDisplay(false);
		setStdCode()
		ManageStateStore.dropdown_state_list = null;
		ManageCityStore.dropdown_city_list = null;
		ManageHeadOfficeStore.dropdown_ho_list = null;
		ManageHubsStore.dropdown_latest_hub_list = null;
		ManageFrainchaiseeStore.dropdown_latest_fr_list = null;
		form.resetFields();
		setDisabled(true);
	};

	return (
		<Drawer
			className="editModal"
			title="Edit Location"
			width="80%"
			visible={props.visible}
			closeIcon={<FontAwesomeIcon icon={faTimes} />}
			onClose={() => { close("submit") }}
			destroyOnClose={true}
			footer={[
				(AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="4"
					type="primary"
					className="cancelBtn mr-35"
					onClick={() => {
						close("submitNext");
					}}
				>
					Skip
				</Button>),
				(typeMode !== Constant.VIEW && <Button
					key="1"
					disabled={disabled}
					className="mr-35"
					form="editLocationForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					onClick={() => {
						setSubmit("submit");
					}}
				>
					Save
				</Button>),
				(typeMode !== Constant.VIEW && AUTH.user.level_id !== Constant.LOCATION_USER && <Button
					key="2"
					disabled={disabled}
					form="editLocationForm"
					loading={saving}
					htmlType="submit"
					type="primary"
					onClick={() => {
						setSubmit("submitNext");
					}}
				>
					Save & Continue
				</Button>),
				(((typeMode === Constant.VIEW) && (AUTH.user.level_id) !== (Constant.LOCATION_USER)) && <Button
					key="3"
					type="primary"
					onClick={() => {
						close("submitNext");
					}}
				>
					Continue
				</Button>)
			]}
		>

			{props.showErrorNotes && props.visible && editValues && <Alert className="mb-25"
				type="error"
				showIcon
				message={
					editValues.is_terminated ? `This location is terminated so you cannot make any changes` : (editValues.is_active === 0) ? `This location is inactive so you cannot make any changes` : null
				}
			/>}
			<GeneralFormComponent
				form={form}
				type={typeMode}
				setDisabled={setDisabled}
				handleSubmit={handleSubmit}
				id="editLocationForm"
				fetchCity={fetchCity}
				setFetchCity={setFetchCity}
				fetchState={fetchState}
				setFetchState={setFetchState}
				fetchCountry={fetchCountry}
				setFetchCountry={setFetchCountry}
				fetchholist={fetchholist}
				setFetcHoList={setFetcHoList}
				setFetchPincode={setFetchPincode}
				fetchpincode={fetchpincode}
				fetchFrList={fetchFrList}
				setFetchFrList={setFetchFrList}
				fetchHubsList={fetchHubsList}
				setFetchHubsList={setFetchHubsList}
				gstDisplay={gstDisplay}
				setGstDisplay={setGstDisplay}
				labelData={labelData}
				setLabelData={setLabelData}
				stdCode={stdCode}
				setStdCode={setStdCode}
			/>
		</Drawer>
	);
});

export default EditComponent;
