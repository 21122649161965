import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerGridConfig from "../../../../config/ServerGridConfig";
import { convertTextToID, convertViewAddresspincodeobj } from "../../../../utils/GlobalFunction";
export default class ManageCorporatesStore {
	agGrid = null;
	per_page = ServerGridConfig.options.paginationPageSize;
	current_page = 1;
	list_data = null;
	editValues = null;
	viewValues = null;
	deleteValues = null;
	statusValues = null;
	total = 0;
	allColumnIds = [];
	dropdown_account_party_quotes_list = null;
	loValues = null;
	kycFormDetails = null;
	defaultValue = null;
	typeMode = null;
	bankInfoValues = null;
	contactInfoValues = null;
	deleteContactInfoValues = null;
	isActiveFilterList = null;
	completePendingFilterList = null;
	isTerminated = 0;
	isRateFilterList = null;

	setDraftValues = (data) => {
		this.draftValues = data;
	}

	setisTerminated = (is_terminated) => {
		this.isTerminated = is_terminated
	}

	setTypeMode = (mode) => {
		this.typeMode = mode;
	}

	// set form values to edit
	setEditValues = (data) => {
		this.editValues = data;
	};

	// set form values to view
	setViewValues = (data) => {
		if (data) {
			data.address = convertViewAddresspincodeobj(data);
		}
		this.viewValues = data;
	};
	//set form values to terminate
	setTerminateValues = (data) => {
		this.terminateValues = data
	}

	setIsTerminatesFilterList = (data) => {
		this.isTerminatesFilterList = data;
	}
	setIsLockFilterList = (data) => {
		this.IsLockFilterList = data;
	}
	setIsKycFilterList = (data) => {
		this.IsKycFilterList = data;
	}
	setBookingPolicyFilterList = (data) => {
		this.bookingPolicyFilterList = data;
	}
	setRateCardFilterList = (data) => {
		this.isRateFilterList = data;
	}
	setContractLevelFilterList = (data) => {
		this.ContractLevelFilterList = data;
	}
	setBillingLevelFilterList = (data) => {
		this.BillingLevelFilterList = data;
	}
	setLegalBusinessFilterList = (data) => {
		this.LegalBusinessFilterList = data;
	}
	setIsActiveFilterList = (data) => {
		this.isActiveFilterList = data;
	}
	setCompletePendingFilterList = (data) => {
		this.completePendingFilterList = data;
	}
	setBillingTypeFilterList = (data) => {
		this.billingTypeFilterList = data;
	}

	setDefaultValue = (data) => {
		this.editValues = data;
		return true;
	}

	// set form Step2 values to view
	setLegalOperationsValues = (data) => {
		this.loValues = data;
	};

	// set form Step4 values to view
	setBankInfoValues = (data) => {
		this.bankInfoValues = data;
	};

	// set form Step5 values to view
	setContactInfoValues = (data) => {
		this.contactInfoValues = data;
	};

	// set form values to delete
	setDeleteContactInfoValues = (data) => {
		this.deleteContactInfoValues = data;
	};

	// set form values to delete
	setDeleteValues = (data) => {
		this.deleteValues = data;
	};

	// set form values to status
	setStatusValues = (data) => {
		this.statusValues = data;
	};

	// Setup grid and set column size to autosize
	setupGrid = (params) => {
		this.agGrid = params;
		const { api } = params;
		let datasource = this.createDatasource(ServerGridConfig.options);
		api.setServerSideDatasource(datasource);
	};

	// change page size, default page size is LocalGridConfig.options.paginationPageSize
	setPageSize = (page = this.per_page) => {
		this.per_page = page;
		if (this.agGrid) {
			this.agGrid.api.paginationSetPageSize(parseInt(page));
		}
	};

	// call api to get records
	getList = (payload) => {
		return Axios.post(`organization/corporates/list`, payload).then(({ data }) => {
			if (data.list.data.length) {
				let startPage = (data.list.current_page - 1) * data.list.per_page;
				data.list.data.map((item, index) => {
					item.srno = startPage + index + 1;
					//     item.cities = item.cities && item.cities.name
					return null;
				});
			}
			this.list_data = data.list ? data.list.data : null;
			this.total = data.list.total;
			this.current_page = data.list.current_page;
			return data;
		});
	};

	// Filter function for no record found message
	changeFilterAndSort = (params) => {
		var final_filter = params.filterModel;
		var final_sort = params.sortModel;

		if (final_filter["is_active"]) {
			final_filter['is_active'].values = convertTextToID(final_filter['is_active'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["allow_ap"]) {
			final_filter['allow_ap'].values = convertTextToID(final_filter['allow_ap'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["kyc_flag"]) {
			final_filter['kyc_flag'].values = convertTextToID(final_filter['kyc_flag'], this.IsKycFilterList, 'name', 'id')
		}
		if (final_filter["is_computerized"]) {
			final_filter['is_computerized'].values = convertTextToID(final_filter['is_computerized'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["is_terminated"]) {
			final_filter['is_terminated'].values = convertTextToID(final_filter['is_terminated'], this.isTerminatesFilterList, 'name', 'id')
		}
		if (final_filter["is_locked"]) {
			final_filter['is_locked'].values = convertTextToID(final_filter['is_locked'], this.IsLockFilterList, 'name', 'id')
		}
		if (final_filter["is_completed"]) {
			final_filter['is_completed'].values = convertTextToID(final_filter['is_completed'], this.isActiveFilterList, 'name', 'id')
		}
		if (final_filter["ct_id"]) {
			final_filter['ct_id'].values = convertTextToID(final_filter['ct_id'], this.completePendingFilterList, 'name', 'id')
		}
		if (final_filter["cb_flag"]) {
			final_filter['cb_flag'].values = convertTextToID(final_filter['cb_flag'], this.completePendingFilterList, 'name', 'id')
		}
		if (final_filter["contract_flag"]) {
			final_filter['contract_flag'].values = convertTextToID(final_filter['contract_flag'], this.completePendingFilterList, 'name', 'id')
		}
		if (final_filter["ab_flag"]) {
			final_filter['ab_flag'].values = convertTextToID(final_filter['ab_flag'], this.bookingPolicyFilterList, 'name', 'id')
		}
		if (final_filter["contract_level_id"]) {
			final_filter['contract_level_id'].values = convertTextToID(final_filter['contract_level_id'], this.ContractLevelFilterList, 'name', 'id')
		}
		if (final_filter["billing_level_id"]) {
			final_filter['billing_level_id'].values = convertTextToID(final_filter['billing_level_id'], this.BillingLevelFilterList, 'name', 'id')
		}
		if (final_filter["lb_flag"]) {
			final_filter['lb_flag'].values = convertTextToID(final_filter['lb_flag'], this.LegalBusinessFilterList, 'name', 'id')
		}
		if (final_filter["rc_id"]) {
			final_filter['rc_id'].values = convertTextToID(final_filter['rc_id'], this.isRateFilterList, 'name', 'id')
		}
		if (final_filter["billing_type_id"]) {
			final_filter['billing_type_id'].values = convertTextToID(final_filter['billing_type_id'], this.billingTypeFilterList, 'name', 'id')
		}
		return { final_filter, final_sort };
	};

	// Filter function for no record found message
	onFilterChanged = (params) => {
		this.agGrid = params;
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
			this.agGrid.api.showNoRowsOverlay();
		}
		if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
			this.agGrid.api.hideOverlay();
		}
	};

	// Create data source to display record in table
	createDatasource = (gridOptions) => {
		return {
			gridOptions,
			getRows: (params) => {
				var filter_data = this.changeFilterAndSort(params.request);
				var payload = {
					filter_data: filter_data.final_filter,
					sort_data: filter_data.final_sort,
					per_page: params.request.endRow - params.request.startRow,
					is_terminated: this.isTerminated,
					page: Math.ceil(
						(params.request.startRow + 1) /
						(params.request.endRow - params.request.startRow)
					),
				};
				this.getList(payload).then((data) => {
					if (data.list.total === 0) {
						this.agGrid.api.showNoRowsOverlay();
					} else {
						this.agGrid.api.hideOverlay();
					}
					params.successCallback(data.list.data, data.list.total);
					var allColumnIds = [];
					if (this.agGrid && this.agGrid.columnApi && data.total) {
						this.agGrid.columnApi.getAllColumns().forEach(function (column) {
							allColumnIds.push(column.col_id);
						});
					}
				});
			},
		};
	};

	refreshList = () => {
		if (this.agGrid) {
			this.setupGrid(this.agGrid);
		}
	}

	// Call add api
	AddData = (formdata) => {
		return Axios.post(`organization/Corporates/new`, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// Call edit api
	EditData = (formdata) => {
		return Axios.post(`organization/corporates/edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...formdata };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	// call api to get records
	ViewData = (ID) => {
		return Axios.get(`organization/corporates/details/${ID}`).then(({ data }) => {
			let setData = data.view;
			if (this.editValues) {
				let oldval = this.editValues;
				setData = { ...oldval, ...data.view };
			}
			this.setEditValues(setData);
			return data;
		});
	};

	// Call delete api
	DeleteData = (formdata) => {
		return Axios.delete(`organization/corporates/destroy/` + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TogglePublishData = (formdata) => {
		return Axios.post("organization/corporates/archive/" + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};


	RestoreData = (formdata) => {
		return Axios.patch("organization/corporates/restore/" + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	GenerateContractData = (formdata) => {
		return Axios.post("organization/corporates/generate-contract?id=" + formdata.id)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	TerminateData = (formdata) => {
		const api_link = formdata.is_terminated === 1 ? "revoke/" : "terminate/";
		return Axios.patch("organization/corporates/" + api_link + formdata.id, formdata)
			.then(({ data }) => {
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//Get List of center for dropdown
	getAccountPatyQuotesList = (conditional = {}) => {
		return Axios.post(`organization/corporates/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_account_party_quotes_list = data.account_party_quotes;
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	// call api to get records
	getLegalOperationData = () => {
		let ID = this.editValues.id;
		return Axios.get(`organization/corporates/legal-business-detail/${ID}`).then(({ data }) => {
			this.setLegalOperationsValues(data.legal_business);
			return data;
		});
	};

	setLegalOperationData = (formdata) => {
		return Axios.post(`organization/corporates/legal-business-edit/` + formdata.id, formdata)
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				if (data && data?.STATUS) {
					const { NOTIFICATION, ...fieldErrors } = data.STATUS;
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get Default KYC Form 
	getKycForm = (conditional = {}) => {
		conditional.id = this.editValues.id;
		return Axios.post(`organization/corporates/kyc-details`, conditional).then(({ data }) => {
			this.kycFormDetails = data.doc_types;
			return data;
		}).catch((response) => {
			return Promise.reject(response);
		});
	};

	// Call delete api
	deleteKYCData = (formdata) => {
		return Axios.delete(`organization/corporates/kyc-destroy/${formdata.doc_id}/${formdata.cr_id}`)
			.then(({ data }) => {
				return data;
			})
			.catch((response) => {
				return Promise.reject(response);
			});
	};

	//finish Kyc
	finishKycData = (cr_id) => {
		return Axios.post(`organization/corporates/kyc-finish`, { id: cr_id })
			.then(({ data }) => {
				let oldval = this.editValues;
				let newval = { ...oldval, ...data.view };
				this.setEditValues(newval);
				if (this.agGrid) {
					this.setupGrid(this.agGrid);
				}
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//
	uploadKYCData = (formdata) => {
		return Axios.post(`organization/corporates/kyc-upload`, formdata)
			.then(({ data }) => {
				return data;
			})
			.catch(({ response: { data } }) => {
				var errors = [];
				var notify = null;
				const { NOTIFICATION, ...fieldErrors } = data.STATUS;
				if (data && data.STATUS) {
					if (NOTIFICATION) {
						notify = NOTIFICATION[0];
					}
					Object.keys(fieldErrors).forEach((name) => {
						errors.push({ name, errors: data.STATUS[name] });
					});
				}
				return Promise.reject({ errors, notify });
			});
	};

	//Get List of level for dropdown
	getLevelList = (conditional = {}) => {
		return Axios.post(`admin/levels/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_level_list = data.levels;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})

	};

	//Get List of level for dropdown
	getCorporatesList = (conditional = {}) => {
		return Axios.post(`organization/corporates/lov`, conditional)
			.then(({ data }) => {
				this.dropdown_corporates_list = data.corporates;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})

	};


	JsonToFormData = (data) => {
		const formData = new FormData();
		Object.keys(data).forEach(key => {
			if (!['id', 'contract_end_date', 'contract_start_date', 'allow_ap'].includes(key)) {
				formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
			}
		})
		return formData
	}
}

decorate(ManageCorporatesStore, {
	per_page: observable,
	agGrid: observable,
	list_data: observable,
	editValues: observable,
	total: observable,
	allColumnIds: observable,
	deleteValues: observable,
	statusValues: observable,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onFilterChanged: action,
	setViewValues: action,
	setDeleteValues: action,
	setStatusValues: action,
	viewValues: observable,
	setEditValues: action,
	dropdown_account_party_quotes_list: observable,
	loValues: observable,
	setLegalOperationsValues: action,
	kycFormDetails: observable,
	getKycForm: action,
	finishKycData: action,
	uploadKYCData: action,
	setDefaultValue: action,
	defaultValue: observable,
	typeMode: observable,
	setTypeMode: action,
	setBankInfoValues: action,
	bankInfoValues: observable,
	setContactInfoValues: action,
	contactInfoValues: observable,
	setDeleteContactInfoValues: action,
	deleteContactInfoValues: observable,
	setIsActiveFilterList: action,
	isActiveFilterList: observable,
	setCompletePendingFilterList: action,
	setIsKycFilterList: action,
	completePendingFilterList: observable,
	setisTerminated: action,
	dropdown_level_list: observable,
	dropdown_corporates_list: observable,
	setLockValues: action,
	setTerminateValues: action,
	terminateValues: observable,
	setIsTerminatesFilterList: action,
	setIsLockFilterList: action,
	setBookingPolicyFilterList: action,
	setContractLevelFilterList: action,
	setBillingLevelFilterList: action,
	setLegalBusinessFilterList: action,
	setBillingTypeFilterList:action,
	billingTypeFilterList:observable
});
